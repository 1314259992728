import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Logger } from '@app/core';
import { Store } from '@ngxs/store';
import { ModeService } from '@app/providers/mode.service';
import { DirectusUserService } from '@app/providers/expo/directus/directus-user.service';
import { DirectusTokenService } from '@app/providers/expo/directus/directus-token.service';
import { map } from 'rxjs/operators';
import { Observable } from 'rxjs';

const log = new Logger('KMSGuard');

@Injectable({
  providedIn: 'root'
})
export class KMSGuard {
  constructor(
    private router: Router,
    private store: Store,
    private directusToken: DirectusTokenService,
    private directusUser: DirectusUserService,
    private modeService: ModeService
  ) {
    this.modeService.setApplicationMode('kms');
  }

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    return this.directusUser.isLoggedIn().pipe(
      map((isLoggedIn: boolean) => {
        // Also set mode to 'kms'
        if (this.modeService.isCurrentMode('kms') && isLoggedIn) {
          return true;
        } else {
          log.debug('No authentications token for Expo user, redirecting to login...');
          this.router.navigate(['/kiosk-expo']);
          return false;
        }
      })
    );
  }
}
