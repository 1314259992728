import { Component, Input, Output, EventEmitter, HostListener, OnChanges, ViewChild, ElementRef } from '@angular/core';
import { Select } from '@ngxs/store';
import { GlobalStateModel } from '@app/store/state.model';
import { User } from '@lib/models/olo.user';
import { Observable } from 'rxjs';
import { ResponseRetrieveSavedProductsSavedProduct } from '@app/models/saved-products.olo';

@Component({
  selector: 'lib-sub-nav',
  templateUrl: './sub-nav.component.html',
  styles: []
})
export class SubNavComponent implements OnChanges {
  @ViewChild('navbarNavAltMarkup') navbarNavAltMarkup: ElementRef;

  @Select((state: GlobalStateModel) => state.user.savedProducts) savedProducts$: Observable<
    ResponseRetrieveSavedProductsSavedProduct[]
  >;
  @Input() links: string[];
  @Input() capital: boolean;
  @Input() hasScrolled = false;
  @Output() subNavClick = new EventEmitter<any>();
  selected = 0;
  subnavScroll: any;
  // hasScrolled = false;

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit() {
    const subnav = this.elementRef.nativeElement.querySelector('.subnav');
    subnav.addEventListener('scroll', this.isCarouselAtRightEdge.bind(this));
  }

  clicked(index?: number) {
    this.selected = index;
    this.subNavClick.emit(index);
  }

  ngOnChanges() {
    this.subnavScroll = document.getElementById('subnav');

    if (this.hasScrolled === true) {
      this.subnavScroll.classList.add('subnavScroll');
      this.hasScrolled = true;
      return false;
    }

    if (this.hasScrolled === false) {
      this.subnavScroll.classList.remove('subnavScroll');
      this.hasScrolled = false;
    }
  }

  scrollLeft() {
    this.navbarNavAltMarkup.nativeElement.scrollLeft -= 250;
  }

  scrollRight() {
    this.navbarNavAltMarkup.nativeElement.scrollLeft += 250;
  }

  isCarouselAtLeftEdge(): boolean {
    const subnav = document.querySelector('.subnav');
    return subnav.scrollLeft === 0;
  }
  isCarouselAtRightEdge() {
    const subnav = this.elementRef.nativeElement.querySelector('.subnav');
    const containerWidth = subnav.clientWidth;
    const scrollWidth = subnav.scrollWidth;
    const scrollLeft = subnav.scrollLeft;
    const isAtRightEdge = containerWidth + scrollLeft >= scrollWidth;
    const rightArrow = this.elementRef.nativeElement.querySelector('.button-right');
    rightArrow.classList.toggle('hidden', isAtRightEdge);
  }
}
