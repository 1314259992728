<div class="popupShell" *ngIf="!openSecondModal">
  <a class="close text-end" aria-label="Close" (click)="xClick()">
    <div class="popup-x-modal">
      <lib-svg-sprite svgID="popup-x"></lib-svg-sprite>
    </div>
  </a>

  <div>
    <div class="popupHeader">
      Important
    </div>
    <div class="deliveryDiv">
      <div style="display: grid; place-items: center; padding: 15px 55px">
        <p style="text-align: center; font-size: 18px; padding: 0 15px">
          Delivery orders are offered through third-party provider Postmates or Doordash and are contingent upon driver
          availability. Delivery and delivery times are not guaranteed. Delivery fees range from $6.99-$10.99.
        </p>
      </div>
      <div>
        <button
          class="btn btn-fifthColor link regularButton"
          id="ghostButton"
          (click)="pickupClick()"
          style="margin-right: 5px;"
        >
          Takeout Instead
        </button>
        <button
          class="btn btn-primary text-white link regularButton"
          style="margin-left: 5px;"
          [disabled]="loading"
          data-toggle="modal"
          (click)="secondModal()"
        >
          Continue
        </button>
      </div>
    </div>
  </div>
</div>

<div class="popupShell" *ngIf="openSecondModal" tabindex="-1">
  <a class="close text-end" aria-label="Close" (click)="xClick()">
    <div class="popup-x-modal">
      <lib-svg-sprite svgID="popup-x"></lib-svg-sprite>
    </div>
  </a>
  <div class="popupHeader">
    {{ title }}
  </div>
  <div class="deliveryDiv">
    <ng-container [ngSwitch]="content">
      <ng-container *ngSwitchCase="'deliveryZone'">
        <lib-search-bar
          #searchBarComponent
          [deliveryZone]="true"
          text="Enter Delivery Address"
          (locChange)="locationChange($event)"
        ></lib-search-bar>
        <div>
          <p style="text-align: center; font-size: 14px; padding: 0px 60px 15px 60px;" class="my-0">
            For best results, use the autocomplete feature to select your delivery address. Please note, once your
            address has been accepted it cannot be changed. Do not enter Apartment or Suite # here; you'll be asked to
            do that later.
          </p>
        </div>

        <button
          class="btn btn-primary text-white link regularButton"
          (click)="continueButton()"
          [disabled]="loading"
          style="width: 200px;"
        >
          <ng-container *ngIf="!deliveryVerified"> CHECK ADDRESS </ng-container>
          <ng-container *ngIf="deliveryVerified"> SUCCESS! CONTINUE </ng-container>
        </button>
      </ng-container>
      <ng-container *ngSwitchCase="'outOfRange'">
        <div>
          <p class="deliveryZoneText f-20">We're sorry!</p>
        </div>
        <div>
          <p class="deliveryZoneText f-20">
            Your address is outside of the delivery zone for this location.
          </p>
        </div>
        <div>
          <p class="deliveryZoneText f-20">Please switch to a takeout order.</p>
        </div>
        <div class="deliveryZoneButtons">
          <lib-button large="true" text="SWITCH TO TAKEOUT" (buttonClick)="pickupClick()"></lib-button>
        </div>
      </ng-container>
      <ng-container *ngSwitchCase="'deliveryUnavailable'">
        <div>
          <p class="deliveryZoneText f-20">We're sorry!</p>
        </div>
        <div>
          <p class="deliveryZoneText f-20">Delivery is currently unavailable for this location.</p>
        </div>
        <div>
          <p class="deliveryZoneText f-20">Please switch to a takeout order.</p>
        </div>
        <div class="deliveryZoneButtons">
          <lib-button large="true" text="SWITCH TO TAKEOUT" (buttonClick)="pickupClick()"></lib-button>
        </div>
      </ng-container>
    </ng-container>
  </div>
</div>
