import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DirectusErrorService } from '../directus/directus-error.service';
import { orderstatus } from '../../../../assets/chepri-modules/src/models/olo.recentorders';
import { IDirectusExpoOrder } from '@app/models/expo-order.interface';
import { BasketStateModel } from '@app/store/state/basket.state';
import { DirectusTokenService } from '@app/providers/expo/directus/directus-token.service';
import { PrinterService } from '@app/providers/expo/printer/printer.service';
import { CurrencyPipe } from '@angular/common';
import { DateTime } from '../../../../assets/chepri-modules/src/models/DateTime';
import { environment } from '@env/environment';
import { ExpoMetadata } from '@app/providers/expo/directus/directus-collections.interface';

@Injectable({
  providedIn: 'root'
})
export class EmailService {
  private baseURL = environment.cmsDomain;
  private emailStyles = `
      <style>
          /* Update import link with new one from Elliott */
          /* This is connected to my personal Adobe Fonts account right now */
          @import url("https://use.typekit.net/fsq1vsq.css");
          :root {
              font-size: 16px;
              font-family: brandon-grotesque, sans-serif;
              color: #333333;
              /* Everything after this is just me being extra */
              /* Get rid of this to set background back to white*/
              background-image: url("https://cms.punchpizza.chepri.com/assets/245b343b-a52a-48df-abcf-cb82fcae0b23");
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              height: 100%;
              width: 100%;
          }
          h1 {
              margin: 0;
              font-size: 2em;
          }
          hr {
              border-top: 2px solid black;
              width: 100%
          }
          .wrapper-grid {
              padding: 32px;
              display: grid;
              grid-gap: 32px;
              place-items: center;
          }
          .wrapper-grid img {
              height: 68px;
              width: auto;
          }
          .price {
              text-align: right;
          }
          .text-center {
              text-align: center;
          }
          .product-grid-wrapper {
              width: 100%;
              display: grid;
              grid-gap: 8px
          }
          .product-grid {
              display: grid;
              grid-gap: 16px;
              grid-template-columns: 1fr auto
          }
          .totals-grid {
              display: grid;
              grid-gap: 16px;
              grid-template-columns: 1fr auto
          }
          .paid-with {
              padding-top: 16px;
          }
      </style>
  `;
  private emailHead =
    `
      <head>
          <title>Punch Pizza - Order Confirmation</title>
          ` +
    this.emailStyles +
    `
      </head>
  `;

  private headers = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json'
  });

  constructor(
    private http: HttpClient,
    private errorService: DirectusErrorService,
    private directusToken: DirectusTokenService,
    private currencyPipe: CurrencyPipe
  ) {}

  postConfirmation<T>(body: {
    expoOrder: ExpoMetadata;
    basket: BasketStateModel;
    emailAddress: string;
  }): Observable<T> {
    let products = '';
    body.basket.previousBasket.products.forEach((product: any) => {
      products += `<tr><td align="left" style="font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;">${product.quantity} - ${
        product.name
      }</td><td align="right" class="price" style="text-align: right; font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;">${this.currencyPipe.transform(product.totalcost)}</td></tr>`;
      if (product.choices && product.choices.length) {
        product.choices.forEach((choice: any) => {
          if (
            !choice.name.includes('Included') &&
            choice.name !== 'Additional Toppings' &&
            choice.name !== 'Additional Ingredients' &&
            choice.name !== 'Mozzarella (Standard, No Charge)'
          ) {
            products += `<tr><td align="left" style="font-family: brandon-grotesque, sans-serif;
          color: #333333 !important; padding-left: 25px">• ${choice.name}</td></tr>`;
          }
        });
      }
      // product.name + ' ' + this.currencyPipe.transform(product.totalcost, 'USD') + '<br />';
    });

    let feesTotal = '';
    body.basket.previousBasket.fees.forEach((fee: any) => {
      feesTotal += fee.amount;
      // Fees` + this.currencyPipe.transform(feesTotal, 'USD') + `<br />
    });

    const time =
      body.basket.previousBasket.deliverymode === 'dinein'
        ? DateTime.fromOlo(body.basket.manualFireTime)
        : DateTime.fromOlo(body.basket.previousBasket.readytime);

    const plainEmailBody =
      `
<html>
<head>
<title>${body.basket.previousBasket.vendorname}</title>
</head>
<body>

<style>
        @import url("https://use.typekit.net/fsq1vsq.css");
</style>
<div class="wrapper-grid" style="padding: 32px;display: grid;grid-gap: 32px;place-items: center; font-size: 16px;
              font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;
              background-image: url('https://cms.punchpizza.chepri.com/assets/245b343b-a52a-48df-abcf-cb82fcae0b23');
              background-size: cover;
              background-repeat: no-repeat;
              background-position: center;
              height: 100%;
              width: max-content;">
              <table width="100%" border="0" cellspacing="0" cellpadding="0">
  <tbody>
    <tr>
      <td align="center">

           <img height="68" width="174"  src="https://cms.punchpizza.chepri.com/assets/4777f7ec-27a2-4e46-8c46-dee804719439" alt="Punch Neapolitan Pizza Logo" style="height: 68px;width: auto; margin: 0 auto;"></td>
        </tr>
  </tbody>
</table>

      <h1 class="text-center" style="text-align: center; font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;"><strong>Thank you for your ${this.beautifyDeliveryMode(
                body.basket.previousBasket.deliverymode
              )} Order!</strong></h1>
      <div class="text-center" style="text-align: center; font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;">
        <div><strong style="font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;">${body.basket.previousBasket.vendorname}</strong></div>
        <div><strong style="font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;">Order #${body.basket.previousBasket.oloid}</strong></div>
        <div>
            <strong style="font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;">${time.moment.format('M/D/YYYY')}</strong>
        </div>
    </div>
    <div class="text-center" style="text-align: center; font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;">` +
      (body.basket.previousBasket.deliverymode !== 'dinein'
        ? `        <div style="font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;">Your order will be ready at <strong>${time.moment.format(
                'h:mm A'
              )}</strong>.</div>
        <div style="font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;">Please visit the counter to check-in${
                body.basket.previousBasket.deliverymode === 'dinein' ? ' and be seated' : ''
              }.</div>`
        : `
            <p><span class="bold text-pp-orange">PLEASE NOTE:</span> Our pizzas take only <span class="bold">MINUTES</span> to make.
                Since our pizzas are <span class="bold">BEST</span> right out of the oven, we don’t fire your pizzas until
                <span class="bold">AFTER</span> you check in.</p>
               <ol style="text-align: left;">
            <li>Please arrive and check in at <strong class="bold">${time.moment.format(
              'h:mm A'
            )}</strong> (or up to 10 mintues before).</li>
            <li>Look for the hanging “CHECK-IN” sign at the back of the restaurant (where we cut pizzas).</li>
            <li>Let us know you are a <strong class="bold">"Digital Dine-in” Customer</strong> and the name on your order.</li>
            <li>We’ll give you a table number and you can be seated.</li>
            <li>Your pizzas will be the next ones made – it only takes a few minutes!</li>
          </ol>
              `) +
      `</div>
    <div class="product-grid-wrapper" style="width: 100%;display: grid;grid-gap: 8px">
        <div><strong style="font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;">Your Order</strong></div>
        <hr style="border-top: 2px solid black; width: 100%">
        <table width="100%" border="0" cellspacing="8px" cellpadding="0">
        <tbody>

      ` +
      products +
      `
      </tbody>
      </table>
        <hr style="border-top: 2px solid black; width: 100%">
        <table width="100%" border="0" cellspacing="8px" cellpadding="0">
        <tbody>
        <tr>
            <td align="left" style="font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;">Subtotal</td>
            <td align="right" style="font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;">${this.currencyPipe.transform(body.basket.previousBasket.subtotal)}</td>
            </tr>
            <tr>
            <td align="left" style="font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;">Tip</td>
            <td align="right" style="font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;">${this.currencyPipe.transform(body.basket.previousBasket.tip)}</td>
            </tr>
` +
      (feesTotal
        ? `
<tr>
      <td align="left" style="font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;">Fees</td>
            <td align="right" style="font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;">${this.currencyPipe.transform(feesTotal)}</td>
            </tr>
      `
        : '') +
      `<tr>
            <td align="left" style="font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;">Tax</td>
            <td align="right" style="font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;">${this.currencyPipe.transform(body.basket.previousBasket.salestax)}</td>
            </tr>
            <tr>
            <td align="left" style="font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;"><strong>Total</strong></td>
            <td align="right" style="font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;"><strong>${this.currencyPipe.transform(
                body.basket.previousBasket.total
              )}</strong></td>
            </tr>
            <tr>
            <td align="left" class="paid-with" style="padding-top: 16px; font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;"><strong>Paid with</strong></td>
            <td align="right" class="price paid-with" style="text-align: right;padding-top: 16px; font-family: brandon-grotesque, sans-serif;
              color: #333333 !important;"><strong>${this.beautifyPaymentMethod(
                body.basket.billingInfo.billingmethod
              ).toUpperCase()}</strong></td>
            </tr>
            </tbody>
            </table>
    </div>
    </body>
    </html>
    `;

    const emailMailTemplate = {
      to: [body.emailAddress],
      subject: 'Order Confirmation - ' + body.basket.previousBasket.vendorname,
      html: plainEmailBody,
      text: 'Thank you for your order!',
      type: 'html'
    };
    return this.post<T>('confirmation', emailMailTemplate);
  }

  post<T>(resource: string, body: any): Observable<T> {
    this.addAuthHeaders();
    return this.http
      .post<T>(this.baseURL + resource, body, { headers: this.headers })
      .pipe(
        map(
          res => {
            return res;
          },
          catchError(e => {
            return this.errorService.onError(e);
          })
        )
      );
  }

  private beautifyDeliveryMode(deliveryMode: string) {
    switch (deliveryMode) {
      case 'curbside':
        return 'Curbside Pickup';
      case 'drivethru':
        return 'Drive-Thru';
      case 'pickup':
        return 'Pickup';
      case 'dispatch':
        return 'Delivery';
      case 'delivery':
        return 'Delivery';
      case 'dinein':
        return 'Dine-In';
      default:
        return '';
    }
  }

  private beautifyPaymentMethod(method: string) {
    switch (method) {
      case 'storedvalue':
        return 'gift card';
      case 'prepaid':
        return 'in-store payment';
      case 'cash':
        return method;
      default:
        return 'card';
    }
  }

  private addAuthHeaders() {
    if (this.directusToken.isAuthed()) {
      this.headers = this.headers.set('Authorization', 'bearer ' + this.directusToken.authToken);
    }
  }
}
