import { Directive, HostListener } from '@angular/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';
import { Capacitor } from '@capacitor/core';

@Directive({
  selector: '[appButtonFeedback]'
})
export class ButtonFeedbackDirective {
  isDevice = Capacitor.getPlatform() !== 'web';

  @HostListener('click', ['$event'])
  hapticFeedback(event: TouchEvent) {
    if (this.isDevice) {
      Haptics.impact({
        style: ImpactStyle.Light
      });
    }
  }
}
