<div class="modal-wrapper justify-content-center">
  <i class="fas fa-times x-close" (click)="close()"></i>
  <div class="d-flex flex-column gap-3">
    <h1 class="modal-title text-center text-uppercase fw-bold bold-modal-txt">IMPORTANT!</h1>
    <div class="d-flex flex-row text-center justify-content-center f-24">
      Once you have placed your order, please proceed to the check-in counter (look for the hanging "CHECK IN" sign) to
      get a table number.
    </div>
    <div class="d-flex flex-row text-center justify-content-center f-24 mb-2 text-uppercase bold-modal-txt">
      Please do not seat yourself.
    </div>
  </div>

  <div class="d-flex flex-row flex-nowrap gap-3 pt-1">
    <button class="flex-grow-1 btn w-100 justify-content-center pp-btn-primary text-white" (click)="confirm()">
      Got It 👍
    </button>
  </div>
</div>
