import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { QueueComponent } from './queue.component';
import { QueueRoutingModule } from './queue-routing.module';
import { SharedModule } from '@app/shared';
import { FormsModule } from '@angular/forms';
import { OrderFireService } from '@app/providers/expo/order-fire.service';

@NgModule({
  declarations: [QueueComponent],
  imports: [CommonModule, QueueRoutingModule, SharedModule, FormsModule],
  providers: [OrderFireService]
})
export class QueueModule {}
