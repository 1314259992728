import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { GuestFormComponent } from './guest-form.component';

import { SingleTextFieldModule } from '../single-text-field/single-text-field.module';
import { ButtonModule } from '../button/button.module';
import { LinkModule } from '../link/link.module';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';
import { CheckboxModule } from '../checkbox/checkbox.module';

@NgModule({
  declarations: [GuestFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SingleTextFieldModule,
    ButtonModule,
    LinkModule,
    SvgSpriteModule,
    CheckboxModule
  ],
  exports: [GuestFormComponent]
})
export class GuestFormModule {}
