<div *ngIf="!couponCode && parentForm">
  <div [formGroup]="parentForm" class="mb-3">
    <label *ngIf="hasLabel">{{ label }}</label>
    <p *ngIf="errorMessage.length > 0" class="m-0 p-0 errorMessage">* {{ errorMessage }}</p>
    <ng-container *ngIf="inputMask; else noInputMask">
      <input
        [autocomplete]="autoComplete"
        [ngClass]="errorMessage.length > 0 ? 'form-control textField error' : 'form-control textField'"
        [type]="inputType === 'email' ? 'text' : inputType"
        [formControlName]="controlName"
        [mask]="inputMask ? inputMask : '_'"
        [placeholder]="placeholder ? placeholder : label"
        (input)="changeInput($event)"
        (blur)="blurClick()"
        (focus)="focusClick()"
        [attr.inputmode]="inputMode"
        autocapitalize="off"
      />
      <small class="text-muted" *ngIf="bottomText">{{ bottomText }}</small>
    </ng-container>
    <ng-template #noInputMask>
      <input
        [autocomplete]="autoComplete"
        [ngClass]="errorMessage.length > 0 ? 'form-control textField error' : 'form-control textField'"
        [type]="inputType === 'email' ? 'text' : inputType"
        [formControlName]="controlName"
        [placeholder]="placeholder ? placeholder : label"
        (input)="changeInput($event)"
        (blur)="blurClick()"
        (focus)="focusClick()"
        [attr.inputmode]="inputMode"
        autocapitalize="off"
      />
      <small class="text-muted" *ngIf="bottomText">{{ bottomText }}</small>
    </ng-template>
  </div>
</div>

<div *ngIf="couponCode && parentForm">
  <div [formGroup]="parentForm" class="mb-3">
    <label *ngIf="hasLabel">{{ label }}</label>
    <ng-container *ngIf="inputMask; else noInputMask">
      <input
        [autocomplete]="autoComplete"
        [type]="inputType === 'email' ? 'text' : inputType"
        [formControlName]="controlName"
        class="form-control textFieldCoupon"
        [mask]="inputMask ? inputMask : '_'"
        [placeholder]="placeholder ? placeholder : label"
        (input)="changeInput($event)"
        (blur)="blurClick()"
        (focus)="focusClick()"
        [attr.inputmode]="inputMode"
        autocapitalize="off"
      />
      <small class="text-muted" *ngIf="bottomText">{{ bottomText }}</small>
    </ng-container>
    <ng-template #noInputMask>
      <input
        [autocomplete]="autoComplete"
        [type]="inputType === 'email' ? 'text' : inputType"
        [formControlName]="controlName"
        class="form-control textFieldCoupon"
        [placeholder]="placeholder ? placeholder : label"
        (input)="changeInput($event)"
        (blur)="blurClick()"
        (focus)="focusClick()"
        [attr.inputmode]="inputMode"
        autocapitalize="off"
      />
      <small class="text-muted" *ngIf="bottomText">{{ bottomText }}</small>
    </ng-template>
  </div>
</div>
