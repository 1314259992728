import { Injectable } from '@angular/core';
@Injectable({
  providedIn: 'root'
})
export class MobileService {
  mobileBreakpoint = 767;
  isMobile: boolean;
  constructor() {
    document.documentElement.style.setProperty('--mobile-breakpoint', this.mobileBreakpoint.toString(10));
    window.addEventListener('resize', e => {
      this.mobileCheck();
    });
  }
  mobileCheck() {
    const width = window.innerWidth;
    this.isMobile = width <= this.mobileBreakpoint;
    // console.log(this.isMobile);
  }
}
