import { Component, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserStateModel } from '@app/store/state/user.state';
import { DirectusExpoProvider } from '@app/providers/expo/directus/directus-expo.provider';
import * as moment from 'moment';
import { OloApiService } from '@app/providers/expo/olo/olo-api.service';
import { NgbModal, NgbDatepicker, NgbDate } from '@ng-bootstrap/ng-bootstrap';
import { CancelOrderModalComponent } from '@app/components/cancel-order-modal/cancel-order-modal.component';
import { TrancloudProviderService } from '@app/datacap-trancloud/services/trancloud-provider.service';
import { PrinterService } from '@app/providers/expo/printer/printer.service';
import { BasketStateModel } from '@app/store/state/basket.state';
import { BasketService } from '@app/providers/basket.service';
import { CurrencyPipe } from '@angular/common';
import { Basket } from '../../../../../assets/chepri-modules/src/models/olo.basket';
import { map } from 'rxjs/operators';
import { RefundConfirmationModalComponent } from '@app/components/refund-confirmation-modal/refund-confirmation-modal.component';
import { ToastrService } from 'ngx-toastr';
import { TruncatePipe } from '@app/shared/pipes/truncate.pipe';
import { Order, OrderStatusProduct } from '../../../../../assets/chepri-modules/src/models/olo.order';
import { ExpoMetadata } from '@app/providers/expo/directus/directus-collections.interface';

@Component({
  selector: 'app-kiosk-past-orders',
  templateUrl: './past-orders.component.html'
})
export class KioskPastOrdersComponent implements OnInit {
  @Select() user$: Observable<UserStateModel>;
  orders: ExpoMetadata[];
  date: string;

  refundingOrder: number;

  constructor(
    private store: Store,
    private expo: DirectusExpoProvider,
    private olo: OloApiService,
    private modalService: NgbModal,
    private printer: PrinterService,
    private basketService: BasketService,
    private currencyPipe: CurrencyPipe,
    private tranCloud: TrancloudProviderService,
    private toastr: ToastrService,
    private truncatePipe: TruncatePipe
  ) {}

  ngOnInit() {
    this.date = moment().format('YYYY-MM-DD');
    this.getPastOrders(this.date);
  }

  getPastOrders(date: string) {
    const location = this.expo.getCurrentLocation();
    this.expo.getExpoPastOrders(date, location).subscribe(expoOrders => {
      this.orders = expoOrders;
    });
  }

  dateSelect(date: NgbDate) {
    const year = this.dateItemToString(date.year);
    const month = this.dateItemToString(date.month);
    const day = this.dateItemToString(date.day);
    const formatDate = year + '-' + month + '-' + day;
    this.getPastOrders(formatDate);
  }

  dateItemToString(num: number) {
    let string = num.toString();
    if (string.length === 1) {
      string = '0' + string;
    }
    return string;
  }

  printReceipt(order: ExpoMetadata) {
    this.basketService.getOrderByBasketId(order.order_id).subscribe((basket: any) => {
      if (order.is_refunded) {
        this.printer.onSendMessageCanvas(this.printRefundReceipt.bind(this, basket, order, true));
      } else {
        this.printer.onSendMessageCanvas(this.generateReceipt.bind(this, order, basket, true));
      }
    });
  }

  generateReceipt(expoOrder: ExpoMetadata, basket: any, reprint: boolean = true) {
    const formattedDateTime = moment(
      basket.deliverymode === 'dinein' ? expoOrder.ready_time : basket.readytime,
      'YYYY-MM-DD HH:mm:ss'
    ).format('MM/DD/YYYY - hh:mm');
    const formattedSubtotal = this.currencyPipe.transform(basket.subtotal, 'USD');
    let formattedDiscount = '';
    if (basket.discounts && basket.discounts.length) {
      formattedDiscount = this.currencyPipe.transform(basket.discounts[0].amount, 'USD');
    }
    const formattedTax = this.currencyPipe.transform(basket.salestax, 'USD');
    const formattedTotal = this.currencyPipe.transform(basket.total, 'USD');

    console.log('basket', basket);
    console.log('order', expoOrder);
    console.log('date', formattedDateTime);
    console.log('subtotal', formattedSubtotal);
    console.log('discount', formattedDiscount);
    console.log('tax', formattedTax);
    console.log('total', formattedTotal);

    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 3);

    // this.printer.drawAlignedText('center', this.printer.centerPosition, 'Punch Neapolitan Pizza');
    // this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1)

    this.printer.drawAlignedText('center', this.printer.centerPosition, basket.vendorname);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);

    this.printer.drawAlignedText('center', this.printer.centerPosition, expoOrder.customer_name);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);

    this.printer.drawAlignedText('center', this.printer.centerPosition, 'Order #' + basket.oloid);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);

    this.printer.drawAlignedText('center', this.printer.centerPosition, formattedDateTime);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 3);

    basket.products.forEach((product: any) => {
      const formattedName = this.truncatePipe.transform(product.name, 27);
      const formattedPrice = this.currencyPipe.transform(product.totalcost, 'USD');
      this.printer.drawAlignedText('left', this.printer.leftPosition, product.quantity + ' - ' + formattedName);
      this.printer.drawAlignedText('right', this.printer.rightPosition, formattedPrice);
      this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
      if (product.choices && product.choices.length) {
        product.choices.forEach((choice: any) => {
          if (
            !choice.name.includes('Included') &&
            choice.name !== 'Additional Toppings' &&
            choice.name !== 'Additional Ingredients' &&
            choice.name !== 'Mozzarella (Standard, No Charge)'
          ) {
            this.printer.drawAlignedText(
              'left',
              this.printer.leftPosition,
              '     • ' + this.truncatePipe.transform(choice.name, 30)
            );
            this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
          }
        });
      }
    });

    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);

    this.printer.context.fillRect(0, this.printer.cursor - 2, this.printer.canvasData.receiptWidth, 2); // Underline
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);

    this.printer.drawAlignedText('left', this.printer.leftPosition, 'Subtotal');
    this.printer.drawAlignedText('right', this.printer.rightPosition, formattedSubtotal);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);

    this.printer.drawAlignedText('left', this.printer.leftPosition, 'Discount');
    if (basket.discounts && basket.discounts.length) {
      this.printer.drawAlignedText('right', this.printer.rightPosition, '-' + formattedDiscount);
    }
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);

    this.printer.drawAlignedText('left', this.printer.leftPosition, 'Tax');
    this.printer.drawAlignedText('right', this.printer.rightPosition, formattedTax);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);

    if (basket.tip) {
      this.printer.drawAlignedText('left', this.printer.leftPosition, 'Tip');
      this.printer.drawAlignedText('right', this.printer.rightPosition, this.currencyPipe.transform(basket.tip, 'USD'));
      this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
    }

    this.printer.drawAlignedText('left', this.printer.leftPosition, 'Total');
    this.printer.drawAlignedText('right', this.printer.rightPosition, formattedTotal);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 3);

    if (expoOrder.card_type != null) {
      if (!expoOrder.is_refunded) {
        this.printer.drawAlignedText('left', this.printer.leftPosition, expoOrder.card_type);
        this.printer.drawAlignedText('right', this.printer.rightPosition, expoOrder.card_number);
        this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
        this.printer.drawAlignedText('left', this.printer.leftPosition, `  Auth: ${expoOrder.auth_code}`);
        this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
        this.printer.drawAlignedText('left', this.printer.leftPosition, `  Ref #: ${expoOrder.ref_number}`);
        this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);
      } else {
        this.printer.drawAlignedText('left', this.printer.leftPosition, expoOrder.refund_card_type);
        this.printer.drawAlignedText('right', this.printer.rightPosition, expoOrder.refund_card_number);
        this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
        this.printer.drawAlignedText('left', this.printer.leftPosition, `  Auth: ${expoOrder.refund_auth_code}`);
        this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
        this.printer.drawAlignedText('left', this.printer.leftPosition, `  Ref #: ${expoOrder.refund_ref_number}`);
        this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);
      }
    }

    // // unlike checkout.component.ts.generateReceipt(), here we don't have access to this information so we can't print it
    // this.printer.drawAlignedText('left', this.printer.leftPosition, 'Paid with:');
    // this.printer.drawAlignedText(
    //   'right',
    //   this.printer.rightPosition,
    //   this.basket.billingInfo.billingmethod ? this.basket.billingInfo.billingmethod.toUpperCase() : 'CARD'
    // );
    if (reprint) {
      this.printer.drawAlignedText(
        'center',
        this.printer.centerPosition,
        '**********************************************'
      );
      this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);

      this.printer.drawAlignedText('center', this.printer.centerPosition, 'RECEIPT COPY');
      this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);

      this.printer.drawAlignedText(
        'center',
        this.printer.centerPosition,
        '**********************************************'
      );
      // this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);
    }
  }

  cancelOrder(order: ExpoMetadata) {
    const cancelOrderModal = this.modalService.open(CancelOrderModalComponent, {
      centered: true,
      keyboard: false,
      backdrop: 'static'
    });
    cancelOrderModal.componentInstance.guestName = order.customer_name;
    cancelOrderModal.componentInstance.partySize = order.party_size;
    cancelOrderModal.componentInstance.readyTime = order.ready_time;
    cancelOrderModal.componentInstance.xClicked.subscribe(() => {
      this.modalService.dismissAll();
    });
    cancelOrderModal.componentInstance.cancelOrder.subscribe(() => {
      // cancel order
      this.olo.manuallyCancelOrderByID(order.order_id).subscribe(() => {
        order.order_status = 'canceled';
        this.expo.updateExpoOrder(order).subscribe(() => {
          this.modalService.dismissAll();
          this.getPastOrders(this.date);
        });
      });
    });
  }

  refundOrder(order: ExpoMetadata) {
    this.refundingOrder = order.id;

    this.olo.getOrderByID(order.order_id).subscribe((oloOrder: Order) => {
      const refundConfirmModal = this.modalService.open(RefundConfirmationModalComponent, {
        centered: true,
        keyboard: false,
        backdrop: 'static'
      });
      refundConfirmModal.componentInstance.order = order;
      refundConfirmModal.componentInstance.guestName = order.customer_name;
      refundConfirmModal.componentInstance.readyTime = order.ready_time;
      refundConfirmModal.componentInstance.products = oloOrder.products;
      refundConfirmModal.componentInstance.total = oloOrder.total;
      refundConfirmModal.componentInstance.tip = oloOrder.tip;
      refundConfirmModal.componentInstance.xClicked.subscribe(() => {
        refundConfirmModal.dismiss();
        this.refundingOrder = null;
      });
      refundConfirmModal.componentInstance.confirmClicked.subscribe(
        (output: { total: number; products: Array<OrderStatusProduct & { selected: boolean }> }) => {
          refundConfirmModal.dismiss();
          if (order.record_number) {
            this.tranCloud
              .refundByRecordNo(order.invoice_number, order.auth_code, output.total, order.record_number)
              .subscribe(res => {
                if (res.RStream.AuthCode && res.RStream.RefNo) {
                  order.refund_card_number = res.RStream.AcctNo || null;
                  order.refund_auth_code = res.RStream.AuthCode || null;
                  order.refund_card_type = res.RStream.CardType || null;
                  order.refund_ref_number = res.RStream.RefNo || null;
                  order.is_refunded = true;
                  order.order_status = 'refunded';
                  this.expo.updateExpoOrder(order).subscribe((updatedOrder: ExpoMetadata) => {
                    console.log(updatedOrder);
                    this.refundingOrder = null;
                    this.toastr.success('Refund Processed');
                    this.printer.onSendMessageCanvas(
                      this.printRefundReceipt.bind(this, output, oloOrder, updatedOrder, false)
                    );
                  });
                } else {
                  this.toastr.error('Refund could not be Processed');
                  this.refundingOrder = null;
                }
              });
          } else {
            this.tranCloud.creditCardRefund(order.invoice_number, order.auth_code, oloOrder.total).subscribe(res => {
              if (res.RStream.CmdStatus === 'Approved' || res.RStream.TextResponse === 'APPROVED') {
                order.refund_card_number = res.RStream.AcctNo || null;
                order.refund_auth_code = res.RStream.AuthCode || null;
                order.refund_card_type = res.RStream.CardType || null;
                order.refund_ref_number = res.RStream.RefNo || null;
                order.is_refunded = true;
                order.order_status = 'refunded';
                this.expo.updateExpoOrder(order).subscribe((updatedOrder: ExpoMetadata) => {
                  this.refundingOrder = null;
                  this.toastr.success('Refund Processed');
                  this.printer.onSendMessageCanvas(
                    this.printRefundReceipt.bind(this, output, oloOrder, updatedOrder, false)
                  );
                });
              } else {
                this.toastr.error('Refund could not be Processed');
                this.refundingOrder = null;
              }
            });
          }
        }
      );

      // pipe(
      //   switchMap(res => {
      // if (res && res.RStream && res.RStream.CmdStatus === 'Approved') {
      //   this.basketSubmit.prepaidtransactionid = res.RStream.RefNo;
      //   this.basketSubmit.prepaiddescription = res.RStream.ApplicationLabel;
      //   body.prepaidtransactionid = res.RStream.RefNo;
      //   body.prepaiddescription = res.RStream.ApplicationLabel;
      //   return this.submitAndSendData(body, authtoken, restaurantId, buckets, user, {
      //     patchState,
      //     getState,
      //     setState,
      //     dispatch
      //   });
      // } else {
      //   if (res && res.RStream && res.RStream.TextResponse) {
      //     this.toastr.warning(res.RStream.TextResponse);
      //   } else {
      //     this.toastr.warning('Card Denied');
      //   }
      // }
      // })
      // );
    });
  }

  printRefundReceipt(
    output: { total: number; products: Array<OrderStatusProduct & { selected: boolean }> },
    order: Order,
    dOrder: ExpoMetadata,
    reprint: boolean = false
  ) {
    const formattedDateTime = moment(
      order.deliverymode === 'dinein' ? dOrder.ready_time : order.readytime,
      'YYYY-MM-DD HH:mm:ss'
    ).format('MM/DD/YYYY - hh:mm');
    const formattedSubtotal = this.currencyPipe.transform(
      (output.total === order.total ? order.total : output.total / 1.0825) * -1,
      'USD'
    );
    let formattedDiscount = '';
    if (order.discounts && order.discounts.length) {
      formattedDiscount = this.currencyPipe.transform(order.discounts[0].amount * -1, 'USD');
    }
    const formattedTax = this.currencyPipe.transform(
      (output.total === order.total ? order.salestax : (output.total / 1.0825) * 0.0825) * -1,
      'USD'
    );
    const formattedTotal = this.currencyPipe.transform(output.total * -1, 'USD');

    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 3);

    // this.printer.drawAlignedText('center', this.printer.centerPosition, 'Punch Neapolitan Pizza');
    // this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1)

    this.printer.drawAlignedText('center', this.printer.centerPosition, order.vendorname);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);

    this.printer.drawAlignedText('center', this.printer.centerPosition, dOrder.customer_name);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);

    this.printer.drawAlignedText('center', this.printer.centerPosition, 'Order #' + order.oloid);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);

    this.printer.drawAlignedText('center', this.printer.centerPosition, formattedDateTime);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 3);

    output.products.forEach(product => {
      if (product.selected) {
        const formattedPrice = this.currencyPipe.transform(product.totalcost * -1, 'USD');
        this.printer.drawAlignedText('left', this.printer.leftPosition, product.quantity + ' - ' + product.name);
        this.printer.drawAlignedText('right', this.printer.rightPosition, formattedPrice);
        this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
      }
    });

    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);

    this.printer.context.fillRect(0, this.printer.cursor - 2, this.printer.canvasData.receiptWidth, 2); // Underline
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);

    this.printer.drawAlignedText('left', this.printer.leftPosition, 'Subtotal');
    this.printer.drawAlignedText('right', this.printer.rightPosition, formattedSubtotal);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);

    this.printer.drawAlignedText('left', this.printer.leftPosition, 'Discount');
    if (order.discounts && order.discounts.length) {
      this.printer.drawAlignedText('right', this.printer.rightPosition, '-' + formattedDiscount);
    }
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);

    this.printer.drawAlignedText('left', this.printer.leftPosition, 'Tax');
    this.printer.drawAlignedText('right', this.printer.rightPosition, formattedTax);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);

    if (order.tip) {
      this.printer.drawAlignedText('left', this.printer.leftPosition, 'Tip');
      this.printer.drawAlignedText(
        'right',
        this.printer.rightPosition,
        this.currencyPipe.transform(this.refundingTip(output, order) ? order.tip * -1 : order.tip, 'USD')
      );
      this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
    }

    this.printer.drawAlignedText('left', this.printer.leftPosition, 'Total');
    this.printer.drawAlignedText('right', this.printer.rightPosition, formattedTotal);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 3);

    if (dOrder.refund_card_type) {
      this.printer.drawAlignedText('left', this.printer.leftPosition, dOrder.refund_card_type);
    }

    if (dOrder.refund_card_number) {
      this.printer.drawAlignedText('right', this.printer.rightPosition, dOrder.refund_card_number);
    }

    if (dOrder.refund_card_type || dOrder.refund_card_number) {
      this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
    }

    if (dOrder.refund_auth_code) {
      this.printer.drawAlignedText('left', this.printer.leftPosition, `  Auth: ${dOrder.refund_auth_code}`);
      this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
    }

    if (dOrder.refund_ref_number) {
      this.printer.drawAlignedText('left', this.printer.leftPosition, `  Ref #: ${dOrder.refund_ref_number}`);
      this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);
    }

    if (reprint) {
      this.printer.drawAlignedText(
        'center',
        this.printer.centerPosition,
        '**********************************************'
      );
      this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);
    }
    this.printer.drawAlignedText(
      'center',
      this.printer.centerPosition,
      reprint ? 'REFUND COPY' : 'TRANSACTION REFUNDED'
    );

    if (reprint) {
      this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);

      this.printer.drawAlignedText(
        'center',
        this.printer.centerPosition,
        '**********************************************'
      );
    }

    // this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);
  }

  private refundingTip(
    output: { total: number; products: Array<OrderStatusProduct & { selected: boolean }> },
    order: Order
  ) {
    return output.total > order.total - order.tip;
  }
}
