import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HomeComponent } from './home.component';
import { HomeRoutingModule } from './home-routing.module';
import { SingleTextFieldModule } from 'src/assets/chepri-modules/src/lib/single-text-field/single-text-field.module';
import { ButtonModule } from 'src/assets/chepri-modules/src/lib/button/button.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared';
import { AccessibilityToggleModule } from '@app/components/customer-kiosk/accessibility-toggle/accessibility-toggle.module';

@NgModule({
  declarations: [HomeComponent],
  imports: [
    CommonModule,
    HomeRoutingModule,
    SingleTextFieldModule,
    ButtonModule,
    ReactiveFormsModule,
    SharedModule,
    AccessibilityToggleModule
  ]
})
export class HomeModule {}
