import { Injectable } from '@angular/core';
import { DirectusExpoProvider } from '@app/providers/expo/directus/directus-expo.provider';
import { Observable } from 'rxjs';
import { DirectusApiService } from '@app/providers/expo/directus/directus-api.service';
import { ErrorLog } from '@app/providers/expo/directus/directus-collections.interface';

@Injectable()
export class TrancloudErrorLoggingService {
  constructor(private directusAPI: DirectusApiService) {}

  logError(tranCode: string, response: object, textResponse: string) {
    return this.directusAPI.postItem<ErrorLog>('error_log', {
      location: Number(localStorage.getItem('currentLocation')),
      tran_code: tranCode,
      response: JSON.stringify(response),
      error: textResponse
    });
  }
}
