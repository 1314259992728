import { Component, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Router } from '@angular/router';
import { Store, Select } from '@ngxs/store';
import { Logout } from '@app/store/actions/user.action';
import { RestaurantStateModel } from '@app/store/state/restaurant.state';
import { Observable } from 'rxjs';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LogoutModalComponent } from '@app/components/logout-modal/logout-modal.component';
import { DirectusExpoProvider } from '../../../../../app/providers/expo/directus/directus-expo.provider';
import { BasketStateModel } from '@app/store/state/basket.state';

@Component({
  selector: 'lib-navbar',
  templateUrl: './navbar.component.html',
  styles: [],
  animations: [
    trigger('slideInOut', [
      state('in', style({})),
      state(
        'out',
        style({
          display: 'none'
        })
      ),
      transition('in => out', animate('0ms ease-in-out')),
      transition('out => in', animate('0ms ease-in-out'))
    ]),
    trigger('switchIconHam', [
      state('ham', style({})),
      state(
        'x',
        style({
          display: 'none'
        })
      ),
      transition('in => out', animate('0ms ease-in-out')),
      transition('out => in', animate('0ms ease-in-out'))
    ]),
    trigger('switchIconX', [
      state('x', style({})),
      state(
        'ham',
        style({
          display: 'none'
        })
      ),
      transition('in => out', animate('0ms ease-in-out')),
      transition('out => in', animate('0ms ease-in-out'))
    ])
  ]
})
export class NavbarComponent {
  menuState = 'out';
  navIcon = 'ham';
  bagDisplay = true;
  kioskPages = ['menu', 'product', 'checkout', 'confirm', 'start-order', 'past-orders'];
  @Select() restaurant$: Observable<RestaurantStateModel>;
  @Select() basket$: Observable<BasketStateModel>;
  @Input() bagNavbar = false;
  @Output() close = new EventEmitter<any>();
  @Select((state: any) => state.user.isMaster) isMaster$: Observable<number>;

  @Input() links = [
    {
      text: 'home',
      url: '/locations'
    },
    {
      text: 'menu',
      url: '/menu'
    }
  ];

  @Input() hasCart = false;
  @Input() centerJustified = true;

  // auth-nav Input extensions
  @Input() authButtons = false;
  @Input() giftText = 'gift card balance';
  @Input() accountText = 'account';
  @Input() logoutText = 'logout';
  @Input() signinText = 'sign in';
  @Input() signupText = 'create an account';

  // cart-nav Input extensions
  @Input() cartButtons = true;
  @Input() basketCreated = true;
  @Input() productCount = 0;
  @Input() orderText = 'order';
  @Input() dropdownChildren = ['individual', 'group', 'catering'];
  @Input() mode: string;

  @Output() bagClicked = new EventEmitter<any>();
  @ViewChild('logOut') logOutModal: ElementRef;

  location = document.location;

  constructor(
    private router: Router,
    private store: Store,
    private modalService: NgbModal,
    private expoService: DirectusExpoProvider
  ) {}

  toggleMenu() {
    this.menuState = this.menuState === 'out' ? 'in' : 'out';
    this.toggleIcon();
  }

  toggleIcon() {
    this.navIcon = this.navIcon === 'ham' ? 'x' : 'ham';
    this.bagDisplay = this.bagDisplay === true ? false : true;
  }

  toggleBag() {
    this.bagClicked.emit();
  }

  onClose() {
    this.close.emit();
  }

  returnToKiosk() {
    this.router.navigateByUrl('/kiosk/start-order');
  }

  routeToKioskCheckout() {
    this.router.navigateByUrl('/kiosk/checkout');
  }

  handleAuth(link: string) {
    switch (link) {
      case 'sign-in': {
        this.router.navigateByUrl('/sign-in');
        break;
      }
      case 'create-account': {
        this.router.navigateByUrl('/sign-up');
        break;
      }
      case 'logout': {
        // Clear user info, reset creds., navigate to homepage
        this.store.dispatch(new Logout());
        this.router.navigateByUrl('/');
        break;
      }
      case 'account': {
        this.router.navigateByUrl('/account');
        break;
      }
      case 'giftcard': {
        this.router.navigateByUrl('/giftcard');
        break;
      }
    }
  }

  isKioskFlow() {
    let isKioskFlow = false;
    this.kioskPages.forEach((page: string) => {
      if (this.router.url.includes(page)) {
        isKioskFlow = true;
      }
    });
    return isKioskFlow;
  }

  logoutClicked() {
    const logoutModal = this.modalService.open(LogoutModalComponent, {
      centered: true,
      keyboard: false,
      backdrop: 'static'
    });
    logoutModal.componentInstance.xClicked.subscribe(() => {
      this.modalService.dismissAll();
    });
    logoutModal.componentInstance.returnClicked.subscribe(() => {
      this.modalService.dismissAll();
    });
    logoutModal.componentInstance.continueClicked.subscribe(() => {
      this.modalService.dismissAll();
      this.expoService.logoutExpoUser();
      this.router.navigateByUrl('/kiosk-expo');
    });
  }
}
