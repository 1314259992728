import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HeaderHeroComponent } from './header-hero.component';
import { OrderInfoModule } from '../order-info/order-info.module';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';

@NgModule({
  declarations: [HeaderHeroComponent],
  imports: [CommonModule, OrderInfoModule, SvgSpriteModule],
  exports: [HeaderHeroComponent]
})
export class HeaderHeroModule {}
