import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import { ReactiveFormsModule } from '@angular/forms';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { CheckoutBagComponent } from './checkout-bag.component';

import { SingleTextFieldModule } from 'src/assets/chepri-modules/src/lib/single-text-field/single-text-field.module';
import { BagItemModule } from 'src/assets/chepri-modules/src/lib/bag-item/bag-item.module';
import { ButtonModule } from 'src/assets/chepri-modules/src/lib/button/button.module';

@NgModule({
  declarations: [CheckoutBagComponent],
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    Angulartics2Module,
    ReactiveFormsModule,
    SingleTextFieldModule,
    BagItemModule,
    ButtonModule
  ],
  exports: [CheckoutBagComponent]
})
export class CheckoutBagModule {}
