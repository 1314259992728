import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { SignUpRoutingModule } from './sign-up-routing.module';
import { SignUpComponent } from './sign-up.component';

import { SignUpFormModule } from 'src/assets/chepri-modules/src/lib/sign-up-form/sign-up-form.module';

@NgModule({
  declarations: [SignUpComponent],
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    Angulartics2Module,
    SignUpRoutingModule,
    SignUpFormModule
  ]
})
export class SignUpPageModule {}
