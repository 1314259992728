import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { DirectusHttpService } from '@app/providers/expo/directus/directus-http.service';
import {
  authentication,
  AuthenticationData,
  createDirectus,
  createItem,
  deleteItem,
  DirectusUser,
  Query,
  readCollection,
  readItem,
  readItems,
  readMe,
  readRole,
  readRoles,
  readUser,
  readUsers,
  realtime,
  rest,
  updateItem
} from '@directus/sdk';
import { DirectusCollection, DirectusSchema } from '@app/providers/expo/directus/directus-collections.interface';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class DirectusApiService {
  private _client = createDirectus<DirectusSchema>(environment.cmsDomain)
    .with(rest())
    .with(
      authentication('json', {
        storage: {
          get: () => JSON.parse(sessionStorage.getItem('cmsAuth')),
          set: (data: AuthenticationData) => sessionStorage.setItem('cmsAuth', JSON.stringify(data))
        }
      })
    )
    .with(
      realtime({
        reconnect: {
          retries: 1000,
          delay: 1000
        },
        heartbeat: true
      })
    );

  readonly client = this._client;

  constructor() {}

  loginUser(email: string, password: string): Observable<AuthenticationData> {
    return from(this._client.login(email, password));
  }

  refreshUser(): Observable<AuthenticationData> {
    return from(this._client.refresh());
  }

  logoutUser(): Observable<void> {
    return from(this._client.logout());
  }

  getCollection(collectionName: keyof DirectusSchema) {
    return from(this._client.request(readCollection(collectionName)));
  }

  getItems(collectionName: keyof DirectusSchema) {
    return from(this._client.request(readItems(collectionName)));
  }

  getSingleItem(collectionName: keyof DirectusSchema, itemID: number) {
    return from(this._client.request(readItem(collectionName, itemID)));
  }

  getQueriedItems<T extends DirectusCollection>(collectionName: keyof DirectusSchema, query: any) {
    return from(this._client.request(readItems(collectionName, query))) as Observable<T[]>;
  }

  postItem<T extends DirectusCollection>(collectionName: keyof DirectusSchema, body: Partial<T>) {
    return from(this._client.request(createItem(collectionName, body))) as Observable<T>;
  }

  getRoles() {
    return from(this._client.request(readRoles()));
  }

  getRoleByID(roleID: string) {
    return from(this._client.request(readRole(roleID)));
  }

  getUsers() {
    return from(this._client.request(readUsers())) as Observable<DirectusUser<DirectusSchema>[]>;
  }

  getUser(id: string) {
    return from(this._client.request(readUser(id))) as Observable<DirectusUser<DirectusSchema>>;
  }

  getMe() {
    return from(this._client.request(readMe())) as Observable<DirectusUser<DirectusSchema>>;
  }

  patchItem<T extends DirectusCollection>(id: number, collectionName: keyof DirectusSchema, body: Partial<T>) {
    return from(this._client.request(updateItem(collectionName, id, body))) as Observable<T>;
  }

  deleteItem(id: number, collectionName: keyof DirectusSchema) {
    return from(this._client.request(deleteItem(collectionName, id)));
  }
}
