import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Product } from '../../models/olo.product';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CmsService } from '@app/providers/cms.service';
import { Select } from '@ngxs/store';
import { GlobalStateModel } from '@app/store/state.model';
import { Observable, Subscription } from 'rxjs';
import { ResponseRetrieveSavedProductsSavedProduct } from '@app/models/saved-products.olo';
import { Category } from '@lib/models/olo.category';
import { distinctUntilKeyChanged, filter, skip } from 'rxjs/operators';
import { AnalyticsService } from '@app/providers/analytics.service';

@Component({
  selector: 'lib-olo-menu',
  templateUrl: './olo-menu.component.html',
  styles: []
})
export class OloMenuComponent implements OnInit, OnDestroy {
  @Select((state: GlobalStateModel) => state.user.savedProducts) savedProducts$: Observable<
    ResponseRetrieveSavedProductsSavedProduct[]
  >;

  @Input() categories: Category[];
  @Input() text: string;
  @Input() restaurantId: string;
  @Input() basketId: string;
  @Input() defaultImageUrl: string;
  @Input() imagePath: string;
  @Input() selectedProdID: number;
  @Input() hasScrolled = false;
  @Input() selectedCat = 0;
  @Output() customizeClick = new EventEmitter<Product>();
  @Output() addToCartClick = new EventEmitter<Product>();
  @Output() addSavedToCartClick = new EventEmitter<ResponseRetrieveSavedProductsSavedProduct>();

  catStrings: string[] = [];
  closeResult: string;

  private subs: Subscription[] = [];
  constructor(private modalService: NgbModal, private cms: CmsService, private analytics: AnalyticsService) {}

  ngOnInit() {
    // If categories not provided, but restaurant is, retrieve menu from OLO
    this.getCatNames();
    this.subs.push(
      this.savedProducts$
        .pipe(
          filter(savedProducts => !!savedProducts),
          skip(1),
          distinctUntilKeyChanged('length')
        )
        .subscribe(savedProducts => {
          if (savedProducts.length === 0 && this.selectedCat === -1) {
            this.selectedCat = 0;
          }
        })
    );
    this.analytics.logProductListView(this.categories[0]);
  }

  ngOnDestroy() {
    this.subs.forEach(sub => sub.unsubscribe());
  }

  open(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'informationModal', centered: true }).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  getCatNames() {
    for (const cat of this.categories) {
      this.catStrings.push(cat.name);
    }
  }

  addToBag(product: Product) {
    // Emit event to add item to cart
    this.addToCartClick.emit(product);
  }

  addSavedToBag(product: ResponseRetrieveSavedProductsSavedProduct) {
    // Emit event to add item to cart
    this.addSavedToCartClick.emit(product);
  }

  customize(product: Product) {
    // Emit event to customize product
    this.customizeClick.emit(product);
    this.analytics.logProductClick(product);
  }

  subNavClicked(catNumber: number) {
    this.selectedCat = catNumber;
    setTimeout(() => {
      window.scrollTo(0, 0);
    }, 150);
    this.analytics.logProductListView(this.categories[catNumber]);
  }

  productCardTrackBy(index: number, product: Product) {
    return `${index}${product.id}`;
  }

  savedProductCardTrackBy(index: number, product: ResponseRetrieveSavedProductsSavedProduct) {
    return `${index}${product.id}`;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
}
