<div class="kiosk-height overflow-hidden">
  <app-navbar></app-navbar>
  <div *ngIf="product$ | async as product" class="overflow-auto" [style.height]="(accessibilityMode$ | async) ? 'calc(100vh - (57.5vh + 172px))' : 'calc(100vh - (172px))'">
    <div *ngIf="product.product && product.modifiers" class="container-fluider h-100">
      <lib-customize-item
        (onAddToCart)="addToCartEvent($event)"
        [product]="product.product"
        [optionGroups]="product.modifiers.optiongroups"
        [isEdit]="isEdit"
        [basketProductId]="basketProductId"
        defaultImageUrl="assets/images/default.png"
        [displayImage]="false"
        [isLoading]="loading"
        [mode]="mode"
        [showEnzoItem]="showEnzoItem"
        [enzoName]="enzoName"
        [glutenFreeName]="glutenFreeName"
        [accessibilityMode]="accessibilityMode$ | async"
      >
      </lib-customize-item>
    </div>
  </div>
  <app-accessibility-toggle></app-accessibility-toggle>
</div>
