import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { ProductComponent } from '@app/components/product/product.component';
import { ModeService } from '@app/providers/mode.service';
import { CapacityService } from '@app/providers/capacity.service';
import { Location } from '@angular/common';
import { AnalyticsService } from '@app/providers/analytics.service';

@Component({
  selector: 'app-kiosk-product',
  templateUrl: './product.component.html',
  styleUrls: ['../customer-kiosk.component.scss']
})
export class CustomerKioskProductComponent extends ProductComponent {
  constructor(
    route: ActivatedRoute,
    store: Store,
    toast: ToastrService,
    router: Router,
    modeService: ModeService,
    capacityService: CapacityService,
    location: Location,
    analytics: AnalyticsService
  ) {
    super(route, store, toast, router, modeService, capacityService, location, analytics);
  }
}
