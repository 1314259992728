import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { UserStateModel } from '@app/store/state/user.state';
import { CredentialsService } from '../../../../../app/core';
import { BasketStateModel } from '../../../../../app/store/state/basket.state';
import { RestaurantStateModel } from '../../../../../app/store/state/restaurant.state';
import { GetBillingSchemes } from '../../../../../app/store/actions/basket.action';

@Component({
  selector: 'lib-auth-nav',
  templateUrl: './auth-nav.component.html',
  styles: []
})
export class AuthNavComponent {
  @Select() basket$: Observable<BasketStateModel>;
  @Select() restaurant$: Observable<RestaurantStateModel>;
  @Input() buttons: boolean;
  @Input() accountText = 'account';
  @Input() giftText = 'gift card balance';
  @Input() logoutText = 'logout';
  @Input() signinText = 'sign in';
  @Input() signupText = 'create an account';
  @Select() user$: Observable<UserStateModel>;
  locationID: number;

  @Output() authNavClick = new EventEmitter<any>();

  constructor(private store: Store, private credService: CredentialsService) {}

  clickEvent(identifier?: string) {
    this.authNavClick.emit(identifier);
  }

  acceptsGiftCards(basketState: BasketStateModel) {
    if (basketState.basket.id !== null && basketState.billingSchemes !== null) {
      if (basketState.billingSchemes.length <= 1) {
        this.store.dispatch(new GetBillingSchemes()).subscribe(res => {
          basketState = res;
          const giftCardScheme = basketState.billingSchemes.filter((scheme: any) => {
            if (scheme.type === 'giftcard') {
              return scheme;
            }
          });
          return giftCardScheme.length > 0;
        });
      } else {
        const giftCardScheme = basketState.billingSchemes.filter((scheme: any) => {
          if (scheme.type === 'giftcard') {
            return scheme;
          }
        });
        return giftCardScheme.length > 0;
      }
    } else if (basketState.basket.id !== null && basketState.billingSchemes === null) {
      this.store.dispatch(new GetBillingSchemes()).subscribe(res => {
        basketState = res;
        const giftCardScheme = basketState.billingSchemes.filter((scheme: any) => {
          if (scheme.type === 'giftcard') {
            return scheme;
          }
        });
        return giftCardScheme.length > 0;
      });
    } else {
      return false;
    }
  }
}
