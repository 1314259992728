<div class="container-fluider kiosk-body" style="padding-top: env(safe-area-inset-top, 0)">
  <div class="formDiv">
    <div class="kioskCont">
      <div class="expoFixed pt-4">
        <div class="d-flex justify-content-between">
          <div class="px-3 align-left">
            <button class="btn btn-primary text-white largeButton" [routerLink]="'/expo/queue'">Live Fire Expo</button>
          </div>
          <div class="px-3">
            <h3 class="text-center">Fired Orders</h3>
          </div>
          <div class="px-3 align-right">
            <div class="timeCont text-end pe-1 pt-1">{{ time | date: 'h:mm:ss aaaaa' }}</div>
          </div>
        </div>

        <!-- //Column Headings -->
        <div class="d-flex justify-content-between expo-heading mx-2 pt-4">
          <div class="col-1 ps-1">
            <h5 class="text-center">Order</h5>
          </div>
          <div class="col-3">
            <h5 class="text-center">Name</h5>
          </div>
          <div class="col-1">
            <h5 class="text-center">Size</h5>
          </div>
          <div class="col-2">
            <h5 class="text-center">Check-in</h5>
          </div>
          <div class="col-2">
            <h5 class="text-center">Pref</h5>
          </div>
          <div class="col-2">
            <h5 class="text-center">Table</h5>
          </div>
          <div class="col-1">
            <h5 class="text-center">Fire</h5>
          </div>
        </div>
      </div>

      <!-- //Row Details -->
      <div class="expoScroll">
        <ng-container *ngIf="(displayExpoOrders || displayExpoOrders.length > 0) && !displayError; else errors">
          <ng-container *ngFor="let order of displayExpoOrders">
            <div class="d-flex justify-content-between py-2 px-2 mx-2 mt-2 align-items-center expoDetails">
              <div class="col-1 text-center mb-0 ps-0">
                <h6>{{ order.ready_time | ShortTimePipe | date: 'h:mm aaaaa' }}</h6>
              </div>
              <div class="col-3 text-center clickable" (click)="openOrderDetails(order)">
                <u>
                  <h6>{{ order.customer_name }}</h6>
                </u>
              </div>
              <div class="col-1 text-center p-0">
                <h6>{{ order.party_size }}</h6>
              </div>
              <div class="col-2 text-center p-0">
                <h6>{{ order.check_in_time | ShortTimePipe | date: 'h:mm aaaaa' }}</h6>
              </div>
              <div class="col-2 text-center p-0">
                <h6>{{ order.table_preference }}</h6>
              </div>
              <div class="col-2 text-center pe-0 text-uppercase">
                <h6>{{ order.table_number }}</h6>
              </div>
              <div class="col-1 text-center pe-0">
                <h6>
                  {{ order.order_status === 'fired' ? (order.fired_time | ShortTimePipe | date: 'h:mm aaaaa') : 'REF' }}
                </h6>
              </div>
            </div>
          </ng-container>
        </ng-container>

        <ng-template #errors>
          <div class="expo-form">
            <div class="error-container">
              <div class="text-center py-3">
                <h2 color="primary pb-2">Uh-Oh</h2>
                <p class="py-3 text-uppercase">
                  No orders have been fired, please check back later.
                </p>
                <button [routerLink]="'/expo/queue'" class="w-100 mx-auto pt-2 btn fireBtn">
                  Return to Live Fire Expo
                </button>
              </div>
            </div>
          </div>
        </ng-template>
      </div>
    </div>
  </div>
</div>
