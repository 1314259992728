import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { SignInRoutingModule } from './sign-in-routing.module';
import { SignInComponent } from './sign-in.component';

import { SignInFormModule } from 'src/assets/chepri-modules/src/lib/sign-in-form/sign-in-form.module';
import { ResetPasswordModalModule } from 'src/assets/chepri-modules/src/lib/reset-password-modal/reset-password-modal.module';

@NgModule({
  declarations: [SignInComponent],
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    Angulartics2Module,
    SignInRoutingModule,
    SignInFormModule,
    ResetPasswordModalModule
  ]
})
export class SignInPageModule {}
