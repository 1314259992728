<div *ngIf="(user$ | async).info?.authtoken; else noAuth" class="d-flex flex-row navbar-nav">
  <ng-container *ngIf="basket$ | async as basket">
    <ng-container *ngIf="restaurant$ | async as restaurant">
      <ng-container *ngIf="basket?.basket?.id && restaurant?.restaurant?.id">
        <lib-link
          class="nav-link menuCaps link-color-override w-auto"
          *ngIf="!buttons"
          [text]="giftText"
          (linkClick)="clickEvent('giftcard')"
        >
        </lib-link>
        <lib-button
          class="button giftcard-button"
          *ngIf="buttons"
          [text]="giftText"
          (buttonClick)="clickEvent('giftcard')"
        >
        </lib-button>
      </ng-container>
    </ng-container>
  </ng-container>
  <lib-link
    class="nav-link menuCaps link-color-override w-auto"
    *ngIf="!buttons"
    [text]="accountText"
    (linkClick)="clickEvent('account')"
  >
  </lib-link>
  <lib-button class="button account-button" *ngIf="buttons" [text]="accountText" (buttonClick)="clickEvent('account')">
  </lib-button>

  <lib-link
    id="menuLogout"
    class="nav-link menuCaps link-color-override w-auto"
    *ngIf="!buttons"
    [text]="logoutText"
    (linkClick)="clickEvent('logout')"
  >
  </lib-link>
  <lib-button class="button logout-button" *ngIf="buttons" [text]="logoutText" (buttonClick)="clickEvent('logout')">
  </lib-button>
</div>

<ng-template #noAuth>
  <div class="d-flex flex-row navbar-nav">
    <ng-container *ngIf="basket$ | async as basket">
      <ng-container *ngIf="restaurant$ | async as restaurant">
        <ng-container *ngIf="basket?.basket?.id && restaurant?.restaurant?.id">
          <lib-link
            class="nav-link menuCaps link-color-override w-auto"
            *ngIf="!buttons"
            [text]="giftText"
            (linkClick)="clickEvent('giftcard')"
          >
          </lib-link>
          <lib-button
            class="button giftcard-button"
            *ngIf="buttons"
            [text]="giftText"
            (buttonClick)="clickEvent('giftcard')"
          >
          </lib-button>
        </ng-container>
      </ng-container>
    </ng-container>
    <lib-link
      class="nav-link menuCaps link-color-override w-auto"
      *ngIf="!buttons"
      [text]="signinText"
      (linkClick)="clickEvent('sign-in')"
    >
    </lib-link>
    <lib-button class="button sign-in-button" *ngIf="buttons" [text]="signinText" (buttonClick)="clickEvent('sign-in')">
    </lib-button>
    <lib-link
      class="nav-link menuCaps link-color-override w-auto"
      *ngIf="!buttons"
      [text]="signupText"
      (linkClick)="clickEvent('create-account')"
    >
    </lib-link>
    <lib-button
      class="button create-account-button"
      *ngIf="buttons"
      [text]="signupText"
      (buttonClick)="clickEvent('create-account')"
    ></lib-button>
  </div>
</ng-template>
