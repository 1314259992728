<div class="modal-wrapper">
  <img
    [@rotate]="rotateState"
    style="width: 100px; height: auto"
    src="assets/images/punchpizza-flame_hexagon.svg"
    alt="Punch Pizza Logo"
  />
  <h1>{{ status }}</h1>
  <div class="f-24 bold">{{ receivedAmount / totalAmount | percent }}</div>
  <div>App will restart after update is complete</div>
</div>
