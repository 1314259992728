<div class="popupShell">
  <a class="close" aria-label="Close" (click)="xClick()" style="text-align: right;">
    <div class="popup-x-modal">
      <lib-svg-sprite svgID="popup-x" style="cursor: pointer;"></lib-svg-sprite>
    </div>
  </a>
  <div class="popupHeader">
    Sold Out of Pizza
  </div>
  <div class="deliveryDiv">
    <div>
      <p class="deliveryZoneFailText">
        Sorry, this location is sold out of pizza for online orders. Please select a different location or visit the
        store to place an order for pizza with the cashier.
      </p>
    </div>
    <div class="continue-button" (click)="continueLinkClicked()">
      Continue to Order a Salad
    </div>
  </div>
</div>
