import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { DirectusTokenService } from '@app/providers/expo/directus/directus-token.service';
import { DirectusErrorService } from './directus-error.service';
import { environment } from '@env/environment';
import { ModeService } from '@app/providers/mode.service';

@Injectable({
  providedIn: 'root'
})
export class DirectusHttpService {
  private baseURL = environment.cmsDomain;

  private headers = new HttpHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json'
  });

  constructor(
    private http: HttpClient,
    private directusToken: DirectusTokenService,
    private errorService: DirectusErrorService,
    private modeService: ModeService
  ) {
    console.log('baseurl', this.baseURL);
  }

  get<T>(resource: string): Observable<T> {
    this.addAuthHeaders();
    return this.http
      .get<T>(this.baseURL + resource, { headers: this.headers })
      .pipe(
        map(
          res => {
            return res;
          },
          catchError(e => {
            return this.errorService.onError(e);
          })
        )
      );
  }

  put<T>(resource: string, body: any): Observable<T> {
    this.addAuthHeaders();
    return this.http
      .put<T>(this.baseURL + resource + '?activity_skip=1', body, { headers: this.headers })
      .pipe(
        map(
          res => {
            return res;
          },
          catchError(e => {
            return this.errorService.onError(e);
          })
        )
      );
  }

  post<T>(resource: string, body: any): Observable<T> {
    this.addAuthHeaders();
    if (resource.includes('login') || resource.includes('refresh')) {
      this.removeAuthHeader();
    }
    return this.http
      .post<T>(this.baseURL + resource + '?activity_skip=1', body, { headers: this.headers })
      .pipe(
        map(
          res => {
            return res;
          },
          catchError(e => {
            return this.errorService.onError(e);
          })
        )
      );
  }

  patch<T>(resource: string, body: any): Observable<T> {
    this.addAuthHeaders();
    return this.http
      .patch<T>(this.baseURL + resource + '?activity_skip=1', body, { headers: this.headers })
      .pipe(
        map(
          res => {
            return res;
          },
          catchError(e => {
            return this.errorService.onError(e);
          })
        )
      );
  }

  delete<T>(resource: string): Observable<T> {
    this.addAuthHeaders();
    return this.http
      .delete<T>(this.baseURL + resource, { headers: this.headers })
      .pipe(
        map(
          res => {
            return res;
          },
          catchError(e => {
            return this.errorService.onError(e);
          })
        )
      );
  }

  private addAuthHeaders() {
    if (this.modeService.getStoredMode() === 'cks') {
      this.headers = this.headers.set('Authorization', 'Bearer ' + environment.kioskUserKey);
    } else {
      if (this.directusToken.isAuthed()) {
        this.headers = this.headers.set('Authorization', 'Bearer ' + this.directusToken.authToken);
      }
    }
  }

  private removeAuthHeader() {
    this.headers = this.headers.delete('Authorization');
  }
}
