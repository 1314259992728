<div class="pastOrderShell">
  <a class="close" aria-label="Close" style="text-align: right;" (click)="xClick()">
    <div class="popup-x-modal"><lib-svg-sprite svgID="popup-x"></lib-svg-sprite></div
  ></a>
  <div *ngIf="(user$ | async).selectedFave as fav" style="margin-bottom: 30px;">
    <div class="pastOrderHeader" style="margin-left: 0;">
      {{ fav.name }}
    </div>
    <div class="removeFavoriteOrderPopup">
      <p>Reordering will remove any current items in your bag.</p>
    </div>
    <div class="pastOrderItem" *ngFor="let product of fav.products">
      <div class="d-flex flex-row pastOrderPopupHeaderRow">
        <div class="col pastOrderPopupName">
          {{ product.name }}
        </div>
        <div class="col pastOrderPopupPrice"></div>
      </div>
      <div class="d-flex flex-row">
        <div *ngFor="let choice of product.choices" class="col-12 pastOrderPopupItem">
          {{ choice.name }}
        </div>
        <div class="col"></div>
      </div>
    </div>
  </div>
  <div class="pastOrderPopupBottom">
    <div class="d-flex flex-row pastOrderPopupTotalRow">
      <div class="col pastOrderPopupTotal"></div>
      <div class="col pastOrderPopupTotalPrice"></div>
    </div>
    <div class="d-flex flex-row pastOrderPopupButton">
      <div class="col" style="margin-bottom: 20px;">
        <lib-button (buttonClick)="handleFavoriteOrders()" large="true" text="REORDER"></lib-button>
      </div>
    </div>
  </div>
</div>
