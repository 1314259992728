<div *ngIf="product$ | async as product" class="h-100">
  <div *ngIf="product.product && product.modifiers" class="container-fluider h-100">
    <lib-customize-item
      (onAddToCart)="addToCartEvent($event)"
      [product]="product.product"
      [optionGroups]="product.modifiers.optiongroups"
      [isEdit]="isEdit"
      [basketProductId]="basketProductId"
      defaultImageUrl="assets/images/default.png"
      [showEnzoItem]="showEnzoItem"
      [enzoName]="enzoName"
      [glutenFreeName]="glutenFreeName"
      [isLoading]="loading"
    >
    </lib-customize-item>
  </div>
</div>
