import { DateTime } from '../../assets/chepri-modules/src/models/DateTime';

let testId = 1000000000;

export interface IDirectusItem {
  id?: number;
  created_by?: number | { id: number };
  created_on?: string; // Directus date format YYYY-MM-DD HH:mm:ss
  status?: string;
}

interface IDirectusCapacitySettings extends IDirectusItem {
  name: string;
  location: IDirectusLocation;
  capacity_days: IDirectusCapacityDay[];
}

interface IDirectusLocation extends IDirectusItem {
  location_name: string;
}

interface IDirectusCapacityDay {
  id: number;
  capacity_settings_id: IDirectusCapacitySettingsId;
  daily_capacity_id: IDirectusDailyCapacity;
}

interface IDirectusCapacitySettingsId extends IDirectusItem {
  name: string;
  location: number;
}

interface IDirectusDailyCapacity extends IDirectusItem {
  start_time: string; // HH:mm:ss
  end_time: string; // HH:mm:ss
  monday: number; // Capacity for this time on Monday
  tuesday: number; // Capacity for this time on tuesday
  wednesday: number; // Capacity for this time on wednesday
  thursday: number; // Capacity for this time on thursday
  friday: number; // Capacity for this time on friday
  saturday: number; // Capacity for this time on saturday
  sunday: number; // Capacity for this time on sunday
  batch_timestamp: string; // Time used internally to reference recently imported settings
  monday_buzz: number;
  tuesday_buzz: number;
  wednesday_buzz: number;
  thursday_buzz: number;
  friday_buzz: number;
  saturday_buzz: number;
  sunday_buzz: number;
}

interface IDirectusCurrentOrder extends IDirectusItem {
  created_on: string;
  ready_time: string; // The end_time of the bucket being assigned to (Directus Format)
  oloid: string;
  location: IDirectusLocation;
  salad_quant: number;
  pizza_quant: number;
}

export interface ICapacityInterval {
  capacity: number; // The same value for salads and for pizzas - but they count separately
  start_time: string; // HH:MM:SS 24 hour clock representation of interval start
  end_time: string; // HH:MM:SS 24 hour clock representation of interval end
}

export class CapacityInterval implements ICapacityInterval {
  capacity: number;
  buzz_capacity: number;
  start_time: string;
  end_time: string;
  /**
   *
   * @param c capacity of interval
   * @param s start time (HH:mm:ss)
   * @param e end time (HH:mm:ss)
   */
  constructor(c: number, s: string, e: string) {
    this.capacity = c;
    this.start_time = s;
    this.end_time = e;
  }
}

export interface DailyCapacity {
  intervals: CapacityInterval[];
}

export class SelectableTime {
  time: DateTime; // HH:mm format
  pizzas: number; // Number of pizzas being added to this bucket
  salads: number; // Number of salads being added to this bucket
  constructor(t: DateTime, p: number, s: number) {
    (this.time = t), (this.pizzas = p), (this.salads = s);
  }
  canFit(pizzas: number, salads: number): boolean {
    return pizzas <= this.pizzas && salads <= this.salads;
  }
}

export class SelectableSpread {
  buckets: SelectableTime[];
  constructor(b: SelectableTime[]) {
    this.buckets = b;
  }
}

export class TimeSlot {
  key: string;
  value: SelectableSpread;
}

export class DirectusCapacitySettings {
  id: number;
  name: string;
  location: DirectusLocation;
  capacity_days: DailyCapacity[];
  constructor(name: string, location: DirectusLocation, days: DailyCapacity[]) {
    this.id = generateId();
    this.name = name;
    this.location = location;
    this.capacity_days = days;
  }
}

export class DirectusCurrentOrder implements IDirectusCurrentOrder {
  id: number;
  created_on: string;
  ready_time: string; // The end_time of the bucket being assigned to (Directus Format)
  oloid: string;
  is_placeholder: boolean;
  is_olo_takeout: boolean;
  location: DirectusLocation;
  salad_quant: number;
  pizza_quant: number;
  enzo_quant: number;
  constructor(
    created: string,
    ready: string,
    oloid: string,
    location: DirectusLocation,
    salads: number,
    pizzas: number
  ) {
    this.id = generateId();
    this.created_on = created;
    this.ready_time = ready;
    this.oloid = oloid;
    this.location = location;
    this.salad_quant = salads;
    this.pizza_quant = pizzas;
  }
}

export class DirectusLocation implements IDirectusLocation {
  id: number;
  location_name: string;
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
  sunday: number;
  monday_buzz: number;
  tuesday_buzz: number;
  wednesday_buzz: number;
  thursday_buzz: number;
  friday_buzz: number;
  saturday_buzz: number;
  sunday_buzz: number;
  constructor(name: string) {
    this.id = generateId();
    this.location_name = name;
  }
}

export class DirectusCapacityDay implements IDirectusCapacityDay {
  id: number;
  capacity_settings_id: DirectusCapacitySettingsId;
  daily_capacity_id: DirectusDailyCapacity;
  constructor(settings: DirectusCapacitySettingsId, capacity: DirectusDailyCapacity) {
    this.id = generateId();
    this.capacity_settings_id = settings;
    this.daily_capacity_id = capacity;
  }
}

export class DirectusCapacitySettingsId implements IDirectusCapacitySettingsId {
  id: number;
  name: string;
  location: number;
  constructor(name: string, location: number) {
    this.id = generateId();
    this.name = name;
    this.location = location;
  }
}

export class DirectusDailyCapacity implements IDirectusDailyCapacity {
  id: number;
  start_time: string;
  end_time: string;
  monday: number;
  tuesday: number;
  wednesday: number;
  thursday: number;
  friday: number;
  saturday: number;
  sunday: number;
  batch_timestamp: string;
  monday_buzz: number;
  tuesday_buzz: number;
  wednesday_buzz: number;
  thursday_buzz: number;
  friday_buzz: number;
  saturday_buzz: number;
  sunday_buzz: number;
  constructor(
    start: string,
    end: string,
    mo: number,
    tu: number,
    we: number,
    th: number,
    fr: number,
    sa: number,
    su: number
  ) {
    this.id = generateId();
    this.start_time = start;
    this.end_time = end;
    this.monday = mo;
    this.tuesday = tu;
    this.wednesday = we;
    this.thursday = th;
    this.friday = fr;
    this.saturday = sa;
    this.sunday = su;
    this.batch_timestamp = '';
  }
}

const generateId = () => {
  testId++;
  return testId;
};
