export class ToggleBag {
  static readonly type = '[App] Toggle Bag';
  constructor(public payload?: string) {}
}

export class ToggleHeader {
  static readonly type = '[App] Toggle Header';
  constructor(public payload: boolean) {}
}

export class SetBanner {
  static readonly type = '[App] Set Banner';
  constructor(
    public text: string,
    public showOrderInfo: boolean,
    public canGoBack: boolean,
    public locationName: string
  ) {}
}

export class ToggleAccessibility {
  static readonly type = '[App] Toggle Accessibility';
  constructor() {}
}
