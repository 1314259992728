import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { ModifierListComponent } from './modifier-list.component';
import { CommonModule } from '@angular/common';
//import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';

@NgModule({
  declarations: [ModifierListComponent],
  imports: [CommonModule, ReactiveFormsModule],
  exports: [ModifierListComponent]
})
export class ModifierListModule {}
