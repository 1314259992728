import { NgModule } from '@angular/core';
import { ButtonComponent } from './button.component';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';
import { ButtonFeedbackDirective } from '../../../../../app/shared/directives/button-feedback.directive';

@NgModule({
  declarations: [ButtonComponent, ButtonFeedbackDirective],
  imports: [CommonModule, NgbDropdownModule, SvgSpriteModule],
  exports: [ButtonComponent]
})
export class ButtonModule {}
