import { Component, TemplateRef, ViewChild, Output, EventEmitter } from '@angular/core';
import { trigger, state, style, transition, animate } from '@angular/animations';
import { Observable } from 'rxjs';
import { Basket, BasketValidation } from '../../../../assets/chepri-modules/src/models/olo.basket';
import { Banner } from '@app/store/state/app.state';
import { Location } from '@angular/common';
import { Router } from '@angular/router';
import { CustomerKioskService } from '@app/providers/customer-kiosk.service';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ClearKioskUserData } from '@app/store/actions/user.action';
import { Select, Store } from '@ngxs/store';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['../customer-kiosk.component.scss'],
  animations: [
    trigger('animate', [
      state('in', style({})),
      state(
        'out',
        style({
          display: 'none'
        })
      ),
      transition('in => out', animate('0ms ease-in-out')),
      transition('out => in', animate('0ms ease-in-out'))
    ])
  ]
})
export class NavbarComponent {
  @Select((state: any) => state.basket.basket) basket$: Observable<Basket>;
  @Select((state: any) => state.basket.basketValidation) basketValidation$: Observable<BasketValidation>;
  @Select((state: any) => state.app.banner) banner$: Observable<Banner>;
  @Select((state: any) => state.app.accessibilityMode) accessibilityMode$: Observable<boolean>;

  @Output() backClicked = new EventEmitter<any>();

  @ViewChild('startOverModal') startOverModal: TemplateRef<any>;

  modalRef: NgbModalRef;

  padding = 0;

  constructor(
    private location: Location,
    private router: Router,
    private cks: CustomerKioskService,
    private modal: NgbModal,
    private store: Store
  ) {
    this.accessibilityMode$.subscribe(accessibility => {
      this.padding = accessibility ? 57.5 : 0;
    });
  }

  goBack(): void {
    this.backClicked.emit();
    this.location.back();
  }

  openStartOverModal(): void {
    this.modalRef = this.modal.open(this.startOverModal, {
      centered: true,
      size: 'lg',
      windowClass: 'hideScroll',
      backdrop: 'static',
      keyboard: false
    });
    setTimeout(() => {
      if (this.padding) {
        const modal = document.getElementsByClassName('modal-dialog-centered').item(0);
        modal.classList.add('d-none');
        modal.className = modal.className.replace('modal-dialog-centered', 'accessibility-transition');
        modal.classList.add('modal-dialog-end');
        modal.classList.remove('d-none');
      }
    });
  }

  startOver(): void {
    this.store.dispatch(new ClearKioskUserData());
    this.router.navigate(['cks', 'home'], { queryParams: { kiosk: this.cks.getStoredToken() } });
  }

  goToCheckout(): void {
    this.router.navigate(['cks', 'order-summary']);
  }
}
