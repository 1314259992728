<div
  class="cks-body d-flex flex-column main-background kiosk-height overflow-hidden"
  *ngIf="restaurant$ | async as restaurant"
>
  <!-- accessibility mode top -->
  <!-- <section class="vh50 bg-pp-orange"></section> -->

  <app-navbar></app-navbar>
  <!-- categories/scroll nav -->
  <section class="fg-0 fs-1 fb-0 align-self-stretch bg-white shadow-sm" *ngIf="restaurant.menu as menu">
    <div class="container" style="max-width: 1100px !important;">
      <div class="d-flex gap-3 flex-row flex-nowrap justify-content-center">
        <!-- category -->
        <ng-container class="gap-0" *ngFor="let cat of menu.categories; let i = index" cla>
          <div *ngIf="cat.name !== 'Gift Cards'" class="pointer" (click)="switchCategory(i)">
            <!-- category name -->
            <div class="nav-categories-names pt-4 pb-3">
              {{ cat.name }}
            </div>
            <!-- underline -->
            <div class="pt-1">
              <div class="nav-categories-underline"></div>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </section>
  <div class="h-100 overflow-auto" #categorySection>
    <section class="fg-0 fs-1 fb-0 align-self-stretch pb-5" *ngIf="restaurant.menu as menu">
      <div class="container">
        <div class="d-flex flex-row flex-wrap w-100">
          <div class="d-flex flex-row flex-nowrap w-100 pt-5 pb-3">
            <div class="fg-1 fb-0 align-self-stretch"></div>
            <div class="fg-1 fb-0 align-self-stretch">
              <hr class="border-pp-orange" />
              <div class="h2 fw-bold text-pp-orange text-uppercase text-center">
                {{ menu.categories[selectedCat]?.name }}
              </div>
              <hr class="border-pp-orange" />
            </div>

            <div class="fg-1 fb-0 align-self-stretch d-flex align-items-center">
              <button
                class="btn pp-btn-primary text-white d-flex align-items-center justify-content-center ms-4"
                (click)="open(content)"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="currentColor"
                  class="bi bi-info-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path
                    d="m8.93 6.588-2.29.287-.082.38.45.083c.294.07.352.176.288.469l-.738 3.468c-.194.897.105 1.319.808 1.319.545 0 1.178-.252 1.465-.598l.088-.416c-.2.176-.492.246-.686.246-.275 0-.375-.193-.304-.533L8.93 6.588zM9 4.5a1 1 0 1 1-2 0 1 1 0 0 1 2 0z"
                  />
                </svg>
              </button>
            </div>
          </div>
        </div>

        <div class="h5 w-100 text-center m-0">
          {{ menu.categories[selectedCat]?.description }}
        </div>
      </div>
    </section>

    <section class="fg-1 fb-0 align-self-stretch pb-5" *ngIf="restaurant.menu as menu">
      <div class="container">
        <div class="grid-4 gap-2">
          <lib-kiosk-product-card
            *ngFor="let product of menu.categories[selectedCat]?.products"
            class="bg-white br-1 p-3 d-flex flex-row flex-wrap fg-1 fb-0 align-self-stretch"
            [product]="product"
            [defaultImageUrl]="defaultImageUrl"
            [imagePath]="product.imagefilename ? imagePath : ''"
            text="Quick Add"
            (addToBag)="addProductToCart(product, maxOrder)"
            (customize)="navigateToProduct(product)"
            [isLoading]="selectedProdID === product.id"
          ></lib-kiosk-product-card>
        </div>
      </div>
    </section>

    <button
      (click)="scrollToTop()"
      id="showScrollToTop"
      class="scrollTop shadow d-flex align-items-center justify-content-center border-0"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        fill="currentColor"
        class="bi bi-arrow-up"
        viewBox="0 0 16 16"
      >
        <path
          fill-rule="evenodd"
          d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"
        />
      </svg>
    </button>
  </div>

  <ng-template class="pup" #content let-modal>
    <div class="popupShell">
      <a class="close" aria-label="Close" (click)="modal.dismiss('Cross click')" style="text-align: right;">
        <div class="popup-x-modal"><lib-svg-sprite svgID="popup-x"></lib-svg-sprite></div>
      </a>
      <div class="popupHeader">
        Information
      </div>
      <div class="popupInnerInfo">
        <p class="popupLine">Additions and substitutions extra charge</p>
        <p class="popupLine">* Item put on after pizza comes out of the oven</p>
        <p class="popupLine">
          <lib-svg-sprite svgID="di-bafala"></lib-svg-sprite>Indicates item is made with mozzarella di bufala
        </p>
      </div>
    </div>
  </ng-template>
  <app-accessibility-toggle></app-accessibility-toggle>
</div>

<ng-template class="pup" #maxOrder let-modal>
  <lib-max-pizza-modal (xClicked)="modal.dismiss()"></lib-max-pizza-modal>
</ng-template>
