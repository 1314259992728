import { Injectable } from '@angular/core';
import { Restaurant } from '../../models/olo.restaurant';
import { SearchService } from './search.service';

@Injectable({
  providedIn: 'root'
})
export class LocationListSearchService {
  public searchQuery: string;

  constructor(private search: SearchService) {}

  public runLocationsQuery(query: string, list: any[]) {
    this.searchQuery = query;
    let queriedList: any[] = [];
    const contains = list.filter(this.filterLocations.bind(this));
    const doesNotContain = list.filter(this.getRemainingLocations.bind(this));
    doesNotContain.sort(this.sortLocationsByLCS.bind(this));
    queriedList = contains.concat(doesNotContain);
    // console.log('query', query);
    // console.log('contains', contains);
    // console.log('doesNotContain', doesNotContain);
    // console.log('queriedList', queriedList);
    return queriedList;
  }

  private filterLocations(location: Restaurant) {
    // console.log('location', location.name);
    const includes = location.name.toLowerCase().includes(this.searchQuery); // ||
    // location.city.toLowerCase().includes(this.searchQuery) ||
    // location.country.toLowerCase().includes(this.searchQuery) ||
    // location.state.toLowerCase().includes(this.searchQuery) ||
    // location.streetaddress.toLowerCase().includes(this.searchQuery) ||
    // location.zip.includes(this.searchQuery);
    return includes;
  }

  private getRemainingLocations(location: Restaurant) {
    const doesNotInclude = !location.name.toLowerCase().includes(this.searchQuery); // &&
    // !location.city.toLowerCase().includes(this.searchQuery) &&
    // !location.country.toLowerCase().includes(this.searchQuery) &&
    // !location.state.toLowerCase().includes(this.searchQuery) &&
    // !location.streetaddress.toLowerCase().includes(this.searchQuery) &&
    // !location.zip.includes(this.searchQuery);
    return doesNotInclude;
  }

  private sortLocationsByLCS(a: Restaurant, b: Restaurant) {
    const scoreA = this.getMaxScoreOfSearchCandidates(a);
    const scoreB = this.getMaxScoreOfSearchCandidates(b);
    return scoreB - scoreA;
  }

  private getMaxScoreOfSearchCandidates(location: Restaurant): number {
    const nameScore = this.search.stringLCS(this.searchQuery, location.name);
    // let streetScore = this.search.stringLCS(this.searchQuery, a.streetaddress);
    // const cityScore = this.search.stringLCS(this.searchQuery, location.city);
    // const zipScore = this.search.stringLCS(this.searchQuery, location.zip);
    // return Math.max(
    //   nameScore.length,
    //   // streetScoreA.length,
    //   cityScore.length,
    //   zipScore.length
    // );
    return nameScore;
  }
}
