<div class="modal-wrapper justify-content-center">
  <h1 class="modal-title text-center fw-bold swipe-title">
    {{ isGiftCard ? 'Please Swipe Card Now' : 'Please Insert/Slide Card Card Now' }}
  </h1>

  <div class="d-flex flex-row text-center justify-content-center py-4">
    <img src="./assets/images/creditcard-outline.svg" style="width:324px; height:auto;" />
  </div>

  <div class="d-flex flex-row pt-4">
    <!--    <div class="col">-->
    <!--      <button class="cancel-btn btn btn-primary text-white bold text-uppercase" (click)="clickedX()">Cancel</button>-->
    <!--    </div>-->
    <div class="col text-end" *ngIf="isCustomerKiosk && isGiftCard">
      <button class="cancel-btn btn btn-primary text-white bold text-uppercase" (click)="manualGiftCardClicked()">
        Enter Manually
      </button>
    </div>
  </div>
</div>
