<div *ngIf="!confirmLocation">
  <mgl-map
    #map
    [style]="'mapbox://styles/punchpizza/cksqn0so4134117preayl9nsa'"
    [zoom]="zoomLevel"
    [center]="[userLocation.longitude, userLocation.latitude]"
    (mapLoad)="onMapLoad()"
    [fitBounds]="bounds"
    [fitBoundsOptions]="{ padding: 50 }"
    class="locationsMap"
  >
    <ng-container *ngFor="let location of locations; first as first">
      <ng-container>
        <mgl-marker [lngLat]="[location.longitude, location.latitude]" #mapMarker [popupShown]="mapLoaded && first">
          <img src="assets/images/mapIcon.svg" />
        </mgl-marker>
        <mgl-popup [marker]="mapMarker">
          <lib-location-info [location]="location" [locationMobile]="true"></lib-location-info>
        </mgl-popup>
      </ng-container>
    </ng-container>
    <mgl-marker [lngLat]="[userLocation.longitude, userLocation.latitude]">
      <svg width="40" height="40" viewbox="0 0 40 40" xmlns="http://www.w3.org/2000/svg">
        <circle cx="20" cy="20" fill="none" r="10" stroke="#00bdf2" stroke-width="2">
          <animate attributeName="r" from="8" to="20" dur="2s" begin="0s" repeatCount="indefinite" />
          <animate attributeName="opacity" from="1" to="0" dur="2s" begin="0s" repeatCount="indefinite" />
        </circle>
        <circle cx="20" cy="20" fill="#00bdf2" r="10" stroke="white" stroke-width="3" />
      </svg>
    </mgl-marker>
  </mgl-map>
</div>

<div *ngIf="confirmLocation">
  <mgl-map
    [style]="'mapbox://styles/punchpizza/cksqn0so4134117preayl9nsa'"
    [zoom]="zoomLevel"
    [center]="[userLocation.longitude, userLocation.latitude]"
    class="confirmMap"
  >
    <ng-container *ngFor="let location of locations">
      <ng-container>
        <mgl-marker [lngLat]="[location.longitude, location.latitude]">
          <img src="assets/images/mapIcon.svg" />
        </mgl-marker>
      </ng-container>
    </ng-container>
  </mgl-map>
</div>
