<div class="container-fluid">
  <ng-container>
    <div class="container-fluider" style="padding-top: env(safe-area-inset-top, 0)">
      <div class="formDiv">
        <div class="kioskCont">
          <div class="expoFixed pt-4">
            <div class="px-3 text-center align-middle kiosk-menu-title mx-4 mt-0">
              <h3 class="text-center my-3 py-auto align-middle">PAST ORDERS</h3>
            </div>

            <form class="form-inline m-4 text-center">
              <div class="mb-3" style="margin: 0 auto;">
                <div class="input-group">
                  <input
                    class="form-control past-orders-datepicker"
                    placeholder="Today"
                    name="dp"
                    type="text"
                    (dateSelect)="dateSelect($event)"
                    ngbDatepicker
                    #d="ngbDatepicker"
                  />
                  <button class="btn past-orders-datepicker" (click)="d.toggle()" type="button">
                    <lib-svg-sprite svgID="searchIcon"></lib-svg-sprite>
                  </button>
                </div>
              </div>
            </form>

            <!-- //Menu Cards -->
            <div class="menu-card-grid mx-4" *ngIf="orders && orders.length > 0; else noAccountInfo">
              <div class="col" *ngFor="let order of orders; index as i">
                <div class="kiosk-card float-left my-auto w-100 p-3">
                  <div class="d-flex flex-row align-middle">
                    <div class="col">
                      <div class="d-flex flex-row mb-3">
                        <div class="col p-0 text-start">
                          <div class="text-uppercase f-20 bold">
                            {{ order.customer_name }}
                          </div>
                          <div>
                            {{ order.order_type === 'dinein' ? 'Dine-In' : 'Carry Out' }}
                          </div>
                        </div>
                        <div class="col p-0 text-end">
                          <div class="text-uppercase f-20 bold">
                            {{ order.ready_time | ShortTimePipe | date: 'h:mm aaaaa' }}
                          </div>
                          <div>
                            {{ order.ready_time | ShortTimePipe | date: 'M/dd/yyyy' }}
                          </div>
                        </div>
                      </div>
                      <div class="d-flex flex-row pb-auto px-0">
                        <div class="col px-0">
                          <button class="w-100 btn regularButton mb-3" text="Customize" (click)="printReceipt(order)">
                            print receipt
                          </button>
                        </div>
                      </div>
                      <div class="d-flex flex-row pb-auto px-0">
                        <div class="col px-0">
                          <button
                            class="w-100 btn regularButton mb-3"
                            [disabled]="order.is_refunded || !order.record_number"
                            (click)="refundOrder(order)"
                          >
                            <ng-container *ngIf="refundingOrder !== order.id">
                              <ng-container *ngIf="!order.is_refunded">
                                Refund
                              </ng-container>
                              <ng-container *ngIf="order.is_refunded">
                                Refunded
                              </ng-container>
                            </ng-container>
                            <ng-container *ngIf="refundingOrder === order.id">
                              <div class="spin-loader-ghost"></div>
                            </ng-container>
                          </button>
                        </div>
                      </div>

                      <div class="d-flex flex-row pb-auto px-0">
                        <div class="col px-0">
                          <button
                            class="w-100 btn regularButton"
                            text="Customize"
                            [disabled]="order.order_status !== 'placed'"
                            (click)="cancelOrder(order)"
                          >
                            Cancel
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </ng-container>
  <div id="canvasFrame" style="display:none">
    <canvas id="canvasPaper" width="576" height="640"></canvas>
  </div>
</div>

<ng-template #noAccountInfo>
  <div class="d-flex flex-row justify-content-center" style="margin-top: 200px">
    <div class="col noAccountInfo">
      <div class="accountDetailsInnerInfo">
        <div class="accountDetailsHeader">No Orders For Selected Date</div>
        <div class="noAccountInfoLine"></div>
      </div>
    </div>

    <!-- <div class="col-lg-4 col-md-0"></div> -->
  </div>
</ng-template>
