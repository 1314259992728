import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { FavoritesOrderModel } from '@lib/lib/favorite-order-name/favorites-order.model';
import { FormBuilder, UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { BasketState } from '@app/store/state/basket.state';
import { Product } from '@lib/models/olo.product';
import { GlobalStateModel } from '@app/store/state.model';
import { User } from '@lib/models/olo.user';

@Component({
  selector: 'app-saved-product-modal',
  templateUrl: './saved-product-modal.component.html',
  styleUrls: ['./saved-product-modal.component.scss']
})
export class SavedProductModalComponent implements OnInit {
  @Input() product: Product;

  @Output() xClicked = new EventEmitter<void>();
  @Output() saveProduct = new EventEmitter<string>();

  favorites: FavoritesOrderModel;
  productNameForm: UntypedFormGroup = new UntypedFormGroup({
    productName: new UntypedFormControl('', [Validators.minLength(1)])
  });

  constructor(private store: Store) {}

  ngOnInit() {
    const user: User = this.store.selectSnapshot<User>((state: GlobalStateModel) => state.user.info);
    const defaultName = `${user.firstname}'s ${this.product.name}`;
    this.productName.setValue(defaultName);
  }

  xClick() {
    this.xClicked.emit();
  }

  saveOrderAsFavorite() {
    this.saveProduct.emit(this.productName.value);
  }

  get productName() {
    return this.productNameForm.get('productName');
  }
}
