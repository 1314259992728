import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CartNavComponent } from './cart-nav.component';
import { ButtonModule } from '../button/button.module';
import { LinkModule } from '../link/link.module';
import { IconModule } from '../icon/icon.module';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';

@NgModule({
  declarations: [CartNavComponent],
  imports: [CommonModule, ButtonModule, LinkModule, IconModule, SvgSpriteModule],
  exports: [CartNavComponent]
})
export class CartNavModule {}
