import { Component, Output, EventEmitter } from '@angular/core';
import { Select } from '@ngxs/store';
import { BasketStateModel } from '@app/store/state/basket.state';
import { Observable } from 'rxjs';
import { Router } from '@angular/router';
import { RestaurantStateModel } from '@app/store/state/restaurant.state';
@Component({
  selector: 'app-my-bag',
  templateUrl: './my-bag.component.html',
  styleUrls: ['./my-bag.component.scss']
})
export class MyBagComponent {
  @Select() basket$: Observable<BasketStateModel>;
  @Select() restaurant$: Observable<RestaurantStateModel>;
  @Output() checkout = new EventEmitter<any>();
  @Output() closeModal = new EventEmitter<any>();

  constructor(private route: Router) {}

  onCheckout() {
    this.checkout.emit();
    this.navigateTo('/order-details');
  }

  onClose() {
    this.closeModal.emit();
  }

  navigateTo(page: string) {
    this.route.navigateByUrl(page);
  }
}
