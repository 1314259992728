<div class="modal-wrapper justify-content-center">
  <i class="fas fa-times x-close" (click)="close()"></i>
  <h1 class="modal-title text-center text-uppercase fw-bold m-0">FIRE ORDER EARLY</h1>

  <div class="d-flex flex-column text-center justify-content-center" style="padding-top:32px; padding-bottom:32px;">
    <span class="bold-modal-txt">
      {{ guestName }}'
      <ng-container *ngIf="guestName[guestName.length - 1] !== 's'">s</ng-container>
    </span>
    <span>order is set to be fired at</span>
    <span class="bold-modal-txt text-uppercase">
      {{ readyTime | ShortTimePipe | date: 'h:mm aaaaa' }}
    </span>
    <div>
      Fire this order early (
      <span class="bold-modal-txt text-uppercase">
        {{ currentTime() | date: 'h:mm aaaaa' }}
      </span>
      ).
    </div>
  </div>

  <div class="d-flex justify-content-center">
    <lib-button text="Return" ghost="true" (click)="close()"></lib-button>
    <div style="width:16px;"></div>
    <lib-button text="Confirm" (click)="fireOrder()"></lib-button>
  </div>
</div>
