import { Component, OnInit, Input, AfterViewInit, ElementRef, HostListener, Inject, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { trigger, state, transition, style, animate } from '@angular/animations';
import { DOCUMENT } from '@angular/common';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { AppStateModel } from '@app/store/state/app.state';
import { ToggleHeader } from '@app/store/actions/app.action';
import { BasketStateModel } from '@app/store/state/basket.state';
import { RestaurantStateModel } from '@app/store/state/restaurant.state';
import { Basket } from 'src/assets/chepri-modules/src/models/olo.basket';
import { UserStateModel } from '@app/store/state/user.state';
import { CredentialsService } from '@app/core';
import { GetUser } from '@app/store/actions/user.action';
import { GetBasket, SetBillingInfo, ValidateBasket } from '@app/store/actions/basket.action';
import { FormBuilder } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { BillingInfo } from '../../../assets/chepri-modules/src/models/olo.billinginfo';
import { ModeService } from '@app/providers/mode.service';

@Component({
  selector: 'app-shell',
  templateUrl: './shell.component.html',
  styleUrls: ['./shell.component.scss'],
  styles: ['@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css";'],
  animations: [
    trigger('fade', [
      state('void', style({ opacity: 0 })),
      transition(':enter', [animate(300)]),
      transition(':leave', [animate(500)])
    ])
  ]
})
export class ShellComponent implements OnInit {
  @Select() app$: Observable<AppStateModel>;
  @Select() basket$: Observable<BasketStateModel>;
  @Select() restaurant$: Observable<RestaurantStateModel>;
  @Select() user$: Observable<UserStateModel>;

  currentBasket = {
    deliveryMode: 'pickup'
  };
  @Input() menuItem: string;
  @Input() orderInfoTop = true;
  sticky = false;

  currentLocation = {
    name: 'Punch Pizza Columbus',
    streetaddress: '123 Fake Street',
    city: 'Columbus',
    state: 'OH'
  };

  cartContainer: any;
  hasScrolled = false;
  private subs: Subscription[] = [];

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private store: Store,
    private credentials: CredentialsService,
    private toastr: ToastrService,
    public modeService: ModeService
  ) {}

  ngOnInit() {
    this.subs.push(
      this.credentials.sub$.subscribe(creds => {
        if (this.credentials.isAuthenticated()) {
          const token = this.credentials.credentials.token;
          this.store.dispatch(new GetUser(token)).subscribe(
            res => {
              // Success - authenticated!
              const billingInfo = new BillingInfo();
              billingInfo.emailaddress = res.user.info.emailaddress;
              billingInfo.usertype = 'user';
              billingInfo.firstname = res.user.info.firstname;
              billingInfo.lastname = res.user.info.lastname;
              this.store.dispatch(new SetBillingInfo(billingInfo));
            },
            err => {
              // Failed - Not authenticated ! :(
              this.credentials.setCredentials(); // Credentials are no good - clear 'em out
            }
          );
        }
      })
    );
    // Check if still authenticated from before
    this.store.dispatch(new GetBasket(null)).subscribe(res => {
      // Basket loaded
    });
  }

  routeTo(route: string) {
    this.router.navigateByUrl(route);
  }

  toggleBag(content: any) {
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
        windowClass: 'bag-modal'
      })
      .result.then(
        (result: any) => {
          // this.closeResult = `Closed with: ${result}`;
        },
        (reason: any) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  reduceQuantity(basket: Basket): number {
    if (basket.products && basket.products.length > 0) {
      return basket.products.map((el: any) => el.quantity).reduce((total: any, el: any) => total + el);
    } else {
      return 0;
    }
  }

  @HostListener('window:scroll', [])
  onWindowScroll() {
    const number = window.pageYOffset;

    if (number >= 100 && this.hasScrolled === false) {
      this.store.dispatch(new ToggleHeader(true));
      this.hasScrolled = true;
      return false;
    }

    if (number === 0 && this.hasScrolled === true) {
      this.store.dispatch(new ToggleHeader(false));
      this.hasScrolled = false;
    }
  }

  onActivate(event: any) {
    // const scrollToTop = window.setInterval(() => {           // Smooth scrolling
    //   const pos = window.pageYOffset;
    //   if (pos > 0) {
    //     window.scrollTo(0, pos - 50); // how far to scroll on each step
    //   } else {
    //     window.clearInterval(scrollToTop);
    //   }
    // }, 10);

    window.scrollTo(0, 0);
  }
}
