import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SearchService {
  constructor() {}

  // Longest Common Subsequence for string comparison

  public stringLCS(query: string, searchCandidate: string) {
    const lcsScoreMatrix = this.getScoreMatrix(query, searchCandidate);
    return this.getLcs(query, searchCandidate, lcsScoreMatrix);
  }

  private getScoreMatrix(query: string, searchCandidate: string): number[][] {
    const scoreMatrix = new Array(query.length);
    for (let i = -1; i < query.length; i = i + 1) {
      scoreMatrix[i] = new Array(searchCandidate.length);
      for (let j = -1; j < searchCandidate.length; j = j + 1) {
        if (i === -1 || j === -1) {
          scoreMatrix[i][j] = 0;
        } else if (query[i] === searchCandidate[j]) {
          scoreMatrix[i][j] = scoreMatrix[i - 1][j - 1] + 1;
        } else {
          scoreMatrix[i][j] = Math.max(scoreMatrix[i - 1][j], scoreMatrix[i][j - 1]);
        }
      }
    }
    return scoreMatrix;
  }

  private getLcs(query: string, searchCandidate: string, lcsLengthsMatrix: number[][]): any {
    const compare: any = (i: number, j: number) => {
      if (!lcsLengthsMatrix[i][j]) {
        return '';
      } else if (query[i] === searchCandidate[j]) {
        return compare(i - 1, j - 1) + query[i];
      } else if (lcsLengthsMatrix[i][j - 1] > lcsLengthsMatrix[i - 1][j]) {
        return compare(i, j - 1);
      } else {
        return compare(i - 1, j);
      }
    };
    return compare(query.length - 1, searchCandidate.length - 1);
  }
}
