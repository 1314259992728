<section class="fg-1 fb-0 align-self-stretch" [formGroup]="form">
  <div class="accordion" id="ingredientAccordions">
    <!-- Radio button -->
    <div class="" *ngIf="listType === 'radio'">
      <div class="bg-white border-bottom p-0" id="headingTwo">
        <h2 class="mb-0">
          <button
            class="btn h4 fw-bold text-center text-uppercase d-flex flex-row flex-nowrap w-100 justify-content-center align-items-center m-0 p-4 gap-1"
            type="button"
            data-toggle="collapse"
            [attr.data-target]="'#d' + optionGroup.id"
            aria-expanded="false"
            aria-controls="collapseTwo"
          >
            <div class="">
              {{ optionGroup.description === 'Customize' ? 'Additional Toppings' : optionGroup.description }}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-caret-down-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
              />
            </svg>
          </button>
        </h2>
      </div>
      <div
        [id]="'d' + optionGroup.id"
        class="collapse container"
        aria-labelledby="headingTwo"
        data-parent="#ingredientAccordions"
      >
        <div class="py-4 mx-auto">
          <div class="grid-4 gap-2">
            <label
              id="ingredientCardRadio1"
              [ngClass]="{ 'ingredient-card-btn-active': form?.value?.option === value }"
              class="ingredient-card-btn"
              *ngFor="let value of values; index as i"
            >
              <div class="w-100 d-flex justify-content-center pb-3">
                <input
                  formControlName="option"
                  [ngClass]="{ 'kiosk-radio-btn': form?.value?.option === value }"
                  [value]="value"
                  type="radio"
                  name="option"
                />
              </div>
              <div class="w-100 text-center h5 mb-3">
                <div class="w-100 mb-1">{{ text[i] }}</div>
                <div class="w-100">
                  <span>{{ cost[i] > 0 ? (cost[i] | currency) : '' }}</span>
                </div>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- Checkboxes -->
    <div class="" *ngIf="listType === 'checkbox' && !long" formArrayName="options">
      <div class="bg-white border-bottom p-0">
        <h2 class="mb-0">
          <button
            class="btn h4 fw-bold text-center text-uppercase d-flex flex-row flex-nowrap w-100 justify-content-center align-items-center m-0 p-4 gap-1"
            type="button"
            data-toggle="collapse"
            [attr.data-target]="'#d' + optionGroup.id"
            aria-expanded="false"
            aria-controls="d"
          >
            <div class="">
              {{ optionGroup.description === 'Customize' ? 'Additional Toppings' : optionGroup.description }}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-caret-down-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
              />
            </svg>
          </button>
        </h2>
      </div>
      <div [id]="'d' + optionGroup.id" class="collapse container mx-auto" data-parent="#ingredientAccordions">
        <div class="py-4">
          <div class="grid-4 gap-2 ">
            <label
              id="ingredientCardBtn1"
              class="ingredient-card-btn"
              [ngClass]="{ 'ingredient-card-btn-active': (form?.value?.options)[i] }"
              *ngFor="let option of options.controls; index as i"
            >
              <div class="w-100 d-flex justify-content-center pb-3">
                <input id="ingredientCheck1" [formControl]="option" [formControlName]="i" type="checkbox" />
              </div>
              <div class="w-100 text-center h5 mb-3">
                <div class="w-100 mb-1">{{ text[i] }}</div>
                <div class="addition-text" style="color: white" *ngIf="selectedItems[i]">
                  {{ selectedItems[i]?.name.replace(text[i], '') }}
                </div>
                <div class="w-100">
                  <span>{{ cost[i] > 0 ? (cost[i] | currency) : '' }}</span>
                </div>
              </div>
              <div class="w-100 d-flex justify-content-center" *ngIf="!long && subModifiers?.length > 1">
                <button (click)="modifyClicked(i)" class="btn modify-btn">Modify</button>
              </div>
            </label>
          </div>
        </div>
      </div>
    </div>
    <!-- List items -->
    <div class="" *ngIf="listType === 'checkbox' && long" formArrayName="options">
      <div class="bg-white border-bottom p-0" id="headingFour">
        <h2 class="mb-0">
          <button
            class="btn h4 fw-bold text-center text-uppercase d-flex flex-row flex-nowrap w-100 justify-content-center align-items-center m-0 p-4 gap-1"
            type="button"
            data-toggle="collapse"
            [attr.data-target]="'#d' + optionGroup.id"
            aria-expanded="false"
            aria-controls="collapseFour"
          >
            <div class="">
              {{ optionGroup.description === 'Customize' ? 'Additional Toppings' : optionGroup.description }}
            </div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-caret-down-fill"
              viewBox="0 0 16 16"
            >
              <path
                d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
              />
            </svg>
          </button>
        </h2>
      </div>
      <div
        [id]="'d' + optionGroup.id"
        class="collapse container mx-auto"
        aria-labelledby="headingFour"
        data-parent="#ingredientAccordions"
      >
        <div class="py-4">
          <div class="d-flex flex-row flex-wrap gap-2">
            <ng-container *ngFor="let option of options.controls; index as i">
              <ng-container *ngIf="!text[i].includes(glutenFreeName); else glutenFreeOption">
                <label
                  [ngClass]="{
                    'disabled-modifier': enzoName && enzoName === text[i] && !showEnzoItem,
                    'ingredient-card-btn-active': (form?.value?.options)[i]
                  }"
                  id="optionalStylesBtn1"
                  class="w-100 optional-styles-btn"
                >
                  <div class="d-flex align-items-center pe-3">
                    <input id="optionalStylesCheck1" [formControl]="option" [formControlName]="i" type="checkbox" />
                  </div>
                  <div class="w-100 h5 m-0 d-flex align-items-center">
                    <div class="">
                      {{ text[i] }}
                      <span> {{ cost[i] > 0 ? (cost[i] | currency) : '' }}</span>
                      <span
                        *ngIf="enzoName && enzoName === text[i] && !showEnzoItem"
                        class="modifierText me-3"
                        style="color: #f04e29"
                        >(SOLD OUT)</span
                      >
                    </div>
                  </div>
                </label>
              </ng-container>
              <ng-template #glutenFreeOption>
                <ng-container *ngIf="!showEnzoItem">
                  <div style="padding-left: 68px; font-size: 1.25rem">{{ text[i] }}</div>
                </ng-container>
              </ng-template>
            </ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>
