import { Injectable } from '@angular/core';
import { OptionGroup } from '../../models/olo.optiongroup';
@Injectable({
  providedIn: 'root'
})
export class CustomizeItemService {
  /**
   * Returns true if the modifiers are in the form expected of pizzas, otherwise returns false
   * @param optionGroups the product modifiers
   */
  isPizzaFormat(optionGroups: OptionGroup[]): boolean {
    return (
      (optionGroups.length === 3 &&
        !!optionGroups[0].metadata?.find(md => md.key === 'type' && md.value === 'cheese') &&
        !!optionGroups[1].metadata?.find(md => md.key === 'type' && md.value === 'customize') &&
        !!optionGroups[2].metadata?.find(md => md.key === 'type' && md.value === 'optional')) ||
      (optionGroups.length === 2 &&
        !!optionGroups[0].metadata?.find(md => md.key === 'type' && md.value === 'customize') &&
        !!optionGroups[1].metadata?.find(md => md.key === 'type' && md.value === 'optional'))
    );
  }

  isOldSalad(optionGroups: OptionGroup[]): boolean {
    return (
      optionGroups.length === 1 && optionGroups[0].description.includes('Size') && optionGroups[0].options.length === 2
    );
  }
}
