import { NgModule } from '@angular/core';
import { KioskCardComponent } from './kiosk-card.component';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '../button/button.module';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';

@NgModule({
  declarations: [KioskCardComponent],
  imports: [CommonModule, ButtonModule, SvgSpriteModule],
  exports: [KioskCardComponent]
})
export class KioskCardModule {}
