<div style="display: flex; flex-direction: row; align-items: center; justify-content: center">
  <button (click)="onValueChange(-1)" class="plusminusbutton" [disabled]="!allowsNegative && currentValue <= 0">
    <lib-svg-sprite [big]="big" svgID="subtract-button"></lib-svg-sprite>
  </button>
  <span
    [ngStyle]="{
      'font-size': big ? '30px' : '16px',
      'margin-left': big ? '5px' : '0px',
      'margin-right': big ? '5px' : '0px'
    }"
  >
    {{ displayValue ? displayValue : currentValue }}
  </span>
  <button
    class="plusminusbutton"
    [ngStyle]="{ opacity: disabled ? '0.5' : '' }"
    (click)="onValueChange(1)"
    [disabled]="disabled"
  >
    <lib-svg-sprite [big]="big" svgID="add-button"></lib-svg-sprite>
  </button>
</div>
