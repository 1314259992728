<lib-sub-nav
  class="menuSubnav"
  [capital]="true"
  [links]="catStrings"
  (subNavClick)="subNavClicked($event)"
  [hasScrolled]="hasScrolled"
>
</lib-sub-nav>
<div class="background">
  <div class="menu-product-container px-0">
    <div class="d-flex flex-row">
      <div class="col-12 px-0">
        <div class="px-3 text-center align-middle kiosk-menu-title mx-4">
          <h3 class="text-center my-3 py-auto align-middle bold text-uppercase">{{ catStrings[selectedCat] }}</h3>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row">
      <div class="col-12 p-0">
        <div class="noPaddingCol kioskCardsRow px-2 m-2">
          <div
            *ngFor="let product of categories[selectedCat]?.products"
            class="my-2 w-100"
            [ngClass]="i % 2 == 0 ? 'ps-4 pe-2' : 'pe-4 ps-2'"
          >
            <lib-kiosk-card
              [attr.data-cy]="product.name"
              [product]="product"
              [defaultImageUrl]="defaultImageUrl"
              [imagePath]="product.imagefilename ? imagePath : ''"
              (addToBag)="addToBag(product)"
              (customize)="customize(product)"
              [isLoading]="selectedProdID === product.id"
            >
            </lib-kiosk-card>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template class="pup" #content let-modal>
  <div class="popupShell">
    <a class="close" aria-label="Close" (click)="modal.dismiss('Cross click')" style="text-align: right;">
      <div class="popup-x-modal">
        <lib-svg-sprite svgID="popup-x"></lib-svg-sprite>
      </div>
    </a>
    <div class="popupHeader">
      Information
    </div>
    <div class="popupInnerInfo">
      <p class="popupLine">Additions and substitutions extra charge</p>
      <p class="popupLine">* Item put on after pizza comes out of the oven</p>
      <p class="popupLine">
        <lib-svg-sprite svgID="di-bafala"></lib-svg-sprite>Indicates item is made with mozzarella di bufala
      </p>
    </div>
  </div>
</ng-template>
