<div class="modal-wrapper">
  <ng-container *ngIf="message.guest_bypass">
    <i class="fas fa-times x-close" (click)="close()"></i>
  </ng-container>
  <ng-container *ngIf="message.title">
    <h1 class="text-center text-uppercase">{{message.title}}</h1>
  </ng-container>
  <ng-container *ngIf="message.message_content">
    <div [innerHTML]="allowHtml(message.message_content)"></div>
  </ng-container>
</div>
