import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { GuestInfoRoutingModule } from './guest-info-routing.module';
import { GuestInfoComponent } from './guest-info.component';

import { GuestFormModule } from 'src/assets/chepri-modules/src/lib/guest-form/guest-form.module';

@NgModule({
  declarations: [GuestInfoComponent],
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    Angulartics2Module,
    GuestInfoRoutingModule,
    GuestFormModule
  ]
})
export class GuestInfoPageModule {}
