import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  OnDestroy,
  OnChanges,
  SimpleChanges,
  SimpleChange,
  ViewChild,
  ElementRef
} from '@angular/core';
import { ProductService } from '../../providers/product.service';
import { BasketProductToAdd } from '../../models/olo.basketproduct';
import { OptionGroup } from '../../models/olo.optiongroup';
import { Product } from '../../models/olo.product';
import { Store, Select } from '@ngxs/store';
import { RestaurantState } from '@app/store/state/restaurant.state';
import { ProductState, ProductStateModel } from '@app/store/state/product.state';
import { Observable } from 'rxjs';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Option } from '../../models/olo.option';
import { CustomizeItemService } from '../customize-item/customize-item.service';
import { GlobalStateModel } from '@app/store/state.model';
import { User } from '../../models/olo.user';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { SavedProductModalComponent } from '@app/components/saved-product-modal/saved-product-modal.component';
import { CreateSavedProduct } from '@app/store/actions/user.action';

export interface IncludedOption {
  id: number;
  name: string;
  deselected: boolean;
  exclude_option_id: number;
  include_option_id: number;
}
@Component({
  selector: 'lib-customize-item-bag',
  templateUrl: './customize-item-bag.component.html',
  styleUrls: ['../../../../../app/components/customer-kiosk/customer-kiosk.component.scss']
})
export class CustomizeItemBagComponent implements OnInit, OnDestroy, OnChanges {
  @Select((state: GlobalStateModel) => state.user.info) user$: Observable<User>;

  @Input() basketId: string;
  @Input() defaultImageUrl: string;
  @Input() displayImage = true;
  @Input() isEdit: boolean;
  @Input() mode: string;
  @Input() accessibilityMode: boolean;
  @Output() addToCartClick = new EventEmitter<any>();
  @Select() product$: Observable<ProductStateModel>;
  @Input() isLoading: boolean;
  selectedArr: number[] = [];
  choices: any = [];
  included: IncludedOption[] = [];
  totalCost = 0;
  @Input() productQuant = 1;
  subs: any = [];
  updatedChoice: any = [];
  imagePath: any;
  zeroQuant: boolean;
  isSalad: boolean;
  editSelectedGroup: any;
  selectedSubModifier: number;
  selectedItems: any[] = [];

  saveProductForm = new UntypedFormGroup({
    saveProduct: new UntypedFormControl(false)
  });
  private _product: Product;
  private _optionGroups: OptionGroup[];
  @ViewChild('content') content: ElementRef;

  get optionGroups(): OptionGroup[] {
    return this._optionGroups;
  }
  @Input()
  set optionGroups(val: OptionGroup[]) {
    this._optionGroups = val;
  }

  get product(): Product {
    return this._product;
  }
  @Input()
  set product(val: Product) {
    this._product = val;
    this.subscribeToSelected();
  }

  constructor(
    private productService: ProductService,
    private store: Store,
    private modalService: NgbModal,
    private customize: CustomizeItemService
  ) {
    this.zeroQuant = false;
  }

  ngOnInit() {
    if (this.product.name.toLowerCase().includes('salad')) {
      this.isSalad = this.customize.isOldSalad(this.optionGroups);
    } else {
      this.isSalad = false;
    }
    this.selectedArr = [...this.productService.getSelected()];
    this.choices = this.setChoices(this.optionGroups);
    this.updateCost();
  }

  ngOnChanges(changes: SimpleChanges) {
    const currentOptionGroups: SimpleChange = changes.optionGroups;
    if (this.product.name.toLowerCase().includes('salad')) {
      this.isSalad = this.customize.isOldSalad(currentOptionGroups.currentValue);
    } else {
      this.isSalad = false;
    }
    if (currentOptionGroups && currentOptionGroups.currentValue) {
      this.choices = this.setChoices(currentOptionGroups.currentValue);
      if (!this.isSalad) {
        this.included = this.getIncluded(currentOptionGroups.currentValue);
      } else {
        this.included = this.getIncludedSalad(currentOptionGroups.currentValue);
      }

      this.updateCost();
    }
  }

  ngOnDestroy() {
    // Unsubscribe from all
    this.subs.forEach((sub: any) => sub.unsubscribe());
  }

  setChoices(groups: OptionGroup[]) {
    let temp: any = [];
    groups.forEach(optionGroup => {
      optionGroup.options.forEach(option => {
        if (this.selectedArr.includes(option.id)) {
          temp.push(option);
        }
        if (option.modifiers) {
          temp = [...temp, ...this.setChoices(option.modifiers)];
        }
      });
    });
    return temp;
  }
  // updateIncluded() {
  //   this.updatedChoice = [];
  //   for (let choice of this.choices) {
  //     if (choice.name.toLowerCase().includes('included') || choice.name.toLowerCase().includes('additional toppings')) {
  //       continue;
  //     }
  //     if (choice.name.includes('Add')) {
  //       this.updatedChoice.push({
  //         id: this.updatedChoice.length + 2,
  //         name: choice.name,
  //         deselected: false,
  //         exclude_option_id: choice.id
  //       });
  //     }
  //   }
  // }

  getIncluded(groups: OptionGroup[]): IncludedOption[] {
    const temp: IncludedOption[] = [];
    groups.forEach(optionGroup => {
      if (optionGroup.description === 'Customize') {
        optionGroup.options.forEach(option => {
          if (option.name.toLowerCase().includes('included')) {
            option.modifiers.forEach(modifier => {
              // Each modifier at this level is actually an ingredient
              // Add to included array the display name, and the id associated with the option to exclude it
              let exclude_id;
              if (modifier.options.find(op => op.name.toLowerCase().includes('no '))) {
                exclude_id = modifier.options.find(op => op.name.toLowerCase().includes('no ')).id;
              }
              let include_id;
              if (
                modifier.options.find(
                  op => op.name.toLowerCase().includes('included') || op.name.toLowerCase().includes('add')
                )
              ) {
                include_id = modifier.options.find(
                  op => op.name.toLowerCase().includes('included') || op.name.toLowerCase().includes('add')
                ).id;
              }

              temp.push({
                id: temp.length,
                name: modifier.description,
                deselected: this.productService.hasOption(exclude_id),
                exclude_option_id: exclude_id,
                include_option_id: include_id
              });
            });
          }
        });
      }
    });
    return temp;
  }

  getIncludedSalad(groups: OptionGroup[]): IncludedOption[] {
    if (groups.some(g => g.description.toLowerCase().includes('size'))) {
      const sizeGroup = groups.find(g => g.description.toLowerCase().includes('size'));
      if (sizeGroup.options.some(o => this.productService.hasOption(o.id))) {
        return this.getIncluded(sizeGroup.options.find(o => this.productService.hasOption(o.id)).modifiers);
      }
    }
  }

  subscribeToSelected() {
    this.subs.push(
      this.productService.selected.subscribe(() => {
        this.selectedArr = [...this.productService.getSelected()];

        const arr = this.productService.getSelected();
        // Update array to send down what submodifier was selected
        const subMods: any[] = [];
        let optGroup;
        if (!this.isSalad) {
          optGroup = this.optionGroups;
        } else {
          if (this.optionGroups.some(g => g.description.toLowerCase().includes('size'))) {
            const sizeGroup = this.optionGroups.find(g => g.description.toLowerCase().includes('size'));
            if (sizeGroup.options.some(o => this.productService.hasOption(o.id))) {
              optGroup = sizeGroup.options.find(o => this.productService.hasOption(o.id)).modifiers;
            }
          }
        }
        if (optGroup.length) {
          optGroup.forEach(optionGroup => {
            if (optionGroup.description === 'Customize') {
              optionGroup.options.forEach(option => {
                if (option.name.toLowerCase().includes('included')) {
                  option.modifiers.forEach((innerGroup: any) => {
                    let submodifier: any;
                    innerGroup.options.forEach((mod: any) => {
                      if (arr.includes(mod.id)) {
                        submodifier = mod;
                      }
                    });
                    subMods.push(submodifier);
                  });
                  this.selectedItems = subMods;
                }
              });
            }
          });
        }

        this.choices = this.setChoices(this.optionGroups);
        if (this.isSalad) {
          this.included = this.getIncludedSalad(this.optionGroups);
        }
        this.updateCost();
      })
    );
  }

  removeChoice(index: number) {
    // Update selected (service)
    this.productService.removeFromSelected(index);

    // TODO: Update Basket
  }

  updateQuant(newQuant: number) {
    this.productQuant = newQuant;
    this.updateCost();
    if (newQuant === 0) {
      this.zeroQuant = true;
    } else {
      this.zeroQuant = false;
    }
  }

  updateCost() {
    // this.updateIncluded();
    let productCost = this.product.cost;
    for (const choice of this.choices) {
      productCost += choice.cost;
    }
    this.totalCost = this.productQuant * productCost;
  }

  addToCart() {
    const prod = new BasketProductToAdd();
    prod.productid = this.product.id;
    prod.quantity = this.productQuant;
    prod.options = this.selectedArr.toString();
    if (!!this.saveProduct.value) {
      // open modal
      const modalRef = this.modalService.open(SavedProductModalComponent, { centered: true });
      modalRef.componentInstance.product = this.product;
      // listen for close
      modalRef.componentInstance.xClicked.subscribe(() => modalRef.close());
      // listen for save
      modalRef.componentInstance.saveProduct.subscribe((name: string) => {
        modalRef.close();
        // fire state action
        this.store.dispatch(new CreateSavedProduct(name, this.product, this.selectedArr, 1));
        this.addToCartClick.emit([prod, this.product.metadata]);
      });
    } else {
      this.addToCartClick.emit([prod, this.product.metadata]);
    }
  }

  toggleIncluded(index: number) {
    const ingredient = this.included[index];
    ingredient.deselected = !ingredient.deselected;
    let optGroup;
    if (!this.isSalad) {
      optGroup = this.optionGroups;
    } else {
      if (this.optionGroups.some(g => g.description.toLowerCase().includes('size'))) {
        const sizeGroup = this.optionGroups.find(g => g.description.toLowerCase().includes('size'));
        if (sizeGroup.options.some(o => this.productService.hasOption(o.id))) {
          optGroup = sizeGroup.options.find(o => this.productService.hasOption(o.id)).modifiers;
        }
      }
    }
    const arr = this.productService.getSelected();
    optGroup.forEach(optionGroup => {
      if (optionGroup.description === 'Customize') {
        optionGroup.options.forEach(option => {
          if (option.name.toLowerCase().includes('included')) {
            option.modifiers.forEach(modifier => {
              if (ingredient.name === modifier.description) {
                this.editSelectedGroup = modifier;
                this.editSelectedGroup.options.forEach((option: Option, index: number) => {
                  if (arr.includes(option.id)) {
                    this.productService.removeFromSelected(option.id);
                  }
                  if (ingredient.deselected) {
                    this.productService.addToSelected(ingredient.exclude_option_id);
                  }
                });
              }
            });
          }
        });
      }
    }); // const ingredient = this.included[index];
    // ingredient.deselected = !ingredient.deselected;
    // if (ingredient.deselected) {
    //   // Add modifier to selected
    //   this.productService.addToSelected(ingredient.exclude_option_id);
    //   this.productService.removeFromSelected(ingredient.include_option_id);
    // } else {
    //   // Remove modifier from selected
    //   /*
    //   this.productService.removeFromSelected(ingredient.exclude_option_id);
    //   this.productService.addToSelected(ingredient.include_option_id);
    //   */
    //   this.showOptionsClick(index);
    // }
  }

  showOptionsClick(index: number) {
    const ingredient = this.included[index];
    let optGroup;
    if (!this.isSalad) {
      optGroup = this.optionGroups;
    } else {
      if (this.optionGroups.some(g => g.description.toLowerCase().includes('size'))) {
        const sizeGroup = this.optionGroups.find(g => g.description.toLowerCase().includes('size'));
        if (sizeGroup.options.some(o => this.productService.hasOption(o.id))) {
          optGroup = sizeGroup.options.find(o => this.productService.hasOption(o.id)).modifiers;
        }
      }
    }
    const arr = this.productService.getSelected();
    optGroup.forEach(optionGroup => {
      if (optionGroup.description === 'Customize') {
        optionGroup.options.forEach(option => {
          if (option.name.toLowerCase().includes('included')) {
            option.modifiers.forEach(modifier => {
              if (ingredient.name === modifier.description) {
                this.editSelectedGroup = modifier;
                this.editSelectedGroup.options.forEach((option: Option, index: number) => {
                  if (arr.includes(option.id)) {
                    this.selectedSubModifier = index;
                  } else if (option.isdefault) {
                    this.selectedSubModifier = index;
                  }
                });
              }
            });
          }
        });
      }
    });
    this.modalService.open(this.content, { ariaLabelledBy: 'informationModal', centered: true });
    setTimeout(() => {
      if (this.store.selectSnapshot(state => state.app.accessibilityMode)) {
        const modal = document.getElementsByClassName('modal-dialog-centered').item(0);
        modal.classList.add('d-none');
        modal.className = modal.className.replace('modal-dialog-centered', 'accessibility-transition');
        modal.classList.add('modal-dialog-end');
        modal.classList.remove('d-none');
      }
    });
  }

  addCustomization(i: any) {
    this.selectedSubModifier = i;
  }

  submitCustomization() {
    if (this.selectedSubModifier != null) {
      const id = this.editSelectedGroup.options[this.selectedSubModifier].id;
      const arr = this.productService.getSelected();
      this.editSelectedGroup.options.forEach((opt: any) => {
        if (arr.includes(opt.id)) {
          this.productService.removeFromSelected(opt.id);
        }
      });
      this.productService.addToSelected(id);
      this.selectedSubModifier = null;
      this.modalService.dismissAll();
    }
  }

  get saveProduct() {
    return this.saveProductForm.get('saveProduct');
  }
}
