import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccountDetailsComponent } from './account-details.component';
import { ButtonModule } from '../button/button.module';
import { LinkModule } from '../link/link.module';
import { IconModule } from '../icon/icon.module';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { SingleTextFieldModule } from '../single-text-field/single-text-field.module';
import { ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [AccountDetailsComponent],
  imports: [
    CommonModule,
    ButtonModule,
    LinkModule,
    IconModule,
    SvgSpriteModule,
    NgbModule,
    SingleTextFieldModule,
    ReactiveFormsModule
  ],
  exports: [AccountDetailsComponent]
})
export class AccountDetailsModule {}
