import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-delivery-zone-fail',
  templateUrl: './delivery-zone-fail.component.html',
  styles: []
})
export class DeliveryZoneFailComponent {
  @Output() continue = new EventEmitter<any>();
  @Output() xClicked = new EventEmitter<any>();
  @Output() deliveryOutOfRange = new EventEmitter<any>();
  constructor() {}

  xClick() {
    this.xClicked.emit();
  }
  goToPickUp() {
    this.deliveryOutOfRange.emit();
  }
}
