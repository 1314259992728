import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { UserStateModel } from '@app/store/state/user.state';
import { Observable } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Validators, UntypedFormBuilder, UntypedFormGroup } from '@angular/forms';
import { BasketState, BasketStateModel } from '@app/store/state/basket.state';
import { CheckBalance, GetBillingSchemes } from '@app/store/actions/basket.action';
import { SetBanner } from '@app/store/actions/app.action';
import { ToastrService } from 'ngx-toastr';
import { BasketService } from '@app/providers/basket.service';
import { Basket } from '../../../assets/chepri-modules/src/models/olo.basket';

@Component({
  selector: 'app-giftcard',
  templateUrl: './giftcard.component.html'
})
export class GiftCardComponent implements OnInit {
  @Select() user$: Observable<UserStateModel>;
  @Select() basket$: Observable<BasketStateModel>;

  addGiftCardForm: UntypedFormGroup;
  showBalance = false;
  cannotApplyGiftCard = true;
  balance = 0;
  errorMessage = '';
  giftCard = false;
  basket: Basket;

  @Output() giftCardSubmit = new EventEmitter<UntypedFormGroup>();

  constructor(
    private store: Store,
    private route: ActivatedRoute,
    private fb: UntypedFormBuilder,
    private router: Router,
    private toastr: ToastrService,
    private basketService: BasketService
  ) {}

  ngOnInit() {
    this.store.dispatch(new SetBanner('GIFT CARD BALANCE', false, true, ''));

    this.addGiftCardForm = this.fb.group({
      cardNumber: ['', [Validators.required]],
      pinNumber: ['', Validators.required]
    });
    this.basket$.subscribe((res: BasketStateModel) => {
      this.basket = res.basket;
    })
  }
  submitGiftCardEvent(form?: UntypedFormGroup) {
    this.giftCardSubmit.emit(this.addGiftCardForm);
  }

  checkBalance() {
    const temp = {
      cardnumber: this.addGiftCardForm.value.cardNumber,
      pin: this.addGiftCardForm.value.pinNumber
    };
    this.store.dispatch(new GetBillingSchemes()).subscribe((res: any) => {
      const giftCardScheme = res.basket.billingSchemes.filter((scheme: any) => {
        if (scheme.type === 'giftcard') {
          return scheme;
        }
      });
      if (giftCardScheme.length > 0) {
        this.basketService.checkGiftBalance(giftCardScheme[0].id, this.basket.id, temp).subscribe((cardRes: any) => {
          if (cardRes.message && !cardRes.success) {
            this.toastr.info(cardRes.message)
          }
          const balance = cardRes.balance;
          if (balance > 0) {
            this.cannotApplyGiftCard = false
          }
            this.balance = balance
          this.showBalance = true

          },
          error => {
            this.showBalance = false;
            this.toastr.warning(error.error.message);
          }
        );
      } else {
        this.toastr.warning('This location does not accept Gift Cards, please choose another location.');
      }
    });
  }
}
