import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { MenuComponent } from './menu.component';
import { Shell } from '@app/components/shell/shell.service';
import { MenuGuard } from '@app/providers/menu.guard';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'menu', component: MenuComponent, data: { title: extract('Menu') }, canActivate: [MenuGuard] },
    { path: 'menu/:id', component: MenuComponent, data: { title: extract('Menu') } }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class MenuRoutingModule {}
