<div class="container-fluid locationsList" style="margin: 0px;">
  <div class="searchBar px-3">
    <lib-search-bar
      #searchBarComponent
      [deliveryZone]="false"
      (locChange)="searchChange($event)"
      (setCurrentLoc)="setCurrentLocation()"
      [geoLocLoading]="geoLocLoading"
    ></lib-search-bar>
  </div>
  <ng-container *ngIf="!geoLocLoading; else loadingLocations">
    <div class="container px-3" *ngIf="user$ | async as user">
      <ng-container *ngFor="let location of locations; index as i">
        <ng-container *ngFor="let cmsLoc of cmsLocations">
          <div
            class="d-flex flex-column locationsMobileSection"
            [attr.data-cy]="location.name"
            *ngIf="location.id === cmsLoc.olo_id"
          >
            <ng-container *ngIf="mobile.isMobile; else notMobile">
              <div class="d-flex flex-row flex-nowrap gap-2">
                <div class="flex-grow-1 text-start" *ngIf="!(environment.production && location.id === '86313')">
                  <lib-location-info [locationMobile]="true" [location]="location"></lib-location-info>
                  <ng-container *ngIf="location.supportsdinein && cmsLoc.disable_dinein && !cmsLoc.disable_takeout">
                    <small>*Dine-In Temporarily Unavailable</small>
                  </ng-container>
                  <ng-container *ngIf="location.canpickup && cmsLoc.disable_takeout && !cmsLoc.disable_dinein">
                    <small>*Takeout Temporarily Unavailable</small>
                  </ng-container>
                  <ng-container *ngIf="cmsLoc.disable_takeout && cmsLoc.disable_dinein">
                    <small>*Online Ordering Temporarily Unavailable</small>
                  </ng-container>
                </div>
                <div class="rightMobile locationsColRight">
                  <div *ngIf="distances && distances[i] && distances[i].dis >= 0">
                    {{ distances[i].dis }} miles away
                  </div>
                  <ng-container *ngFor="let calendar of locStoreTimes">
                    <div *ngIf="calendar.name == location.name">
                      {{ calendar.calendar }}
                    </div>
                  </ng-container>
                </div>
              </div>
              <div class="w-100 mt-2">
                <div class="locationsButtons ms-auto" *ngIf="location">
                  <ng-container *ngIf="mobile.isMobile; else notMobile">
                    <div class="d-flex flex-column m-0">
                      <div class="d-flex flex-row gap-4">
                        <div class="col w-100 py-2" *ngIf="location.supportsdinein">
                          <!--*ngIf="location.supportsdinein">-->
                          <lib-button
                            text="Dine-In"
                            [disabled]="cmsLoc.disable_dinein"
                            (buttonClick)="mode = 'dinein'; passOrderHeader(location.id, 'dinein')"
                            [large]="true"
                            [isLoading]="selectedLocation === location?.id && mode === 'dinein'"
                          >
                          </lib-button>
                        </div>
                        <div class="col py-2 me-0 ms-auto w-100">
                          <lib-button
                            text="Takeout"
                            class="pickButMobile"
                            [disabled]="cmsLoc.disable_takeout"
                            [isLoading]="selectedLocation === location?.id && mode === 'pickup'"
                            [large]="true"
                            (buttonClick)="mode = 'pickup'; passOrderHeader(location.id, 'pickup')"
                          >
                          </lib-button>
                        </div>
                      </div>
                      <div class="col py-2 me-0 ms-auto w-100" *ngIf="location.supportsdispatch">
                        <lib-button
                          text="Delivery"
                          [ghost]="true"
                          [large]="true"
                          [isLoading]="selectedLocation === location?.id && mode === 'dispatch'"
                          (buttonClick)="mode = 'dispatch'; deliveryZone(content); deliveryLocation = location"
                        >
                        </lib-button>
                      </div>
                    </div>
                  </ng-container>
                </div>
              </div>
            </ng-container>
            <ng-template #notMobile>
              <div class="d-flex flex-row flex-nowrap">
                <div class="col text-start" *ngIf="!(environment.production && location.id === '86313')">
                  <lib-location-info [locationMobile]="true" [location]="location"></lib-location-info>
                  <div *ngIf="distances && distances[i] && distances[i].dis >= 0">
                    {{ distances[i].dis }} miles away
                  </div>
                  <ng-container *ngFor="let calendar of locStoreTimes">
                    <div *ngIf="calendar.name == location.name">
                      {{ calendar.calendar }}
                    </div>
                  </ng-container>
                  <ng-container *ngIf="location.supportsdinein && cmsLoc.disable_dinein && !cmsLoc.disable_takeout">
                    <small>*Dine-In Temporarily Unavailable</small>
                  </ng-container>
                  <ng-container *ngIf="location.canpickup && cmsLoc.disable_takeout && !cmsLoc.disable_dinein">
                    <small>*Takeout Temporarily Unavailable</small>
                  </ng-container>
                  <ng-container *ngIf="cmsLoc.disable_takeout && cmsLoc.disable_dinein">
                    <small>*Online Ordering Temporarily Unavailable</small>
                  </ng-container>
                </div>
                <div class="col my-auto">
                  <div class="locationsButtons ms-auto" *ngIf="location">
                    <div class="d-flex flex-column m-0">
                      <div class="col w-100  py-2 text-end" *ngIf="location.supportsdinein">
                        <lib-button
                          [text]="'Dine-In'"
                          [disabled]="cmsLoc.disable_dinein"
                          (buttonClick)="mode = 'dinein'; passOrderHeader(location.id, 'dinein')"
                          [isLoading]="selectedLocation === location?.id && mode === 'dinein'"
                        >
                        </lib-button>
                      </div>
                      <div class="col w-100 py-2 me-0 ms-auto text-end">
                        <lib-button
                          text="Takeout"
                          class="pickButMobile"
                          [disabled]="cmsLoc.disable_takeout"
                          [isLoading]="selectedLocation === location?.id && mode === 'pickup'"
                          (buttonClick)="mode = 'pickup'; passOrderHeader(location.id, 'pickup')"
                        >
                        </lib-button>
                      </div>
                      <div class="col py-2 me-0 ms-auto text-end" *ngIf="location.supportsdispatch">
                        <lib-button
                          text="Delivery"
                          [ghost]="true"
                          [isLoading]="selectedLocation === location?.id && mode === 'dispatch'"
                          (buttonClick)="mode = 'dispatch'; deliveryZone(content); deliveryLocation = location"
                        >
                        </lib-button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </ng-template>

            <div class="lineBorder"></div>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </ng-container>
</div>

<ng-template class="pup" #content let-modal>
  <lib-delivery-zone-popup
    (locChange)="searchChange($event)"
    (xClicked)="modal.dismiss()"
    [restaurant]="deliveryLocation"
    (pickupClicked)="modal.dismiss(); passOrderHeader(deliveryLocation.id, 'pickup')"
  ></lib-delivery-zone-popup>
</ng-template>

<ng-template class="pup" #outOfZone let-outOfZone>
  <lib-delivery-zone-fail
    (xClicked)="outOfZone.dismiss()"
    (deliveryOutOfRange)="outOfZone.dismiss(); passOrderHeader(deliveryLocation.id, 'pickup')"
  ></lib-delivery-zone-fail>
</ng-template>

<ng-template #loadingLocations>
  <div class="text-center">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
</ng-template>
