import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { OrderDetailsRoutingModule } from './order-details-routing.module';
import { OrderDetailsComponent } from './order-details.component';

import { LocationInfoModule } from 'src/assets/chepri-modules/src/lib/location-info/location-info.module';
import { ButtonModule } from 'src/assets/chepri-modules/src/lib/button/button.module';
import { CheckboxModule } from 'src/assets/chepri-modules/src/lib/checkbox/checkbox.module';
import { BagItemModule } from 'src/assets/chepri-modules/src/lib/bag-item/bag-item.module';
import { SvgSpriteModule } from 'src/assets/chepri-modules/src/lib/svg-sprite/svg-sprite.module';
import { FavoriteOrderNameModule } from 'src/assets/chepri-modules/src/lib/favorite-order-name/favorite-order-name.module';
import { SingleTextFieldModule } from 'src/assets/chepri-modules/src/lib/single-text-field/single-text-field.module';
import { PartySizeModule } from 'src/assets/chepri-modules/src/lib/party-size/party-size.module';

import { CheckoutBagModule } from '../checkout-bag/checkout-bag.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { AppModule } from '@app/app.module';

@NgModule({
  declarations: [OrderDetailsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    FormsModule,
    SharedModule,
    Angulartics2Module,
    OrderDetailsRoutingModule,
    LocationInfoModule,
    ButtonModule,
    CheckboxModule,
    CheckoutBagModule,
    BagItemModule,
    SvgSpriteModule,
    FavoriteOrderNameModule,
    SingleTextFieldModule,
    ReactiveFormsModule,
    PartySizeModule
  ]
})
export class OrderDetailsPageModule {}
