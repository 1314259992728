import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-login-modal',
  templateUrl: './login-modal.component.html',
  styleUrls: ['./login-modal.component.scss']
})
export class LoginModalComponent {
  @Output() xClicked = new EventEmitter<any>();
  @Output() changeLocationClicked = new EventEmitter<any>();
  @Output() confirmClicked = new EventEmitter<any>();
  @Input() locationName: string;

  title = 'Confirm Location';

  clickedX(): void {
    this.xClicked.emit();
  }

  clickedChangeLocation(): void {
    this.changeLocationClicked.emit();
  }

  clickedConfirm(): void {
    this.confirmClicked.emit();
  }
}
