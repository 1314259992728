import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-party-size',
  templateUrl: './party-size.component.html',
  styles: []
})
export class PartySizeComponent {
  @Input() maxTableSize: Number;
  @Input() selectedTableSize: Number;

  @Output() tableSizeEmit = new EventEmitter<number>();

  tableSizeArray = Array;

  constructor() {}

  ngOnInit() {}

  tableSizeSelected(i: number) {
    this.tableSizeEmit.emit(i);
  }
}
