<div class="modal-wrapper justify-content-center">
  <i class="fas fa-times x-close" (click)="closeModal()"></i>
  <h1 class="modal-title text-center text-uppercase fw-bold">Are you sure?</h1>

  <div class="d-flex flex-row text-center align-items-center py-3">
    Deleting your account will cause you to lose access to your account and all of your data. You will need to sign up
    again if you wish to use an account with us.
  </div>

  <div class="d-flex flex-row pt-1">
    <div class="col ps-0 pe-0 me-3">
      <lib-button text="No" [ghost]="false" [large]="true" (buttonClick)="closeModal()"></lib-button>
    </div>
    <div class="col pe-0">
      <lib-button
        [large]="true"
        [ghost]="true"
        text="Yes"
        [isLoading]="deleteLoading"
        (buttonClick)="deleteAccount()"
      ></lib-button>
    </div>
  </div>
</div>
