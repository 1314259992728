import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

import { Logger } from '../logger.service';
import { CredentialsService } from './credentials.service';

const log = new Logger('GuestGuard');

@Injectable({
  providedIn: 'root'
})
export class GuestGuard  {
  constructor(private router: Router, private credentialsService: CredentialsService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (this.credentialsService.isAuthenticated() || this.credentialsService.isGuest()) {
      return true;
    }

    log.debug('Not a guest or authenticated, redirecting and adding redirect url...');
    this.router.navigate(['/sign-in'], { queryParams: { from: 'checkout', redirect: state.url }, replaceUrl: true });
    return false;
  }
}
