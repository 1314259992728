import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'lib-swipe-card-modal',
  templateUrl: './swipe-card-modal.component.html',
  styleUrls: ['./swipe-card-modal.component.scss']
})
export class SwipeCardModalComponent {
  @Input() isCustomerKiosk: boolean = false;
  @Input() isGiftCard: boolean = false;
  @Output() manualClicked = new EventEmitter<any>();
  @Output() xClicked = new EventEmitter<any>();
  @Output() confirmSwipeCard = new EventEmitter<any>();

  title = 'Start Over?';

  clickedX(): void {
    this.xClicked.emit();
  }

  manualGiftCardClicked(): void {
    this.manualClicked.emit();
  }

  SwipeCard(): void {
    this.confirmSwipeCard.emit();
  }
}
