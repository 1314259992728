import { NgModule } from '@angular/core';
import { ModifierSelectorComponent } from './modifier-selector.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RadioButtonModule } from '../radio-button/radio-button.module';
import { ServicesModule } from '../../providers/services.module';
import { CheckboxModule } from '../checkbox/checkbox.module';
import { ModifierListModule } from 'src/app/components/customer-kiosk/modifier-list/modifier-list.module';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';
import { SharedModule } from '@app/shared';

@NgModule({
  declarations: [ModifierSelectorComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    ServicesModule,
    RadioButtonModule,
    CheckboxModule,
    SvgSpriteModule,
    ModifierListModule,
    SharedModule
  ],
  exports: [ModifierSelectorComponent]
})
export class ModifierSelectorModule {}
