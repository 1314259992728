<div class="container-fluider">
  <div class="formDiv">
    <div class="container formCont">
      <div class="d-flex flex-row">
        <div class="col-12"></div>
      </div>
      <div class="d-flex flex-row">
        <div class="col-12">
          <input class="resetPasswordField" placeholder="Email Address" />
        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="col-12 firstButton">
          <lib-button (click)="resetPasswordModal(resetPass)" large="true" text="Submit"> </lib-button>
        </div>
      </div>
    </div>
  </div>
</div>

<ng-template #resetPass let-modal>
  <lib-reset-password-modal (xClicked)="modal.dismiss()"></lib-reset-password-modal>
</ng-template>
