<div class="modal-wrapper justify-content-center">
  <i class="fas fa-times x-close" (click)="clickedX()"></i>
  <h1 class="modal-title text-center text-uppercase fw-bold">{{ title }}</h1>

  <div class="d-flex flex-row text-center justify-content-center py-4">
    This will clear out your current order.
    <br />
    Proceed with starting over?
  </div>

  <div class="d-flex flex-row pt-1">
    <div class="col me-3">
      <lib-button text="Cancel" ghost="true" large="true" (click)="clickedX()"></lib-button>
    </div>
    <div class="col">
      <lib-button large="true" text="Yes, Start Over" (click)="startOver()"></lib-button>
    </div>
  </div>
</div>
