import { MenuItemLabel } from './olo.menuitemlabel';
import { Image } from './olo.image';
import { Availability } from './olo.availability';
import { MetaData } from './olo.metadata';

export class Product {
  id: number; // Olo product id. ,
  chainproductid: number; // Olo's chain wide product id. This id is not to be used in any Ordering API call. ,
  name: string; // Name of the product. ,
  description: string; // Description of the product. ,
  cost: number; // Cost of the product. ,
  basecalories: string; // If the restaurant has decided to show calories, this will be the base calories of the product...
  maxcalories: string; // If the restaurant has decided to show calories, this will be the max calories of the product...
  displayid: string; // Legacy field. ,
  starthour: number; // Legacy field. Currently set to always return 0. ,
  endhour: number; // Legacy field. Currently set to always return 24. ,
  imagefilename: string; // Legacy field. Points to default image filename. ,
  maximumquantity: string; // Maximum quantity for an individual product in a basket...
  minimumquantity: string; // Minimum quantity for an individual product in a basket...
  maximumbasketquantity: string; // Total maximum quantity of the product that can be in a single basket. ,
  minimumbasketquantity: string; // Total minimum quantity of the product that must be in a single basket...
  quantityincrement: string; // Quantity increment for the product if it is ordered...
  shortdescription?: string; // Short description of the product. ,
  caloriesseparator?: string; // Possible values are "-" and "/". ,
  metadata: MetaData[];
  menuitemlabels?: MenuItemLabel[];
  images: Image[];
  availability?: Availability;
  unavailablehandoffmodes: string[]; // List of unavailable handoff modes for the product...
  alcoholstatus: AlcoholStatus; // Alcohol status of the product...
}

export enum AlcoholStatus {
  NONE = 'None',
  ALCOHOL = 'Alcohol',
  FOOD_AND_ALCOHOL = 'FoodAndAlcohol'
}
