import { Component } from '@angular/core';

@Component({
  selector: 'lib-logo',
  template: `
    <lib-svg-sprite svgID="logo-svg"></lib-svg-sprite>
  `,
  styles: []
})
export class LogoComponent {
  // @Input('title') title;
  // @Input('subtitle') subtitle;
  // @Input('content') content = '😄';
  // @Output() btnClicked = new EventEmitter<boolean>();
  // handleBtnClick() {
  // this.btnClicked.emit(true);
  // }
}
