<div class="popupShell">
  <a class="close" aria-label="Close" (click)="xClick()" style="text-align: right;">
    <div class="popup-x-modal">
      <lib-svg-sprite svgID="popup-x"></lib-svg-sprite>
    </div>
  </a>
  <div class="popupHeader">
    Reset Password
  </div>
  <ng-container *ngIf="view === 'input'">
    <form [formGroup]="form" (ngSubmit)="submit()">
      <div class="d-flex flex-row">
        <div class="col-12">
          <span *ngIf="errorMessage" class="small" style="color: red; float: left; margin-left: 5px;">{{
            errorMessage
          }}</span>
          <input formControlName="emailAddress" class="resetPasswordField" placeholder="Email Address" />
        </div>
      </div>

      <div class="col-12 resetButton">
        <lib-button [disabled]="!form.valid" buttonType="submit" class="deliveryFailButton" large="true" text="Submit">
        </lib-button>
      </div>
    </form>
  </ng-container>
  <div *ngIf="view === 'confirm'" class="deliveryDiv">
    <div>
      <p class="deliveryZoneFailText">An email has been sent to your email. Follow the link to reset your password</p>
    </div>
    <div class="deliveryZoneFailButtons">
      <lib-button class="deliveryFailButton" (buttonClick)="xClick()" large="true" text="Close"></lib-button>
    </div>
  </div>
</div>
