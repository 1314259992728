import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { MenuComponent } from '@app/components/menu/menu.component';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ProductService } from '@app/providers/product.service';
import { ToastrService } from 'ngx-toastr';
import { CmsService } from '@app/providers/cms.service';
import { ModeService } from '@app/providers/mode.service';

@Component({
  selector: 'app-cks-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['../customer-kiosk.component.scss']
})
export class CustomerKioskMenuComponent extends MenuComponent {
  @ViewChild('categorySection') categorySection: ElementRef;

  constructor(
    route: ActivatedRoute,
    router: Router,
    store: Store,
    modalService: NgbModal,
    productService: ProductService,
    toast: ToastrService,
    cms: CmsService,
    modeService: ModeService
  ) {
    super(route, router, store, modalService, productService, toast, cms, modeService);
  }

  switchCategory(index: number) {
    this.selectedCat = index;
    this.scrollToTop();
  }

  scrollToTop() {
    this.categorySection.nativeElement.scrollTop = 0;
  }
}
