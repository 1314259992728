<div class="card cardd">
  <div class="cardimagecol">
    <img class="cardimage" src="{{ imagePath }}" [alt]="product.name" />
    <a
      *ngIf="includesBafala"
      class="bafala-icon clickable"
      style="overflow:hidden"
      data-toggle="tooltip"
      data-placement="top"
      title="Contains Mozzarella Di Bufala"
    >
      <div
        style="transform: rotate(45deg); background-color: #333333; position: relative; top: 50px; right: 50px; height: 100px; width: 100px"
      ></div>
      <div>
        <lib-svg-sprite svgID="di-bafala" class="bafala-icon-svg"></lib-svg-sprite>
      </div>
    </a>
  </div>
  <div class="card-body" style="float: bottom">
    <div class="d-flex flex-row mb-3">
      <div class="col-12">
        <h5 class="card-title">{{ product.name }}</h5>
        <p class="cardprice small">{{ product.product.name }}</p>
        <p class="card-text small">
          <ng-container *ngFor="let choice of product.product.choices | savedProductChoices; last as last">
            <ng-container *ngIf="choice.available">{{ choice.name }}{{ last ? '' : ', ' }}</ng-container>
          </ng-container>
        </p>
      </div>
    </div>
    <ng-container *ngIf="!doneLoading">
      <div class="d-flex flex-row">
        <div class="col-12">
          <div class="d-flex flex-row">
            <div class="col-12">
              <lib-button
                href="javascript:void(0)"
                text="Loading..."
                [ngClass]="{ buttonLoading: isLoading === product.id }"
                [isLoading]="isLoading"
                [productID]="product.id"
              ></lib-button>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="doneLoading">
      <div class="d-flex flex-row">
        <div class="col-12">
          <div class="d-flex flex-row">
            <div class="col-12">
              <lib-button
                href="javascript:void(0)"
                (click)="emitAddToBag(product)"
                [text]="text"
                [isLoading]="isLoading"
                [productID]="product.id"
                [attr.data-cy]="product.name"
              ></lib-button>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="col-12" style="padding-top: 15px;">
              <a href="javascript:void(0)" (click)="deleteEmit(product)">Delete</a>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>
</div>
