import { Pipe, PipeTransform } from '@angular/core';
import { Option } from '@lib/models/olo.option';

@Pipe({
  name: 'selectedOptions',
  pure: false
})
export class SelectedOptionsPipe implements PipeTransform {
  transform(options: Option[], selectedIds: number[]): Option[] {
    return options.filter(option => selectedIds.includes(option.id));
  }
}
