import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { KioskMenuComponent } from './menu.component';
import { Shell } from '@app/components/shell/shell.service';
import { KMSGuard } from '@app/providers/kms.guard';

const routes: Routes = [
  Shell.childRoutes([
    { path: 'kiosk/menu', component: KioskMenuComponent, data: { title: extract('Menu') }, canActivate: [KMSGuard] },
    { path: 'kiosk/menu/:id', component: KioskMenuComponent, data: { title: extract('Menu') }, canActivate: [KMSGuard] }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class KioskMenuRoutingModule {}
