<ng-container *ngIf="banner$ | async as banner">
  <div class="w-100">
    <!-- navbar -->
    <section
      class="fg-0 fs-1 fb-0 align-self-stretch nav-background shadow-sm accessibility-transition"
      [style.padding-top.vh]="padding"
    >
      <!-- container -->
      <div class="container">
        <!-- flex -->
        <div class="d-flex flex-row">
          <!-- start over btn -->
          <div class="fg-1 fb-0 align-self-stretch d-flex align-items-center py-3">
            <button
              type="button"
              data-toggle="modal"
              data-target="#startOverModal"
              class="btn pp-btn-outline-primary"
              (click)="openStartOverModal()"
            >
              <div>Start Over</div>
            </button>
          </div>

          <!-- punch logo -->
          <div class="fg-1 fb-0 align-self-stretch">
            <img
              class="nav-logo w-100 mw-0 px-3"
              src="https://cms.punchpizza.chepri.com/assets/14a98aff-528d-4816-bfa6-26a53ea87231"
              alt="Punch Pizza Logo"
            />
          </div>

          <!-- cart btn -->
          <ng-container *ngIf="basket$ | async as basket">
            <div class="fg-1 fb-0 align-self-stretch d-flex align-items-center py-3">
              <button
                class="btn pp-btn-primary text-white ms-auto justify-content-center"
                style="min-width: 100px;"
                *ngIf="basket.total > 0"
                (click)="goToCheckout()"
              >
                <div class="position-relative">
                  <img
                    class="pe-3"
                    src="https://cms.punchpizza.chepri.com/assets/495acc14-98a8-432b-a2c8-990e88048632"
                    alt="Cart"
                  />
                  <div *ngIf="basket.products.length > 0" class="bag-icon-number-badge-kiosk">
                    <div>{{ basket.products.length }}</div>
                  </div>
                </div>
                <div style="margin-left: 5px;">
                  <ng-container>
                    CHECKOUT
                  </ng-container>
                </div>
              </button>
            </div>
          </ng-container>
        </div>
      </div>
    </section>

    <!-- subnav -->
    <section class="fg-0 fs-1 fb-0 align-self-stretch bg-pp-dark-tan text-white">
      <!-- container -->
      <div class="container">
        <button
          class="d-flex flex-row align-items-center py-4 bg-transparent border-0 text-white"
          (click)="goBack()"
          *ngIf="banner.canGoBack"
        >
          <!-- replace this SVG tag with back button -->
          <div class="d-flex align-items-center pe-3 pointer">
            <lib-svg-sprite svgID="back-button"></lib-svg-sprite>
          </div>
          <!-- current page -->
          <div class="text-uppercase h5 m-0 pointer">
            {{ banner.text }}
          </div>
        </button>
      </div>
    </section>
  </div>
</ng-container>

<ng-template #startOverModal>
  <div class="modal-header border-0 flex-row flex-wrap">
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="modalRef.dismiss()">
      <span aria-hidden="true">&times;</span>
    </button>
    <h5 class="modal-title h3 text-uppercase text-pp-orange fw-bold text-center w-100 p-3" id="startOverModalLabel">
      Start Over
    </h5>
  </div>
  <div class="modal-body border-0 justify-content-center p-3">
    <div
      class="btn-toolbar flex-nowrap w-100 h4 m-0 justify-content-center w-100"
      role="toolbar"
      aria-label="Toolbar with button groups"
    >
      By selecting the 'Start Over' button your order will be deleted.
    </div>
  </div>
  <div class="modal-footer m-0 p-3 border-0 gap-2 flex-row flex-nowrap">
    <button
      type="button"
      class="btn pp-btn-outline-primary justify-content-center m-0 w-100"
      (click)="modalRef.dismiss()"
    >
      Cancel
    </button>
    <button
      type="button"
      class="btn pp-btn-primary text-white justify-content-center m-0 w-100"
      (click)="modalRef.dismiss(); startOver()"
    >
      Start Over
    </button>
  </div>
</ng-template>
