import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Store } from '@ngxs/store';
import { AddUser } from '@app/store/actions/user.action';

@Component({
  selector: 'lib-sign-in-form',
  templateUrl: './sign-in-form.component.html',
  styles: []
})
export class SignInFormComponent implements OnInit {
  @Input() isCheckout = false;
  @Input() forgotPasswordText = 'Forgot Password?';
  @Input() signInButtonText = 'SIGN IN';
  @Input() createAccountButtonText = 'CREATE AN ACCOUNT';
  @Input() continueAsGuestText = 'CONTINUE AS GUEST';

  @Output() signInSubmitEvent = new EventEmitter<UntypedFormGroup>();
  @Output() leavePageEvent = new EventEmitter<string>();

  signInForm: UntypedFormGroup;
  errorMessage = '';

  constructor(private fb: UntypedFormBuilder, private store: Store) {}

  ngOnInit() {
    this.signInForm = this.fb.group({
      emailAddress: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      remember: false
    });
    // this.signInForm.valueChanges.subscribe(newVal => console.log(newVal));
  }

  clickEvent(identifier?: string) {
    this.leavePageEvent.emit(identifier);
    // console.log(identifier);
  }

  submitForm(form?: UntypedFormGroup) {
    // Check here for email and password validity
    this.signInSubmitEvent.emit(this.signInForm);
    // console.log('Checkout Auth Submit: ', this.signInForm);

    // Dispatch create new user function
    // TODO: uncomment when User Model and Add User are implemented
    // this.store.dispatch(new AddUser())
  }
}
