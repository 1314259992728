<div [formGroup]="form" class="selector-grid">
  <div *ngFor="let value of values; index as i" class="radio justify-content-center">
    <label
      class="container radioCont m-0 p-0"
      style="flex-direction: column;display: inline-flex;justify-content: flex-start;align-items: center;"
    >
      <input type="radio" [formControlName]="controlName" [value]="value" />
      <span class="checkmark"></span>
      <p class="modifierText text-center" style="margin: 0">{{ text[i] }}</p>
      <p class="modifierPriceText" style="margin: 0">{{ cost[i] > 0 ? (cost[i] | currency) : '' }}</p>
    </label>
  </div>
</div>
