import { NgModule } from '@angular/core';
import { ProductCardComponent } from './product-card.component';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '../button/button.module';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';

@NgModule({
  declarations: [ProductCardComponent],
  imports: [CommonModule, ButtonModule, SvgSpriteModule],
  exports: [ProductCardComponent]
})
export class ProductCardModule {}
