import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModeService } from '@app/providers/mode.service';
import { DirectusExpoProvider } from '@app/providers/expo/directus/directus-expo.provider';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginModalComponent } from '@app/components/login-modal/login-modal.component';
import { Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Select } from '@ngxs/store';
import { CustomerKioskService } from '@app/providers/customer-kiosk.service';
import { TrancloudProviderService } from '@app/datacap-trancloud/services/trancloud-provider.service';
import { PrinterService } from '@app/providers/expo/printer/printer.service';
import { Locations } from '@app/providers/expo/directus/directus-collections.interface';

@Component({
  selector: 'app-customer-kiosk-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class CustomerKioskLoginComponent implements OnInit, OnDestroy {
  @ViewChild('logIn') logInModal: ElementRef;
  expoKioskLoginForm: UntypedFormGroup;
  errorMessage: string = null;

  isLoading = false;

  private subs: Subscription[] = [];

  constructor(
    private expoService: DirectusExpoProvider,
    private fb: UntypedFormBuilder,
    private router: Router,
    private modeService: ModeService,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private kioskService: CustomerKioskService,
    private tranCloud: TrancloudProviderService,
    private printer: PrinterService
  ) {
    this.modeService.setApplicationMode('cks');
    this.subs.push(
      this.expoService.error$.subscribe((error: string) => {
        this.errorMessage = error;
      })
    );
  }

  ngOnInit() {
    this.expoKioskLoginForm = this.fb.group({
      emailAddress: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      kioskID: ['', Validators.required]
    });
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.forEach(sub => sub.unsubscribe());
    }
  }

  inputChanged() {
    this.errorMessage = '';
  }

  emailAddress() {
    return this.expoKioskLoginForm.get('emailAddress');
  }

  password() {
    return this.expoKioskLoginForm.get('password');
  }

  login() {
    this.isLoading = true;
    const form = this.expoKioskLoginForm.value;
    if (form.emailAddress === 'punchkiosk@punchpizza.com' && form.password === 'punchcks01') {
      this.kioskService.setKioskAuth(true);
      this.kioskService.updateStoredToken(form.kioskID);
      this.kioskService.getKioskInfo(form.kioskID).subscribe(kiosk => {
        const kioskInfo = kiosk;
        this.kioskService.getLocationTrancloudInfo((kioskInfo.location as Locations).id).subscribe(locInfo => {
          this.printer.setPrinterAddress(kioskInfo.printer_ip);
          this.tranCloud.tranDeviceID = locInfo.location_trancloud_id;
          this.tranCloud.merchantID = locInfo.location_merchant_id;
          this.tranCloud
            .checkConnection(
              kioskInfo.mac_ip_address,
              kioskInfo.pin_pad_type === 'lane3000' ? 'EMV_LANE3000' : 'EMV_LINK2500',
              kioskInfo.pin_pad_provider === 'datacap' ? 'DATACAP_E2E' : 'HEARTLAND',
              kioskInfo.use_mac_address ? 'MAC' : 'IP'
            )
            .subscribe(
              () => {
                this.isLoading = false;
                this.toastr.success('Pin Pad Connected!');
                this.router.navigateByUrl('/cks/home');
              },
              error => {
                this.toastr.warning(typeof error === 'string' ? error : error.message);
                this.isLoading = false;
              }
            );
        });
      });
    } else {
      this.isLoading = false;
      this.errorMessage = 'invalid credentials';
    }
  }
}
