import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IDirectusExpoOrder } from '@app/models/expo-order.interface';
import { OrderStatusProduct } from '@lib/models/olo.order';

@Component({
  selector: 'lib-order-details-modal',
  templateUrl: './order-details-modal.component.html',
  styleUrls: ['./order-details-modal.component.scss']
})
export class OrderDetailsModalComponent implements OnInit {
  @Output() xClicked = new EventEmitter<any>();
  @Output() refundClicked = new EventEmitter<any>();
  @Input() order: IDirectusExpoOrder;
  @Input() guestName: string;
  @Input() readyTime: string;
  @Input() orderStatus: string;
  @Input() products: OrderStatusProduct[];
  @Input() total: string;

  title = 'Order Details';

  ngOnInit() {}

  refund(): void {
    this.refundClicked.emit();
  }

  close(): void {
    this.xClicked.emit();
  }
}
