<div class="detailsRightTop d-lg-block d-none">
  <lib-button *ngIf="!isConfirmation" large="true" ghost="false" text="View Menu" (buttonClick)="viewMenu()">
  </lib-button>
  <p class="secondaryHeader">YOUR ORDER</p>
  <lib-bag-item *ngFor="let product of basket.products" [product]="product" isCheckoutBag="true"></lib-bag-item>
</div>
<div class="d-flex flex-row orderDetailsBottomTotals">
  <div class="col-12 px-0">
    <!--        <div class="d-flex flex-row couponCodeMobile" *ngIf="isPayment">-->
    <!--          <div class="col-8 couponTextField">-->
    <!--            <form [formGroup]="couponPromoForm">-->
    <!--              <lib-single-text-field-->
    <!--                label="Coupon / Promo Code"-->
    <!--                inputType="text"-->
    <!--                couponCode="true"-->
    <!--                controlName="couponCode"-->
    <!--                [parentForm]="couponPromoForm"-->
    <!--              >-->
    <!--              </lib-single-text-field>-->
    <!--            </form>-->
    <!--          </div>-->
    <!--          <div class="col-4 couponButton">-->
    <!--            <lib-button large="true" text="APPLY"></lib-button>-->
    <!--          </div>-->
    <!--        </div>-->
    <div *ngIf="!isOrderDetails" class="lineBorderWeb"></div>
    <div *ngIf="!isOrderDetails && basket$ | async as basket" class="totalsText">
      <div class="d-flex flex-row">
        <div class="col">
          <p class="detailsLeft">Subtotal</p>
        </div>
        <div class="col">
          <p class="detailsRight">
            {{ (basket.basketValidation ? basket.basketValidation.subtotal : basket.basket.subtotal) | currency }}
          </p>
        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="col">
          <p class="detailsLeft">Tax</p>
        </div>
        <div class="col">
          <p class="detailsRight">
            {{ (basket.basketValidation ? basket.basketValidation.tax : basket.basket.salestax) | currency }}
          </p>
        </div>
      </div>
      <ng-container *ngIf="!isConfirmation && basket.basket.coupondiscount > 0">
        <div class="d-flex flex-row">
          <div class="col">
            <p class="detailsLeft">Coupon</p>
          </div>
          <div class="col">
            <p *ngIf="!isConfirmation" class="detailsRight">
              -{{ (basket.basket.coupondiscount > 0 ? basket.basket.coupondiscount : 0) | currency }}
            </p>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isConfirmation && basket.previousBasket.discount > 0">
        <div class="d-flex flex-row">
          <div class="col">
            <p class="detailsLeft">Coupon</p>
          </div>
          <div class="col">
            <p *ngIf="isConfirmation" class="detailsRight">
              -{{ (basket.previousBasket.discount > 0 ? basket.previousBasket.discount : 0) | currency }}
            </p>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="!isConfirmation">
        <div
          *ngIf="basket.basket.deliverymode === 'dispatch' || basket.basket.deliverymode === 'delivery'"
          class="d-flex flex-row"
        >
          <div class="col">
            <p class="detailsLeft">Delivery Fee</p>
          </div>
          <div class="col">
            <p class="detailsRight">
              {{
                (basket.basketValidation
                  ? basket.basketValidation.customerhandoffcharge
                  : basket.basket.customerhandoffcharge
                ) | currency
              }}
            </p>
          </div>
        </div>
      </ng-container>
      <ng-container *ngIf="isConfirmation">
        <div
          *ngIf="basket.previousBasket.deliverymode === 'dispatch' || basket.previousBasket.deliverymode === 'delivery'"
          class="d-flex flex-row"
        >
          <div class="col">
            <p class="detailsLeft">Delivery Fee</p>
          </div>
          <div class="col">
            <p class="detailsRight">
              {{ basket.previousBasket.customerhandoffcharge | currency }}
            </p>
          </div>
        </div>
      </ng-container>
      <div class="d-flex flex-row">
        <div class="col">
          <p class="detailsLeft">Tip</p>
        </div>
        <div class="col">
          <p *ngIf="!isConfirmation" class="detailsRight">
            {{ basket.basket.tip | currency }}
          </p>
          <p *ngIf="isConfirmation" class="detailsRight">
            {{ basket.previousBasket.tip | currency }}
          </p>
        </div>
      </div>
      <div class="d-flex flex-row" *ngFor="let card of basket.giftCard; index as i">
        <div class="col">
          <p class="detailsLeft gifttext">
            Gift Card
            <span *ngIf="this.router.url === '/checkout'"
              >- <span>*{{ card.cardnumber.substr(card.cardnumber.length - 4) }}</span></span
            >
          </p>
        </div>
        <div class="col" *ngIf="i != basket.giftCard.length - 1">
          <p class="detailsRight gifttext">-{{ basket.giftCard[i].balance | currency }}</p>
        </div>
        <div class="col" *ngIf="i === basket.giftCard.length - 1">
          <p class="detailsRight gifttext" *ngIf="basket.basketValidation.total <= giftCardBalance">
            -{{ basket.basketValidation.total - (giftCardBalance - basket.giftCard[i].balance) | currency }}
          </p>
          <p class="detailsRight gifttext" *ngIf="basket.basketValidation.total > giftCardBalance">
            -{{ basket.giftCard[i].balance | currency }}
          </p>
        </div>
      </div>
      <div class="d-flex flex-row totalTextRow">
        <!-- Regular totals -->
        <div class="col">
          <p class="detailsLeft totaltext">Total</p>
        </div>
        <div class="col" *ngIf="!isConfirmation">
          <p class="detailsRight totaltext" *ngIf="!basket.applyGiftCard">
            {{ (basket.basketValidation ? basket.basketValidation.total : basket.basket.total) | currency }}
          </p>
          <p class="detailsRight totaltext" *ngIf="basket.applyGiftCard">
            {{
              (basket.basketValidation
                ? basket.basketValidation.total - giftCardBalance < 0
                  ? 0
                  : basket.basketValidation.total - giftCardBalance
                : basket.basket.total
              ) | currency
            }}
          </p>
        </div>

        <!-- Confimation totals-->
        <div class="col" *ngIf="isConfirmation">
          <p class="detailsRight totaltext" *ngIf="!basket.applyGiftCard">
            {{
              (basket.basketValidation.total ? basket.basketValidation.total : basket.previousBasket.total) | currency
            }}
          </p>
          <p class="detailsRight totaltext" *ngIf="basket.applyGiftCard">
            {{
              (basket.previousBasket.total
                ? basket.previousBasket.total - giftCardBalance < 0
                  ? 0
                  : basket.previousBasket.total - giftCardBalance
                : basket.previousBasket.total
              ) | currency
            }}
          </p>
        </div>
      </div>
    </div>
    <!-- <div *ngIf="isConfirmation">
      <lib-button large="true" text="PLACE ANOTHER ORDER" (buttonClick)="newOrder()"> </lib-button>
    </div> -->
    <div *ngIf="!isConfirmation">
      <lib-button
        *ngIf="isOrderDetails"
        large="true"
        text="CONTINUE"
        id="checkoutContinueBtn"
        [disabled]="!buttonAvailable"
        [isLoading]="isLoading"
        (buttonClick)="continueToNextPage()"
      ></lib-button>
      <ng-container *ngIf="!isOrderDetails">
        <ng-container [ngSwitch]="paymentType">
          <ng-container *ngSwitchCase="'Apple Pay'">
            <div
              style="display: inline-block;-webkit-appearance: -apple-pay-button; -apple-pay-button-type: order; -apple-pay-button-style: black; width: 100%; height: 40px; border-radius: 4px"
              lang="en"
              (click)="continueToNextPage()"
            ></div>
          </ng-container>
          <ng-container *ngSwitchCase="'Google Pay'">
            <button
              type="button"
              aria-label="Google Pay"
              class="gpay-button black order en"
              (click)="continueToNextPage()"
            ></button>
          </ng-container>
          <lib-button
            *ngSwitchDefault
            large="true"
            id="placeOrderBtn"
            text="PLACE ORDER"
            (buttonClick)="continueToNextPage()"
            [isLoading]="isLoading"
          >
          </lib-button>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
