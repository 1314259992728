import { Component, Input } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
@Component({
  selector: 'lib-radio-button',
  templateUrl: './radio-button.component.html',
  styles: []
})
export class RadioButtonComponent {
  @Input() values: number[];
  @Input() controlName: string;
  @Input() form: UntypedFormGroup;
  // @Input() label: string;
  // @Input() children: any;
  // @Input() customizeRadio: boolean;
  // @Input() customizeCheckbox: boolean;
  @Input() text: string[];
  @Input() cost: number[];

  constructor() {}
}
