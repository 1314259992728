import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute, RoutesRecognized, NavigationEnd } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { AppStateModel } from '@app/store/state/app.state';
import { Location } from '@angular/common';
import { Observable, Subscription } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { CredentialsService } from '@app/core';
import { map } from 'rxjs/operators';
import { Login } from '@app/store/actions/user.action';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { Toast, ToastrService } from 'ngx-toastr';
import { SetBanner } from '@app/store/actions/app.action';
import { SetBillingInfo } from '@app/store/actions/basket.action';
import { BillingInfo } from 'src/assets/chepri-modules/src/models/olo.billinginfo';
import { BasketState } from '@app/store/state/basket.state';
import { filter, pairwise } from 'rxjs/operators';
import { Plugins } from '@capacitor/core';
import { NavigationService } from '@app/providers/navigation.service';
const { Storage } = Plugins;

@Component({
  selector: 'app-sign-in',
  templateUrl: './sign-in.component.html',
  styleUrls: ['./sign-in.component.scss']
})
export class SignInComponent implements OnInit, OnDestroy {
  @Select() app$: Observable<AppStateModel>;
  from: Observable<string>;
  subs: Subscription[] = [];
  private history: string[] = [];

  constructor(
    private router: Router,
    private credentialService: CredentialsService,
    private route: ActivatedRoute,
    private store: Store,
    private modalService: NgbModal,
    private toastService: ToastrService,
    private navService: NavigationService
  ) {}

  async ngOnInit() {
    this.store.dispatch(new SetBanner('SIGN IN', false, true, ''));
    this.from = this.route.queryParamMap.pipe(map(params => params.get('from') || 'None'));
  }

  ngOnDestroy() {
    this.subs.forEach((el: Subscription) => {
      el.unsubscribe();
    });
  }

  leavePage(route: string, content: any) {
    if (route === 'sign-up') {
      this.router.navigateByUrl('/sign-up');
    } else if (route === 'guest-auth') {
      this.router.navigateByUrl('/guest-info');
    } else if (route === 'forgot-password') {
      // Forgot password logic here
      this.openModal(content);
    }
  }

  openModal(content: any) {
    this.modalService
      .open(content, {
        ariaLabelledBy: 'modal-basic-title',
        centered: true
      })
      .result.then(
        (result: any) => {
          // this.closeResult = `Closed with: ${result}`;
        },
        (reason: any) => {
          // this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
        }
      );
  }

  submitFormData(form: UntypedFormGroup) {
    // Form group data
    this.subs.push(
      this.store
        .dispatch(
          new Login(
            {
              login: form.get('emailAddress').value,
              password: form.get('password').value,
              basketid: this.store.selectSnapshot(state =>
                state.basket && state.basket.basket ? state.basket.basket.id : null
              )
            },
            form.get('remember').value
          )
        )
        .subscribe((res: any) => {
          if (res.user && res.user.info && res.user.info.authtoken) {
            const redirect = this.route.queryParamMap.pipe(map(params => params.get('redirect') || null));
            this.subs.push(
              redirect.subscribe((val: string) => {
                if (val) {
                  this.router.navigateByUrl(val);
                } else {
                  this.navService.routeSignIn();
                }
              })
            );
            const billingInfo = new BillingInfo();
            billingInfo.emailaddress = form.get('emailAddress').value;
            billingInfo.usertype = 'user';
            billingInfo.firstname = res.user.info.firstname;
            billingInfo.lastname = res.user.info.lastname;
            this.store.dispatch(new SetBillingInfo(billingInfo));
          }
        })
    );
  }
}
