<ng-container *ngIf="(app$ | async).banner as banner" style="margin-top: 300px;">
  <div class="container-fluid locationsPageCont">
    <ng-container *ngIf="!geoLocationLoading; else loadingPage">
      <div class="d-flex flex-column flex-lg-row">
        <div *ngIf="banner.locationName; else noLocation" class="col-lg-6 leftLocations">
          <lib-location-landing [location]="location" (proceedToMenuEvent)="collectOrderHeader($event)">
          </lib-location-landing>
        </div>
        <div *ngIf="(restaurant$ | async).location as location; else defaultMap" class="col-lg-8 rightMap">
          <lib-map
            [fitBounds]="!locationUpdated"
            [locationsMap]="true"
            [userLocation]="{ latitude: location.latitude, longitude: location.longitude }"
            [zoomLevel]="zoomLevel"
            [locations]="locationsArray"
            [confirmLocation]="false"
          >
          </lib-map>
        </div>
      </div>
    </ng-container>
  </div>

  <ng-template #noLocation>
    <div class="col leftLocations">
      <lib-location-list
        (checkDelivery)="setAddress($event)"
        [locations]="locationsArray"
        [cmsLocations]="cmsLocations"
        (proceedToMenuEvent)="collectOrderHeader($event)"
        (locationClickEvent)="collectLocation($event)"
        [selectedLocation]="selectedLocation"
        (locationChange)="setAddress($event)"
        (setCurrentLoc)="setCurrentLocation(true)"
        [geoLocLoading]="geoLocationLoading"
        #locationListComponent
      >
      </lib-location-list>
    </div>
  </ng-template>
  <ng-template #defaultMap>
    <div class="col rightMap">
      <lib-map
        *ngIf="!geoLocationLoading; else mapLoader"
        [userLocation]="defaultLocation"
        [zoomLevel]="defaultZoom"
        [locations]="locationsArray"
        [confirmLocation]="false"
      >
      </lib-map>
    </div>
  </ng-template>
  <ng-template #mapLoader>
    <div class="spin-loader-black map-loader"></div>
  </ng-template>
</ng-container>

<ng-template #loadingPage>
  <div class="text-center map-loader">
    <div class="spinner-border text-primary" role="status">
      <span class="sr-only">Loading...</span>
    </div>
  </div>
  <!--  <div class="spin-loader-ghost map-loader"></div>-->
</ng-template>
