import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'ShortTimePipe'
})
export class ShortTimePipe implements PipeTransform {
  transform(value: any): any {
    if (value) {
      const shortTime = value.toString().replace(' ', 'T');
      return new Date(shortTime);
    } else {
      return null;
    }
  }
}
