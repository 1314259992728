import { Component, ElementRef, EventEmitter, Input, NgZone, Output, ViewChild } from '@angular/core';
import { Store } from '@ngxs/store';
import { RestaurantState } from '@app/store/state/restaurant.state';
import { CheckDelivery } from '../../../../../app/store/actions/restaurant.action';
import { ToastrService } from 'ngx-toastr';
import { CreateBasket, SetDispatchAddress, TransferBasket } from '../../../../../app/store/actions/basket.action';
import { Basket } from '../../models/olo.basket';
import { BasketState } from '../../../../../app/store/state/basket.state';
import { Result } from '@mapbox/mapbox-gl-geocoder';

@Component({
  selector: 'lib-delivery-zone-popup',
  templateUrl: './delivery-zone-popup.component.html',
  styles: []
})
export class DeliveryZonePopupComponent {
  @Output() continue = new EventEmitter<any>();
  @Output() xClicked = new EventEmitter<any>();
  @Output() pickupClicked = new EventEmitter<any>();
  @Output() deliveryOutOfRange = new EventEmitter<any>();
  @Output() locChange = new EventEmitter<any>();
  @Input() inputWidth = '300px';
  @Input() restaurant: any;
  @ViewChild('search', { static: true }) searchBarRef: ElementRef;
  address: any;
  supportsDispatch = false;
  deliveryVerified = false;
  locationID: number;
  loading = false;
  basket: Basket;
  buttonText = 'Check Address';
  title = 'Delivery Zone Check';
  content = 'deliveryZone';
  openSecondModal = false;

  constructor(private store: Store, private ngZone: NgZone, private toastr: ToastrService) {}

  ngOnInit() {
    const restaurant = this.store.selectSnapshot(RestaurantState);
    this.address = {
      streetAddress: restaurant.location.streetAddress,
      city: restaurant.location.city,
      zip: restaurant.location.zip,
      specialInstructions: ''
    };
    this.locationID = this.restaurant.id;
    this.basket = this.store.selectSnapshot(BasketState).basket;

    // console.log('delivery address', restaurant.address);
  }

  secondModal() {
    this.openSecondModal = true;
  }

  continueClick() {
    this.continue.emit(this.restaurant);
  }

  xClick() {
    this.xClicked.emit();
  }

  pickupClick() {
    this.pickupClicked.emit();
  }

  outOfRange() {
    this.deliveryOutOfRange.emit();
  }

  locationChange(location: Result) {
    this.locChange.emit(location);
  }

  checkDelivery() {
    this.store.dispatch(new CheckDelivery(this.locationID)).subscribe(
      res => {
        this.loading = true;
        this.supportsDispatch = res.restaurant.candeliver;
        if (!this.supportsDispatch) {
          this.outOfRangeContent();
        } else {
          if (res.basket.basket === null) {
            this.store.dispatch(new CreateBasket(this.locationID.toString())).subscribe(
              res => {
                this.setDispatch(res.basket.basket.id);
              },
              () => {
                this.deliveryUnavailable();
              }
            );
          } else if (res.basket.basket.vendorid !== this.locationID) {
            this.store.dispatch(new TransferBasket(this.locationID)).subscribe(
              res => {
                this.setDispatch(res.basket.basket.id);
              },
              () => {
                this.deliveryUnavailable();
              }
            );
          } else {
            this.setDispatch(res.basket.basket.id);
          }
        }
      },
      error => {
        this.deliveryUnavailable();
      }
    );
  }

  setDispatch(basketID: string) {
    const location = this.store.selectSnapshot(RestaurantState).location;
    const address = {
      streetAddress: location.streetaddress,
      city: location.city,
      zip: location.zip,
      specialInstructions: ''
    };
    this.store.dispatch(new SetDispatchAddress(address, basketID)).subscribe(
      res => {
        this.deliveryVerified = true;
        this.loading = false;
      },
      error => {
        this.loading = false;
        this.deliveryUnavailable();
      }
    );
  }

  outOfRangeContent() {
    this.title = 'Outside of Delivery Zone';
    this.content = 'outOfRange';
    this.loading = false;
  }

  deliveryUnavailable() {
    this.title = 'Delivery Unavailable';
    this.content = 'deliveryUnavailable';
    this.loading = false;
  }

  continueButton() {
    if (this.deliveryVerified) {
      this.continueClick();
    } else {
      this.checkDelivery();
    }
  }
}
