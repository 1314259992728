import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'lib-start-over-modal',
  templateUrl: './start-over-modal.component.html',
  styleUrls: ['./start-over-modal.component.scss']
})
export class StartOverModalComponent {
  @Output() xClicked = new EventEmitter<any>();
  @Output() confirmStartOver = new EventEmitter<any>();

  title = 'Start Over?';

  clickedX(): void {
    this.xClicked.emit();
  }

  startOver(): void {
    this.confirmStartOver.emit();
  }
}
