import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { environment } from '@env/environment';
import { OloMenu } from 'src/assets/chepri-modules/src/models/olo.menu';
import { Restaurant } from 'src/assets/chepri-modules/src/models/olo.restaurant';
import { RestaurantCalendars } from 'src/assets/chepri-modules/src/models/olo.calendars';
import * as moment from 'moment';
import { OloHttpService } from '@app/providers/expo/olo/olo-http.service';
import { Cacheable } from 'ts-cacheable';

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {
  domainAPI = this.environmentService.getDomainAPI();
  constructor(private http: OloHttpService, private environmentService: EnvironmentService) {}

  getRestaurants(includePrivate?: boolean): Observable<any> {
    let stringPrivate = '';
    if ((!environment.production || window.location.host.includes('stage')) && includePrivate) {
      stringPrivate = '?includePrivate=true';
    }
    return this.http.get<Restaurant[]>('/restaurants' + stringPrivate);
  }

  @Cacheable({
    maxAge: 5 * 60 * 1000, // 5 minutes,
    maxCacheCount: 15
  })
  getRestaurant(restaurantId: number): Observable<Restaurant> {
    return this.http.get<Restaurant>('/restaurants/' + restaurantId);
  }

  getMenu(restaurantID: string) {
    if (!restaurantID) {
      return;
      // this.router.navigate(['/locations']);
      // return Observable.throw('restaurant id is NaN');
    }
    return this.http.get<OloMenu>('/restaurants/' + restaurantID + '/menu');
  }

  checkDelivery(payload: any) {
    if (!payload) {
      return;
    }
    const body: any = {
      handoffmode: 'dispatch',
      timewantedmode: 'asap',
      street: payload.street,
      city: payload.city,
      zipcode: payload.zip
    };
    return this.http.post<any>('/restaurants/' + payload.restaurantId + '/checkdeliverycoverage', body);
  }

  getCalendars(restaurantID: string) {
    if (!restaurantID) {
      return;
      // this.router.navigate(['/locations']);
      // return Observable.throw('restaurant id is NaN');
    }
    const from = moment().format('YYYYMMDD');
    const to = moment().format('YYYYMMDD');
    return this.http.get<RestaurantCalendars>('/restaurants/' + restaurantID + '/calendars?from=' + from + '&to=' + to);
  }
}
