import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import moment from 'moment';

@Component({
  selector: 'app-alcohol-status-modal',
  templateUrl: './alcohol-status-modal.component.html',
  styleUrls: ['./alcohol-status-modal.component.scss']
})
export class AlcoholStatusModalComponent implements OnInit {
  @Output() xClicked = new EventEmitter<any>();

  public minAgeDate: Date = moment()
    .subtract(21, 'years')
    .startOf('day')
    .toDate();

  constructor() {}

  ngOnInit(): void {}

  close(): void {
    this.xClicked.emit();
  }
}
