import { Injectable, OnDestroy } from '@angular/core';

import { Preferences } from '@capacitor/preferences';
import { from, Observable } from 'rxjs';

export interface Credentials {
  // Customize received credentials here
  username: string;
  token: string;
  isGuest: boolean;
}

export interface User {
  authtoken: string;
  cardsuffix: any;
  emailaddress: string;
  firstname: string;
  lastname: string;
}

const credentialsKey = 'credentials';
const userKey = 'user';

/**
 * Provides storage for authentication credentials.
 * The Credentials interface should be replaced with proper implementation.
 */
@Injectable({
  providedIn: 'root'
})
export class CredentialsService implements OnDestroy {
  sub$: Observable<any> = from(this.getCreds());
  user$: Observable<any> = from(this.getUser());
  private _credentials: Credentials | null = null;
  private _user: User | null = null;
  private subs: Array<any>;

  constructor() {
    // const savedCredentials = sessionStorage.getItem(credentialsKey) || localStorage.getItem(credentialsKey);
    this.subs = [];
    this.subs.push(
      this.sub$.subscribe(creds => {
        let savedCredentials = null;
        if (creds) {
          savedCredentials = creds;
        } else {
          if (sessionStorage.getItem(credentialsKey)) {
            savedCredentials = JSON.parse(sessionStorage.getItem(credentialsKey));
          }
        }
        if (savedCredentials) {
          this._credentials = savedCredentials;
        }
      })
    );
    this.subs.push(
      this.user$.subscribe(user => {
        let savedUser = null;
        if (user) {
          savedUser = user;
        } else {
          if (sessionStorage.getItem(userKey)) {
            savedUser = JSON.parse(sessionStorage.getItem(userKey));
          }
        }
        if (savedUser) {
          this._user = savedUser;
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs[0].unsubscribe();
  }

  async getCreds() {
    const store = await Preferences.get({ key: credentialsKey });
    return JSON.parse(store.value);
  }

  async getUser() {
    const store = await Preferences.get({ key: userKey });
    return JSON.parse(store.value);
  }

  /**
   * Checks is the user is authenticated.
   * @return True if the user is authenticated.
   */
  isAuthenticated(): boolean {
    return !!this.credentials && !this.credentials.isGuest && !!this.credentials.token;
  }

  isGuest(): boolean {
    return !!this.credentials && this.credentials.isGuest;
  }

  /**
   * Gets the user credentials.
   * @return The user credentials or null if the user is not authenticated.
   */
  get credentials(): Credentials | null {
    return this._credentials;
  }

  get user(): User | null {
    return this._user;
  }

  /**
   * Sets the user credentials.
   * The credentials may be persisted across sessions by setting the `remember` parameter to true.
   * Otherwise, the credentials are only persisted for the current session.
   * @param credentials The user credentials.
   * @param remember True to remember credentials across sessions.
   */
  async setCredentials(credentials?: Credentials, remember?: boolean) {
    this._credentials = credentials || null;
    if (credentials) {
      const storage = remember ? Storage : sessionStorage;
      const toRemove = remember ? sessionStorage : Storage;
      if (storage === sessionStorage) {
        storage.setItem(credentialsKey, JSON.stringify(credentials));
      } else {
        await Preferences.set({ key: credentialsKey, value: JSON.stringify(credentials) });
      }
      if (toRemove === sessionStorage) {
        toRemove.removeItem(credentialsKey); // This allows switching from guest to signed in
      } else {
        await Preferences.remove({ key: credentialsKey });
      }
    } else {
      sessionStorage.removeItem(credentialsKey);
      // localStorage.removeItem(credentialsKey);
      await Preferences.remove({ key: credentialsKey });
    }
  }

  async setUser(user?: User, remember?: boolean) {
    this._user = user || null;
    if (user) {
      const storage = remember ? Storage : sessionStorage;
      const toRemove = remember ? sessionStorage : Storage;
      if (storage === sessionStorage) {
        storage.setItem(userKey, JSON.stringify(user));
      } else {
        await Preferences.set({ key: userKey, value: JSON.stringify(user) });
      }
      if (toRemove === sessionStorage) {
        toRemove.removeItem(userKey); // This allows switching from guest to signed in
      } else {
        await Preferences.remove({ key: userKey });
      }
    } else {
      sessionStorage.removeItem(userKey);
      // localStorage.removeItem(userKey);
      await Preferences.remove({ key: userKey });
    }
  }
}
