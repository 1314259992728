import { Product } from 'src/assets/chepri-modules/src/models/olo.product';

export class GetProduct {
  static readonly type = '[Product] Get Product';
  constructor(public productId: number) {}
}

export class ProdImg {
  static readonly type = '[Product] Prod Img';
  constructor(public payload: Product) {}
}
