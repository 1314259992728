import { Component, Input, Output, EventEmitter, OnChanges, NgZone, SimpleChanges } from '@angular/core';

@Component({
  selector: 'lib-pill',
  template: `
    <div [ngClass]="lineThrough ? 'pill-line-through clickable' : ''">
      <span class="badge rounded-pill badge-light pill">
        <lib-svg-sprite svgID="editIcon" (click)="reset()">></lib-svg-sprite>
        <p class="pill-text text-pp-black" (click)="reset()">
          {{ text }} {{ extraItemName ? '(' + extraItemName + ')' : '' }}
        </p>
        <button *ngIf="!lineThrough" class="btn btn-sm" (click)="closed.emit()">
          <lib-svg-sprite svgID="pill-container-x"></lib-svg-sprite>
        </button>
      </span>
    </div>
  `,
  styles: []
})
export class PillComponent implements OnChanges {
  @Input() text: string;
  @Input() lineThrough: boolean;
  @Input() extraItemName: string;
  @Output() closed = new EventEmitter<any>();
  @Output() showOptions = new EventEmitter<any>();

  constructor(private zone: NgZone) {}

  ngOnChanges(changes: SimpleChanges) {
    this.zone.run(() => {});
  }

  reset() {
    if (this.lineThrough) {
      this.closed.emit();
    } else {
      this.showOptions.emit();
    }
  }
}
