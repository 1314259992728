import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { Store } from '@ngxs/store';
import { ForgotPassword } from '@app/store/actions/user.action';

@Component({
  selector: 'lib-reset-password-modal',
  templateUrl: './reset-password-modal.component.html',
  styles: []
})
export class ResetPasswordModalComponent implements OnInit {
  @Output() continue = new EventEmitter<any>();
  @Output() xClicked = new EventEmitter<any>();
  @Output() deliveryOutOfRange = new EventEmitter<any>();

  views: any = ['input', 'confirm'];
  view = 'input';
  errorMessage = 'Email not on record';
  form: UntypedFormGroup;
  constructor(private fb: UntypedFormBuilder, private store: Store) {}

  ngOnInit() {
    this.form = this.fb.group({
      emailAddress: ['', [Validators.required, Validators.email]]
    });
  }
  xClick() {
    this.xClicked.emit();
  }

  submit() {
    // Attempt to send reset password link
    this.errorMessage = '';
    const email = this.form.get('emailAddress').value;
    this.store.dispatch(new ForgotPassword(email)).subscribe(
      (res: any) => {
        // Show confirm
        this.view = this.views[1];
      },
      (error: any) => {
        // Update error message
        this.errorMessage = error.message;
      }
    );

    // If successful - show confirm

    // Else, display error message
  }
}
