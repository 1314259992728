<div class="container-fluider">
  <div *ngIf="restaurants | async as locations" style="padding-top: env(safe-area-inset-top, 0)">
    <lib-locations
      [locations]="locations"
      [cmsLocations]="cmsLocations$ | async"
      (passOrderHeaderEvent)="goToMenu($event)"
      [selectedLocation]="selectedLocation"
    ></lib-locations>
  </div>
</div>

<ng-template #message let-messageModal>
  <lib-scheduled-message-modal
    [message]="activeMessage"
    (xClicked)="messageModal.dismiss()"
  ></lib-scheduled-message-modal>
</ng-template>
