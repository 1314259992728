<div class="container d-flex flex-column justify-content-center align-items-center">
  <div class="d-flex flex-row justify-content-center w-100" style="margin-top:200px; max-width: 640px">
    <div class="col">
      <div class="d-flex flex-row justify-content-center">
        <div class="col">
          <lib-single-text-field
            label="Card Number"
            inputType="text"
            controlName="cardNumber"
            [parentForm]="addGiftCardForm"
            inputMode="numeric"
          >
          </lib-single-text-field>
        </div>
      </div>

      <div class="d-flex flex-row gap-3 p-0">
        <div class="col">
          <lib-single-text-field
            label="Pin Number"
            inputType="text"
            controlName="pinNumber"
            [parentForm]="addGiftCardForm"
            inputMode="numeric"
          >
          </lib-single-text-field>
        </div>
        <div class="col">
          <lib-button (click)="checkBalance()" large="true" text="CHECK BALANCE"></lib-button>
        </div>
      </div>

      <div class="d-flex flex-row p-0">
        <div class="col mt-2" style="font-size: 19px;">
          <span *ngIf="showBalance"><strong>Card Balance: </strong>{{ this.balance | currency }}</span>
        </div>
      </div>
    </div>
  </div>
</div>
