import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KioskMenuComponent } from './menu.component';
import { KioskMenuRoutingModule } from './menu-routing.module';
import { OloKioskMenuModule } from '../olo-menu/olo-menu.module';
import { SoldoutModalModule } from 'src/assets/chepri-modules/src/lib/soldout-modal/soldout-modal.module';
import { MaxPizzaModalModule } from 'src/assets/chepri-modules/src/lib/max-pizza-modal/max-pizza-modal.module';
import { SharedModule } from '@app/shared';

@NgModule({
  declarations: [KioskMenuComponent],
  imports: [
    CommonModule,
    SharedModule,
    KioskMenuRoutingModule,
    OloKioskMenuModule,
    SoldoutModalModule,
    MaxPizzaModalModule
  ]
})
export class KioskMenuModule {}
