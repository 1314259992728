import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { Modifiers } from 'src/assets/chepri-modules/src/models/olo.modifiers';
import { Product } from 'src/assets/chepri-modules/src/models/olo.product';
import { BasketProductToAdd, BasketProduct } from 'src/assets/chepri-modules/src/models/olo.basketproduct';
import { OloHttpService } from '@app/providers/expo/olo/olo-http.service';
import { Observable } from 'rxjs';
import { Store } from '@ngxs/store';
import { GlobalStateModel } from '@app/store/state.model';
import { Cacheable } from 'ts-cacheable';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  selectedArr: any = [];
  selected = new EventEmitter<any>();

  domainAPI = this.environment.getDomainAPI();

  constructor(private http: OloHttpService, private environment: EnvironmentService, private store: Store) {}

  @Cacheable({
    maxAge: 5 * 60 * 1000,
    maxCacheCount: 100
  })
  getProductModifiers(productID: string | number) {
    return this.http.get<Modifiers>('/products/' + productID + '/modifiers').pipe();
  }

  /**
   * Returns Top Level Option Groups Only
   * @param productID
   */

  @Cacheable({
    maxAge: 5 * 60 * 1000,
    maxCacheCount: 100
  })
  getProductOptions(productID: string | number) {
    return this.http.get<Modifiers>('/products/' + productID + '/options');
  }

  getSelected() {
    return this.selectedArr;
  }

  setSelected(newVal: any) {
    this.selectedArr = [...newVal];
    this.selected.emit();
  }

  removeFromSelected(id: string) {
    if (this.selectedArr.includes(id)) {
      this.selectedArr.splice(this.selectedArr.indexOf(id), 1);
      this.selected.emit();
    } else {
      // cannot remove option
    }
  }

  addToSelected(id: string) {
    if (!this.selectedArr.includes(id)) {
      this.selectedArr.push(id);
      this.selected.emit();
    } else {
      // cannot add duplicate
    }
  }

  convertProductToBasketProductToAdd(product: Product, options: string[], quantity: number): BasketProductToAdd {
    const result: BasketProductToAdd = {
      productid: product.id,
      quantity,
      options: options.toString()
    };
    return result;
  }

  convertBasketProductToBasketProductToAdd(product: BasketProduct): BasketProductToAdd {
    const result: BasketProductToAdd = {
      productid: product.productId,
      quantity: product.quantity,
      options: product.choices.map(choice => choice.optionid).toString()
    };
    return result;
  }

  getProductFromChainProductID(chainProductID: number | string): Product {
    const menu = this.store.selectSnapshot((state: GlobalStateModel) => state.restaurant.menu);
    if (!menu) {
      throw new Error('Menu not found');
    }
    const category = menu.categories.find(cat =>
      cat.products.find(prod => String(prod.chainproductid) === String(chainProductID))
    );
    if (!category) {
      throw new Error('Product not found');
    }
    const product = category.products.find(prod => String(prod.chainproductid) === String(chainProductID));
    if (!product) {
      throw new Error('Product not found');
    }
    return product;
  }
}
