import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { OloHttpService } from '@app/providers/expo/olo/olo-http.service';
import { switchMap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class OloApiService {
  constructor(private oloHTTP: OloHttpService) {}

  getOrderByID<T>(orderGUID: string): Observable<T> {
    return this.oloHTTP.get<T>('/orders/' + orderGUID);
  }

  manuallyFireOrderByID<T>(
    orderGUID: string,
    tableNumber: string,
    partySize: string,
    tablePref: string
  ): Observable<T> {
    return this.getOrderByID(orderGUID).pipe(
      switchMap((o: any) => {
        const customFields = [
          {
            id: o.customfields.find((cf: any) => cf.label === 'Table Number').id,
            value: tableNumber
          }
        ];
        const body = {
          handoffmode: 'dinein',
          customfieldvalues: customFields
        };
        return this.oloHTTP.post<T>('/orders/byid/' + orderGUID + '/manualfire', body);
      })
    );
  }

  manuallyCancelOrderByID<T>(orderGUID: string): Observable<T> {
    return this.oloHTTP.post<T>('/orders/' + orderGUID + '/cancel');
  }
}
