<div class="popupShell">
  <a class="close" aria-label="Close" style="text-align: right;">
    <div class="popup-x-modal">
      <!--
      <lib-svg-sprite svgID="popup-x" style="cursor: pointer;"></lib-svg-sprite>
      -->
    </div>
  </a>
  <div class="popupHeader mt-3">
    Sorry, your time has elapsed.
  </div>
  <div class="deliveryDiv">
    <div></div>
    <div class="continue-button" (click)="continueLinkClicked()">
      Please select a new time
    </div>
  </div>
</div>
