import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import { ReactiveFormsModule, NgModel, FormsModule } from '@angular/forms';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { GiftCardRoutingModule } from './giftcard-routing.module';
import { GiftCardComponent } from './giftcard.component';

import { ButtonModule } from 'src/assets/chepri-modules/src/lib/button/button.module';
import { SingleTextFieldModule } from 'src/assets/chepri-modules/src/lib/single-text-field/single-text-field.module';

import { GuestFormModule } from 'src/assets/chepri-modules/src/lib/guest-form/guest-form.module';

@NgModule({
  declarations: [GiftCardComponent],
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    ReactiveFormsModule,
    FormsModule,
    Angulartics2Module,
    GiftCardRoutingModule,
    GuestFormModule,
    SingleTextFieldModule,
    ButtonModule
  ]
})
export class GiftCardPageModule {}
