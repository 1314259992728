<div class="d-flex flex-column home-background kiosk-height" *ngIf="basket$ | async as basket">
  <!-- thank you -->
  <section class="d-flex fg-1 fb-0 align-items-center align-self-stretch">
    <div class="container">
      <!-- logo -->
      <div class="d-flex flex-row py-5">
        <div class="fg-1 fb-0 align-self-stretch">
          <img
            class="mw-0 w-100 h-auto px-5"
            src="https://cms.punchpizza.chepri.com/assets/f0d78ad9-3595-4160-88da-d160b2c92454"
            alt="Thank You For Your Order"
          />
        </div>
      </div>

      <!-- order info -->
      <div class="d-flex flex-row flex-wrap fg-1 fb-0 align-self-stretch py-5">
        <!-- your order will be ready at -->
        <div class="display-4 fw-bold text-center text-uppercase text-pp-orange w-100">
          Your {{ basket.previousBasket.deliverymode | handoff }} order<br />will be ready at:
        </div>

        <!-- clock -->
        <div
          class="ty-main-time-text home-times-font w-100 pt-4 pb-5"
          *ngIf="basket.previousBasket.deliverymode !== 'dinein'"
        >
          {{ readyTime | date: 'h:mm' }}<span class="display-3 home-times-font">{{ readyTime | date: 'a' }}</span>
        </div>

        <div
          class="ty-main-time-text home-times-font w-100 pt-4 pb-5"
          *ngIf="basket.previousBasket.deliverymode === 'dinein'"
        >
          {{ basket?.manualFireTime | date: 'h:mm'
          }}<span class="display-3 home-times-font">{{ basket?.manualFireTime | date: 'a' }}</span>
        </div>

        <ng-container *ngIf="basket.previousBasket.deliverymode === 'dinein'">
          <div
            class="confirm-blink confirm-secondary-text please-proceed-text text-white text-uppercase text-center fw-bold w-100 pt-5"
          >
            <div class="w-100 fw-bold">Please proceed to the</div>
            <div class="w-100 fw-bold">check-in counter to get</div>
            <div class="w-100 fw-bold">your table number</div>
          </div>

          <div class="confirm-blink w-100 p-5">
            <hr class="border-white w-100 my-3" />
            <div class="h1 text-white text-uppercase text-center fw-bold m-0 w-100">
              Please don't seat yourself
            </div>
            <hr class="border-white w-100 my-3" />
          </div>
        </ng-container>
        <ng-container *ngIf="basket.previousBasket.deliverymode === 'pickup'">
          <div
            class="confirm-blink confirm-secondary-text please-proceed-text text-white text-uppercase text-center fw-bold w-100 pt-5"
          >
            <div class="w-100 fw-bold">Please pick up your order</div>
            <div class="w-100 fw-bold">at the check-in counter</div>
          </div>
        </ng-container>
      </div>

      <!-- order type btn container -->
      <div class="d-flex flex-row py-5">
        <div class="fg-1 fb-0 align-self-stretch"></div>
        <!-- order type btn row -->
        <div class="d-flex fg-2 fb-0 align-self-stretch flex-row flex-wrap justify-content-center gap-4">
          <!-- dine in btn -->
          <button class="dine-in-btn shadow w-100 py-3" (click)="startOver()">
            New Order
          </button>
        </div>
        <div class="fg-1 fb-0 align-self-stretch"></div>
      </div>
    </div>
  </section>
</div>
