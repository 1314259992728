import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Logger } from '@app/core';
import { Store } from '@ngxs/store';
import { ModeService } from '@app/providers/mode.service';
import { DirectusUserService } from '@app/providers/expo/directus/directus-user.service';
import { DirectusTokenService } from '@app/providers/expo/directus/directus-token.service';
import { CustomerKioskService } from './customer-kiosk.service';

const log = new Logger('KMSGuard');

@Injectable({
  providedIn: 'root'
})
export class CKSGuard  {
  constructor(
    private router: Router,
    private store: Store,
    private directusToken: DirectusTokenService,
    private custKisok: CustomerKioskService,
    private directusUser: DirectusUserService,
    private modeService: ModeService
  ) {
    this.modeService.setApplicationMode('cks');
  }

  canActivate(): boolean {
    if (this.custKisok.isAuthed() && this.custKisok.getStoredToken()) {
      return true;
    } else {
      log.debug('No authentications token for Expo user, redirecting to login...');
      this.router.navigate(['/kiosk-login']);
      return false;
    }
  }
}
