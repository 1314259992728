import { Pipe, PipeTransform } from '@angular/core';
import { OptionGroup } from '@lib/models/olo.optiongroup';

@Pipe({
  name: 'longOptionGroup'
})
export class LongOptionGroupPipe implements PipeTransform {
  transform(optionGroup: OptionGroup): boolean {
    return (
      !!optionGroup.metadata?.some(md => md.key === 'type' && md.value === 'optional') ||
      !!optionGroup.metadata?.some(md => md.key === 'type' && md.value === 'special')
    );
  }
}
