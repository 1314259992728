import { Injectable } from '@angular/core';
import { webSocket, WebSocketSubject } from 'rxjs/webSocket';
import { catchError, tap, switchAll } from 'rxjs/operators';
import { BehaviorSubject, EMPTY, Observable, Subject } from 'rxjs';
import { environment } from '@env/environment';
export const WS_ENDPOINT = environment.wsEndpoint;

@Injectable({
  providedIn: 'root'
})
export class WebSocketsService {
  public socket$: WebSocketSubject<any>;

  public connect(): void {
    if (this.socket$) {
      this.socket$.unsubscribe();
    }
    this.socket$ = this.getNewWebSocket();
  }

  close() {
    this.socket$.complete();
  }

  private getNewWebSocket() {
    if (window.location.href.includes('localhost')) {
      return webSocket(`wss://${environment.wsEndpoint}`);
    } else {
      return webSocket(`wss://${environment.wsEndpoint}`);
    }
  }
}
