<div class="d-flex flex-row" [ngClass]="{ delivery: deliveryZone }">
  <div class="col" style="display: flex; justify-content: center">
    <div *ngIf="!deliveryZone" id="mapboxlocationinput" class="w-100"></div>
    <div *ngIf="deliveryZone" id="mapboxdeliveryinput" class="w-100"></div>
  </div>
</div>

<div class="d-flex flex-row" *ngIf="!deliveryZone">
  <div class="col" style="display: flex; justify-content: center">
    <div class="useLocation">
      <lib-svg-sprite class="searchL clickable" svgID="location"></lib-svg-sprite>
      <a (click)="setCurrentLocation()" class="useLocLink clickable">Use my current location</a>
      <input
        *ngIf="hasButton"
        type="submit"
        value="Search"
        class="searchButton"
        [ngStyle]="{ 'background-color': buttonColor }"
      />
    </div>
  </div>
</div>
