<div class="modal-wrapper justify-content-center">
  <i class="fas fa-times x-close" (click)="close()"></i>
  <h1 class="modal-title text-center text-uppercase fw-bold">{{ title }}</h1>

  <div class="d-flex flex-row text-center justify-content-center pt-4">
    Order for &nbsp;
    <a class="bold-modal-txt text-uppercase">
      {{ guestName }}
    </a>
    &nbsp; at &nbsp;
    <a class="bold-modal-txt text-uppercase">
      {{ readyTime | ShortTimePipe | date: 'h:mm aaaaa' }}
    </a>
  </div>

  <div class="d-flex flex-column text-center justify-content-center pt-3">
    <!-- TODO: ADD ORDER DETAILS HERE -->
    <ng-container *ngFor="let prod of products">
      <div class="w-100">{{ prod.quantity }} - {{ prod.name }}</div>
    </ng-container>
  </div>

  <div class="d-flex flex-row text-center bold-modal-txt justify-content-center py-3">
    <!-- TODO: ADD ORDER TOTAL HERE -->
    Total: ${{ total }}
  </div>

  <div class="d-flex flex-row pt-1 align-items-center">
    <div class="col">
      <lib-button large="true" ghost="true" text="Return" (click)="close()"></lib-button>
    </div>
    <ng-container *ngIf="orderStatus !== 'refunded'">
      <!--      <div class="col">-->
      <!--        <lib-button large="true" text="Refund" (click)="refund()"></lib-button>-->
      <!--      </div>-->
    </ng-container>
  </div>
</div>
