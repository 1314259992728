<div *ngIf="children; else noChildren" ngbDropdown>
  <a *ngIf="caps" class="caps clickable" ngbDropdownToggle class="link-with-children">{{ text }}</a>
  <a *ngIf="edit" class="edit clickable" ngbDropdownToggle class="link-with-children">{{ text }}</a>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic">
    <lib-link
      ngbDropdownItem
      *ngFor="let child of children.links; index as i"
      url="{{ child.url }}"
      text="{{ child.text }}"
      isChild="true"
      (linkClick)="clickEvent(i + 1)"
    >
    </lib-link>
  </div>
</div>
<ng-template #noChildren>
  <a
    *ngIf="url"
    href="{{ url }}"
    (click)="clickEvent()"
    [ngClass]="isChild ? 'child-link clickable' : 'link clickable'"
    >{{ text }}</a
  >
  <a *ngIf="!url" (click)="clickEvent()" class="link-with-no-href clickable">{{ text }}</a>
</ng-template>
