import { NgModule } from '@angular/core';

import { CustomerKioskProductComponent } from './product.component';
import { SharedModule } from '@app/shared';
import { CustomerKioskProductRoutingModule } from './product-routing.module';
import { CustomizeItemModule } from 'src/assets/chepri-modules/src/lib/customize-item/customize-item.module';
import { CoreModule } from '@app/core';
import { CommonModule } from '@angular/common';
import { NavbarModule } from '@app/components/customer-kiosk/navbar/navbar.module';
import { AccessibilityToggleModule } from '@app/components/customer-kiosk/accessibility-toggle/accessibility-toggle.module';

@NgModule({
  declarations: [CustomerKioskProductComponent],
  imports: [
    SharedModule,
    CoreModule,
    CommonModule,
    CustomerKioskProductRoutingModule,
    CustomizeItemModule,
    NavbarModule,
    AccessibilityToggleModule
  ]
})
export class CustomerKioskProductModule {}
