import { Component, OnInit, OnDestroy, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Product } from 'src/assets/chepri-modules/src/models/olo.product';
import { OptionGroup } from 'src/assets/chepri-modules/src/models/olo.optiongroup';
import { Store, Select } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { ProductStateModel, ProductState } from '@app/store/state/product.state';
import {
  BasketProductToAdd,
  BasketProduct,
  BasketChoice
} from 'src/assets/chepri-modules/src/models/olo.basketproduct';
import { AddProductToBasket, UpdateSingleProductInBasket } from '@app/store/actions/basket.action';
import { ToastrService } from 'ngx-toastr';
import { Limiters, Limiter } from 'src/assets/chepri-modules/src/models/Limiter';
import { SetBanner } from '@app/store/actions/app.action';
import { GetProduct, ProdImg } from '@app/store/actions/product.action';
import { map } from 'rxjs/operators';
import { BasketState } from '@app/store/state/basket.state';
import { Basket } from 'src/assets/chepri-modules/src/models/olo.basket';
import { ModeService } from '@app/providers/mode.service';
import { CapacityService } from '@app/providers/capacity.service';
import { RestaurantState } from '@app/store/state/restaurant.state';
import { take } from 'rxjs/operators';
import { Location } from '@angular/common';
import { GlobalStateModel } from '@app/store/state.model';
import { AnalyticsService } from '@app/providers/analytics.service';
@Component({
  selector: 'app-product',
  templateUrl: './product.component.html',
  styleUrls: ['./product.component.scss']
})
export class ProductComponent implements OnInit, OnDestroy {
  @Select() product$: Observable<ProductStateModel>;
  @Select((state: any) => state.basket.basket) basket$: Observable<Basket>;
  @Select((state: any) => state.app.accessibilityMode) accessibilityMode$: Observable<boolean>;
  mode: string;

  loading = false;

  id: string;
  isEdit = false;
  basketProductId: number;
  showEnzoItem = false;
  enzoName: string;
  glutenFreeName: string;
  enzoQuantAvailable: number = 0;

  private subs: Subscription[] = [];

  constructor(
    private route: ActivatedRoute,
    private store: Store,
    private toast: ToastrService,
    private router: Router,
    private modeService: ModeService,
    private capacityService: CapacityService,
    private location: Location,
    private analytics: AnalyticsService
  ) {
    this.mode = this.modeService.getStoredMode();
  }

  ngOnInit() {
    //check enzo
    const restaurant = this.store.selectSnapshot(RestaurantState);
    const products = this.store.selectSnapshot(BasketState).basket.products;
    this.enzoName = restaurant.settings.enzo_modifier_name;
    this.glutenFreeName = restaurant.settings.gluten_free_name;
    this.capacityService.checkEnzoAvailability(restaurant.restaurant.id).subscribe((res: number) => {
      let currentBasketEnzoQuant = 0;
      products.forEach((p: BasketProduct) => {
        p.choices.forEach((c: BasketChoice) => {
          if (c.name === this.enzoName) {
            currentBasketEnzoQuant = currentBasketEnzoQuant + 1 * p.quantity;
          }
        });
      });
      this.enzoQuantAvailable = res - currentBasketEnzoQuant;
      if (this.enzoQuantAvailable > 0) {
        this.showEnzoItem = true;
      }
    });
    this.store.dispatch(new SetBanner('CUSTOMIZE', false, true, ''));
    this.subs.push(
      this.route.params.subscribe(params => {
        this.id = params.id;
        this.store.dispatch(new GetProduct(parseInt(this.id))).subscribe((res: GlobalStateModel) => {
          const productName = res.product.product.name;
          if (this.isEdit) {
            this.store.dispatch(new SetBanner('EDIT ' + productName, false, true, ''));
          } else {
            this.store.dispatch(new SetBanner('CUSTOMIZE', false, true, ''));
          }
          this.analytics.logProductDetailView(res.product.product);
        });
      })
    );
    this.subs.push(
      this.route.queryParamMap.pipe(map(params => params.get('basketProd') || '')).subscribe(val => {
        this.basketProductId = parseInt(val);
        if (this.basketProductId) {
          this.isEdit = true;
          this.store.dispatch(new SetBanner('EDIT ITEM', false, true, ''));
        }
      })
    );
  }

  ngOnDestroy() {
    this.subs.forEach(sub => {
      sub.unsubscribe();
    });
  }

  addToCartEvent(params: [BasketProductToAdd, any]) {
    this.loading = true;
    this.addToCart(...params);
  }

  addToCart(product: BasketProductToAdd, metadata: any) {
    // Check capacity (using variable quantity)
    const limiters = new Limiters(this.store.selectSnapshot(state => state.basket.capacity.items));
    if (!this.isEdit && metadata && limiters && limiters.hasCategory(parseInt(metadata[0].value))) {
      const limiterState = limiters.items[limiters.find(parseInt(metadata[0].value))];
      const limiter = new Limiter(limiterState.categoryId, limiterState.max, limiterState.products);
      if (limiter.isFull() || !limiter.canAdd(product.quantity)) {
        this.toast.warning(
          `Cannot add more than ${limiter.max} items of this type. You currently have ${limiter.length()}.`
        );
        this.loading = false;
      } else {
        this.addOrUpdateItem(product, metadata);
      }
    } else if (metadata && limiters) {
      // Has variable quantity, so check if it is too high
      const max = parseInt(metadata[1].value);
      if (product.quantity > max) {
        this.toast.warning(`Cannot add more than ${max} items of this type.`);
        this.loading = false;
      } else {
        this.addOrUpdateItem(product, metadata);
      }
    } else {
      // No metadata or isEdit
      this.addOrUpdateItem(product, metadata);
    }
  }

  checkEnzoQuantity(product: BasketProductToAdd): number {
    let quant = 0;
    this.product$.pipe(take(1)).subscribe(p => {
      p.modifiers.optiongroups.forEach(og => {
        if (og.options && og.options.length) {
          og.options.forEach(op => {
            if (product.options.includes(op.id.toString())) {
              if (op.name === this.enzoName) {
                quant = product.quantity;
              }
            }
          });
        }
      });
    });
    return quant;
  }

  cksNavBack() {
    this.location.back();
  }

  addOrUpdateItem(product: BasketProductToAdd, metadata?: any) {
    if (this.checkEnzoQuantity(product) > 0) {
      if (this.checkEnzoQuantity(product) > this.enzoQuantAvailable) {
        this.toast.warning('Sorry, Gluten-Free is sold out.');
        this.loading = false;
        return;
      }
    }
    if (!this.isEdit) {
      this.store.dispatch(new AddProductToBasket(product, metadata)).subscribe(
        res => {
          this.loading = false;
          if (this.modeService.isCurrentMode('kms')) {
            this.router.navigateByUrl('/kiosk/menu');
          } else if (this.modeService.isCurrentMode('cks')) {
            this.router.navigateByUrl('/cks/menu');
          } else {
            this.router.navigateByUrl('/menu');
          }
        },
        err => {
          this.loading = false;
          if (err.error && err.error.message) {
            this.toast.warning(err.error.message);
          } else {
            this.toast.warning('Something went wrong: ' + err.message);
          }
        }
      );
    } else {
      const products: BasketProduct[] = this.store.selectSnapshot(BasketState).basket.products;
      const currentItem = products.find(p => p.id == this.basketProductId);

      this.store.dispatch(new UpdateSingleProductInBasket(this.basketProductId.toString(), product, false)).subscribe(
        res => {
          this.loading = false;
          const newItem: BasketProduct = res.basket.basket.products.find(
            (p: BasketProduct) => p.id == this.basketProductId
          );
          if (product.quantity !== newItem.quantity) {
            this.toast.warning('Failed to update item, maximum order size exceeded');
          } else {
            this.toast.success('Product updated!');
            if (this.modeService.isCurrentMode('kms')) {
              this.router.navigateByUrl('/kiosk/menu');
            } else if (this.modeService.isCurrentMode('cks')) {
              this.router.navigateByUrl('/cks/menu');
            } else {
              this.router.navigateByUrl('/menu');
            }
          }
        },
        err => {
          this.loading = false;
          this.toast.warning(err.error.message);
        }
      );
    }
  }
}
