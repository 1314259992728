<div class="modal-wrapper justify-content-center">
  <i class="fas fa-times x-close" (click)="close()"></i>
  <h1 class="modal-title text-center text-uppercase fw-bold">{{ title }}</h1>
  <div class="d-flex flex-row text-center justify-content-center align-items-center pt-4">
    <a class="bold-modal-txt text-uppercase">{{ guestName }}</a>
  </div>
  <div class="d-flex flex-row text-center justify-content-center align-items-center py-3">
    <div class="d-flex w-fit-content pe-2">
      Party of:
    </div>
    <div class="d-flex w-fit-content pe-2">
      <select
        name="partSize"
        class="btn party-dropdown dropdown-toggle"
        (change)="partySize = $event.target.value.toString()"
      >
        <option
          class="dropdown-item"
          [value]="i + 1"
          *ngFor="let num of Arr(maxTableSize).fill(1); index as i"
          [selected]="partySize === (i + 1).toString()"
          >{{ i + 1 }}</option
        >
      </select>
    </div>
    <div class="d-flex w-fit-content">
      at <a class=" ps-2 bold-modal-txt text-uppercase">{{ readyTime | ShortTimePipe | date: 'h:mm aaaaa' }}</a>
    </div>
  </div>

  <div class="d-flex flex-row text-center align-items-center pt-1 pb-4">
    <div class="col text-end align-middle pe-2">
      Seating Preference:
    </div>
    <div class="col ps-0 align-top">
      <div class="btn-group btn-group-toggle" data-toggle="buttons">
        <label
          class="btn ghostSelector"
          [ngClass]="{ 'ghostSelector-active': tablePreference === 'inside' }"
          (click)="tablePreference = 'inside'"
        >
          Inside
        </label>
        <label
          class="btn ghostSelector"
          [ngClass]="{ 'ghostSelector-active': tablePreference === 'outside' }"
          (click)="tablePreference = 'outside'"
        >
          Outside
        </label>
        <label
          class="btn ghostSelector"
          [ngClass]="{ 'ghostSelector-active': tablePreference === 'any' }"
          (click)="tablePreference = 'any'"
        >
          Any
        </label>
      </div>
    </div>
  </div>

  <div class="d-flex flex-row pt-4">
    <div class="col">
      <lib-button text="Return" ghost="true" (click)="close()"></lib-button>
    </div>
    <div class="col">
      <lib-button text="Confirm" (click)="confirmButtonClicked()"></lib-button>
    </div>
  </div>
</div>
