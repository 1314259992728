import { Injectable } from '@angular/core';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class DirectusErrorService {
  constructor(private router: Router) {}

  onError(e: any) {
    localStorage.removeItem('currentLocation');
    localStorage.removeItem('locationDirectusID');
    localStorage.removeItem('authToken');
    this.router.navigateByUrl('/kiosk-expo');
    return e;
  }
}
