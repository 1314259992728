<div class="container-fluider mb-5" *ngIf="restaurant$ | async as restaurant">
  <div *ngIf="restaurant.menu as menu">
    <div *ngIf="app$ | async as app" style="padding-top: env(safe-area-inset-top, 0)">
      <lib-olo-kiosk-menu
        [categories]="menu.categories"
        [imagePath]="menu.imagepath"
        defaultImageUrl="assets/images/default.png"
        (customizeClick)="navigateToProduct($event)"
        (addToCartClick)="addProductToCart($event, null)"
        [hasScrolled]="app.header"
        [selectedProdID]="selectedProdID"
      >
      </lib-olo-kiosk-menu>
    </div>
  </div>
</div>

<ng-template #message let-messageModal>
  <lib-scheduled-message-modal
    [message]="activeMessage"
    (xClicked)="messageModal.dismiss()"
  ></lib-scheduled-message-modal>
</ng-template>
