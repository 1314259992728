import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-svg-sprite',
  templateUrl: './svg-sprite.component.html',
  styles: []
})
export class SvgSpriteComponent {
  @Input() svgID: string;
  @Input() big: boolean;
}
