import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, AbstractControl } from '@angular/forms';

@Component({
  selector: 'lib-sign-up-form',
  templateUrl: './sign-up-form.component.html',
  styles: []
})
export class SignUpFormComponent implements OnInit {
  @Input() isCheckout = false;
  @Input() emailListText = 'Check here to join The Punch List for updates, deals and more!';
  @Input() termsAndConditionsText = 'Accept Terms and Conditions';
  @Input() createAccountButtonText = 'CREATE ACCOUNT';
  @Input() signInButtonText = 'SIGN IN';
  @Input() continueAsGuestText = 'CONTINUE AS GUEST';

  @Output() signUpSubmitEvent = new EventEmitter<UntypedFormGroup>();
  @Output() leavePageEvent = new EventEmitter<string>();

  createAccountForm: UntypedFormGroup;
  errorMessage = '';
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.createAccountForm = this.fb.group({
      emailAddress: ['', [Validators.required, Validators.email]],
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      passwords: this.fb.group(
        {
          password: ['', Validators.required],
          confirm: ['', Validators.required]
        },
        { validator: this.passwordConfirming }
      ),
      phoneNumber: ['', Validators.required],
      emailOptIn: [true],
      smsOptIn: [false]
    });
    // this.createAccountForm.valueChanges.subscribe(newVal => console.log(newVal));
  }

  clickEvent(identifier?: string) {
    this.leavePageEvent.emit(identifier);
  }

  submitForm(form?: UntypedFormGroup) {
    this.signUpSubmitEvent.emit(this.createAccountForm);
  }

  passwordConfirming(formGroup: AbstractControl): { invalid: boolean } {
    if (formGroup.get('password').value !== formGroup.get('confirm').value) {
      if (formGroup.get('password').touched && formGroup.get('password').touched) {
      }
      return { invalid: true };
    }
  }
}
