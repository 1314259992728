import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { WaitTimeRoutingModule } from './wait-time-routing.module';
import { LocationSelectionComponent } from './location-selection/location-selection.component';
import { WaitTimeDisplayComponent } from './wait-time-display/wait-time-display.component';
import { TimeDisplayComponent } from './time-display/time-display.component';
import {SharedModule} from '@app/shared';


@NgModule({
  declarations: [LocationSelectionComponent, WaitTimeDisplayComponent, TimeDisplayComponent],
  imports: [
    CommonModule,
    WaitTimeRoutingModule,
    SharedModule,
  ],
})
export class WaitTimeModule { }
