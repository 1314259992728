<div class="modal-wrapper justify-content-center">
  <i class="fas fa-times x-close" (click)="clickedX()"></i>
  <h1 class="modal-title text-center text-uppercase fw-bold">{{ title }}</h1>

  <div class="d-flex flex-column text-center justify-content-center pt-4">
    You've Selected:
    <div class="bold-modal-txt">
      {{ locationName }}
    </div>
  </div>

  <div class="d-flex flex-column text-center align-items-center py-3">
    Please confirm that this is the correct location.
    <br />
    (You can change locations by logging out.)
  </div>

  <div class="d-flex flex-row gap-3 flex-nowrap pt-1">
    <div class="col ps-0 pe-0">
      <lib-button text="Cancel" ghost="true" large="true" (click)="clickedChangeLocation()"></lib-button>
    </div>
    <div class="col pe-0">
      <lib-button large="true" id="confirmLocBtn" text="Confirm" (click)="clickedConfirm()"></lib-button>
    </div>
  </div>
</div>
