<div class="kiosk-checkout-section" *ngIf="(basket$ | async).basket as basket">
  <!-- Order summary -->
  <div class="d-flex flex-row align-center kiosk-section">
    <div class="col p-0">
      <div class="d-flex flex-row">
        <div class="col p-0 text-center">
          <div class="kiosk-chkout-title">
            {{ basket.deliverymode === 'dinein' ? 'Digital Dine-in' : 'Takeout' }} order Summary
          </div>
        </div>
      </div>
      <div
        class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto"
        *ngFor="let prod of basket.products"
      >
        <div class="p-0 text-start">
          <div class="kiosk-chkout-prod-name">{{ prod.name }} ({{ prod.quantity }})</div>
          <ng-container *ngIf="cleanChoices(prod.choices) as choices">
            <span *ngFor="let choice of choices; index as i">
              {{ choice.quantity > 1 ? choice.quantity + ' x ' : '' }}{{ choice.name
              }}{{ choice.cost ? ' + ' + (choice.cost * choice.quantity | currency) : ''
              }}{{ i < choices.length - 1 ? ', ' : '' }}
            </span>
          </ng-container>
          <ng-container *ngIf="prod.specialinstructions">
            <div style="margin-top: 20px">Special Instructions: {{ prod.specialinstructions }}</div>
          </ng-container>
          <div class="d-flex flex-row mt-3">
            <div class=" p-0 pe-2" style="width: 150px">
              <lib-button text="EDIT" (click)="editItem(prod)"></lib-button>
            </div>
            <div class=" p-0 ps-2">
              <lib-button text="Remove" ghost="true" (click)="deleteItem(prod)"></lib-button>
            </div>
          </div>
        </div>
        <div class="p-0 text-end">
          {{ prod.totalcost | currency }}
        </div>
      </div>
    </div>
  </div>
  <div class="kiosk-chckout-divider"></div>

  <!-- Select a time -->
  <div class="d-flex flex-row kiosk-section">
    <div class="col p-0">
      <div class="d-flex flex-row">
        <div class="col p-0 text-center">
          <div class="kiosk-chkout-title">
            Select A {{ basket.deliverymode === 'dinein' ? 'Digital Dine-In' : 'Takeout' }} Time
          </div>
        </div>
      </div>
      <div class="d-flex flex-row justify-content-between mx-auto">
        <div class="col p-0 text-center">
          If you edit the order then you will need to reselect a time.
        </div>
      </div>
      <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
        <div class="col p-0 text-start">
          <div>{{ basket.deliverymode === 'dinein' ? 'Digital Dine-In' : 'Takeout' }} Time</div>
          <select
            [formControl]="time"
            *ngIf="(basket$ | async).timeSlots as timeSlots"
            class="kiosk-input-field"
            id="time-select"
          >
            <option>Select Time</option>
            <option *ngFor="let availableTime of timeSlots; index as i" [value]="i">
              {{ availableTime.key }}
            </option>
            <option *ngIf="!timeSlots">Sorry, Sold Out</option>
          </select>
        </div>
      </div>
    </div>
  </div>
  <div class="kiosk-chckout-divider"></div>

  <!-- Name and Party Size -->
  <div class="d-flex flex-row kiosk-section">
    <div class="col p-0">
      <div class="d-flex flex-row">
        <div class="col p-0 text-center">
          <div class="kiosk-chkout-title">
            Confirm Name {{ basket.deliverymode === 'dinein' ? '& Party Size' : '' }}
          </div>
        </div>
      </div>
      <form [formGroup]="userForm">
        <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
          <div class="col p-0 pe-2 text-start">
            <div>First Name</div>
            <input placeholder="First Name" class="kiosk-input-field ps-3" formControlName="firstName" />
          </div>
          <div class="col p-0 ps-2 text-start">
            <div>Last Name</div>
            <input placeholder="Last Name" class="kiosk-input-field ps-3" formControlName="lastName" />
          </div>
        </div>
        <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
          <div class="col p-0 pe-2 text-start">
            <div>Email Address (Optional)</div>
            <input placeholder="Email Address" class="kiosk-input-field ps-3" formControlName="emailAddress" />
          </div>
        </div>
      </form>
      <div
        *ngIf="basket.deliverymode === 'dinein'"
        class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto"
      >
        <div class="col p-0 text-start">
          <div>Party Size</div>
          <lib-party-size
            [maxTableSize]="maxTableSize"
            [selectedTableSize]="selectedTableSize"
            (tableSizeEmit)="tableSizeSelected($event)"
          >
          </lib-party-size>
        </div>
      </div>
    </div>
  </div>

  <ng-container *ngIf="basket.deliverymode === 'dinein'">
    <div class="kiosk-chckout-divider"></div>

    <div class="d-flex flex-row kiosk-section">
      <div class="col p-0">
        <div class="d-flex flex-row">
          <div class="col p-0 text-center">
            <div class="kiosk-chkout-title">Table Number</div>
          </div>
        </div>
        <form [formGroup]="tableNumberForm">
          <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
            <div class="col p-0 pe-1 couponTextField">
              <input
                placeholder="Table Number (if checking in immediately)"
                class="kiosk-input-field ps-3"
                label="Table Number"
                formControlName="tableNumberControl"
                inputmode="numeric"
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  </ng-container>

  <div class="kiosk-chckout-divider"></div>

  <!-- Tip the crew -->
  <div class="d-flex flex-row kiosk-section" *ngIf="showTip">
    <div class="col p-0">
      <div class="d-flex flex-row">
        <div class="col p-0 text-center">
          <div class="kiosk-chkout-title">Tip The Crew</div>
        </div>
      </div>
    </div>
  </div>
  <div class="kiosk-chckout-divider" *ngIf="showTip"></div>

  <!-- Coupon Code -->
  <div class="d-flex flex-row kiosk-section">
    <div class="col p-0">
      <div class="d-flex flex-row">
        <div class="col p-0 text-center">
          <div class="kiosk-chkout-title">Coupon Code</div>
        </div>
      </div>
      <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
        <div class="col p-0 text-start">
          <div>Comp Codes</div>
          <select
            *ngIf="couponCollection"
            class="kiosk-input-field"
            id="coupon-select"
            [attr.disabled]="basket.coupon && basket.coupon.couponcode"
            (change)="onCouponChange($event.target.value)"
          >
            <option>Select Comp Coupon</option>
            <option *ngFor="let coupon of couponCollection; index as i" [value]="coupon.coupon_code">
              {{ coupon.coupon_name }}
            </option>
            <option *ngIf="!couponCollection">Sorry, No Available Coupons</option>
          </select>
        </div>
      </div>
      <form [formGroup]="couponPromoForm">
        <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
          <div class="col-8 p-0 pe-1 couponTextField">
            <input
              placeholder="Coupon Code"
              class="kiosk-input-field ps-3"
              label="Coupon / Promo Code"
              formControlName="couponCode"
              [attr.disabled]="basket.coupon && basket.coupon.couponcode"
            />
          </div>
          <div class="col-4 p-0 ps-1 couponButton pe-0">
            <ng-container *ngIf="basket.coupon && basket.coupon.couponcode; else noCoupon">
              <button class="kiosk-checkout-btn" (click)="removeCoupon()">REMOVE</button>
            </ng-container>
            <ng-template #noCoupon>
              <button class="kiosk-checkout-btn" (click)="applyCoupon(couponCode)">APPLY</button>
            </ng-template>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="kiosk-chckout-divider"></div>

  <!-- Tip Section -->
  <div class="d-flex flex-row kiosk-section">
    <div class="col p-0">
      <div class="d-flex flex-row">
        <div class="col p-0 text-center">
          <div class="kiosk-chkout-title">Tip</div>
        </div>
      </div>
      <form [formGroup]="tipForm">
        <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
          <div class="col-8 p-0 pe-1 couponTextField">
            <input
              placeholder="$0.00"
              class="kiosk-input-field ps-3"
              label="Tip"
              formControlName="tip"
              type="number"
              inputmode="decimal"
            />
          </div>
          <div class="col-4 p-0 ps-1 couponButton pe-0">
            <ng-container *ngIf="basket.tip; else noTip">
              <button class="kiosk-checkout-btn" (click)="removeTip()">REMOVE</button>
            </ng-container>
            <ng-template #noTip>
              <button class="kiosk-checkout-btn" (click)="applyTip(tip.value)">APPLY</button>
            </ng-template>
          </div>
        </div>
      </form>
    </div>
  </div>
  <div class="kiosk-chckout-divider"></div>

  <!-- First Payment Method -->
  <div class="d-flex flex-row kiosk-section">
    <div class="col p-0">
      <div class="d-flex flex-row">
        <div class="col p-0 text-center">
          <div class="kiosk-chkout-title">Payment Method</div>
        </div>
      </div>
      <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
        <div class="col p-0">
          <p>
            <input
              type="radio"
              id="creditcard"
              checked
              value="creditcard"
              (change)="newPaymentSelected()"
              [formControl]="firstPaymentMethod"
              [disabled]="fullComp === true"
            />
            <label for="creditcard" [ngClass]="{ 'checkbox-disabled': fullComp === true }">Credit/Debit Card</label>
          </p>
          <p>
            <input
              type="radio"
              id="paywithcash"
              value="paywithcash"
              (change)="newPaymentSelected()"
              [formControl]="firstPaymentMethod"
              [disabled]="fullComp === true"
            />
            <label for="paywithcash" [ngClass]="{ 'checkbox-disabled': fullComp === true }">Pay with Cash</label>
          </p>
          <!-- first Pay with Cash -->
          <div *ngIf="firstPaymentMethod.value === 'paywithcash'">
            <form [formGroup]="firstCashPayment">
              <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto mb-3">
                <div class="col-8 p-0 pe-1 couponTextField">
                  <div>Cash Received</div>
                  <input
                    id="cashReceived"
                    type="number"
                    placeholder="$0.00"
                    class="kiosk-input-field ps-3 numbers-only"
                    formControlName="amount"
                    inputmode="decimal"
                  />
                </div>
                <div class="col-4 p-0 ps-1 couponButton pe-0 mt-4">
                  <button id="cashReceivedBtn" class="kiosk-checkout-btn" (click)="cashPaymentAdded(0)">Apply</button>
                </div>
              </div>
              <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto mb-3" *ngIf="changeDue > 0">
                <div class="col p-0 pe-1 couponTextField">
                  <div>Change Due: {{ changeDue | currency }}</div>
                </div>
              </div>
            </form>
          </div>
          <p>
            <input
              type="radio"
              id="giftcard"
              value="giftcard"
              [formControl]="firstPaymentMethod"
              (change)="newPaymentSelected()"
              [disabled]="fullComp === true"
            />
            <label for="giftcard" [ngClass]="{ 'checkbox-disabled': fullComp === true }">Gift Card</label>
          </p>
          <!-- first gift card -->
          <div *ngIf="firstPaymentMethod.value === 'giftcard'">
            <form [formGroup]="firstGiftCardForm">
              <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
                <div class="col p-0 text-start">
                  <div>Gift Card Number</div>
                  <input
                    placeholder="_ _ _ _ _ - _ _ _ _ - _ _ _ _ _"
                    class="kiosk-input-field ps-3"
                    formControlName="cardNumber"
                    mask="00000-0000-00000"
                  />
                </div>
              </div>
              <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
                <div class="col-8 p-0 pe-1 couponTextField">
                  <div>Gift Card Pin</div>
                  <input placeholder="_ _ _ _ _ _" class="kiosk-input-field ps-3" formControlName="pinNumber" />
                </div>
                <div class="col-4 p-0 ps-1 couponButton pe-0 mt-4">
                  <button class="kiosk-checkout-btn" (click)="scanGiftCard(0)">Scan Card</button>
                </div>
              </div>
              <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
                <div class="col p-0">
                  <button class="kiosk-checkout-btn" (click)="checkBalance(0, null)">Apply Gift Card</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ng-container *ngIf="fullComp">
        <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
          <div class="col p-0 text-center">
            <p class="full-comp">No payment needed. The basket is fully compensated.</p>
          </div>
        </div>
      </ng-container>
    </div>
  </div>

  <!-- Second Payment Method -->
  <div class="d-flex flex-row kiosk-section" *ngIf="needsSecondPayment && allowSplitPayment">
    <div class="col p-0">
      <div class="d-flex flex-row">
        <div class="col p-0 text-center">
          <div class="kiosk-chkout-title">Second Payment Method</div>
        </div>
      </div>
      <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
        <div class="col p-0">
          <p *ngIf="firstPaymentMethod.value !== 'creditcard'">
            <input
              type="radio"
              id="secondcreditcard"
              checked
              value="secondcreditcard"
              (change)="removeGiftCardIndex(1)"
              [formControl]="secondPaymentMethod"
            />
            <label for="secondcreditcard">Credit/Debit Card</label>
          </p>
          <div *ngIf="secondPaymentMethod.value === 'secondcreditcard'" class="mb-4">
            <form [formGroup]="creditCardPayment">
              <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
                <div class="col p-0 text-start">
                  <div>Credit Card Number</div>
                  <input
                    [mask]="currentCardMask"
                    (input)="checkAndSwitchMask($event)"
                    placeholder="_ _ _ _ - _ _ _ _ - _ _ _ _ - _ _ _ _"
                    class="kiosk-input-field ps-3"
                    formControlName="cardNumber"
                  />
                </div>
              </div>
              <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
                <div class="col p-0 pe-1 couponTextField">
                  <div>Exp Date</div>
                  <input
                    placeholder="_ _ / _ _"
                    mask="00/00"
                    class="kiosk-input-field ps-3"
                    formControlName="expDate"
                  />
                </div>
                <div class="col p-0 ps-1 couponTextField">
                  <div>CVV Code</div>
                  <input
                    placeholder="_ _ _"
                    [mask]="currentCVVMask"
                    class="kiosk-input-field ps-3"
                    formControlName="cvvCode"
                  />
                </div>
              </div>
              <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
                <div class="col p-0 pe-1 couponTextField">
                  <div>Zip Code</div>
                  <input
                    placeholder="_ _ _ _ _"
                    [mask]="currentZIPMask"
                    class="kiosk-input-field ps-3"
                    formControlName="zipCode"
                  />
                </div>
              </div>
            </form>
          </div>
          <p *ngIf="firstPaymentMethod.value !== 'paywithcash'">
            <input
              type="radio"
              id="secondpaywithcash"
              value="secondpaywithcash"
              (change)="removeGiftCardIndex(1)"
              [formControl]="secondPaymentMethod"
            />
            <label for="secondpaywithcash">Finish with Cash</label>
          </p>
          <!-- second Pay with Cash -->
          <div *ngIf="secondPaymentMethod.value === 'secondpaywithcash'">
            <form [formGroup]="secondCashPayment">
              <div class="d-flex flex-row kiosk-inside-row mb-3">
                <div class="col-8 p-0 pe-1 couponTextField">
                  <div>Cash Received</div>
                  <input
                    id="cashReceived"
                    type="number"
                    placeholder="$0.00"
                    class="kiosk-input-field ps-3 numbers-only"
                    formControlName="amount"
                    inputmode="decimal"
                  />
                </div>
                <div class="col-4 p-0 ps-1 couponButton pe-0 mt-4">
                  <button id="cashReceivedBtn" class="kiosk-checkout-btn" (click)="cashPaymentAdded(1)">Apply</button>
                </div>
              </div>
              <div class="d-flex flex-row kiosk-inside-row mb-3" *ngIf="changeDue > 0">
                <div class="col p-0 pe-1 couponTextField">
                  <div>Change Due: {{ changeDue | currency }}</div>
                </div>
              </div>
            </form>
          </div>
          <p *ngIf="firstPaymentMethod.value !== 'paywithcash'">
            <input type="radio" id="secondgiftcard" value="secondgiftcard" [formControl]="secondPaymentMethod" />
            <label for="secondgiftcard">Gift Card</label>
          </p>
          <!-- second gift card -->
          <div *ngIf="secondPaymentMethod.value === 'secondgiftcard'">
            <form [formGroup]="secondGiftCardForm">
              <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
                <div class="col p-0 text-start">
                  <div>Gift Card Number</div>
                  <input
                    placeholder="_ _ _ _ _ - _ _ _ _ - _ _ _ _ _"
                    class="kiosk-input-field ps-3"
                    formControlName="cardNumber"
                    mask="00000-0000-00000"
                  />
                </div>
              </div>
              <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
                <div class="col-8 p-0 pe-1 couponTextField">
                  <div>Gift Card Pin</div>
                  <input placeholder="_ _ _ _ _ _" class="kiosk-input-field ps-3" formControlName="pinNumber" />
                </div>
                <div class="col-4 p-0 ps-1 couponButton pe-0 mt-4">
                  <button class="kiosk-checkout-btn" (click)="scanGiftCard(1)">Scan Card</button>
                </div>
              </div>
              <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
                <div class="col p-0">
                  <button class="kiosk-checkout-btn" (click)="checkBalance(1, null)">Apply Gift Card</button>
                </div>
              </div>
            </form>
          </div>
          <div *ngIf="basket$ | async as stateBasket">
            <!-- finish with credit card -->
            <div *ngIf="stateBasket && stateBasket.giftCard && stateBasket.giftCard.length === 2 && needsSecondPayment">
              <div class="d-flex flex-row mt-4">
                <div class="col p-0 text-center">
                  <div class="kiosk-chkout-title">Finish with Card</div>
                </div>
              </div>
              <form [formGroup]="creditCardPayment">
                <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
                  <div class="col p-0 text-start">
                    <div>Credit Card Number</div>
                    <input
                      [mask]="currentCardMask"
                      (input)="checkAndSwitchMask($event)"
                      placeholder="_ _ _ _ - _ _ _ _ - _ _ _ _ - _ _ _ _"
                      class="kiosk-input-field ps-3"
                      formControlName="cardNumber"
                    />
                  </div>
                </div>
                <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
                  <div class="col p-0 pe-1 couponTextField">
                    <div>Exp Date</div>
                    <input
                      placeholder="_ _ / _ _"
                      mask="00/00"
                      class="kiosk-input-field ps-3"
                      formControlName="expDate"
                    />
                  </div>
                  <div class="col p-0 ps-1 couponTextField">
                    <div>CVV Code</div>
                    <input
                      placeholder="_ _ _"
                      [mask]="currentCVVMask"
                      class="kiosk-input-field ps-3"
                      formControlName="cvvCode"
                    />
                  </div>
                </div>
                <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
                  <div class="col p-0 pe-1 couponTextField">
                    <div>Zip Code</div>
                    <input
                      placeholder="_ _ _ _ _"
                      [mask]="currentZIPMask"
                      class="kiosk-input-field ps-3"
                      formControlName="zipCode"
                    />
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="kiosk-chckout-divider"></div>

  <!-- Totals Section -->
  <div class="d-flex flex-row kiosk-section" *ngIf="basket$ | async as chepribasket">
    <div class="col p-0">
      <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
        <div class="col p-0">
          <div class="d-flex flex-row">
            <div class="col p-0 text-start">
              <div>Subtotal</div>
            </div>
            <div class="col p-0 text-end">
              <div>
                {{
                  (chepribasket.basketValidation
                    ? chepribasket.basketValidation.subtotal
                    : chepribasket.basket.subtotal
                  ) | currency
                }}
              </div>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="col p-0 text-start">
              <div class="mt-3">Tax</div>
            </div>
            <div class="col p-0 text-end">
              <div class="mt-3">
                {{
                  (chepribasket.basketValidation ? chepribasket.basketValidation.tax : chepribasket.basket.salestax)
                    | currency
                }}
              </div>
            </div>
          </div>
          <ng-container *ngIf="chepribasket.basket?.coupondiscount && chepribasket.basket?.coupondiscount > 0">
            <div class="d-flex flex-row">
              <div class="col p-0 text-start">
                <div class="mt-3">Coupon</div>
              </div>
              <div class="col p-0 text-end">
                <div class="mt-3">
                  -{{ (chepribasket.basket.coupondiscount > 0 ? chepribasket.basket.coupondiscount : 0) | currency }}
                </div>
              </div>
            </div>
          </ng-container>
          <div class="d-flex flex-row">
            <div class="col p-0 text-start">
              <div class="mt-3">Tip</div>
            </div>
            <div class="col p-0 text-end">
              <div class="mt-3">
                {{ chepribasket.basket.tip | currency }}
              </div>
            </div>
          </div>
          <div class="d-flex flex-row" *ngIf="cashPaymentTotal > 0">
            <div class="col p-0 text-start">
              <div class="mt-3">Cash Applied</div>
            </div>
            <div class="col p-0 text-end">
              <div class="mt-3">-{{ cashPaymentTotal | currency }}</div>
            </div>
          </div>
          <ng-container *ngIf="chepribasket.applyGiftCard">
            <div class="d-flex flex-row" *ngFor="let card of chepribasket.giftCard; index as i">
              <div class="col p-0 text-start">
                <div class="mt-3 gifttext">
                  Gift Card
                  <span
                    >- <span>*{{ card.cardnumber.substr(card.cardnumber.length - 4) }}</span></span
                  >
                </div>
              </div>
              <div class="col p-0 text-end" *ngIf="i != chepribasket.giftCard.length - 1">
                <div class="mt-3 gifttext">-{{ chepribasket.giftCard[i].balance | currency }}</div>
              </div>
              <div class="col p-0 text-end" *ngIf="i === chepribasket.giftCard.length - 1">
                <div *ngIf="chepribasket.basketValidation; else noValidation">
                  <div class="mt-3 gifttext" *ngIf="chepribasket.basketValidation.total <= giftCardBalance">
                    -{{
                      chepribasket.basketValidation.total - (giftCardBalance - chepribasket.giftCard[i].balance)
                        | currency
                    }}
                  </div>
                  <div class="mt-3 gifttext" *ngIf="chepribasket.basketValidation.total > giftCardBalance">
                    -{{ chepribasket.giftCard[i].balance | currency }}
                  </div>
                </div>
                <ng-template #noValidation>
                  <div class="mt-3 gifttext" *ngIf="chepribasket.basket.total <= giftCardBalance">
                    -{{ chepribasket.basket.total - (giftCardBalance - chepribasket.giftCard[i].balance) | currency }}
                  </div>
                  <div class="mt-3 gifttext" *ngIf="chepribasket.basket.total > giftCardBalance">
                    -{{ chepribasket.giftCard[i].balance | currency }}
                  </div>
                </ng-template>
              </div>
            </div>
          </ng-container>
          <div class="d-flex flex-row">
            <!-- Regular totals -->
            <div class="col p-0 text-start">
              <div class="mt-3 kiosk-chkout-prod-name">Total</div>
            </div>
            <div class="col p-0 text-end">
              <div class="mt-3 kiosk-chkout-prod-name" *ngIf="!chepribasket.applyGiftCard">
                {{
                  (chepribasket.basketValidation
                    ? chepribasket.basketValidation.total - cashPaymentTotal < 0
                      ? 0
                      : chepribasket.basketValidation.total - cashPaymentTotal
                    : chepribasket.basket.total - cashPaymentTotal < 0
                    ? 0
                    : chepribasket.basket.total - cashPaymentTotal
                  ) | currency
                }}
              </div>
              <div class="mt-3 kiosk-chkout-prod-name" *ngIf="chepribasket.applyGiftCard">
                {{
                  (chepribasket.basketValidation
                    ? chepribasket.basketValidation.total - cashPaymentTotal - giftCardBalance < 0
                      ? 0
                      : chepribasket.basketValidation.total - cashPaymentTotal - giftCardBalance
                    : chepribasket.basket.total - cashPaymentTotal - giftCardBalance < 0
                    ? 0
                    : chepribasket.basket.total - cashPaymentTotal - giftCardBalance
                  ) | currency
                }}
              </div>
            </div>
          </div>
          <div class="d-flex flex-row kiosk-inside-row justify-content-between mx-auto">
            <div class="col p-0">
              <button class="kiosk-checkout-btn" id="submitOrderBtn" (click)="submitOrder()">
                <div *ngIf="!isLoading">Submit Order</div>
                <div class="spin-loader" *ngIf="isLoading"></div>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
