import { Injectable } from '@angular/core';
import { ProductService } from '@app/providers/product.service';
import { ToastrService } from 'ngx-toastr';
import { PrinterDeviceService } from '@app/providers/expo/printer/printer-device.service';

declare var StarWebPrintBuilder: any;
declare var StarWebPrintTrader: any;

interface ICanvasData {
  fontSize: number;
  lineSpace: number;
  receiptWidth: number;
  logoScale: number;
}

@Injectable({
  providedIn: 'root'
})
export class PrinterService {
  protocol = 'https://';
  printerIPAddress = '0.0.0.0';
  endpoint = '/StarWebPRNT/SendMessage';

  builder = new StarWebPrintBuilder();
  trader = new StarWebPrintTrader({ url: this.protocol + this.printerIPAddress + this.endpoint });

  cursor = 0;
  lineSpace = 0;
  leftPosition = 0;
  centerPosition = 0;
  rightPosition = 0;
  paperWidth: 'inch2' | 'inch3' | 'inch4' = 'inch3';
  canvasID = 'canvasPaper';
  context: CanvasRenderingContext2D;
  canvasData: ICanvasData;

  paperConfigs = {
    inch2: {
      width: 384,
      height: 555,
      canvasData: {
        fontSize: 28,
        lineSpace: 28,
        receiptWidth: 384,
        logoScale: 1
      }
    },
    inch3: {
      width: 576,
      height: 640,
      canvasData: {
        fontSize: 32,
        lineSpace: 32,
        receiptWidth: 576,
        logoScale: 1.5
      }
    },
    inch4: {
      width: 832,
      height: 952,
      canvasData: {
        fontSize: 48,
        lineSpace: 48,
        receiptWidth: 832,
        logoScale: 2
      }
    }
  };

  constructor(private printerDevice: PrinterDeviceService, private toast: ToastrService) {
    const storedAddress = this.printerDevice.getStoredPrinterIPAddress();
    if (storedAddress) {
      this.printerIPAddress = storedAddress;
      this.trader = new StarWebPrintTrader({ url: this.protocol + this.printerIPAddress + this.endpoint });
    }
  }

  setPrinterAddress(ipAddress: string) {
    this.printerDevice.updateStoredPrinterIPAddress(ipAddress);
    this.printerIPAddress = ipAddress;
    this.trader = new StarWebPrintTrader({ url: this.protocol + this.printerIPAddress + this.endpoint });
  }

  onSendMessageCanvas(makeReceipt: () => void) {
    const canvas = this.getCanvas();
    if (canvas && canvas.getContext) {
      this.context = canvas.getContext('2d');
      this.canvasData = this.paperConfigs[this.paperWidth].canvasData;
      this.buildMessageCanvas(makeReceipt);
      this.sendMessageCanvas(makeReceipt);
    }
  }

  sendMessageCanvas(makeReceipt: () => void) {
    //   showNowPrinting(); // start loading here

    const image = new Image();
    image.crossOrigin = 'Anonymous';

    image.src = 'https://cms.punchpizza.chepri.com/assets/fe4e0ad4-8661-402a-97c2-7a619ed5629e';
    const scale = 0.25; // this additional scaling is image specific
    const totalScale = this.canvasData.logoScale * scale;
    const scaledWidth = image.width * totalScale;
    const scaledHeight = image.height * totalScale;

    const canvas = this.getCanvas();
    if (canvas && canvas.getContext) {
      const context = canvas.getContext('2d');

      image.onload = () => {
        // context.drawImage(image, (canvas.width / 2) + (-scaledWidth / 2), (canvas.height / 2) + (-scaledHeight / 2), scaledWidth, scaledHeight); // direct center

        this.trader.onReceive = (response: any) => {
          // hideNowPrinting(); // stop loading here

          let msg = '';
          // msg += 'PrinterSuccess:\n' + response.traderSuccess + '\n';
          // msg += 'TraderCode:\n' + response.traderCode + '\n';
          // msg += 'Status:\n' + response.traderStatus + '\n';

          if (this.trader.isCoverOpen({ traderStatus: response.traderStatus })) {
            msg += '\tCover Open,\n';
          }
          if (this.trader.isOffLine({ traderStatus: response.traderStatus })) {
            msg += '\tOff Line,\n';
          }
          if (this.trader.isCompulsionSwitchClose({ traderStatus: response.traderStatus })) {
            msg += '\tCompulsion Switch Close,\n';
          }
          if (this.trader.isEtbCommandExecute({ traderStatus: response.traderStatus })) {
            msg += '\tEtb Command Execute,\n';
          }
          if (this.trader.isHighTemperatureStop({ traderStatus: response.traderStatus })) {
            msg += '\tHigh Temperature Stop,\n';
          }
          if (this.trader.isNonRecoverableError({ traderStatus: response.traderStatus })) {
            msg += '\tNon Recoverable Error,\n';
          }
          if (this.trader.isAutoCutterError({ traderStatus: response.traderStatus })) {
            msg += '\tAuto Cutter Error,\n';
          }
          if (this.trader.isBlackMarkError({ traderStatus: response.traderStatus })) {
            msg += '\tBlack Mark Error,\n';
          }
          if (this.trader.isPaperEnd({ traderStatus: response.traderStatus })) {
            msg += '\tPaper End,\n';
          }
          if (this.trader.isPaperNearEnd({ traderStatus: response.traderStatus })) {
            msg += '\tPaper Near End,\n';
          }

          // msg += '\tEtbCounter = ' + this.trader.extractionEtbCounter({traderStatus:response.traderStatus}).toString() + ' ]\n';
          // msg += 'Status : [ ' + response.status + ' ]\n';
          // msg += 'ResponseText : [ ' + response.responseText + ' ]\n';
          if (msg === '') {
            this.toast.success('Printed Receipt');
          } else {
            this.toast.warning(msg);
          }
        };

        this.trader.onError = (response: any) => {
          // hideNowPrinting(); // stop loading
          let msg = '';
          // msg += '\tStatus:' + response.status + '\n';
          msg += 'Error:' + response.responseText;
          this.toast.error(msg);
        };

        try {
          let request = '';

          canvas.height = this.cursor;
          this.setDefaultsSettings(context);
          makeReceipt();
          context.drawImage(image, canvas.width / 2 + -scaledWidth / 2, 0, scaledWidth, scaledHeight); // top center

          request += this.builder.createInitializationElement();
          request += this.builder.createBitImageElement({
            context: context,
            x: 0,
            y: 0,
            width: canvas.width,
            height: Math.ceil(canvas.height)
          });
          request += this.builder.createCutPaperElement({ feed: true });

          this.trader.sendMessage({ request: request });
        } catch (e) {
          // hideNowPrinting(); // stop loading
          this.toast.error(e.message);
        }
      };
    }

    image.onerror = () => {
      // hideNowPrinting(); // stop loading
      this.toast.error('Image file was not able to be loaded.');
    };
  }

  buildMessageCanvas(makeReceipt: () => void) {
    const canvas = this.getCanvas();
    if (canvas && canvas.getContext) {
      const context = canvas.getContext('2d');

      this.clearReceipt(canvas, context);
      this.setDefaultsSettings(context);
      makeReceipt();
      // this.getWYSIWYGReceipt(context);
      console.log('Cursor:' + this.cursor + ', ' + 'Canvas:' + canvas.height);
    }
  }

  onResizeCanvas() {
    // this happens on paper size change
    const canvas = this.getCanvas();
    if (canvas) {
      canvas.width = this.paperConfigs[this.paperWidth].width;
      canvas.height = this.paperConfigs[this.paperWidth].height;
      document.getElementById('canvasPaper').style.width = '700px';
    }
  }

  setDefaultsSettings(context: CanvasRenderingContext2D) {
    context.font = this.canvasData.fontSize + 'px ' + 'Arial';
    // this.cursor = 0;
    this.cursor = 55 * this.canvasData.logoScale;
  }

  getWYSIWYGReceipt(context: CanvasRenderingContext2D) {
    // this would be where we get the WYSIWYG receipt and translate it into the printer format

    this.drawLineSpace(this.canvasData.lineSpace, 3);

    this.drawAlignedText('center', this.centerPosition, 'Test');
    this.drawLineSpace(this.canvasData.lineSpace, 1);
  }

  clearReceipt(canvas: HTMLCanvasElement, context: CanvasRenderingContext2D) {
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.textBaseline = 'top';
    // context.textAlign = 'start';
    this.leftPosition = 0;
    this.centerPosition = canvas.width / 2;
    this.rightPosition = canvas.width;
    // this.centerPosition = (canvas.width - 16) / 2;
    // this.rightPosition = (canvas.width - 16);
  }

  drawAlignedText(align: CanvasTextAlign, position: number, text: any) {
    const context = this.getCanvasContext();
    if (context) {
      context.textAlign = align;
      context.fillText(text, position, this.cursor);
      context.textAlign = 'start';
    }
  }

  drawLineSpace(lineSpace: number, count: number) {
    for (let i = 0; i < count; i += 1) {
      this.cursor += lineSpace;
    }
  }

  getCanvasContext(): CanvasRenderingContext2D | false {
    const canvas = this.getCanvas();
    if (canvas && canvas.getContext) {
      return canvas.getContext('2d');
    } else {
      return false;
    }
  }

  getCanvas(): HTMLCanvasElement | false {
    const canvas = document.getElementById(this.canvasID) as HTMLCanvasElement;
    if (canvas) {
      return canvas;
    } else {
      return false;
    }
  }

  // onSendMessageApi() {
  // let request = '';
  //
  // request += this.builder.createInitializationElement();
  //
  // switch (this.paperWidth) {
  //   case 'inch2' :
  //     request += this.builder.createTextElement({characterspace:2});
  //     break;
  //   case 'inch3DotImpact' :
  //     request += this.builder.createTextElement({characterspace:2});
  //     break;
  //   case 'inch4' :
  //     request += this.builder.createTextElement({characterspace:1});
  //     break;
  //   default :
  //     request += this.builder.createTextElement({characterspace:0});
  //     break;
  // }
  //
  // request += this.builder.createAlignmentElement({position:'right'});
  // request += this.builder.createLogoElement({number:1});
  // request += this.builder.createTextElement({data:'TEL 9999-99-9999\n'});
  //
  // request += this.builder.createAlignmentElement({position:'left'});
  // request += this.builder.createTextElement({data:'\n'});
  //
  // request += this.builder.createAlignmentElement({position:'center'});
  // request += this.builder.createTextElement({data:'Thank you for your coming.\n'});
  // request += this.builder.createTextElement({data:'We hope you\'ll visit again.\n'});
  //
  // request += this.builder.createAlignmentElement({position:'left'});
  // request += this.builder.createTextElement({data:'\n'});
  //
  // switch (this.paperWidth) {
  //   case 'inch2':
  //     request += this.builder.createTextElement({data:'Apple                $20.00\n'});
  //     request += this.builder.createTextElement({data:'Banana               $30.00\n'});
  //     request += this.builder.createTextElement({data:'Grape                $40.00\n'});
  //     request += this.builder.createTextElement({data:'Lemon                $50.00\n'});
  //     request += this.builder.createTextElement({data:'Orange               $60.00\n'});
  //     request += this.builder.createTextElement({emphasis:true, data:'Subtotal            $200.00\n'});
  //     request += this.builder.createTextElement({data:'\n'});
  //
  //     request += this.builder.createTextElement({underline:true, data:'Tax                  $10.00\n'});
  //     request += this.builder.createTextElement({underline:false});
  //
  //     request += this.builder.createTextElement({emphasis:true});
  //     request += this.builder.createTextElement({width:2, data:'Total'});
  //     request += this.builder.createTextElement({width:1, data:'   '});
  //     request += this.builder.createTextElement({width:2, data:'$210.00\n'});
  //     request += this.builder.createTextElement({width:1});
  //     request += this.builder.createTextElement({emphasis:false});
  //
  //     request += this.builder.createTextElement({data:'\n'});
  //
  //     request += this.builder.createTextElement({data:'Received            $300.00\n'});
  //
  //     request += this.builder.createTextElement({width:2, data:'Change'});
  //     request += this.builder.createTextElement({width:1, data:'   '});
  //     request += this.builder.createTextElement({width:2, data:'$90.00\n'});
  //     break;
  //   case 'inch3DotImpact':
  //     request += this.builder.createTextElement({data:'Apple                               $20.00\n'});
  //     request += this.builder.createTextElement({data:'Banana                              $30.00\n'});
  //     request += this.builder.createTextElement({data:'Grape                               $40.00\n'});
  //     request += this.builder.createTextElement({data:'Lemon                               $50.00\n'});
  //     request += this.builder.createTextElement({data:'Orange                              $60.00\n'});
  //     request += this.builder.createTextElement({emphasis:true, data:'Subtotal                           $200.00\n'});
  //     request += this.builder.createTextElement({data:'\n'});
  //
  //     request += this.builder.createTextElement({underline:true, data:'Tax                                 $10.00\n'});
  //     request += this.builder.createTextElement({underline:false});
  //
  //     request += this.builder.createTextElement({emphasis:true});
  //     request += this.builder.createTextElement({width:2, data:'Total         $210.00\n'});
  //     request += this.builder.createTextElement({width:1});
  //     request += this.builder.createTextElement({emphasis:false});
  //
  //     request += this.builder.createTextElement({data:'\n'});
  //
  //     request += this.builder.createTextElement({data:'Received                           $300.00\n'});
  //
  //     request += this.builder.createTextElement({width:2, data:'Change         $90.00\n'});
  //     break;
  //   case 'inch4':
  //     request += this.builder.createTextElement({data:'Apple                                                     $20.00\n'});
  //     request += this.builder.createTextElement({data:'Banana                                                    $30.00\n'});
  //     request += this.builder.createTextElement({data:'Grape                                                     $40.00\n'});
  //     request += this.builder.createTextElement({data:'Lemon                                                     $50.00\n'});
  //     request += this.builder.createTextElement({data:'Orange                                                    $60.00\n'});
  //     request += this.builder.createTextElement({emphasis:true, data:'Subtotal                                                 $200.00\n'});
  //     request += this.builder.createTextElement({data:'\n'});
  //
  //     request += this.builder.createTextElement({underline:true, data:'Tax                                                       $10.00\n'});
  //     request += this.builder.createTextElement({underline:false});
  //
  //     request += this.builder.createTextElement({emphasis:true});
  //     request += this.builder.createTextElement({width:2, data:'Total                    $210.00\n'});
  //     request += this.builder.createTextElement({width:1});
  //     request += this.builder.createTextElement({emphasis:false});
  //
  //     request += this.builder.createTextElement({data:'\n'});
  //
  //     request += this.builder.createTextElement({data:'Received                                                 $300.00\n'});
  //
  //     request += this.builder.createTextElement({width:2, data:'Change                    $90.00\n'});
  //     break;
  //   default :
  //     request += this.builder.createTextElement({data:'Apple                                     $20.00\n'});
  //     request += this.builder.createTextElement({data:'Banana                                    $30.00\n'});
  //     request += this.builder.createTextElement({data:'Grape                                     $40.00\n'});
  //     request += this.builder.createTextElement({data:'Lemon                                     $50.00\n'});
  //     request += this.builder.createTextElement({data:'Orange                                    $60.00\n'});
  //     request += this.builder.createTextElement({emphasis:true, data:'Subtotal                                 $200.00\n'});
  //     request += this.builder.createTextElement({data:'\n'});
  //
  //     request += this.builder.createTextElement({underline:true, data:'Tax                                       $10.00\n'});
  //     request += this.builder.createTextElement({underline:false});
  //
  //     request += this.builder.createTextElement({emphasis:true});
  //     request += this.builder.createTextElement({width:2, data:'Total            $210.00\n'});
  //     request += this.builder.createTextElement({width:1});
  //     request += this.builder.createTextElement({emphasis:false});
  //
  //     request += this.builder.createTextElement({data:'\n'});
  //
  //     request += this.builder.createTextElement({data:'Received                                 $300.00\n'});
  //
  //     request += this.builder.createTextElement({width:2, data:'Change            $90.00\n'});
  //     break;
  // }
  //
  // request += this.builder.createTextElement({width:1});
  // request += this.builder.createTextElement({data:'\n'});
  // request += this.builder.createTextElement({characterspace:0});
  //
  // request += this.builder.createCutPaperElement({feed:true});
  //
  // sendMessageApi(request);
  // }

  // sendMessageApi(request) {
  // showNowPrinting();
  //
  // let url = document.getElementById('url').value;
  // let trader = new StarWebPrintTrader({url:url});
  //
  // this.trader.onReceive = function (response) {
  //   hideNowPrinting();
  //
  //   let msg = '- onReceive -\n\n';
  //   msg += 'TraderSuccess : [ ' + response.traderSuccess + ' ]\n';
  //   // msg += 'TraderCode : [ ' + response.traderCode + ' ]\n';
  //   msg += 'TraderStatus : [ ' + response.traderStatus + ',\n';
  //
  //   if (this.trader.isCoverOpen            ({traderStatus:response.traderStatus})) {msg += '\tCoverOpen,\n';}
  //   if (this.trader.isOffLine              ({traderStatus:response.traderStatus})) {msg += '\tOffLine,\n';}
  //   if (this.trader.isCompulsionSwitchClose({traderStatus:response.traderStatus})) {msg += '\tCompulsionSwitchClose,\n';}
  //   if (this.trader.isEtbCommandExecute    ({traderStatus:response.traderStatus})) {msg += '\tEtbCommandExecute,\n';}
  //   if (this.trader.isHighTemperatureStop  ({traderStatus:response.traderStatus})) {msg += '\tHighTemperatureStop,\n';}
  //   if (this.trader.isNonRecoverableError  ({traderStatus:response.traderStatus})) {msg += '\tNonRecoverableError,\n';}
  //   if (this.trader.isAutoCutterError      ({traderStatus:response.traderStatus})) {msg += '\tAutoCutterError,\n';}
  //   if (this.trader.isBlackMarkError       ({traderStatus:response.traderStatus})) {msg += '\tBlackMarkError,\n';}
  //   if (this.trader.isPaperEnd             ({traderStatus:response.traderStatus})) {msg += '\tPaperEnd,\n';}
  //   if (this.trader.isPaperNearEnd         ({traderStatus:response.traderStatus})) {msg += '\tPaperNearEnd,\n';}
  //
  //   msg += '\tEtbCounter = ' + this.trader.extractionEtbCounter({traderStatus:response.traderStatus}).toString() + ' ]\n';
  //   // msg += 'Status : [ ' + response.status + ' ]\n';
  //   // msg += 'ResponseText : [ ' + response.responseText + ' ]\n';
  //
  //   alert(msg);
  // }
  //
  // this.trader.onError = function (response) {
  //   hideNowPrinting();
  //   let msg = '- onError -\n\n';
  //   msg += '\tStatus:' + response.status + '\n';
  //   msg += '\tResponseText:' + response.responseText;
  //   alert(msg);
  // }
  //
  // this.trader.sendMessage({request:request});
  // }
}
