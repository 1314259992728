import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LocationLandingComponent } from './location-landing.component';
import { DeliveryZonePopupModule } from 'src/assets/chepri-modules/src/lib/delivery-zone-popup/delivery-zone-popup.module';

// Lib Dependencies
import { LocationInfoModule } from '../location-info/location-info.module';
import { ButtonModule } from '../button/button.module';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';
import { DeliveryZoneFailModule } from '../delivery-zone-fail/delivery-zone-fail.module';

@NgModule({
  declarations: [LocationLandingComponent],
  imports: [
    CommonModule,
    DeliveryZoneFailModule,
    SvgSpriteModule,
    LocationInfoModule,
    ButtonModule,
    SvgSpriteModule,
    DeliveryZonePopupModule
  ],
  exports: [LocationLandingComponent]
})
export class LocationLandingModule {}
