import { Pipe, PipeTransform } from '@angular/core';
import { SavedAccount } from '@app/models/saved-account.olo';

@Pipe({
  name: 'savedCardDedupe'
})
export class SavedCardDedupePipe implements PipeTransform {
  transform(cards: SavedAccount[]): SavedAccount[] {
    return cards.filter(
      (card, index) =>
        cards.findIndex(c => `${c.description}${c.expiration}` === `${card.description}${card.expiration}`) === index
    );
  }
}
