<div class="position-relative">
  <ng-container *ngIf="basketCreated">
    <lib-svg-sprite svgID="menu-bag"></lib-svg-sprite>
    <span
      *ngIf="productCount > 0"
      class="position-absolute top-0 start-100 translate-middle badge rounded-pill bg-primary"
    >
      {{ productCount }}<span class="visually-hidden">products in cart</span>
    </span>
  </ng-container>
</div>
