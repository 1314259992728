import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { UserStateModel } from '@app/store/state/user.state';
import { Select, Store } from '@ngxs/store';
import { Logout } from '@app/store/actions/user.action';
import { RestaurantStateModel } from '@app/store/state/restaurant.state';
import { CredentialsService } from '../../../../../app/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { StartOverModalComponent } from '@app/components/start-over-modal/start-over-modal.component';
import { EmptyBasket } from '@app/store/actions/basket.action';
import { Router } from '@angular/router';
import { TrancloudProviderService } from '../../../../../app/datacap-trancloud/services/trancloud-provider.service';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { PinPadDeviceService } from '../../../../../app/datacap-trancloud/services/pin-pad-device.service';
import { PrinterService } from '../../../../../app/providers/expo/printer/printer.service';
import { take } from 'rxjs/operators';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-menu-slide',
  templateUrl: './menu-slide.component.html',
  styles: []
})
export class MenuSlideComponent implements OnInit {
  @Select() user$: Observable<UserStateModel>;
  @Select() restaurant$: Observable<RestaurantStateModel>;

  @Input() mode: string;
  @Input() links = [
    {
      text: 'home',
      url: '/locations'
    },
    {
      text: 'locations',
      url: '/locations'
    },
    {
      text: 'menu',
      url: '/menu'
    },
    {
      text: 'Sign in',
      url: '/sign-in'
    }
  ];

  @Output() closeBar = new EventEmitter<any>();
  @Output() kioskSignOutEmit = new EventEmitter<any>();
  @Select((state: any) => state.user.isMaster) isMaster$: Observable<number>;

  pinPadConfigForm: UntypedFormGroup;
  printerConfigForm: UntypedFormGroup;
  pinPadEditMode = false;
  printerEditMode = false;
  errorMessage = '';
  docElem = document.documentElement;
  fullScreen: boolean;
  connectLoading = false;
  syncLoading = false;

  constructor(
    private store: Store,
    public credService: CredentialsService,
    private modalService: NgbModal,
    private tranCloud: TrancloudProviderService,
    private fb: UntypedFormBuilder,
    private pinPadAddress: PinPadDeviceService,
    private router: Router,
    private printer: PrinterService,
    private toastr: ToastrService
  ) {}

  ngOnInit() {
    this.pinPadConfigForm = this.fb.group({
      selectedPinPad: [this.pinPadAddress.pinPadMACAddress, [Validators.required]]
    });
    this.printerConfigForm = this.fb.group({
      printerAddress: [this.printer.printerIPAddress, [Validators.required]]
    });
  }

  stopProp(e: Event) {
    e.stopPropagation();
  }

  closeHam() {
    this.closeBar.emit();
  }

  startOver() {
    this.closeBar.emit();

    const startOverModal = this.modalService.open(StartOverModalComponent, {
      centered: true,
      keyboard: false,
      backdrop: 'static'
    });
    startOverModal.componentInstance.xClicked.subscribe(() => {
      this.modalService.dismissAll();
    });
    startOverModal.componentInstance.confirmStartOver.subscribe(() => {
      this.modalService.dismissAll();
      this.store.dispatch(new EmptyBasket());
      this.router.navigateByUrl('kiosk/start-order');
    });
  }

  logout() {
    this.store.dispatch(new Logout());
    this.closeHam();
  }

  kioskSignOut() {
    this.closeBar.emit();
    this.kioskSignOutEmit.emit();
  }

  // only intended for use on chrome
  toggleFullScreen() {
    if (this.isFullScreen()) {
      console.log(document);
      document.exitFullscreen();
    } else {
      this.docElem.requestFullscreen();
    }
  }

  isFullScreen() {
    return screen.width === window.innerWidth;
  }

  connectPinPad() {
    this.connectLoading = true;
    const macaddress = this.pinPadConfigForm.get('selectedPinPad').value;
    this.user$.pipe(take(1)).subscribe((user: UserStateModel) => {
      user.cardReaders.forEach(cr => {
        if (cr.mac_ip_address === macaddress) {
          this.tranCloud
            .checkConnection(
              cr.mac_ip_address,
              cr.pin_pad_type === 'lane3000' ? 'EMV_LANE3000' : 'EMV_LINK2500',
              cr.pin_pad_provider === 'datacap' ? 'DATACAP_E2E' : 'HEARTLAND',
              cr.use_mac_address ? 'MAC' : 'IP'
            )
            .subscribe(
              () => {
                this.connectLoading = false;
                this.toastr.success('PinPad Connected!');
              },
              error => {
                this.connectLoading = false;
                this.toastr.error('Could not connect to PinPad. '.concat(error));
              }
            );
        }
      });
    });
  }

  syncPinPad() {
    this.syncLoading = true;
    const macaddress = this.pinPadConfigForm.get('selectedPinPad').value;
    this.user$.pipe(take(1)).subscribe((user: UserStateModel) => {
      user.cardReaders.forEach(cr => {
        if (cr.mac_ip_address === macaddress) {
          this.tranCloud
            .syncPinPad(
              cr.mac_ip_address,
              cr.pin_pad_type === 'lane3000' ? 'EMV_LANE3000' : 'EMV_LINK2500',
              cr.pin_pad_provider === 'datacap' ? 'DATACAP_E2E' : 'HEARTLAND',
              cr.use_mac_address ? 'MAC' : 'IP'
            )
            .subscribe(
              _ => {
                this.syncLoading = false;
                this.toastr.success('PinPad Updated');
              },
              error => {
                this.syncLoading = false;
                this.toastr.error(error);
              }
            );
        }
      });
    });
  }

  savePrinterAddress() {
    this.printerEditMode = false;
    this.syncPrinter();
  }

  syncPrinter() {
    this.printer.setPrinterAddress(this.printerConfigForm.get('printerAddress').value);
  }

  testPrinter() {
    this.printer.onSendMessageCanvas(this.generateReceipt.bind(this));
  }

  generateReceipt() {
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 3);
    this.printer.drawAlignedText('center', this.printer.centerPosition, 'Test Print');
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
  }

  inputChanged() {
    this.errorMessage = '';
  }
}
