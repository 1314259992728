import { Pipe, PipeTransform } from '@angular/core';
import { ResponseSavedProductChoice } from '@app/models/saved-products.olo';

export interface OptionWithAvailability {
  name: string;
  available: boolean;
  deleted: boolean;
}
@Pipe({
  name: 'savedProductChoices'
})
export class SavedProductChoicesPipe implements PipeTransform {
  transform(choices: ResponseSavedProductChoice[]): OptionWithAvailability[] {
    return choices
      .map(choice => {
        return {
          name: choice.name,
          available: choice.availability.available,
          deleted: choice.availability.isdeleted
        };
      })
      .reverse()
      .filter(choice => {
        return !(
          choice.name.toLowerCase().includes('included') || choice.name.toLowerCase().includes('additional toppings')
        );
      });
  }
}
