import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'lib-guest-info-modal',
  templateUrl: './guest-info-modal.component.html',
  styleUrls: ['./guest-info-modal.component.scss']
})
export class GuestInfoModalComponent {
  @Output() startOrderClicked = new EventEmitter<any>();
  @Output() confirmClicked = new EventEmitter<any>();
  @Output() xClicked = new EventEmitter<any>();

  @Input() type: string;

  userForm = new UntypedFormGroup({
    firstName: new UntypedFormControl(),
    lastName: new UntypedFormControl()
  });

  maxTableSize: Number = 8;
  selectedTableSize: number = null;
  title = 'Guest Information';
  isLoading = false;

  constructor(private toastr: ToastrService) {}

  startOrder(): void {
    this.isLoading = true;
    if (this.userForm.get('firstName').value && this.userForm.get('lastName').value) {
      if (this.type === 'dinein' && this.selectedTableSize === null) {
        this.toastr.warning('Please select a Party Size');
        this.isLoading = false;
        return;
      }
      const guestInfo = {
        firstName: this.userForm.get('firstName').value,
        lastName: this.userForm.get('lastName').value,
        partySize: this.selectedTableSize ? this.selectedTableSize : null
      };
      this.startOrderClicked.emit(guestInfo);
    } else {
      this.toastr.warning('Please enter a First and Last Name');
      this.isLoading = false;
    }
  }

  clickedX() {
    this.xClicked.emit();
  }

  clickedConfirm(): void {
    this.confirmClicked.emit();
  }

  tableSizeSelected(index: number) {
    this.selectedTableSize = index;
  }
}
