<div *ngIf="(basket$ | async).basket as basket; else noBasket">
  <div *ngIf="user$ | async as user">
    <lib-navbar
      [centerJustified]="false"
      [hasCart]="true"
      [productCount]="reduceQuantity(basket)"
      (routeClickEvent)="routeTo($event)"
      (bagClicked)="toggleBag(content)"
      [mode]="modeService.applicationMode$ | async"
    >
    </lib-navbar>
  </div>
</div>
<ng-container *ngIf="(modeService.applicationMode$ | async) !== 'cks' && (modeService.applicationMode$ | async) !== 'wait-time'">
  <div *ngIf="app$ | async as app" class="headerHero headerHeroScroll" id="headerHero1">
    <lib-header-hero
      [orderInfoTop]="orderInfoTop"
      [hasScrolled]="app.header"
      [mode]="modeService.applicationMode$ | async"
    >
    </lib-header-hero>
  </div>
</ng-container>
<ng-template #content let-modal>
  <div class="bag-triangle"></div>
  <lib-navbar
    class="navbarBag"
    [centerJustified]="false"
    [hasCart]="false"
    [bagNavbar]="true"
    (close)="modal.dismiss()"
    [mode]="modeService.applicationMode$ | async"
  >
  </lib-navbar>
  <div class="bagHeaderHero">
    <div class="yourOrderMobile">Your Order</div>
  </div>
  <div class="modal-body p-0">
    <app-my-bag (checkout)="modal.dismiss()" (closeModal)="modal.dismiss()"></app-my-bag>
  </div>
</ng-template>
<ng-template #noBasket>
  <lib-navbar
    [centerJustified]="false"
    [hasCart]="false"
    (routeClickEvent)="routeTo($event)"
    [mode]="modeService.applicationMode$ | async"
  >
  </lib-navbar>
</ng-template>

<router-outlet (activate)="onActivate($event)"></router-outlet>
