import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ModeService {
  modeKey = 'modeKey';
  applicationMode$ = new BehaviorSubject<string>('order');
  availableModes = ['order', 'kms', 'cks', 'wait-time'];

  constructor() {
    this.setApplicationMode('order');
  }

  setApplicationMode(newMode: string): boolean {
    if (this.availableModes.includes(newMode)) {
      this.applicationMode$.next(newMode);
      localStorage.setItem(this.modeKey, newMode);
      return true;
    }
    return false;
  }

  isCurrentMode(mode: string): boolean {
    return mode === this.getStoredMode();
  }

  getStoredMode(): string {
    return localStorage.getItem(this.modeKey);
  }
}
