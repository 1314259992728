import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';

import { ButtonModule } from 'src/assets/chepri-modules/src/lib/button/button.module';
import { SingleTextFieldModule } from 'src/assets/chepri-modules/src/lib/single-text-field/single-text-field.module';
import { PartySizeModule } from 'src/assets/chepri-modules/src/lib/party-size/party-size.module';

import { GuestInfoModalComponent } from './guest-info-modal.component';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule, NgModel, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [GuestInfoModalComponent],
  imports: [
    CoreModule,
    SharedModule,
    CommonModule,
    ButtonModule,
    SingleTextFieldModule,
    SharedModule,
    PartySizeModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [GuestInfoModalComponent]
})
export class GuestInfoModalModule {}
