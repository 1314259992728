import { Component, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { BasketStateModel } from '@app/store/state/basket.state';
import { Select } from '@ngxs/store';
import { RestaurantStateModel } from '@app/store/state/restaurant.state';

@Component({
  selector: 'lib-order-info',
  templateUrl: './order-info.component.html',
  styles: []
})
export class OrderInfoComponent {
  @Input() location: any;
  @Input() basket: any;
  @Input() orderInfoTop: boolean;
  @Input() orderInfoBag: boolean;
  @Select() basket$: Observable<BasketStateModel>;
  @Select() restaurant$: Observable<RestaurantStateModel>;
}
