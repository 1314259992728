<div *ngIf="basket$ | async as basket" class="d-flex flex-row">
  <div
    *ngIf="basket && basket.previousBasket; else loading"
    class="col-lg-8 col-md-12 orderDetailsLeftCol"
    align="center"
  >
    <div class="insideInfo" style="text-align:left;">
      <p class="headerFont">YOUR ORDER HAS BEEN PLACED!</p>
      <h5 class="confirmId">Order #{{ basket?.previousBasket?.oloid }}</h5>
      <div class="d-flex flex-row instructionsRow">
        <div class="col-12 noPaddingCol">
          <span *ngIf="orderType == 'pickup'" class="instructionsHeaderLeft">Pickup Instructions</span>
          <span *ngIf="orderType == 'dispatch'" class="instructionsHeaderLeft">Delivery Instructions</span>
          <span *ngIf="orderType == 'dinein'" class="instructionsHeaderLeft"
            >PLEASE MAKE NOTE OF YOUR DINE-IN TIME!</span
          >
          <p *ngIf="orderType == 'pickup'">{{ pickupInstructions }}</p>
          <p *ngIf="orderType == 'dispatch' || orderType == 'delivery'">
            {{ basket.previousBasket.deliveryaddress.specialinstructions }}
          </p>
          <div *ngIf="orderType == 'dinein'">
            <div class="mt-2 text-start">
              <p>
                <span class="bold text-pp-orange">PLEASE NOTE:</span> Our pizzas take only
                <span class="bold">MINUTES</span> to make. Since our pizzas are <span class="bold">BEST</span> right out
                of the oven, we don’t fire your pizzas until <span class="bold">AFTER</span> you check in.
              </p>
              <ol>
                <li>
                  Please arrive and check in at <strong class="bold">{{ getTime(basket?.manualFireTime) }}</strong> (or
                  up to 10 minutes before).
                </li>
                <li>Look for the hanging “CHECK-IN” sign at the back of the restaurant (where we cut pizzas).</li>
                <li>
                  Let us know you are a <strong class="bold">"Digital Dine-in” Customer</strong> and the name on your
                  order.
                </li>
                <li>We’ll give you a table number and you can be seated.</li>
                <li>Your pizzas will be the next ones made – it only takes a few minutes!</li>
              </ol>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-row locationConfirmCol">
        <div *ngIf="basket?.previousBasket?.deliverymode == 'pickup' || 'dinein'" class="col noPaddingCol">
          <div *ngIf="(restaurant$ | async).restaurant as restaurant">
            <lib-location-info [location]="restaurant" isOrderConfirmation="true"></lib-location-info>
          </div>
        </div>
        <div
          *ngIf="basket?.previousBasket?.deliverymode == 'dispatch' || basket.previousBasket.deliverymode == 'delivery'"
          class="col noPaddingCol"
          style="text-align: left"
        >
          <ng-container *ngIf="user$ | async as user">
            <ng-container *ngIf="user.info?.authtoken; else noAuth">
              <!-- Use user info -->
              <p class="bold m-0">{{ user.info.firstname }} {{ user.info.lastname }}</p>
            </ng-container>
            <ng-template #noAuth>
              <!-- Use guest info -->
              <p class="bold m-0">{{ basket.billingInfo.firstname }} {{ basket.billingInfo.lastname }}</p>
            </ng-template>
          </ng-container>
          <ng-container *ngIf="basket.previousBasket.deliveryaddress as address">
            <p class="m-0">{{ address.streetaddress }}</p>
            <p class="m-0">{{ address.city }}, {{ address.zipcode }}</p>
          </ng-container>
        </div>
        <div class="col noPaddingCol" style="text-align: right;">
          <span *ngIf="orderType == 'pickup'" style="font-family: brandonBold; margin-bottom: 0px;">Takeout Time</span>
          <span *ngIf="orderType == 'dinein'" style="font-family: brandonBold; margin-bottom: 0px;"
            >Please check in with the host at:</span
          >
          <span *ngIf="orderType == 'dispatch'" style="font-family: brandonBold; margin-bottom: 0px;"
            >Estimated Delivery</span
          >
          <p style="font-family: brandonBold; font-size: 32px;" *ngIf="orderType !== 'dinein'">
            {{ getTime(basket?.previousBasket?.readytime) }}
          </p>
          <p style="font-family: brandonBold; font-size: 32px;" *ngIf="orderType === 'dinein'">
            {{ getTime(basket?.manualFireTime) }}
          </p>
        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="col-12 noPaddingCol">
          <div *ngIf="restaurant$ | async as restaurant" style="margin-bottom: 20px;">
            <lib-map
              *ngIf="orderType == 'pickup' || 'dinein'"
              [locationsMap]="true"
              [userLocation]="restaurant.restaurant"
              [zoomLevel]="14"
              [locations]="restaurant.restaurants"
              [confirmLocation]="true"
            ></lib-map>
            <lib-map
              *ngIf="orderType == 'dispatch' || orderType == 'delivery'"
              [locationsMap]="true"
              [zoomLevel]="14"
              [fitBounds]="false"
              [userLocation]="restaurant.location"
              [locations]="restaurant.location"
              [confirmLocation]="true"
            ></lib-map>
          </div>
          <lib-button
            *ngIf="orderType == 'pickup' || 'dinein'"
            large="true"
            text="GET DIRECTIONS"
            (click)="getDirections()"
          >
          </lib-button>
          <div class="col faqLinkCol">
            <a class="faqLink" href="https://punchpizza.com/faq/">FREQUENTLY ASKED QUESTIONS</a><br />
            <a class="faqLink" href="https://www.iubenda.com/privacy-policy/12495188">PRIVACY POLICY</a><br />
            <a class="faqLink" href="https://www.iubenda.com/terms-and-conditions/12495188">TERMS AND CONDITIONS</a
            ><br />
            <a class="faqLink" href="mailto:feedback@punchpizza.com">SEND FEEDBACK</a>
          </div>
        </div>
      </div>
      <div class="d-flex d-lg-none flex-column gap-3 mobileBagItem">
        <div class="lineBorder"></div>
        <div class="col-12 noPaddingCol">
          <div *ngIf="(basket$ | async).previousBasket as basket">
            <lib-bag-item *ngFor="let product of basket.products" [product]="product" isCheckoutBag="true">
            </lib-bag-item>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="basket && basket.previousBasket" class="col-lg-4 col-sm-12 orderDetailsRightCol">
    <div *ngIf="(basket$ | async).previousBasket as basket">
      <app-checkout-bag [basket]="basket" isConfirmation="true" (nextPageEvent)="continueToNextPage()">
      </app-checkout-bag>
    </div>
  </div>
</div>

<ng-template #loading>
  <div style="position: absolute; top: 50%; left: 50%">
    <div class="spin-loader-black"></div>
  </div>
</ng-template>
