import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '@env/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class TranCloudHttpService {
  private baseURL = 'https://trancloud.dsipscs.com/';

  constructor(private http: HttpClient) {}

  getHeaders() {
    const accountID = window.location.href.includes('localhost')
      ? environment.datacapSandboxID
      : environment.datacapProdID;
    const authCode = window.location.href.includes('localhost')
      ? environment.datacapSandboxCode
      : environment.datacapProdCode;
    return new HttpHeaders({
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + btoa(accountID + ':' + authCode)
    });
  }

  get<T>(resource: string): Observable<T> {
    return this.http
      .get<T>(this.baseURL + resource, { headers: this.getHeaders() })
      .pipe(
        map(
          res => {
            return res;
          },
          catchError(e => {
            return e;
          })
        )
      );
  }

  put<T>(resource: string, body: any): Observable<T> {
    return this.http
      .put<T>(this.baseURL + resource, body, { headers: this.getHeaders() })
      .pipe(
        map(
          res => {
            return res;
          },
          catchError(e => {
            return e;
          })
        )
      );
  }

  post<T>(resource: string, body: any): Observable<T> {
    return this.http
      .post<T>(this.baseURL + resource, body, { headers: this.getHeaders() })
      .pipe(
        map(
          res => {
            return res;
          },
          catchError(e => {
            return e;
          })
        )
      );
  }

  patch<T>(resource: string, body: any): Observable<T> {
    return this.http
      .patch<T>(this.baseURL + resource, body, { headers: this.getHeaders() })
      .pipe(
        map(
          res => {
            return res;
          },
          catchError(e => {
            return e;
          })
        )
      );
  }

  delete<T>(resource: string): Observable<T> {
    return this.http
      .delete<T>(this.baseURL + resource, { headers: this.getHeaders() })
      .pipe(
        map(
          res => {
            return res;
          },
          catchError(e => {
            return e;
          })
        )
      );
  }
}
