import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ConfirmationComponent } from './confirmation.component';
import { ConfirmationRoutingModule } from '@app/components/customer-kiosk/confirmation/confirmation-routing.module';
import { SharedModule } from '@app/shared';

@NgModule({
  declarations: [ConfirmationComponent],
  imports: [CommonModule, ConfirmationRoutingModule, SharedModule]
})
export class CustomerKioskConfirmationModule {}
