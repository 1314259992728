<div class="popupShell">
  <a class="close" aria-label="Close" (click)="xClick()" style="text-align: right;">
    <div class="popup-x-modal">
      <lib-svg-sprite svgID="popup-x"></lib-svg-sprite>
    </div>
  </a>
  <div class="popupHeader">
    Capacity Error
  </div>
  <div class="deliveryDiv">
    <div>
      <p class="deliveryZoneFailText">
        Sorry, there was an issue submitting your order with our capacity manager. Please restart your order or reach
        out to the restaurant directly.
      </p>
    </div>
  </div>
  <div class="d-flex flex-row button-row">
    <div class="col-12">
      <lib-button large="true" text="Restart Order" (click)="restartOrder()"></lib-button>
    </div>
  </div>
</div>
