import { NgModule } from '@angular/core';
import { OrderInfoComponent } from './order-info.component';
import { CommonModule } from '@angular/common';
import { LinkModule } from '../link/link.module';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';

@NgModule({
  declarations: [OrderInfoComponent],
  imports: [CommonModule, LinkModule, SvgSpriteModule],
  exports: [OrderInfoComponent]
})
export class OrderInfoModule {}
