import { NgModule } from '@angular/core';
import { CustomizeItemComponent } from './customize-item.component';
import { CommonModule } from '@angular/common';
import { CustomizeItemBagModule } from '../customize-item-bag/customize-item-bag.module';
import { ModifierSelectorModule } from '../modifier-selector/modifier-selector.module';
import { ServicesModule } from '../../providers/services.module';
import { SingleTextFieldModule } from '../single-text-field/single-text-field.module';

@NgModule({
  declarations: [CustomizeItemComponent],
  imports: [CommonModule, CustomizeItemBagModule, ModifierSelectorModule, ServicesModule, SingleTextFieldModule],
  exports: [CustomizeItemComponent]
})
export class CustomizeItemModule {}
