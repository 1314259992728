import { Pipe, PipeTransform } from '@angular/core';
import { Option } from '@lib/models/olo.option';

@Pipe({
  name: 'notAvailableWithEnzoCheck',
  pure: false
})
export class NotAvailableWithEnzoCheckPipe implements PipeTransform {
  transform(option: Option, enzoSelected: boolean, selectedOptions: Option[]): boolean {
    const isTakeoutPlusSelected = selectedOptions.some(selectedOption =>
      selectedOption.metadata?.some(m => m.key === 'isTakeoutPlus' && m.value === 'true')
    );

    const isParbakeSelected = selectedOptions.some(selectedOption =>
      selectedOption.metadata?.some(m => m.key === 'isParbake' && m.value === 'true')
    );

    const glutenCondition =
      enzoSelected && !!option.metadata?.find(m => m.key === 'allowedWithGluten' && m.value === 'false');
    const takeoutPlusCondition =
      isTakeoutPlusSelected && !!option.metadata?.find(m => m.key === 'allowedWithTakeoutPlus' && m.value === 'false');
    const parbakeCondition =
      isParbakeSelected && !!option.metadata?.find(m => m.key === 'allowedWithParbake' && m.value === 'false');

    return glutenCondition || takeoutPlusCondition || parbakeCondition;
  }
}
