import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import {LocationSelectionComponent} from '@app/components/wait-time/location-selection/location-selection.component';
import {WaitTimeDisplayComponent} from '@app/components/wait-time/wait-time-display/wait-time-display.component';
import {Shell} from '@app/components/shell/shell.service';
import {extract} from '@app/core';


const routes: Routes = [
  Shell.childRoutes([{
    path: 'wait-time',
    component: LocationSelectionComponent,
    data: {title: extract('Choose a Location')}
  },
  {
    path:'wait-time/:locationID',
    component: WaitTimeDisplayComponent,
    data: {title: extract('Current Wait Time')}
  }])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class WaitTimeRoutingModule { }
