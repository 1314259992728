import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { TranCloudHttpService } from '@app/datacap-trancloud/services/trancloud-http.service';

@Injectable({
  providedIn: 'root'
})
export class TranCloudApiService {
  constructor(private tranCloudHTTP: TranCloudHttpService) {}

  processEMVTransaction<T>(body: any): Observable<T> {
    return this.tranCloudHTTP.post<T>('ProcessEMVTransaction', body);
  }

  lookup<T>(body: any): Observable<T> {
    return this.tranCloudHTTP.post<T>('Lookup', body);
  }

  processLocalEMVTransaction<T>(body: any): Observable<T> {
    return this.tranCloudHTTP.post<T>('ProcessEMVTransaction', body);
  }
}
