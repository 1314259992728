import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-capacity-error',
  templateUrl: './capacity-error.component.html',
  styleUrls: ['./capacity-error.component.scss']
})
export class CapacityErrorComponent {
  @Output() continue = new EventEmitter<any>();
  @Output() xClicked = new EventEmitter<any>();
  @Output() restart = new EventEmitter<any>();
  @Output() deliveryOutOfRange = new EventEmitter<any>();
  constructor() {}

  xClick() {
    this.xClicked.emit();
  }

  restartOrder() {
    this.restart.emit();
  }
}
