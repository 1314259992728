import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'lib-cancel-order-modal',
  templateUrl: './cancel-order-modal.component.html',
  styleUrls: ['./cancel-order-modal.component.scss']
})
export class CancelOrderModalComponent implements OnInit {
  @Input() guestName: string;
  @Input() partySize: string;
  @Input() readyTime: string;
  @Output() xClicked = new EventEmitter<any>();
  @Output() cancelOrder = new EventEmitter<any>();

  expoTitle = 'Cancel Order?';

  constructor(private router: Router) {}

  ngOnInit() {}

  close(): void {
    this.xClicked.emit();
  }

  isKioskFlow() {
    let isKioskFlow = false;
    if (this.router.url.includes('/past-orders')) {
      isKioskFlow = true;
    }
    return isKioskFlow;
  }

  cancelOrderClicked() {
    this.cancelOrder.emit();
  }
}
