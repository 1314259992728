<div
  style="width: 100%; height: 100%; background-color: rgba(0, 0, 0, 0.5); position: fixed; top: 0; z-index: 1031;"
  (click)="closeHam()"
>
  <div [ngClass]="mode !== 'kms' ? 'menu-slide' : 'kiosk-menu-slide'" (click)="stopProp($event)">
    <div style="height: 40px; width: 40px; display:grid; place-items: center; margin-left: 32px" (click)="closeHam()">
      <lib-svg-sprite svgID="popup-x"></lib-svg-sprite>
    </div>
    <ul class="navbar-nav ms-auto itemsSlide" *ngIf="mode !== 'kms'">
      <li class="nav-item">
        <a class="nav-link menuCapsSlide" (click)="closeHam()" routerLink="/locations">Home</a>
      </li>
      <li *ngIf="(restaurant$ | async)?.menu" class="nav-item">
        <a class="nav-link menuCapsSlide" (click)="closeHam()" routerLink="/menu">Menu</a>
      </li>
      <ng-container *ngIf="credService?.credentials?.token; else noAuth">
        <li class="nav-item">
          <a class="nav-link menuCapsSlide" (click)="closeHam()" routerLink="/account">Account</a>
        </li>
        <li class="nav-item">
          <a class="nav-link menuCapsSlide" (click)="closeHam()" routerLink="/giftcard">Gift Card Balance</a>
        </li>
        <li class="nav-item">
          <a id="menuLogout" class="nav-link menuCapsSlide" (click)="logout()" routerLink="/">Logout</a>
        </li>
      </ng-container>
      <ng-template #noAuth>
        <li class="nav-item">
          <a class="nav-link menuCapsSlide" (click)="closeHam()" routerLink="/sign-in">Sign In</a>
        </li>
        <li class="nav-item">
          <a class="nav-link menuCapsSlide" (click)="closeHam()" routerLink="/sign-up">Create An Account</a>
        </li>
      </ng-template>
      <li class="nav-item">
        <a href="https://www.punchpizza.com/faq" class="punchlinkSlide" target="_blank">FAQ</a>
      </li>
      <li class="nav-item">
        <a href="https://www.punchpizza.com" class="punchlinkSlide" target="_blank">punchpizza.com</a>
      </li>
    </ul>

    <ul
      class="navbar-nav ms-auto itemsSlide pt-4"
      *ngIf="mode === 'kms'"
      style="overflow-y: scroll; height: 100%; padding-bottom: 20px;"
    >
      <lib-svg-sprite class="kiosk-logo" svgID="logo-svg"></lib-svg-sprite>
      <li class="nav-item" *ngIf="!(isMaster$ | async)">
        <a class="nav-link menuCapsSlideKiosk pt-4" (click)="closeHam()" routerLink="/kiosk/menu">MENU</a>
      </li>
      <li class="nav-item" *ngIf="!(isMaster$ | async)">
        <a class="nav-link menuCapsSlideKiosk clickable" (click)="startOver()">START OVER</a>
      </li>
      <li class="nav-item" *ngIf="!(isMaster$ | async)">
        <a class="nav-link menuCapsSlideKiosk" (click)="closeHam()" routerLink="/kiosk/past-orders">PAST ORDERS</a>
      </li>
      <li class="nav-item" *ngIf="!(isMaster$ | async)">
        <a class="nav-link menuCapsSlideKiosk" (click)="closeHam()" routerLink="/expo/queue">LIVE FIRE EXPO</a>
      </li>

      <li class="nav-item">
        <a id="signOutLink" class="nav-link menuCapsSlideKiosk menuCapsOrange clickable" (click)="kioskSignOut()"
          >SIGN OUT</a
        >
      </li>

      <li class="nav-item">
        <a
          id="toggleFullScreen"
          class="nav-link menuCapsSlideKiosk menuCapsOrange clickable"
          (click)="toggleFullScreen()"
          ><ng-container *ngIf="!isFullScreen(); else els">ENTER FULLSCREEN</ng-container>
          <ng-template #els>EXIT FULLSCREEN</ng-template>
        </a>
      </li>
      <ng-container *ngIf="user$ | async as user">
        <div *ngIf="!(isMaster$ | async)" style="max-width: 272px; margin: 0 auto; margin-bottom: 200px">
          <ng-container>
            <form [formGroup]="pinPadConfigForm">
              <div style="max-width: 350px; margin: 0 auto">
                <div class="d-flex flex-row" style="margin-top: 10px">
                  <div class="col-12">
                    <p class="p-0 m-0 fs-16" style="text-align: left;">Pin Pad</p>
                  </div>
                </div>
                <div class="d-flex flex-row">
                  <div class="col">
                    <select formControlName="selectedPinPad" class="form-select">
                      <option
                        class="dropdown-item"
                        *ngFor="let cardReader of user.cardReaders"
                        [value]="cardReader.mac_ip_address"
                        >{{ cardReader.mac_ip_address }} - {{ cardReader.pin_pad_type }}</option
                      >
                    </select>
                  </div>
                </div>
              </div>
            </form>
            <div class="d-flex flex-row pt-2">
              <div class="col-12">
                <div class="mt-1">
                  <lib-button
                    class="button expo-button mt-2"
                    text="Sync PinPad Device"
                    large="true"
                    [isLoading]="connectLoading"
                    (click)="connectPinPad()"
                  >
                  </lib-button>
                </div>
              </div>
            </div>
            <div class="d-flex flex-row pt-2">
              <div class="col-12">
                <div class="mt-1">
                  <lib-button
                    class="button expo-button mt-2"
                    text="Macro Pause"
                    large="true"
                    ghost="true"
                    [isLoading]="syncLoading"
                    (click)="syncPinPad()"
                  >
                  </lib-button>
                </div>
              </div>
            </div>
          </ng-container>
          <ng-container>
            <form [formGroup]="printerConfigForm">
              <div style="max-width: 350px; margin: 0 auto">
                <div class="d-flex flex-row" style="margin-top: 10px">
                  <div class="col-12">
                    <p class="p-0 m-0 fs-16" style="text-align: left;">Pinter IP Address</p>
                  </div>
                </div>
                <div class="d-flex flex-row">
                  <div class="col">
                    <select formControlName="printerAddress" class="form-select">
                      <option class="dropdown-item" *ngFor="let printerIP of user.printerIPs" [value]="printerIP.ip">{{
                        printerIP.ip
                      }}</option>
                    </select>
                  </div>
                </div>
              </div>
            </form>
            <div class="d-flex flex-row pt-2">
              <div class="col-12">
                <div class="mt-1">
                  <lib-button
                    class="button expo-button mt-2"
                    text="Sync Printer"
                    large="true"
                    (click)="savePrinterAddress()"
                  >
                  </lib-button>
                </div>
                <div class="mt-2">
                  <lib-button class="button expo-button mt-2" text="Test Printer" large="true" (click)="testPrinter()">
                  </lib-button>
                </div>
              </div>
            </div>
          </ng-container>
        </div>
      </ng-container>
    </ul>

    <div id="canvasFrame" style="display:none">
      <canvas id="canvasPaper" width="576" height="640"></canvas>
    </div>

    <lib-svg-sprite class="panThing" svgID="pan-backdrop"></lib-svg-sprite>
    <div class="sliderBottomSection d-flex flex-column" *ngIf="mode !== 'kms'">
      <a
        href="https://www.iubenda.com/privacy-policy/12495188"
        class="iubenda-white no-brand iubenda-embed"
        title="Privacy Policy "
        >Privacy Policy</a
      >
      <script type="text/javascript">
        (function(w, d) {
          var loader = function() {
            var s = d.createElement('script'),
              tag = d.getElementsByTagName('script')[0];
            s.src = 'https://cdn.iubenda.com/iubenda.js';
            tag.parentNode.insertBefore(s, tag);
          };
          if (w.addEventListener) {
            w.addEventListener('load', loader, false);
          } else if (w.attachEvent) {
            w.attachEvent('onload', loader);
          } else {
            w.onload = loader;
          }
        })(window, document);
      </script>
      <a
        href="https://www.iubenda.com/terms-and-conditions/12495188"
        class="iubenda-white no-brand iubenda-embed"
        title="Terms and Conditions "
        >Terms and Conditions</a
      >
      <script type="text/javascript">
        (function(w, d) {
          var loader = function() {
            var s = d.createElement('script'),
              tag = d.getElementsByTagName('script')[0];
            s.src = 'https://cdn.iubenda.com/iubenda.js';
            tag.parentNode.insertBefore(s, tag);
          };
          if (w.addEventListener) {
            w.addEventListener('load', loader, false);
          } else if (w.attachEvent) {
            w.attachEvent('onload', loader);
          } else {
            w.onload = loader;
          }
        })(window, document);
      </script>
    </div>
  </div>
</div>
