export class BasketProductToAdd {
  productid: number;
  quantity: number;
  options: string; // Comma-delimited list of option (choice) id's
  specialinstructions?: string;
  recipient?: string;
  customdata?: string;
  choicecustomfields?: {
    choiceid: number;
    quantity?: number;
    customfields: {
      fieldid: number;
      value: string;
    }[];
  }[];
}

export class BasketProduct {
  id: number;
  productId: number; // For some reason productId is camel case for BasketProduct (from OLO)
  name: string;
  quantity: number;
  basecost: number;
  totalcost: number;
  choices: BasketChoice[]; // Comma-delimited list of option (choice) id's
  specialinstructions?: string;
  recipient?: string;
  customdata?: string;
  choicecustomfields?: {
    choiceid: number;
    quantity?: number;
    customfields: {
      fieldid: number;
      value: string;
    }[];
  }[];
}

export class BasketChoice {
  id: number; // Olo basket option id. This id is unique to each basket product.
  optionid: number; // Olo option id. This is the option id from the restaurant's menu.
  name: string; // Name of the option.
  metric: number; // Display order metric for options.
  indent: number; // Display indent-level for nested options (e.g. Light/Regular/Heavy).
  cost: number; // Cost of the option.
  quantity: number; // Quantity selected of the option.
  customfields: { fieldid: number; value: string }[]; // Any custom fields that were applied to the option.
}
