import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { BasketState, BasketStateModel } from '@app/store/state/basket.state';
import {
  SubmitBasketMultiPay,
  SaveExtraCreditCard,
  RemoveGiftCard,
  ValidateBasket
} from '@app/store/actions/basket.action';
import { BillingAccount } from 'src/app/models/billingaccount.interface';
import { UntypedFormGroup } from '@angular/forms';
import { from, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';
import { Basket } from '@lib/models/olo.basket';
import { GlobalStateModel } from '@app/store/state.model';
import { Capacitor } from '@capacitor/core';
import { OloPaySDK } from '@olo/pay-capacitor';

@Injectable({
  providedIn: 'root'
})
export class SplitPaymentService {
  constructor(private toastr: ToastrService, private router: Router, private store: Store) {}

  multiplePayment(
    giftTotal: number,
    ccForm: UntypedFormGroup,
    cashAmount: number,
    prepaidAmount: number,
    isKiosk: boolean,
    tableNumber: string,
    isApplePay: boolean = false,
    isGooglePay: boolean = false
  ): Observable<any> {
    let billingInfo: BillingAccount[] = [];
    const basket: BasketStateModel = this.store.selectSnapshot(BasketState);
    const bilingschemegift: number = basket.billingSchemes.find((sche: any) => sche.type === 'giftcard').id;
    const bilingschemecredit: number = basket.billingSchemes.find((sche: any) => sche.type === 'creditcard').id;
    const basketTotal: number = basket.basketValidation.total;
    const totalnotip: number = basket.basketValidation.total - basket.basket.tip;
    if (!isApplePay && !isGooglePay) {
      if (basket.basket.tip) {
        // Account for tip in Multipay
        if (totalnotip > giftTotal) {
          // cc handles tip
          billingInfo = this.creditCardTip(basket, bilingschemegift, bilingschemecredit, ccForm, giftTotal);
        } else {
          // Tip split between payments
          billingInfo = this.gcSplitcc(
            basket,
            bilingschemegift,
            bilingschemecredit,
            basketTotal,
            totalnotip,
            ccForm,
            giftTotal
          );
        }
      } else {
        // Multipay without tip
        if (cashAmount && ccForm) {
          billingInfo = this.splitPayNoTip(
            basket,
            bilingschemegift,
            bilingschemecredit,
            basketTotal,
            ccForm,
            0,
            cashAmount
          );
        } else if (prepaidAmount || cashAmount) {
          billingInfo = this.prepaidSplitPayNoTip(
            basket,
            bilingschemegift,
            basketTotal,
            giftTotal,
            prepaidAmount,
            cashAmount
          );
        } else {
          billingInfo = this.splitPayNoTip(
            basket,
            bilingschemegift,
            bilingschemecredit,
            basketTotal,
            ccForm,
            giftTotal,
            0
          );
        }
      }
      if (billingInfo) {
        if (billingInfo.find((sche: any) => sche.billingmethod === 'creditcard')) {
          const cc = billingInfo.find((sche: any) => sche.billingmethod === 'creditcard');
          const month = cc.expirymonth > 9 ? cc.expirymonth : '0' + cc.expirymonth;
          const year = cc.expiryyear.toString().substr(2, 2);
          let card = {
            cardnumber: cc.cardnumber,
            cvv: cc.cvv,
            expiry: month + year,
            zip: cc.zip
          };
          this.store.dispatch(new SaveExtraCreditCard(card));
        }
      }
      return this.store.dispatch(new SubmitBasketMultiPay(billingInfo, null, null, null, null, null, tableNumber));
    } else {
      const applePayScheme: number = basket.billingSchemes.find(
        (scheme: any) => scheme.type === 'external' && scheme.name === 'Apple Pay'
      )?.id;
      const googlePayScheme: number = basket.billingSchemes.find(
        (scheme: any) => scheme.type === 'external' && scheme.name === 'Google Pay'
      )?.id;
      return this.store.dispatch(new ValidateBasket()).pipe(
        switchMap(() => {
          // CC takes care of tip
          basket.giftCard.forEach((card: any, i: any) => {
            const billingAccountGift = {
              billingmethod: 'storedvalue',
              amount: card.balance,
              tipportion: 0,
              billingschemeid: bilingschemegift,
              billingfields: [
                {
                  name: 'number',
                  value: card.cardnumber
                },
                {
                  name: 'pin',
                  value: card.pin
                }
              ]
            } as BillingAccount;
            billingInfo.push(billingAccountGift);
          });
          return this.store
            .selectOnce(state => state.basket.basket)
            .pipe(
              switchMap((currentBasket: Basket) => {
                return from(
                  OloPaySDK.getDigitalWalletPaymentMethod({
                    currencyCode: 'USD',
                    countryCode: 'US',
                    amount: currentBasket.total - giftTotal
                  })
                ).pipe(
                  switchMap(res => {
                    const billingAccountWallet: BillingAccount = {
                      amount: currentBasket.total - giftTotal,
                      tipportion: currentBasket.tip,
                      token: res.paymentMethod.id,
                      cardtype: res.paymentMethod.cardType,
                      cardlastfour: res.paymentMethod.last4,
                      billingmethod: 'digitalwallet',
                      billingschemeid: isApplePay ? applePayScheme : googlePayScheme
                    };

                    billingInfo.push(billingAccountWallet);
                    return this.store.dispatch(
                      new SubmitBasketMultiPay(billingInfo, null, null, null, null, null, tableNumber)
                    );
                  })
                );
              })
            );
        })
      );
    }
  }

  // Split payment -- CC takes care of tip
  creditCardTip(
    basket: BasketStateModel,
    bilingschemegift: number,
    bilingschemecredit: number,
    ccForm: UntypedFormGroup,
    giftTotal: number
  ) {
    const billingInfo = [];
    // CC takes care of tip
    basket.giftCard.forEach((card: any, i: any) => {
      const billingAccountGift = {
        billingmethod: 'storedvalue',
        amount: card.balance,
        tipportion: 0,
        billingschemeid: bilingschemegift,
        billingfields: [
          {
            name: 'number',
            value: card.cardnumber
          },
          {
            name: 'pin',
            value: card.pin
          }
        ]
      } as BillingAccount;
      billingInfo.push(billingAccountGift);
    });
    const billingAccountCredit = {
      billingmethod: 'creditcard',
      amount: Number((basket.basketValidation.total - giftTotal).toFixed(2)),
      tipportion: basket.basket.tip,
      cardnumber: ccForm.value.cardNumber,
      expiryyear: parseInt('20' + ccForm.value.expDate.substring(2, 4)),
      expirymonth: parseInt(ccForm.value.expDate.substring(0, 2)),
      cvv: ccForm.value.cvvCode,
      zip: ccForm.value.zipCode,
      billingschemeid: bilingschemecredit
    } as BillingAccount;
    billingInfo.push(billingAccountCredit);
    return billingInfo;
  }

  // Split payment -- tip split between Items
  gcSplitcc(
    basket: BasketStateModel,
    bilingschemegift: number,
    bilingschemecredit: number,
    basketTotal: number,
    totalnotip: number,
    ccForm: UntypedFormGroup,
    giftTotal: number
  ) {
    const billingInfo = [];
    if (ccForm && ccForm.value.cardNumber) {
      // CC included
      basket.giftCard.forEach((card: any, i: any) => {
        if (i === basket.giftCard.length - 1) {
          if (basket.basket.tip - (basketTotal - giftTotal) > card.balance) {
            // Tip takes up all last GC
            console.log('takes up all last gc');
            const billingAccountGift = {
              billingmethod: 'storedvalue',
              amount: Number(card.balance.toFixed(2)),
              tipportion: Number(card.balance.toFixed(2)),
              billingschemeid: bilingschemegift,
              billingfields: [
                {
                  name: 'number',
                  value: card.cardnumber
                },
                {
                  name: 'pin',
                  value: card.pin
                }
              ]
            } as BillingAccount;
            billingInfo.push(billingAccountGift);
          } else {
            // Tip takes up partial last GC
            console.log('partial last gc');
            const billingAccountGift = {
              billingmethod: 'storedvalue',
              amount: Number(
                (totalnotip - basket.giftCard[0].balance > 0
                  ? totalnotip - basket.giftCard[0].balance + (basket.basket.tip - (basketTotal - giftTotal))
                  : totalnotip + (basket.basket.tip - (basketTotal - giftTotal))
                ).toFixed(2)
              ),
              tipportion: Number((basket.basket.tip - (basketTotal - giftTotal)).toFixed(2)),
              billingschemeid: bilingschemegift,
              billingfields: [
                {
                  name: 'number',
                  value: card.cardnumber
                },
                {
                  name: 'pin',
                  value: card.pin
                }
              ]
            } as BillingAccount;
            billingInfo.push(billingAccountGift);
          }
        } else {
          if (totalnotip > card.balance) {
            // No tip on first card
            console.log('no tip on first card');
            const billingAccountGift = {
              billingmethod: 'storedvalue',
              amount: Number(card.balance.toFixed(2)),
              tipportion: 0,
              billingschemeid: bilingschemegift,
              billingfields: [
                {
                  name: 'number',
                  value: card.cardnumber
                },
                {
                  name: 'pin',
                  value: card.pin
                }
              ]
            } as BillingAccount;
            billingInfo.push(billingAccountGift);
          } else {
            // Split tip on first card
            console.log('split tip on first card');
            const billingAccountGift = {
              billingmethod: 'storedvalue',
              amount: Number(
                (totalnotip + (basket.basket.tip - (basketTotal - giftTotal) - basket.giftCard[1].balance)).toFixed(2)
              ),
              tipportion: Number(
                (basket.basket.tip - (basketTotal - giftTotal) - basket.giftCard[1].balance).toFixed(2)
              ),
              billingschemeid: bilingschemegift,
              billingfields: [
                {
                  name: 'number',
                  value: card.cardnumber
                },
                {
                  name: 'pin',
                  value: card.pin
                }
              ]
            } as BillingAccount;
            billingInfo.push(billingAccountGift);
          }
        }
      });
      const billingAccountCredit = {
        billingmethod: 'creditcard',
        amount: Number((basketTotal - giftTotal).toFixed(2)),
        tipportion: Number((basketTotal - giftTotal).toFixed(2)),
        cardnumber: ccForm.value.cardNumber,
        expiryyear: parseInt('20' + ccForm.value.expDate.substring(2, 4)),
        expirymonth: parseInt(ccForm.value.expDate.substring(0, 2)),
        cvv: ccForm.value.cvvCode,
        zip: ccForm.value.zipCode,
        billingschemeid: bilingschemecredit
      } as BillingAccount;
      billingInfo.push(billingAccountCredit);
    } else {
      // No CC, split between GC
      console.log('no cc, split between GC');
      if (totalnotip > basket.giftCard[0].balance) {
        // Second GC takes tip
        console.log('second gc takes tip');
        basket.giftCard.forEach((card: any, i: any) => {
          if (i === basket.giftCard.length - 1) {
            const billingAccountGift = {
              billingmethod: 'storedvalue',
              amount: Number((totalnotip - basket.giftCard[0].balance + basket.basket.tip).toFixed(2)),
              tipportion: Number(basket.basket.tip.toFixed(2)),
              billingschemeid: bilingschemegift,
              billingfields: [
                {
                  name: 'number',
                  value: card.cardnumber
                },
                {
                  name: 'pin',
                  value: card.pin
                }
              ]
            } as BillingAccount;
            billingInfo.push(billingAccountGift);
          } else {
            const billingAccountGift = {
              billingmethod: 'storedvalue',
              amount: Number(card.balance.toFixed(2)),
              tipportion: 0,
              billingschemeid: bilingschemegift,
              billingfields: [
                {
                  name: 'number',
                  value: card.cardnumber
                },
                {
                  name: 'pin',
                  value: card.pin
                }
              ]
            } as BillingAccount;
            billingInfo.push(billingAccountGift);
          }
        });
      } else {
        // tip split between GCs
        console.log('tip split between gcs');
        basket.giftCard.forEach((card: any, i: any) => {
          if (i === basket.giftCard.length - 1) {
            const billingAccountGift = {
              billingmethod: 'storedvalue',
              amount: Number((basketTotal - basket.giftCard[0].balance).toFixed(2)),
              tipportion: Number((basketTotal - basket.giftCard[0].balance).toFixed(2)),
              billingschemeid: bilingschemegift,
              billingfields: [
                {
                  name: 'number',
                  value: card.cardnumber
                },
                {
                  name: 'pin',
                  value: card.pin
                }
              ]
            } as BillingAccount;
            billingInfo.push(billingAccountGift);
          } else {
            const billingAccountGift = {
              billingmethod: 'storedvalue',
              amount: Number((totalnotip + (card.balance - totalnotip)).toFixed(2)),
              tipportion: Number((card.balance - totalnotip).toFixed(2)),
              billingschemeid: bilingschemegift,
              billingfields: [
                {
                  name: 'number',
                  value: card.cardnumber
                },
                {
                  name: 'pin',
                  value: card.pin
                }
              ]
            } as BillingAccount;
            billingInfo.push(billingAccountGift);
          }
        });
      }
    }
    return billingInfo;
  }

  // Split  payment -- no tip

  splitPayNoTip(
    basket: BasketStateModel,
    bilingschemegift: number,
    bilingschemecredit: number,
    basketTotal: number,
    ccForm: UntypedFormGroup,
    giftTotal: number,
    cashAmount: number
  ) {
    console.log('split payment no tip');
    const billingInfo = [];
    if (basket && basket.giftCard && basket.giftCard.length) {
      basket.giftCard.forEach((card: any, i: any) => {
        if (i === basket.giftCard.length - 1) {
          const billingAccountGift = {
            billingmethod: 'storedvalue',
            amount: Number(
              (basketTotal < giftTotal ? basketTotal - (giftTotal - card.balance) : card.balance).toFixed(2)
            ),
            tipportion: 0,
            billingschemeid: bilingschemegift,
            billingfields: [
              {
                name: 'number',
                value: card.cardnumber
              },
              {
                name: 'pin',
                value: card.pin
              }
            ]
          } as BillingAccount;
          billingInfo.push(billingAccountGift);
        } else {
          const billingAccountGift = {
            billingmethod: 'storedvalue',
            amount: card.balance,
            tipportion: 0,
            billingschemeid: bilingschemegift,
            billingfields: [
              {
                name: 'number',
                value: card.cardnumber
              },
              {
                name: 'pin',
                value: card.pin
              }
            ]
          } as BillingAccount;
          billingInfo.push(billingAccountGift);
        }
      });
    }
    if (cashAmount) {
      // finish with cash
      const billingAccountCash = {
        billingmethod: 'cash',
        amount: Number(cashAmount.toFixed(2)),
        tipportion: 0
      } as BillingAccount;
      billingInfo.push(billingAccountCash);
    }
    if (ccForm && ccForm.value.cardNumber) {
      const billingAccountCredit = {
        billingmethod: 'creditcard',
        amount: Number((basket.basketValidation.total - giftTotal - cashAmount).toFixed(2)),
        tipportion: 0,
        cardnumber: ccForm.value.cardNumber,
        expiryyear: parseInt('20' + ccForm.value.expDate.substring(2, 4)),
        expirymonth: parseInt(ccForm.value.expDate.substring(0, 2)),
        cvv: ccForm.value.cvvCode,
        zip: ccForm.value.zipCode,
        billingschemeid: bilingschemecredit
      } as BillingAccount;
      billingInfo.push(billingAccountCredit);
    }
    return billingInfo;
  }

  // prepaid Split  payment -- no tip

  prepaidSplitPayNoTip(
    basket: BasketStateModel,
    bilingschemegift: number,
    basketTotal: number,
    giftTotal: number,
    prepaidAmount: number,
    cashAmount: number
  ) {
    const billingInfo = [];
    if (basket && basket.giftCard && basket.giftCard.length) {
      basket.giftCard.forEach((card: any, i: any) => {
        if (i === basket.giftCard.length - 1) {
          const billingAccountGift = {
            billingmethod: 'storedvalue',
            amount: Number(
              (basketTotal < giftTotal ? basketTotal - (giftTotal - card.balance) : card.balance).toFixed(2)
            ),
            tipportion: 0,
            billingschemeid: bilingschemegift,
            billingfields: [
              {
                name: 'number',
                value: card.cardnumber
              },
              {
                name: 'pin',
                value: card.pin
              }
            ]
          } as BillingAccount;
          billingInfo.push(billingAccountGift);
        } else {
          const billingAccountGift = {
            billingmethod: 'storedvalue',
            amount: card.balance,
            tipportion: 0,
            billingschemeid: bilingschemegift,
            billingfields: [
              {
                name: 'number',
                value: card.cardnumber
              },
              {
                name: 'pin',
                value: card.pin
              }
            ]
          } as BillingAccount;
          billingInfo.push(billingAccountGift);
        }
      });
    }
    if (prepaidAmount) {
      // finish with prepaid
      const billingAccountPrepaid = {
        billingmethod: 'prepaid',
        amount: Number(prepaidAmount.toFixed(2)),
        tipportion: 0
      } as BillingAccount;
      billingInfo.push(billingAccountPrepaid);
    }
    if (cashAmount) {
      // finish with cash
      const billingAccountCash = {
        billingmethod: 'cash',
        amount: Number(cashAmount.toFixed(2)),
        tipportion: 0
      } as BillingAccount;
      billingInfo.push(billingAccountCash);
    }

    return billingInfo;
  }
}
