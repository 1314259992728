import { Component, Input, OnInit } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { GetOloBillingAccounts, DeleteCard, SetDefaultCard } from '@app/store/actions/user.action';
import { UserStateModel } from '@app/store/state/user.state';
import { Observable } from 'rxjs';

@Component({
  selector: 'lib-saved-cards',
  templateUrl: './saved-cards.component.html',
  styles: []
})
export class SavedCardsComponent implements OnInit {
  @Select() user$: Observable<UserStateModel>;

  @Input() buttons = true;
  @Input() basketCreated = true;
  @Input() orderText = 'order';
  @Input() dropdownChildren = ['indivdual', 'group', 'catering'];
  @Input() hasSavedCards = false;

  accountLoading = '';

  constructor(private store: Store) {}

  ngOnInit() {
    this.store.dispatch(new GetOloBillingAccounts());
  }

  clickEvent(event: any) {}

  removeCard(accountId: string) {
    this.accountLoading = accountId;
    this.store.dispatch(new DeleteCard(accountId)).subscribe(res => {
      this.accountLoading = '';
    });
  }

  makeDefault(accountId: string) {
    if (this.accountLoading !== accountId) {
      this.accountLoading = accountId;
      this.store.dispatch(new SetDefaultCard(accountId)).subscribe(res => {
        this.accountLoading = '';
      });
    }
  }
}
