<div class="wait-time-background">
  <div class="page-shell">
    <h1 class="select-location fw-bold boldText text-uppercase">Select a location</h1>
    <ng-container *ngFor="let location of locations; index as index">
      <button class="w-100 fw-bold boldText text-uppercase bg-primary" (click)="routeToWaitTime(location)">
        {{ location.location_name | cmsLocationName }}
      </button>
    </ng-container>
  </div>
</div>
