import { NgModule } from '@angular/core';
import { OloMenuComponent } from './olo-menu.component';
import { CommonModule } from '@angular/common';
import { SubNavModule } from '../sub-nav/sub-nav.module';
import { ProductCardModule } from '../product-card/product-card.module';
import { ServicesModule } from '../../providers/services.module';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';
import { SavedProductCardModule } from '@lib/lib/saved-product-card/saved-product-card.module';

@NgModule({
  declarations: [OloMenuComponent],
  imports: [CommonModule, SubNavModule, ProductCardModule, ServicesModule, SvgSpriteModule, SavedProductCardModule],
  exports: [OloMenuComponent]
})
export class OloMenuModule {}
