import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerKioskCheckoutComponent } from './checkout.component';
import { CheckoutRoutingModule } from '@app/components/customer-kiosk/checkout/checkout-routing.module';
import { SharedModule } from '@app/shared';
import { SingleTextFieldModule } from '../../../../assets/chepri-modules/src/lib/single-text-field/single-text-field.module';
import { ButtonModule } from '../../../../assets/chepri-modules/src/lib/button/button.module';
import { ReactiveFormsModule } from '@angular/forms';
import { NavbarModule } from '@app/components/customer-kiosk/navbar/navbar.module';
import { AccessibilityToggleModule } from '@app/components/customer-kiosk/accessibility-toggle/accessibility-toggle.module';
import { SvgSpriteModule } from 'src/assets/chepri-modules/src/lib/svg-sprite/svg-sprite.module';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@NgModule({
  declarations: [CustomerKioskCheckoutComponent],
  imports: [
    CommonModule,
    CheckoutRoutingModule,
    SharedModule,
    SingleTextFieldModule,
    ButtonModule,
    ReactiveFormsModule,
    NavbarModule,
    AccessibilityToggleModule,
    SvgSpriteModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  providers: [provideNgxMask()]
})
export class CustomerKioskCheckoutModule {}
