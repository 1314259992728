import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerKioskOrderSummaryComponent } from './order-summary.component';
import { OrderSummaryRoutingModule } from '@app/components/customer-kiosk/order-summary/order-summary-routing.module';
import { SharedModule } from '@app/shared';
import { ReactiveFormsModule } from '@angular/forms';
import { NavbarModule } from '@app/components/customer-kiosk/navbar/navbar.module';
import { AccessibilityToggleModule } from '@app/components/customer-kiosk/accessibility-toggle/accessibility-toggle.module';

@NgModule({
  declarations: [CustomerKioskOrderSummaryComponent],
  imports: [
    CommonModule,
    OrderSummaryRoutingModule,
    SharedModule,
    ReactiveFormsModule,
    NavbarModule,
    AccessibilityToggleModule
  ]
})
export class CustomerKioskOrderSummaryModule {}
