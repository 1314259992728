<div class="modal-wrapper justify-content-center">
  <i class="fas fa-times x-close" (click)="close()"></i>

  <h1 class="modal-title text-center text-uppercase fw-bold bold-modal-txt">Age Verification</h1>
  <div class="d-flex flex-row text-center justify-content-center pt-4 mb-4">
    Please confirm that the customer's birthday is on or before {{ minAgeDate | date: 'M/dd/yyyy' }}.
  </div>

  <div class="d-flex flex-row pt-1">
    <div class="col-12 ps-0">
      <lib-button text="Return" [large]="true" (click)="close()"></lib-button>
    </div>
  </div>
</div>
