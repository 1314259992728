import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class ProductService {
  selectedArr: number[] = [];
  selected = new EventEmitter<any>();

  domainAPI = this.environment.getDomainAPI();

  constructor(private http: HttpClient, private environment: EnvironmentService) {}

  getProductModifiers(productID: string | number) {
    return this.http
      .get(this.domainAPI + '/api/products/' + productID + '/modifiers', this.environment.getOLOHeaders())
      .pipe();
  }

  getSelected() {
    return this.selectedArr;
  }

  setSelected(newVal: any) {
    this.selectedArr = [...newVal];
    this.selected.emit();
    this.arrayUpdated();
  }

  hasOption(id: number): boolean {
    return this.selectedArr.includes(id);
  }

  removeFromSelected(id: number) {
    if (this.selectedArr.includes(id)) {
      this.selectedArr.splice(this.selectedArr.indexOf(id), 1);
      this.selected.emit();
      this.arrayUpdated();
    } else {
      // cannot remove option
    }
  }

  addToSelected(id: number) {
    if (!this.selectedArr.includes(id)) {
      this.selectedArr.push(id);
      this.selected.emit();
      this.arrayUpdated();
    } else {
      // cannot add duplicate
    }
  }

  /*
   * For debugging purposes - called every time the array is updated
   */
  arrayUpdated() {}
}
