import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';

import { SignUpFormComponent } from './sign-up-form.component';

// Lib Dependencies
import { SingleTextFieldModule } from '../single-text-field/single-text-field.module';
import { ButtonModule } from '../button/button.module';
import { LinkModule } from '../link/link.module';
import { RadioButtonModule } from '../radio-button/radio-button.module';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';

@NgModule({
  declarations: [SignUpFormComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    SingleTextFieldModule,
    ButtonModule,
    LinkModule,
    RadioButtonModule,
    SvgSpriteModule
  ],
  exports: [SignUpFormComponent]
})
export class SignUpFormModule {}
