import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { MenuSlideComponent } from './menu-slide.component';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';
import { RouterModule } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { LoginRoutingModule } from '../../../../../app/components/kiosk-expo/login/login-routing.module';
import { SingleTextFieldModule } from '../single-text-field/single-text-field.module';
import { ButtonModule } from '../button/button.module';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@NgModule({
  declarations: [MenuSlideComponent],
  imports: [
    CommonModule,
    RouterModule,
    SvgSpriteModule,
    ReactiveFormsModule,
    SingleTextFieldModule,
    ButtonModule,
    NgxMaskDirective,
    NgxMaskPipe
  ],
  exports: [MenuSlideComponent],
  providers: [provideNgxMask()]
})
export class MenuSlideModule {}
