import { Injectable } from '@angular/core';
import { IAuthenticateResponse } from '@directus/sdk-js/dist/types/schemes/auth/Authenticate';
import { IConfigurationOptions } from '@directus/sdk-js/dist/types/Configuration';
import { DirectusTokenService } from '@app/providers/expo/directus/directus-token.service';
import { DirectusApiService } from '@app/providers/expo/directus/directus-api.service';
import { map, tap } from 'rxjs/operators';
import { from, Observable } from 'rxjs';
import { environment } from '@env/environment';
import { AuthenticationData } from '@directus/sdk';

@Injectable({
  providedIn: 'root'
})
export class DirectusUserService {
  domain = environment.cmsDomain;
  tokenRefreshInterval = 5 * 60 * 1000;
  refreshInterval: number = null;

  constructor(private directusToken: DirectusTokenService, private directusAPI: DirectusApiService) {}

  isLoggedIn() {
    return from(this.directusAPI.client.getToken()).pipe(
      map(token => {
        return token !== null;
      })
    );
  }

  loginClient(email: string, password: string, persist: boolean): Observable<AuthenticationData> {
    return this.directusAPI.loginUser(email, password);
  }

  logoutClient() {
    return this.directusAPI.logoutUser();
  }

  loginUser(email: string, password: string, persist: boolean): Observable<AuthenticationData> {
    return this.directusAPI.loginUser(email, password);
  }

  logoutUser() {
    return this.directusAPI.logoutUser();
  }

  refreshUser() {
    return this.directusAPI.refreshUser();
  }

  getCurrentUser(id: string) {
    return this.directusAPI.getUser(id);
  }

  getMe() {
    return this.directusAPI.getMe();
  }

  getRoles() {
    return this.directusAPI.getRoles();
  }
}
