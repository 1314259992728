import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { Haptics } from '@capacitor/haptics';
import { ButtonFeedbackDirective } from '../../../../../app/shared/directives/button-feedback.directive';

@Component({
  selector: 'lib-button',
  providers: [NgbDropdownModule],
  templateUrl: './button.component.html',
  styles: ['@import "https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.11.2/css/all.css";']
})
export class ButtonComponent {
  @Input() text: string;
  @Input() disabled: boolean;
  @Input() ghost: boolean;
  @Input() isLoading: boolean;
  @Input() large = false;
  @Input() children: string[];
  @Input() buttonType = 'button';
  @Input() productID: number | string;
  @Output() buttonClick = new EventEmitter<any>();

  clickEvent(index?: number) {
    if (index) {
      // Log index of child selected
      this.buttonClick.emit(index);
    } else {
      // Emit button clicked
      this.buttonClick.emit();
    }
  }
}
