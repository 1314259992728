<div class="container-fluider" style="padding-top: env(safe-area-inset-top, 0)">
  <div class="expo-form">
    <!-- //TODO: this button should be disabled by default and enabled once any scrolling occurs -->
    <div class="d-flex" flex-row>
      <div class="col" *ngIf="isMaster$ | async">
        <select id="locationSelect" (change)="locationSelectChange($event)">
          <option value="ALL_LOCATIONS">
            All Locations
          </option>
          <ng-container *ngFor="let heatmap of heatmapLocations">
            <ng-container *ngIf="heatmap.showAsMaster">
              <option [value]="heatmap.restaurantID">
                {{ heatmap.locationName }}
              </option>
            </ng-container>
          </ng-container>
        </select>
      </div>
      <div class="col ms-auto current-btn" *ngIf="!(isMaster$ | async)">
        <button class="returnToKioskBtn btn py-2 pe-2 ps-1" [disabled]="!hasScrolled" (click)="scrollBack()">
          Current &nbsp; &nbsp;
          <i class="far fa-clock me-2"></i>
        </button>
      </div>
    </div>

    <div class="kioskCont mt-0 pb-5">
      <div class="d-flex flex-row">
        <h3 class="text-center mx-auto my-0 f-20">
          Capacity Heat Map
        </h3>
      </div>
      <ng-container *ngFor="let heatmap of heatmapLocations; index as l">
        <!-- If is not master, or all locations are selected and this is supposed to be shown as master, or is currently selected -->
        <ng-container
          *ngIf="
            !(isMaster$ | async) ||
            (locationSelectValue === 'ALL_LOCATIONS' && heatmap.showAsMaster) ||
            heatmap.restaurantID === locationSelectValue
          "
        >
          <div style="margin: 8px 0;" [ngClass]="{ 'master-margin': isMaster$ | async }">
            <div class="d-flex flex-row pb-0">
              <h3 class="text-center text-uppercase f-20 mx-auto my-0">
                {{ heatmap.locationName }}
              </h3>
            </div>
            <div *ngIf="heatmap.capacityBuckets && heatmap.capacityBuckets.length; else showLoader">
              <!-- //TODO: pull times in - each time slot should be in a 15min increment starting at the current time which is in the block-->
              <div class="ribbon-items" (scroll)="ribbonScroll($event)" id="ribbon-scroll">
                <table id="timeSlots" class="table table-striped table-sm mb-1" width="100%">
                  <thead>
                    <tr class="times pt-1" style="width:0">
                      <!-- // the "width:0" style prevents the times from spreading across the screen when there are not many of them -->
                      <ng-container *ngFor="let slot of heatmap.timeStamps; index as i">
                        <ng-container *ngIf="i === 0; else timeSlot">
                          <div>
                            <span style="white-space: nowrap">{{ slot | ShortTimePipe | date: 'h:mm aaaaa' }}</span
                            ><i class="fas fa-caret-down"></i>
                          </div>
                        </ng-container>
                        <ng-template #timeSlot>
                          <span>{{ slot | ShortTimePipe | date: 'h:mm aaaaa' }}</span>
                        </ng-template>
                      </ng-container>
                    </tr>
                  </thead>
                </table>

                <!-- //TODO: pull capacity numbers in with corresponding colors - color codes are listed at the bottom of start-order.component.scss -->
                <table id="timeRibbon" class="table table-striped table-sm">
                  <thead>
                    <tr class="buckets" style="width:0">
                      <!-- // the "width:0" style prevents the buckets from spreading across the screen when there are not many of them -->
                      <th
                        *ngFor="let slot of heatmap.capacityBuckets"
                        [ngClass]="'th-color-' + slot.pizzas"
                        class="test-color"
                      >
                        {{ slot.pizzas }}
                      </th>
                    </tr>
                  </thead>
                </table>
              </div>

              <!-- //TODO: Wire cap mgmt and cms to pizza buckets
            //remember to use custom time pipe on times bc of ios/safari issue
            //it's already imported so you just need to do {{ [pizza time] | ShortTimePipe | date: 'h:mm aaaaa' }} -->

              <div class="card-container">
                <div class="d-flex flex-row">
                  <div class="col-3 px-2 py-0">
                    <div class="card">
                      <div class="card-body p-2" *ngIf="heatmap.upcomingSlots && heatmap.upcomingSlots[0]; else noSlot">
                        <h5 class="card-title">
                          1 Pizza
                        </h5>
                        <p class="card-text">
                          {{ heatmap.upcomingSlots[0].key | intervalToMinutesRemaining: heatmap.restaurantID | async }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 px-2 py-0">
                    <div class="card">
                      <div class="card-body p-2" *ngIf="heatmap.upcomingSlots && heatmap.upcomingSlots[1]; else noSlot">
                        <h5 class="card-title">
                          2 Pizzas
                        </h5>
                        <p class="card-text">
                          {{ heatmap.upcomingSlots[1].key | intervalToMinutesRemaining: heatmap.restaurantID | async }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 px-2 py-0">
                    <div class="card">
                      <div class="card-body p-2" *ngIf="heatmap.upcomingSlots && heatmap.upcomingSlots[2]; else noSlot">
                        <h5 class="card-title">
                          3 Pizzas
                        </h5>
                        <p class="card-text">
                          {{ heatmap.upcomingSlots[2].key | intervalToMinutesRemaining: heatmap.restaurantID | async }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 px-2 py-0">
                    <div class="card pizza-card">
                      <div class="card-body p-2">
                        <h5 class="card-title pizza-amt">{{ heatmap.nextHourQuant }}</h5>
                        <p class="card-text pizza-subhead">Next Hour</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="d-flex flex-row">
                  <div class="col-3 px-2 py-0">
                    <div class="card">
                      <div class="card-body p-2" *ngIf="heatmap.upcomingSlots && heatmap.upcomingSlots[3]; else noSlot">
                        <h5 class="card-title">
                          4 Pizzas
                        </h5>
                        <p class="card-text">
                          {{ heatmap.upcomingSlots[3].key | intervalToMinutesRemaining: heatmap.restaurantID | async }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 px-2 py-0">
                    <div class="card">
                      <div class="card-body p-2" *ngIf="heatmap.upcomingSlots && heatmap.upcomingSlots[4]; else noSlot">
                        <h5 class="card-title">
                          5 Pizzas
                        </h5>
                        <p class="card-text">
                          {{ heatmap.upcomingSlots[4].key | intervalToMinutesRemaining: heatmap.restaurantID | async }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 px-2 py-0">
                    <div class="card">
                      <div class="card-body p-2" *ngIf="heatmap.upcomingSlots && heatmap.upcomingSlots[5]; else noSlot">
                        <h5 class="card-title">
                          6 Pizzas
                        </h5>
                        <p class="card-text">
                          {{ heatmap.upcomingSlots[5].key | intervalToMinutesRemaining: heatmap.restaurantID | async }}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div class="col-3 px-2 py-0">
                    <div class="card pizza-card">
                      <div class="card-body p-2">
                        <h5 class="card-title pizza-amt">{{ heatmap.restOfDayQuant }}</h5>
                        <p class="card-text pizza-subhead">Rest of Day</p>
                      </div>
                    </div>
                  </div>
                </div>
                <ng-container *ngIf="!(isMaster$ | async)">
                  <div class="d-flex flex-row pt-3 pb-3 justify-content-center">
                    <div class="w-100 pe-2 text-end my-auto">
                      <button
                        id="kioskDineInBtn"
                        class="btn expoGhostButton f-18 w-100 largeButton"
                        (click)="guestInfoModal('dinein', l)"
                      >
                        <div *ngIf="isLoading !== 'dinein'">
                          Digital Dine-In
                        </div>
                        <div class="spin-loader-ghost" *ngIf="isLoading === 'dinein'"></div>
                      </button>
                    </div>
                    <div class="w-100 ps-2 text-start my-auto">
                      <button
                        class="btn btn-primary text-white f-18 w-100 largeButton"
                        (click)="guestInfoModal('pickup', l)"
                      >
                        <div *ngIf="isLoading !== 'pickup'">Take Out</div>
                        <div class="spin-loader-ghost" *ngIf="isLoading === 'pickup'"></div>
                      </button>
                    </div>
                  </div>
                </ng-container>
                <div
                  *ngIf="heatmap.gfLeft !== null"
                  class="d-flex flex-row text-center mb-3"
                  style="align-items: center; justify-content: center;"
                >
                  <div class="col-3 px-2 py-0">
                    <div class="card">
                      <div class="card-body p-2" style="height: auto;">
                        <p class="card-title pizza-amt">
                          GF LEFT: <span class="enzo-remaining">{{ heatmap.gfLeft }}</span>
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <ng-template #noSlot>
                <div class="card-body p-2">
                  <p class="card-text">Empty</p>
                </div>
              </ng-template>
            </div>

            <ng-template #showLoader>
              <div class="d-flex flex-row">
                <div class="col">
                  <div class="spin-loader-heat-map"></div>
                </div>
              </div>
            </ng-template>
          </div>
        </ng-container>
      </ng-container>
    </div>
  </div>
</div>
