<div class="modal-wrapper justify-content-center">
  <i class="fas fa-times x-close" (click)="clickedX()"></i>
  <h1 class="modal-title text-center text-uppercase fw-bold">{{ title }}</h1>

  <div class="d-flex flex-row text-center justify-content-center align-items-center py-3">
    Logging out will allow you to change your location.
  </div>

  <div class="d-flex flex-row pt-3">
    <div class="col me-3">
      <lib-button text="Cancel" ghost="true" large="true" (click)="clickedReturn()"></lib-button>
    </div>
    <div class="col">
      <lib-button id="logOutBtn" large="true" text="Log Out" (click)="clickedContinue()"></lib-button>
    </div>
  </div>
</div>
