import { AfterViewInit, Component, OnDestroy } from '@angular/core';
import { UntypedFormBuilder } from '@angular/forms';
import { Select, Store } from '@ngxs/store';
import { Observable, Subscription } from 'rxjs';
import { BasketStateModel } from '@app/store/state/basket.state';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { SetBanner } from '@app/store/actions/app.action';
import { DateTime } from 'src/assets/chepri-modules/src/models/DateTime';
import { PrinterService } from '@app/providers/expo/printer/printer.service';
import { CurrencyPipe } from '@angular/common';
import * as moment from 'moment';
import {
  CleanGiftCard,
  ClearChange,
  ClearExtraCard,
  ClearTrancloudCardData,
  SetBillingInfo
} from '@app/store/actions/basket.action';
import { DirectusExpoProvider } from '@app/providers/expo/directus/directus-expo.provider';
import { EmailService } from '@app/providers/expo/email/email.service';
import { filter } from 'rxjs/operators';
import { TruncatePipe } from '@app/shared/pipes/truncate.pipe';
import { BasketChoice, BasketProduct } from 'src/assets/chepri-modules/src/models/olo.basketproduct';
import { CapacityService } from '@app/providers/capacity.service';
import { DirectusCurrentOrder } from '@app/models/capacity.model';

@Component({
  selector: 'app-confirmation',
  templateUrl: './confirmation.component.html',
  styleUrls: ['./confirmation.component.scss']
})
export class KioskConfirmationComponent implements AfterViewInit, OnDestroy {
  @Select() basket$: Observable<BasketStateModel>;

  basket: BasketStateModel;
  expoOrder: any;
  receiptPrinted = false;

  subscriptions: Subscription[] = [];

  constructor(
    private fb: UntypedFormBuilder,
    private router: Router,
    private store: Store,
    private toastr: ToastrService,
    private printer: PrinterService,
    private currencyPipe: CurrencyPipe,
    private expo: DirectusExpoProvider,
    private email: EmailService,
    private truncatePipe: TruncatePipe,
    private capacityService: CapacityService
  ) {
    this.store.dispatch(new SetBanner('CONFIRMATION', true, false, ''));
  }

  ngAfterViewInit() {
    this.subscriptions.push(
      this.basket$.pipe(filter(b => b.basket !== null && b.previousBasket !== null)).subscribe(basket => {
        this.basket = basket;
        this.expo.getExpoOrderByOrderId(basket.previousBasket.id).subscribe(res => {
          this.expoOrder = res[0];
          const emailAddress = localStorage.getItem('emailAddress');
          localStorage.removeItem('emailAddress');
          if (emailAddress && emailAddress !== '') {
            this.email
              .postConfirmation({ expoOrder: this.expoOrder, basket: this.basket, emailAddress })
              .subscribe(() => {});
          }
          if (!this.receiptPrinted) {
            this.printer.onSendMessageCanvas(this.generateReceipt.bind(this));
            this.receiptPrinted = true;
          }
        });
      })
    );
    this.deleteOldCapacity();
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
    this.store.dispatch(new ClearChange());
    this.store.dispatch(new ClearTrancloudCardData());
    this.store.dispatch(new CleanGiftCard()); // make it all nice and shiny, then obliterate it immediately
    this.store.dispatch(new ClearExtraCard());
    this.store.dispatch(new SetBillingInfo(null));
  }

  startNewOrder() {
    this.router.navigateByUrl('/kiosk/start-order');
  }

  getTime(oloTime: string): string {
    return DateTime.fromOlo(oloTime).time;
  }

  deleteOldCapacity() {
    this.capacityService.getOldCapacityOrders().subscribe((orders: DirectusCurrentOrder[]) => {
      orders.forEach(o => {
        this.capacityService.deleteOldCapacityOrders(o.id.toString()).subscribe(() => {});
      });
    });
  }

  generateReceipt() {
    console.log('Date Time');
    const formattedDateTime = moment(
      this.basket.previousBasket.deliverymode === 'dinein'
        ? this.expoOrder.ready_time
        : this.basket.previousBasket.readytime,
      'YYYY-MM-DD HH:mm:ss'
    ).format('MM/DD/YYYY - hh:mm');
    console.log(formattedDateTime);
    const cardData = this.store.selectSnapshot(state => state.basket.trancloudInfo);
    const formattedSubtotal = this.currencyPipe.transform(this.basket.previousBasket.subtotal, 'USD');
    let formattedDiscount = '';
    if (this.basket.previousBasket.discounts && this.basket.previousBasket.discounts.length) {
      formattedDiscount = this.currencyPipe.transform(this.basket.previousBasket.discounts[0].amount, 'USD');
    }
    const formattedTax = this.currencyPipe.transform(this.basket.previousBasket.salestax, 'USD');
    const formattedTotal = this.currencyPipe.transform(this.basket.previousBasket.total, 'USD');

    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 3);

    // this.printer.drawAlignedText('center', this.printer.centerPosition, 'Punch Neapolitan Pizza');
    // this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1)

    this.printer.drawAlignedText('center', this.printer.centerPosition, this.basket.previousBasket.vendorname);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);

    this.printer.drawAlignedText(
      'center',
      this.printer.centerPosition,
      this.basket.billingInfo.firstname + ' ' + this.basket.billingInfo.lastname
    );
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);

    this.printer.drawAlignedText('center', this.printer.centerPosition, 'Order #' + this.basket.previousBasket.oloid);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);

    this.printer.drawAlignedText('center', this.printer.centerPosition, formattedDateTime);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 3);

    this.basket.previousBasket.products.forEach((product: any) => {
      const formattedName = this.truncatePipe.transform(product.name, 27);
      const formattedPrice = this.currencyPipe.transform(product.totalcost, 'USD');
      this.printer.drawAlignedText('left', this.printer.leftPosition, product.quantity + ' - ' + formattedName);
      this.printer.drawAlignedText('right', this.printer.rightPosition, formattedPrice);
      this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
      if (product.choices && product.choices.length) {
        product.choices.forEach((choice: any) => {
          if (
            !choice.name.includes('Included') &&
            choice.name !== 'Additional Toppings' &&
            choice.name !== 'Additional Ingredients' &&
            choice.name !== 'Mozzarella (Standard, No Charge)'
          ) {
            this.printer.drawAlignedText(
              'left',
              this.printer.leftPosition,
              '     • ' + this.truncatePipe.transform(choice.name, 30)
            );
            this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
          }
        });
      }
    });

    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);

    this.printer.context.fillRect(0, this.printer.cursor - 2, this.printer.canvasData.receiptWidth, 2); // Underline
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);

    this.printer.drawAlignedText('left', this.printer.leftPosition, 'Subtotal');
    this.printer.drawAlignedText('right', this.printer.rightPosition, formattedSubtotal);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);

    this.printer.drawAlignedText('left', this.printer.leftPosition, 'Discount');
    if (this.basket.previousBasket.discounts && this.basket.previousBasket.discounts.length) {
      this.printer.drawAlignedText('right', this.printer.rightPosition, '-' + formattedDiscount);
    }
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);

    this.printer.drawAlignedText('left', this.printer.leftPosition, 'Tax');
    this.printer.drawAlignedText('right', this.printer.rightPosition, formattedTax);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);

    if (this.basket.previousBasket.tip) {
      this.printer.drawAlignedText('left', this.printer.leftPosition, 'Tip');
      this.printer.drawAlignedText(
        'right',
        this.printer.rightPosition,
        this.currencyPipe.transform(this.basket.previousBasket.tip, 'USD')
      );
      this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
    }

    this.printer.drawAlignedText('left', this.printer.leftPosition, 'Total');
    this.printer.drawAlignedText('right', this.printer.rightPosition, formattedTotal);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 3);

    if (cardData) {
      this.printer.drawAlignedText('left', this.printer.leftPosition, cardData.cardType);
      this.printer.drawAlignedText('right', this.printer.rightPosition, cardData.cardNumber);
      this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
      this.printer.drawAlignedText('left', this.printer.leftPosition, `  Auth: ${cardData.authCode}`);
      this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 1);
      this.printer.drawAlignedText('left', this.printer.leftPosition, `  Ref #: ${cardData.refNo}`);
      this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);
    }

    this.printer.drawAlignedText('left', this.printer.leftPosition, 'Paid with:');

    const paymentMethods =
      (this.basket.giftCard !== null
        ? 'GIFT CARD'
        : this.basket.billingInfo.billingmethod || this.basket.extraCard
        ? 'CASH'
        : 'CREDIT CARD') +
      (this.basket.extraCard
        ? ' / CREDIT CARD'
        : this.basket.giftCard !== null &&
          this.basket.giftCard.length === 1 &&
          this.basket.giftCard[0].balance < this.basket.previousBasket.total
        ? ' / CASH'
        : '');

    console.log('Payment method(s): ' + paymentMethods);
    console.log('Basket: ', this.basket);

    this.printer.drawAlignedText('right', this.printer.rightPosition, paymentMethods);
    this.printer.drawLineSpace(this.printer.canvasData.lineSpace, 2);
  }
}
