<div class="d-flex flex-row flex-wrap text-center align-items-start justify-content-center pb-3 h-fit-content">
  <img
    class="of-contain w-100 br-1 mb-3"
    [src]="imagePath ? imagePath : 'assets/images/default.png'"
    [alt]="product.name"
  />

  <div class="h4 fw-bold w-100">
    {{ product.name }}
  </div>

  <div class="h5 w-100" *ngIf="product.cost">
    {{ product.cost | currency }}
  </div>

  <div class="w-100">
    {{ product.description }}
  </div>
</div>

<div class="d-flex flex-row flex-wrap justify-content-center gap-1 align-self-end w-100">
  <div class="d-flex justify-content-center w-100" *ngIf="product.cost">
    <button class="btn w-100 justify-content-center pp-btn-primary text-white" (click)="emitAddToBag()">
      <div *ngIf="!isLoading">Quick Add</div>
      <div *ngIf="isLoading" class="spin-loader"></div>
    </button>
  </div>

  <div class="d-flex justify-content-center w-100" *ngIf="hasModifiers">
    <button class="btn w-100 justify-content-center pp-btn-outline-primary" (click)="emitCustomize()">
      Customize
    </button>
  </div>
</div>
