import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { Modifiers } from 'src/assets/chepri-modules/src/models/olo.modifiers';
import { Product } from 'src/assets/chepri-modules/src/models/olo.product';
import { BasketProductToAdd, BasketProduct } from 'src/assets/chepri-modules/src/models/olo.basketproduct';
import { ToastrService } from 'ngx-toastr';

@Injectable({
  providedIn: 'root'
})
export class GiftCardService {
  constructor(private toastr: ToastrService) {}

  checkIfCardAdded(basket: any, temp: any) {
    let cardAdded = false;
    if (basket && basket.giftCard && basket.giftCard.length === 2) {
      this.toastr.warning('Cant add more than 2 gift cards');
      cardAdded = true;
    }
    if (basket && basket.giftCard) {
      basket.giftCard.forEach((card: any) => {
        if (temp.cardnumber === card.cardnumber) {
          this.toastr.warning('Card already in use');
          cardAdded = true;
        }
      });
    }
    return cardAdded;
  }

  gcDetails(index: number, firstGCForm: any, secondGCForm: any) {
    let temp = {
      cardnumber: '',
      pin: ''
    };
    if (index === 0) {
      temp = {
        cardnumber: firstGCForm.value.cardNumber,
        pin: firstGCForm.value.pinNumber
      };
    } else if (index === 1) {
      temp = {
        cardnumber: secondGCForm.value.cardNumber,
        pin: secondGCForm.value.pinNumber
      };
    } else {
    }
    return temp;
  }

  gcTotal(basket: any) {
    let giftTotal = 0;
    if (basket && basket.giftCard) {
      basket.giftCard.forEach((card: any) => {
        giftTotal = giftTotal + card.balance;
      });
    }
    return giftTotal;
  }
}
