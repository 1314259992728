import { NgModule } from '@angular/core';
import { KioskProductCardComponent } from './product-card.component';
import { CommonModule } from '@angular/common';
import { ButtonModule } from 'src/assets/chepri-modules/src/lib/button/button.module';
import { SvgSpriteModule } from 'src/assets/chepri-modules/src/lib/svg-sprite/svg-sprite.module';

@NgModule({
  declarations: [KioskProductCardComponent],
  imports: [CommonModule, ButtonModule, SvgSpriteModule],
  exports: [KioskProductCardComponent]
})
export class KioskProductCardModule {}
