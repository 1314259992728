import { Injectable } from '@angular/core';
import { Observable, of, throwError } from 'rxjs';
import { TranCloudApiService } from '@app/datacap-trancloud/services/trancloud-api.service';
import { TranCloudTransactionRequest } from '@app/datacap-trancloud/models/tran-cloud-request.interface';
import { catchError, delay, map, switchMap, take, tap } from 'rxjs/operators';
import { PinPadDeviceService } from '@app/datacap-trancloud/services/pin-pad-device.service';
import { TranCloudAdmin } from '@app/datacap-trancloud/models/admin/tran-cloud-admin.interface';
import { ReturnByRecordNo } from '@app/datacap-trancloud/models/transactions/emv/return-by-record-no.interface';
import { ToastrService } from 'ngx-toastr';
import { EMVPadResetResponse } from '@app/datacap-trancloud/models/transactions/emv/emv-pad-reset.interface';
import { DirectusExpoProvider } from '@app/providers/expo/directus/directus-expo.provider';
import { TrancloudErrorLoggingService } from '@app/datacap-trancloud/services/trancloud-error-logging.service';
import { DecimalPipe } from '@angular/common';
import { environment } from '@env/environment';
import { DirectusApiService } from '@app/providers/expo/directus/directus-api.service';
import { DirectusUser } from '@directus/sdk';
import { DirectusSchema } from '@app/providers/expo/directus/directus-collections.interface';
import { ModeService } from '@app/providers/mode.service';

@Injectable({
  providedIn: 'root'
})
export class TrancloudProviderService {
  merchantID: string;
  tranDeviceID: string;
  private posPackageID = 'EMVUSClient:1.26';
  private sequenceNo = '0010010010';
  private invoiceNo = '001';

  private debug = window.location.href.includes('localhost');
  private testMode = JSON.parse(localStorage.getItem('testModeSet'));

  constructor(
    // @Inject('config') private config: BrandConfig,
    private tranCloudAPI: TranCloudApiService,
    private pinPadDevice: PinPadDeviceService,
    private errorLogging: TrancloudErrorLoggingService,
    private decimalPipe: DecimalPipe,
    private directusAPI: DirectusApiService,
    private mode: ModeService
  ) {}

  initializePad(): Observable<any> {
    console.log(this.debug && !this.testMode);
    if (this.debug && !this.testMode) {
      return this.setTestMode().pipe(
        take(1),
        switchMap((testModeRes: any) => {
          this.sequenceNo = testModeRes.RStream.SequenceNo;
          return this.resetPad().pipe(
            delay(1600),
            tap(resetRes => {
              this.sequenceNo = resetRes.RStream.SequenceNo;
            })
          );
        })
      );
    } else {
      return this.resetPad().pipe(
        delay(1600),
        tap(resetRes => {
          this.sequenceNo = resetRes.RStream.SequenceNo;
        })
      );
    }
  }

  creditCardSale(purchaseAmount: number): Observable<any> {
    let newDelimitedAddress = '';
    if (this.pinPadDevice.pinPadAddressType === 'MAC') {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    } else {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    }
    const purchaseAmt = this.decimalPipe.transform(purchaseAmount, '1.2-2');
    return this.resetPad().pipe(
      delay(1600),
      switchMap(resetRes => {
        const transRequest: TranCloudTransactionRequest = {
          TStream: {
            Transaction: {
              MerchantID: this.merchantID,
              TerminalID: this.pinPadDevice.terminalID,
              POSPackageID: this.posPackageID,
              TranCode: 'EMVSale',
              CardType: 'Credit',
              SecureDevice: this.pinPadDevice.pinPadType + '_' + this.pinPadDevice.pinPadProvider,
              SequenceNo: this.sequenceNo,
              TranDeviceID: this.tranDeviceID,
              InvoiceNo: this.invoiceNo,
              RefNo: this.invoiceNo,
              RecordNo: 'RecordNumberRequested',
              Frequency: 'OneTime',
              Amount: {
                Purchase: purchaseAmt.toString()
              },
              // PartialAuth: 'Disallow', // this may be different per device and might need to be in the config
              OKAmount: this.pinPadDevice.pinPadType?.includes('LINK2500') ? 'Disallow' : undefined,
              PinPadMACAddress: this.pinPadDevice.pinPadAddressType === 'MAC' ? newDelimitedAddress : '',
              PinPadIpAddress: this.pinPadDevice.pinPadAddressType === 'IP' ? newDelimitedAddress : '',
              PinPadIpPort: '12000'
            }
          }
        };

        this.invoiceNo += 1;

        return this.tranCloudAPI.processEMVTransaction(transRequest).pipe(
          catchError(err => {
            return this.errorLogging.logError('EMVSale', err.message, 'error').pipe(
              switchMap(() => {
                return this.resetPad().pipe(switchMap(() => throwError(err)));
              })
            );
          }),
          tap((transactionRes: any) => {
            this.sequenceNo = transactionRes.RStream.SequenceNo;
          }),
          switchMap((res: any) => {
            if (res.RStream.CmdStatus == 'Error') {
              return this.errorLogging.logError('EMVSale', res, res.RStream.TextResponse).pipe(
                switchMap(() => {
                  return this.resetPad().pipe(switchMap(() => throwError(res.RStream.TextResponse)));
                })
              );
            } else {
              return this.resetPad().pipe(
                map(() => {
                  return res;
                })
              );
            }
          })
        );
      })
    );
  }

  creditCardRefund(invoiceNo: string, authCode: string, purchaseAmount: number): Observable<any> {
    let newDelimitedAddress = '';
    if (this.pinPadDevice.pinPadAddressType === 'MAC') {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    } else {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    }
    const purchaseAmt = (Math.round(Number(purchaseAmount) * 100) / 100).toFixed(2);
    return this.resetPad().pipe(
      delay(1600),
      switchMap(resetRes => {
        const transRequest: TranCloudTransactionRequest = {
          TStream: {
            Transaction: {
              MerchantID: this.merchantID,
              TerminalID: this.pinPadDevice.terminalID,
              POSPackageID: this.posPackageID,
              TranCode: 'EMVVoidSale',
              SecureDevice: this.pinPadDevice.pinPadType + '_' + this.pinPadDevice.pinPadProvider,
              InvoiceNo: invoiceNo,
              RefNo: invoiceNo,
              AuthCode: authCode,
              Amount: {
                Purchase: purchaseAmt.toString()
              },
              SequenceNo: this.sequenceNo,
              TranDeviceID: this.tranDeviceID,
              PinPadMACAddress: this.pinPadDevice.pinPadAddressType === 'MAC' ? newDelimitedAddress : '',
              PinPadIpAddress: this.pinPadDevice.pinPadAddressType === 'IP' ? newDelimitedAddress : '',
              PinPadIpPort: '12000'
            }
          }
        };

        return this.tranCloudAPI.processEMVTransaction(transRequest).pipe(
          tap((transactionRes: any) => {
            this.sequenceNo = transactionRes.RStream.SequenceNo;
          }),
          switchMap((res: any) => {
            if (res.RStream.CmdStatus == 'Error') {
              return this.errorLogging.logError('EMVVoidSale', res, res.RStream.TextResponse).pipe(
                switchMap(() => {
                  return this.resetPad().pipe(switchMap(() => throwError(res.RStream.TextResponse)));
                })
              );
            } else {
              return this.resetPad().pipe(
                map(() => {
                  return res;
                })
              );
            }
          })
        );
      })
    );
  }

  giftCardSale(): Observable<any> {
    let newDelimitedAddress = '';
    if (this.pinPadDevice.pinPadAddressType === 'MAC') {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    } else {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    }
    return this.resetPad().pipe(
      delay(1600),
      switchMap(resetRes => {
        const transRequest: TranCloudTransactionRequest = {
          TStream: {
            Transaction: {
              MerchantID: this.merchantID,
              TerminalID: this.pinPadDevice.terminalID,
              POSPackageID: this.posPackageID,
              TranCode: 'GetPrePaidStripe',
              SecureDevice: this.pinPadDevice.pinPadType + '_' + this.pinPadDevice.pinPadProvider,
              MinLen: '1',
              MaxLen: '20',
              PrePaidStripeTimeout: '30',
              SequenceNo: this.sequenceNo,
              TranDeviceID: this.tranDeviceID,
              PinPadMACAddress: this.pinPadDevice.pinPadAddressType === 'MAC' ? newDelimitedAddress : '',
              PinPadIpAddress: this.pinPadDevice.pinPadAddressType === 'IP' ? newDelimitedAddress : '',
              PinPadIpPort: '12000'
            }
          }
        };

        return this.tranCloudAPI.processEMVTransaction(transRequest).pipe(
          tap((transactionRes: any) => {
            this.sequenceNo = transactionRes.RStream.SequenceNo;
          }),
          switchMap((res: any) => {
            if (res.RStream.CmdStatus == 'Error') {
              return this.errorLogging.logError('GetPrePaidStripe', res, res.RStream.TextResponse).pipe(
                switchMap(() => {
                  return this.resetPad().pipe(switchMap(() => throwError(res.RStream.TextResponse)));
                })
              );
            } else {
              return this.resetPad().pipe(
                map(() => {
                  return res;
                })
              );
            }
          })
        );
      })
    );
  }

  refundByRecordNo(invoiceNo: string, refNo: string, amount: number, recordNo: string) {
    let newDelimitedAddress = '';
    if (this.pinPadDevice.pinPadAddressType === 'MAC') {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    } else {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    }
    return this.resetPad().pipe(
      delay(1600),
      switchMap(resetRes => {
        const transBody: ReturnByRecordNo = {
          MerchantID: this.merchantID,
          TerminalID: this.pinPadDevice.terminalID,
          POSPackageID: this.posPackageID,
          TranType: 'Credit',
          TranCode: 'ReturnByRecordNo',
          SecureDevice: this.pinPadDevice.pinPadType + '_' + this.pinPadDevice.pinPadProvider,
          InvoiceNo: invoiceNo,
          RefNo: refNo,
          Amount: {
            Purchase: amount.toFixed(2)
          },
          SequenceNo: this.sequenceNo,
          RecordNo: recordNo,
          Frequency: 'OneTime',
          TranDeviceID: this.tranDeviceID,
          PinPadMACAddress: this.pinPadDevice.pinPadAddressType === 'MAC' ? newDelimitedAddress : '',
          PinPadIpAddress: this.pinPadDevice.pinPadAddressType === 'IP' ? newDelimitedAddress : '',
          PinPadIpPort: '12000'
        };
        return this.tranCloudAPI.processEMVTransaction({ TStream: { Transaction: transBody } }).pipe(
          tap((transactionRes: any) => {
            this.sequenceNo = transactionRes.RStream.SequenceNo;
            this.resetPad().subscribe(() => {});
          }),
          switchMap((res: any) => {
            if (res.RStream.CmdStatus == 'Error') {
              return this.errorLogging.logError('ReturnByRecordNumber', res, res.RStream.TextResponse).pipe(
                switchMap(() => {
                  return this.resetPad().pipe(switchMap(() => throwError(res.RStream.TextResponse)));
                })
              );
            } else {
              return of(res);
            }
          })
        );
      })
    );
  }

  getServerVersion(): Observable<any> {
    let newDelimitedAddress = '';
    if (this.pinPadDevice.pinPadAddressType === 'MAC') {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    } else {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    }
    return this.resetPad().pipe(
      delay(1600),
      switchMap(resetRes => {
        const transRequest: TranCloudTransactionRequest = {
          TStream: {
            Transaction: {
              MerchantID: this.merchantID,
              TerminalID: this.pinPadDevice.terminalID,
              POSPackageID: this.posPackageID,
              TranCode: 'ServerVersion',
              SecureDevice: this.pinPadDevice.pinPadType + '_' + this.pinPadDevice.pinPadProvider,
              SequenceNo: this.sequenceNo,
              TranDeviceID: this.tranDeviceID,
              PinPadMACAddress: this.pinPadDevice.pinPadAddressType === 'MAC' ? newDelimitedAddress : '',
              PinPadIpAddress: this.pinPadDevice.pinPadAddressType === 'IP' ? newDelimitedAddress : '',
              PinPadIpPort: '12000'
            }
          }
        };

        return this.tranCloudAPI.processEMVTransaction(transRequest).pipe(
          tap((transactionRes: any) => {
            this.sequenceNo = transactionRes.RStream.SequenceNo;
          }),
          switchMap((res: any) => {
            if (res.RStream.CmdStatus == 'Error') {
              return this.errorLogging.logError('ServerVersion', res, res.RStream.TextResponse).pipe(
                switchMap(() => {
                  return throwError(res.RStream.TextResponse);
                })
              );
            } else {
              return of(res);
            }
          })
        );
      })
    );
  }

  downloadParams(): Observable<any> {
    let newDelimitedAddress = '';
    if (this.pinPadDevice.pinPadAddressType === 'MAC') {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    } else {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    }
    return this.resetPad().pipe(
      delay(1600),
      switchMap(resetRes => {
        console.log('sending param download');
        const transRequest: TranCloudTransactionRequest = {
          TStream: {
            Admin: {
              MerchantID: this.merchantID,
              TerminalID: this.pinPadDevice.terminalID,
              POSPackageID: this.posPackageID,
              TranCode: 'EMVParamDownload',
              SecureDevice: this.pinPadDevice.pinPadType + '_' + this.pinPadDevice.pinPadProvider,
              SequenceNo: this.sequenceNo,
              TranDeviceID: this.tranDeviceID,
              PinPadMACAddress: this.pinPadDevice.pinPadAddressType === 'MAC' ? newDelimitedAddress : '',
              PinPadIpAddress: this.pinPadDevice.pinPadAddressType === 'IP' ? newDelimitedAddress : '',
              PinPadIpPort: '12000'
            }
          }
        };

        return this.tranCloudAPI.processEMVTransaction(transRequest).pipe(
          tap((transactionRes: any) => {
            this.sequenceNo = transactionRes.RStream.SequenceNo;
          }),
          switchMap((res: any) => {
            if (res.RStream.CmdStatus == 'Error') {
              return this.errorLogging.logError('EMVParamDownload', res, res.RStream.TextResponse).pipe(
                switchMap(() => {
                  return throwError(res.RStream.TextResponse);
                })
              );
            } else {
              return of(res);
            }
          })
        );
      })
    );
  }

  resetPad(): Observable<any> {
    return this.resetTranDeviceID().pipe(
      switchMap(() => {
        let newDelimitedAddress: string;
        if (this.pinPadDevice.pinPadAddressType === 'MAC') {
          newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
        } else {
          newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
        }
        const transRequest: TranCloudTransactionRequest = {
          TStream: {
            Transaction: {
              MerchantID: this.merchantID,
              TerminalID: this.pinPadDevice.terminalID,
              POSPackageID: this.posPackageID,
              TranCode: 'EMVPadReset',
              SecureDevice: this.pinPadDevice.pinPadType + '_' + this.pinPadDevice.pinPadProvider,
              SequenceNo: this.sequenceNo,
              TranDeviceID: this.tranDeviceID,
              PinPadMACAddress: this.pinPadDevice.pinPadAddressType === 'MAC' ? newDelimitedAddress : '',
              PinPadIpAddress: this.pinPadDevice.pinPadAddressType === 'IP' ? newDelimitedAddress : '',
              PinPadIpPort: '12000'
            }
          }
        };

        return this.tranCloudAPI.processEMVTransaction(transRequest).pipe(
          tap((transactionRes: any) => {
            this.sequenceNo = transactionRes.RStream.SequenceNo;
          }),
          switchMap((res: any) => {
            if (res.RStream.CmdStatus == 'Error') {
              return this.errorLogging.logError('EMVPadReset', res, res.RStream.TextResponse).pipe(
                switchMap(() => {
                  return throwError(res.RStream.TextResponse);
                })
              );
            } else {
              return of(res);
            }
          })
        );
      })
    );
  }

  setTestMode(): Observable<any> {
    let newDelimitedAddress: string;
    if (this.pinPadDevice.pinPadAddressType === 'MAC') {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    } else {
      newDelimitedAddress = this.pinPadDevice.pinPadMACAddress;
    }
    const transRequest: TranCloudAdmin = {
      Admin: {
        MerchantID: this.merchantID,
        TerminalID: this.pinPadDevice.terminalID,
        POSPackageID: this.posPackageID,
        TranCode: 'TestMode',
        SecureDevice: this.pinPadDevice.pinPadType + '_' + this.pinPadDevice.pinPadProvider,
        SequenceNo: this.sequenceNo,
        TranDeviceID: this.tranDeviceID,
        PinPadMACAddress: this.pinPadDevice.pinPadAddressType === 'MAC' ? newDelimitedAddress : '',
        PinPadIpAddress: this.pinPadDevice.pinPadAddressType === 'IP' ? newDelimitedAddress : '',
        PinPadIpPort: '12000'
      }
    };

    const request: TranCloudTransactionRequest = {
      TStream: transRequest
    };

    return this.tranCloudAPI.processEMVTransaction(request).pipe(
      tap((transactionRes: any) => {
        console.log(transactionRes);
        this.sequenceNo = transactionRes.RStream.SequenceNo;
        this.testMode = transactionRes.RStream.CmdStatus !== 'Error';
        sessionStorage.setItem('testModeSet', JSON.stringify(this.testMode));
      })
    );
  }

  syncPinPad(
    newPinPadMacAddress: string,
    newPinPadType: string,
    newPinPadProvider: string,
    newPinPadAddressType: string
  ) {
    this.pinPadDevice.updateStoredPinPadMACAddress(newPinPadMacAddress);
    this.pinPadDevice.updateStoredPinPadType(newPinPadType);
    this.pinPadDevice.updateStoredPinPadProvider(newPinPadProvider);
    this.pinPadDevice.updateStoredPinPadAddressType(newPinPadAddressType);
    return this.downloadParams().pipe(
      switchMap((res: any) => {
        // tslint:disable-next-line:triple-equals
        if (res.RStream.CmdStatus != 'Success' && res.RStream.DSIXReturnCode != '003326') {
          return throwError('Issue syncing PinPad. Try again');
        } else {
          return of(res);
        }
      })
    );
  }

  voidByRecordNo(
    amount: number,
    refNo: string,
    authCode: string,
    recordNo: string,
    invoiceNo: string,
    processData: string,
    acqDataRef: string
  ) {
    return this.resetPad().pipe(
      delay(1600),
      switchMap(resetRes => {
        const transRequest: TranCloudTransactionRequest = {
          TStream: {
            Transaction: {
              MerchantID: this.merchantID,
              TerminalID: this.pinPadDevice.terminalID,
              POSPackageID: this.posPackageID,
              TranType: 'Credit',
              TranCode: 'VoidSaleByRecordNo',
              SecureDevice: this.pinPadDevice.pinPadType + '_' + this.pinPadDevice.pinPadProvider,
              InvoiceNo: invoiceNo,
              RefNo: refNo,
              AuthCode: authCode,
              Amount: {
                Purchase: amount.toFixed(2)
              },
              SequenceNo: this.sequenceNo,
              RecordNo: recordNo,
              Frequency: 'OneTime',
              AcqRefData: acqDataRef,
              ProcessData: processData,
              TranDeviceID: this.tranDeviceID
            }
          }
        };
        return this.tranCloudAPI.processEMVTransaction(transRequest).pipe(
          switchMap((res: any) => {
            this.sequenceNo = res.RStream.SequenceNo;
            if (res.RStream.CmdStatus == 'Error') {
              return this.errorLogging.logError('VoidSaleByRecordNo', res, res.RStream.TextResponse).pipe(
                switchMap(() => {
                  return this.resetPad().pipe(switchMap(() => throwError(res.RStream.TextResponse)));
                })
              );
            } else {
              return this.resetPad().pipe(
                map(() => {
                  return res;
                })
              );
            }
          })
        );
      })
    );
  }

  checkConnection(
    newPinPadMacAddress: string,
    newPinPadType: string,
    newPinPadProvider: string,
    newPinPadAddressType: string
  ) {
    this.pinPadDevice.updateStoredPinPadMACAddress(newPinPadMacAddress);
    this.pinPadDevice.updateStoredPinPadType(newPinPadType);
    this.pinPadDevice.updateStoredPinPadProvider(newPinPadProvider);
    this.pinPadDevice.updateStoredPinPadAddressType(newPinPadAddressType);
    return this.resetPad().pipe(
      switchMap((res: EMVPadResetResponse) => {
        this.sequenceNo = res.RStream.SequenceNo;
        if (res.RStream.CmdStatus == 'Error') {
          return this.errorLogging.logError('CheckConnection', res, res.RStream.TextResponse).pipe(
            switchMap(() => {
              return throwError(res.RStream.TextResponse);
            })
          );
        } else {
          return of(res);
        }
      })
    );
  }

  private ensurePinPadSetup(): Observable<boolean> {
    if (
      !this.pinPadDevice.pinPadProvider ||
      !this.pinPadDevice.pinPadType ||
      !this.pinPadDevice.pinPadAddressType ||
      !this.pinPadDevice.pinPadMACAddress ||
      !this.tranDeviceID ||
      !this.merchantID
    ) {
      return throwError('Pin Pad not connected. Please run sync.');
    } else {
      return of(true);
    }
  }

  // chunkMAC(str: string): string[] {
  //   let ret = [];
  //   let i;
  //   let len;
  //
  //   for (i = 0, len = str.length; i < len; i += 2) {
  //     ret.push(str.substr(i, 2));
  //   }
  //
  //   return ret;
  // }

  // chunkIP(str: string) {
  //   let ret = [];
  //   let i;
  //   let len;
  //
  //   for (i = 0, len = str.length; i < len; i += 3) {
  //     ret.push(str.substr(i, 3));
  //   }
  //
  //   return ret;
  // }

  private resetTranDeviceID() {
    if (this.mode.getStoredMode() === 'kms') {
      return this.directusAPI.getMe().pipe(
        switchMap((me: DirectusUser<DirectusSchema>) => {
          if (!me.location_trancloud_id) {
            return throwError(() => new Error('No TranCloud ID found for this location'));
          }
          if (!me.location_merchant_id) {
            return throwError(() => new Error('No Merchant ID found for this location'));
          }
          this.tranDeviceID = me.location_trancloud_id;
          this.merchantID = me.location_merchant_id;
          return of(true);
        })
      );
    }
    return of(true);
  }
}
