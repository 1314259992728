import { Component, OnInit } from '@angular/core';
import {ModeService} from '@app/providers/mode.service';
import {CmsService} from '@app/providers/cms.service';
import {Locations} from '@app/models/directus.collections';
import {Router} from '@angular/router';

@Component({
  selector: 'app-location-selection',
  templateUrl: './location-selection.component.html',
  styleUrls: ['./location-selection.component.scss']
})
export class LocationSelectionComponent implements OnInit {

  locations: Locations[] = []

  constructor(
    private mode: ModeService,
    private cms: CmsService,
    private router: Router
  ) {
    this.mode.setApplicationMode('wait-time')
  }

  ngOnInit(): void {
    this.cms.getCMSLocations().subscribe((locations) => {
      this.locations = locations.filter(loc => !loc.location_name.toLowerCase().includes('demo') && !loc.location_name.toLowerCase().includes('onboarding'))
    })
  }

  routeToWaitTime(location: Locations): void {
    this.router.navigate(['wait-time', location.olo_id])
  }



}
