<div class="wait-time-background w-100 h-100">
  <div class="page-shell">
    <h1 class="current-wait-time fw-bold boldText text-uppercase">Current Wait Time:</h1>
    <h3 class="after-ordering fw-bold boldText text-uppercase">(After Ordering)</h3>
    <h2 class="pizza-count fw-bold boldText text-uppercase">
      <span class="fw-bold boldText text-uppercase">1</span> pizza
    </h2>
    <h2 class="pizza-count fw-bold boldText text-uppercase">
      <span class="fw-bold boldText text-uppercase">2</span> pizzas
    </h2>
    <h2 class="pizza-count fw-bold boldText text-uppercase">
      <span class="fw-bold boldText text-uppercase">3</span> pizzas
    </h2>
    <ng-container *ngFor="let slot of upcomingSlots; index as index">
      <ng-container *ngIf="index < 3">
        <app-time-display [waitMinutes]="slot"></app-time-display>
      </ng-container>
    </ng-container>
  </div>
  <img
    class="pp-logo"
    src="/assets/images/punchpizza-flame_hexagon.svg"
    alt="Punch Pizza Logo"
    routerLink="/wait-time"
  />
</div>
