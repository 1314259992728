import { NgModule } from '@angular/core';
import { AuthNavComponent } from './auth-nav.component';
import { CommonModule } from '@angular/common';
import { ButtonModule } from '../button/button.module';
import { LinkModule } from '../link/link.module';

@NgModule({
  declarations: [AuthNavComponent],
  imports: [CommonModule, LinkModule, ButtonModule],
  exports: [AuthNavComponent]
})
export class AuthNavModule {}
