import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { CheckboxComponent } from './checkbox.component';
import { CommonModule } from '@angular/common';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';
import { SharedModule } from '@app/shared';

@NgModule({
  declarations: [CheckboxComponent],
  imports: [CommonModule, SvgSpriteModule, ReactiveFormsModule, SharedModule],
  exports: [CheckboxComponent]
})
export class CheckboxModule {}
