import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { CmsService } from '@app/providers/cms.service';
import { Product } from 'src/assets/chepri-modules/src/models/olo.product';
import { OloMenuComponent } from 'src/assets/chepri-modules/src/lib/olo-menu/olo-menu.component';
import { AnalyticsService } from '@app/providers/analytics.service';

@Component({
  selector: 'lib-olo-kiosk-menu',
  templateUrl: './olo-menu.component.html',
  styles: []
})
export class OloKioskMenuComponent extends OloMenuComponent {
  constructor(modalService: NgbModal, cms: CmsService, analytics: AnalyticsService) {
    super(modalService, cms, analytics);
  }
}
