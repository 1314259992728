import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { MenuRoutingModule } from './menu-routing.module';
import { MenuComponent } from './menu.component';

import { OloMenuModule } from 'src/assets/chepri-modules/src/lib/olo-menu/olo-menu.module';
import { ButtonModule } from 'src/assets/chepri-modules/src/lib/button/button.module';
import { MaxPizzaModalModule } from 'src/assets/chepri-modules/src/lib/max-pizza-modal/max-pizza-modal.module';
import { SoldoutModalModule } from 'src/assets/chepri-modules/src/lib/soldout-modal/soldout-modal.module';
import { SoldoutModalComponent } from 'src/assets/chepri-modules/src/lib/soldout-modal/soldout-modal.component';
import { KioskProductCardModule } from '../customer-kiosk/product-card/product-card.module';

@NgModule({
  declarations: [MenuComponent],
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    Angulartics2Module,
    MenuRoutingModule,
    OloMenuModule,
    ButtonModule,
    MaxPizzaModalModule,
    SoldoutModalModule,
    KioskProductCardModule
  ]
})
export class MenuPageModule {}
