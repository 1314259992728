import { MetaData } from './olo.metadata';
import { Image } from './olo.image';
import { Product } from './olo.product';

export class Category {
  id: number; // Olo product category id.
  name: string; // Name of the product category.
  description: string; // Description of the product category.
  metadata: MetaData[];
  images: Image[];
  products: Product[];
}
