<div class="popupShell">
  <a class="close" aria-label="Close" (click)="xClick()" style="text-align: right;"
    ><div class="popup-x-modal"><lib-svg-sprite svgID="popup-x"></lib-svg-sprite></div
  ></a>
  <div class="popupHeader">
    Enter Favorite Name
  </div>
  <p>Name your perfect creation.</p>
  <div class="d-flex flex-row">
    <div class="col">
      <form [formGroup]="productNameForm">
        <lib-single-text-field
          class="searchBox"
          label="Favorite Product Name"
          inputType="text"
          controlName="productName"
          [parentForm]="productNameForm"
        ></lib-single-text-field>
      </form>
    </div>
  </div>
  <div class="deliveryZoneButtons">
    <lib-button id="checkoutContinueBtn" text="CONTINUE" (buttonClick)="saveOrderAsFavorite()"></lib-button>
  </div>
</div>
