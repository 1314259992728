import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FavoriteOrdersComponent } from './favorite-orders.component';
import { ButtonModule } from '../button/button.module';
import { LinkModule } from '../link/link.module';
import { IconModule } from '../icon/icon.module';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';
import { FavoriteOrderModalModule } from '../favorite-order-modal/favorite-order-modal.module';

@NgModule({
  declarations: [FavoriteOrdersComponent],
  imports: [CommonModule, ButtonModule, LinkModule, IconModule, SvgSpriteModule, FavoriteOrderModalModule],
  exports: [FavoriteOrdersComponent]
})
export class FavoriteOrdersModule {}
