import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { FiredComponent } from './fired.component';
import { Shell } from '@app/components/shell/shell.service';
import { KMSGuard } from '@app/providers/kms.guard';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/expo/fired', pathMatch: 'full' },
    { path: 'expo/fired', component: FiredComponent, data: { title: extract('Fired') }, canActivate: [KMSGuard] }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class FiredRoutingModule {}
