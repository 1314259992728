<div class="d-flex flex-column bg-pp-light-tan kiosk-height overflow-hidden" *ngIf="basket$ | async as basket">
  <app-navbar (backClicked)="setUserInfo()"></app-navbar>

  <div class="h-100 overflow-auto">
    <!-- customer info -->
    <section class="fg-1 fb-0 align-self-stretch pt-5 pb-4">
      <div class="container">
        <!-- info shell -->
        <div class="bg-white br-1 d-flex justify-content-center flex-row flex-wrap p-3 py-5">
          <!-- info title -->
          <div class="h2 fw-bold text-pp-orange text-center text-uppercase w-100 m-0 px-3 pb-5">
            {{ basket.deliverymode | handoff }} Order Information
          </div>

          <form class="w-100 px-3" [formGroup]="infoForm" autocomplete="off">
            <!-- first/last name -->
            <div class="mb-3 d-flex flex-row flex-nowrap gap-2 pb-3 mb-3">
              <!-- first name -->
              <div class="d-flex flex-row flex-wrap w-100">
                <label for="" class="h4 fw-bold text-pp-orange text-uppercase w-100">
                  First Name*
                </label>
                <input
                  type="text"
                  class="form-control form-control-lg h-fit-content p-3 w-100 border-pp-dark-brown bw-2"
                  id=""
                  aria-describedby=""
                  placeholder="First name"
                  formControlName="first"
                  autocomplete="off"
                />
              </div>

              <!-- last initial -->
              <div class="d-flex flex-row flex-wrap w-100">
                <label for="" class="h4 fw-bold text-pp-orange text-uppercase w-100">
                  Last Initial*
                </label>
                <input
                  type="text"
                  class="form-control form-control-lg h-fit-content p-3 w-100 border-pp-dark-brown bw-2"
                  id=""
                  aria-describedby=""
                  placeholder="Last initial"
                  formControlName="last"
                  autocomplete="off"
                />
              </div>
            </div>

            <!-- email address -->
            <div class="mb-3 d-flex flex-row flex-nowrap pb-3 mb-3">
              <div class="d-flex flex-row flex-wrap w-100">
                <label for="" class="h4 fw-bold text-pp-orange text-uppercase w-100 mb-1">
                  Optional: Email Address (for digital receipt)
                </label>
                <small class="mb-2 h6">
                  Note: If you want a printed receipt, please visit the check-in counter
                </small>
                <input
                  type="email"
                  class="form-control form-control-lg h-fit-content p-3 w-100 border-pp-dark-brown bw-2"
                  id=""
                  aria-describedby=""
                  placeholder="Email address"
                  formControlName="email"
                  autocomplete="off"
                />
              </div>
            </div>
            <ng-container *ngIf="basket.deliverymode === 'dinein'">
              <!-- party size -->
              <div class="d-flex flex-row flex-wrap w-100 pb-3 mb-3">
                <label for="" class="h4 fw-bold text-pp-orange text-uppercase w-100">
                  Party Size*
                </label>
                <div class="btn-toolbar flex-nowrap w-100" role="toolbar" aria-label="Toolbar with button groups">
                  <div class="btn-group d-flex flex-row flex-nowrap w-100" role="group" aria-label="First group">
                    <ng-container *ngFor="let size of tableSizeChoicesArray">
                      <label
                        [for]="'partySizeRadioBtn' + size"
                        [class.btn-outline-primary-active]="selectedPartySize === size"
                        class="btn btn-outline-primary fg-1 fb-0 align-self-stretch justify-content-center m-0"
                      >
                        <input
                          type="radio"
                          name="partySizeRadio"
                          [id]="'partySizeRadioBtn' + size"
                          (click)="selectedPartySize = size"
                        />
                        {{ size }}
                      </label>
                    </ng-container>
                  </div>
                </div>
              </div>
            </ng-container>
            <!-- time selection -->
            <div class="mb-3 d-flex flex-row flex-nowrap pb-3 mb-3">
              <!-- first name -->
              <div class="d-flex flex-row flex-wrap w-100">
                <label for="" class="h4 fw-bold text-pp-orange text-uppercase w-100"
                  >{{ basket.deliverymode | handoff }} Time</label
                >
                <select
                  class="form-select form-select-lg h-fit-content p-3 w-100 border-pp-dark-brown bw-2"
                  id=""
                  [formControl]="timeSelect"
                  (change)="newTimeClicked($event.target.value)"
                >
                  <ng-container *ngIf="timeSlots$ | async as timeSlots; else noSlots">
                    <option selected="selected" value="asap">ASAP</option>
                    <ng-container *ngFor="let availableTime of timeSlots; index as i">
                      <option [value]="i">{{ availableTime.key }}</option>
                    </ng-container>
                  </ng-container>
                  <ng-template #noSlots>
                    <option>Sorry, Sold Out</option>
                  </ng-template>
                </select>
              </div>
            </div>

            <!-- continue to payment button -->
            <button class="btn pp-btn-primary text-white w-100 justify-content-center" (click)="continueToNextPage()">
              <ng-container *ngIf="!isLoading; else buttonLoading">Continue to Payment</ng-container>
              <ng-template #buttonLoading>
                <div class="spin-loader"></div>
              </ng-template>
            </button>
          </form>
        </div>
      </div>
    </section>

    <!-- order summary -->
    <section class="fg-1 fb-0 align-self-stretch pb-5">
      <div class="container">
        <!-- info shell -->
        <div class="bg-white br-1 d-flex justify-content-center flex-row flex-wrap p-3 py-5">
          <!-- info title -->
          <div class="h2 fw-bold text-pp-orange text-center text-uppercase w-100 m-0 px-3 pb-5">
            {{ basket.deliverymode | handoff }} Order Summary
          </div>

          <ng-container *ngFor="let product of basket.products; last as last">
            <!-- products -->
            <div class="d-flex flex-row flex-nowrap w-100 px-3">
              <!-- product info -->
              <div class="w-100">
                <!-- product name -->
                <div class="h4 fw-bold mb-3">{{ product.name }} ({{ product.quantity }})</div>

                <!-- ingredients/modifiers -->
                <div class="w-100 pb-3">
                  <ng-container *ngFor="let choice of product.choices; last as last">
                    {{ choice.name }}
                    <ng-container *ngIf="choice.cost"> +{{ choice.cost | currency }}</ng-container>
                    <ng-container *ngIf="!last">,</ng-container>
                  </ng-container>
                </div>

                <!-- edit/remove -->
                <div class="d-flex flex-row flex-nowrap gap-1">
                  <!-- edit button -->
                  <button class="btn pp-btn-primary text-white" (click)="editProduct(product.productId, product.id)">
                    Edit
                  </button>

                  <!-- remove button -->
                  <button class="btn pp-btn-outline-primary" style="min-width: 112px;" (click)="removeProduct(product)">
                    <div *ngIf="!isRemoveLoading">Remove</div>
                    <div *ngIf="isRemoveLoading" class="spin-loader"></div>
                  </button>
                </div>
              </div>

              <!-- price -->
              <div class="w-100">
                <div class="h4 text-end m-0">
                  {{ product.totalcost | currency }}
                </div>
              </div>
            </div>

            <ng-container *ngIf="!last">
              <!-- divider -->
              <div class="px-3 w-100">
                <hr class="w-100 my-4" />
              </div>
            </ng-container>
          </ng-container>
        </div>
      </div>
    </section>
  </div>
  <app-accessibility-toggle></app-accessibility-toggle>
</div>
