import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CustomerKioskMenuComponent } from './menu.component';
import { CustomerKioskMenuRoutingModule } from './menu-routing.module';
import { SingleTextFieldModule } from 'src/assets/chepri-modules/src/lib/single-text-field/single-text-field.module';
import { ButtonModule } from 'src/assets/chepri-modules/src/lib/button/button.module';
import { SvgSpriteModule } from 'src/assets/chepri-modules/src/lib/svg-sprite/svg-sprite.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared';
import { KioskProductCardModule } from '../product-card/product-card.module';
import { NavbarModule } from '@app/components/customer-kiosk/navbar/navbar.module';
import { AccessibilityToggleModule } from '@app/components/customer-kiosk/accessibility-toggle/accessibility-toggle.module';
import { MaxPizzaModalModule } from 'src/assets/chepri-modules/src/lib/max-pizza-modal/max-pizza-modal.module';

@NgModule({
  declarations: [CustomerKioskMenuComponent],
  imports: [
    CommonModule,
    CustomerKioskMenuRoutingModule,
    SingleTextFieldModule,
    ButtonModule,
    ReactiveFormsModule,
    SharedModule,
    SvgSpriteModule,
    KioskProductCardModule,
    NavbarModule,
    AccessibilityToggleModule,
    MaxPizzaModalModule
  ]
})
export class CustomerKioskMenuModule {}
