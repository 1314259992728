import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/components/shell/shell.service';
import { CustomerKioskCheckoutComponent } from '@app/components/customer-kiosk/checkout/checkout.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'cks/checkout',
      component: CustomerKioskCheckoutComponent,
      data: { title: extract('Checkout') }
      // canActivate: [KMSGuard]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class CheckoutRoutingModule {}
