<div class="d-flex flex-column main-background kiosk-height overflow-hidden" *ngIf="basket$ | async as basket">
  <app-navbar></app-navbar>
  <div class="d-flex flex-row py-2 text-center time-remaining-bar w-100">
    <div class="col" *ngIf="!timeLimitLoading; else timeloading">
      Time Selected Guaranteed for {{ timeLeft ? timeLeft : '0:00' }}
    </div>
    <ng-template #timeloading>
      <div class="col">
        <div class="spin-loader"></div>
      </div>
    </ng-template>
  </div>
  <div class="h-100 overflow-auto">
    <!-- payments -->
    <section class="d-flex fg-1 fb-0 align-self-stretch py-5">
      <div class="container">
        <!-- payment method buttons -->
        <div class="pb-5">
          <div class="display-4 fw-bold text-center text-uppercase py-5">Choose a<br />Payment Method</div>
          <ng-container *ngIf="accessibilityMode$ | async">
            <div class="alert" *ngIf="errorMessage" [innerText]="errorMessage"></div>
          </ng-container>
          <div class="d-flex flex-row flex-nowrap gap-2 pb-4">
            <!-- credit/debit -->
            <button class="bg-pp-white border border-pp-orange bw-2 br-1 p-3 w-100" (click)="submitCreditCard()">
              <div class="text-pp-orange py-4">
                <i class="bi bi-credit-card"></i>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="100"
                  height="100"
                  fill="currentColor"
                  class="bi bi-credit-card"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M0 4a2 2 0 0 1 2-2h12a2 2 0 0 1 2 2v8a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V4zm2-1a1 1 0 0 0-1 1v1h14V4a1 1 0 0 0-1-1H2zm13 4H1v5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V7z"
                  />
                  <path d="M2 10a1 1 0 0 1 1-1h1a1 1 0 0 1 1 1v1a1 1 0 0 1-1 1H3a1 1 0 0 1-1-1v-1z" />
                </svg>
              </div>
              <div class="h2 fw-bold text-center text-uppercase">
                Credit/Debit
              </div>
            </button>

            <!-- gift card -->
            <button class="bg-pp-white border border-pp-orange bw-2 br-1 p-3 w-100" (click)="submitGiftCard()">
              <div class="py-4">
                <img
                  width="100"
                  height="100"
                  src="https://cms.punchpizza.chepri.com/assets/26b6d0ee-73ba-4962-af8c-a5a097084ee1"
                  alt=""
                />
              </div>
              <div class="h2 fw-bold text-center text-uppercase">
                Gift Card*
              </div>
            </button>
          </div>
          <ng-container *ngIf="!(accessibilityMode$ | async)">
            <div class="alert" *ngIf="errorMessage" [innerText]="errorMessage"></div>
          </ng-container>
          <div class="h4 fw-bold text-center text-uppercase">
            *single gift card must cover the entire purchase
          </div>
        </div>
        <!--        Coupon Code     -->
        <div class="mb-3 d-flex flex-row flex-nowrap pb-3 mb-3">
          <div class="d-flex flex-row flex-wrap w-100">
            <label for="" class="h4 fw-bold text-pp-orange text-uppercase w-100 mb-1">
              Coupon Code
            </label>
            <div class="d-flex flex-row flex-nowrap gap-3 w-100">
              <input
                [formControl]="couponCode"
                type="text"
                class="form-control form-control-lg h-fit-content p-3 w-100 border-pp-dark-brown bw-2"
                id=""
                aria-describedby=""
                placeholder="Coupon Code"
                formControlName="couponCode"
                autocomplete="off"
              />
              <ng-container *ngIf="!basket.coupon; else couponApplied">
                <button class="btn justify-content-center pp-btn-primary text-white w-25" (click)="applyCoupon()">
                  Apply
                </button>
              </ng-container>
              <ng-template #couponApplied>
                <button class="btn justify-content-center pp-btn-primary text-white w-25" (click)="removeCoupon()">
                  Remove
                </button>
              </ng-template>
            </div>
          </div>
        </div>
        <!-- payment summary -->
        <div class="bg-white p-3 py-5 br-1">
          <!-- title -->
          <div class="h2 fw-bold text-pp-orange text-center text-uppercase mb-5 px-3">
            Payment Summary
          </div>

          <!-- totals text -->
          <div class="d-flex flex-row flex-no-wrap w-100 px-3">
            <!-- fee types -->
            <div class="d-flex flex-column flex-wrap w-100">
              <div class="h5 w-100 pb-2">
                Subtotal
              </div>
              <div class="h5 w-100 pb-2">
                Tax
              </div>
              <div class="h5 w-100 pb-2" *ngIf="basket.totalfees">
                Fees
              </div>
              <div class="h5 w-100 pb-2" *ngIf="basket.coupondiscount">
                Discount
              </div>
              <div class="h3 fw-bold w-100">
                Total
              </div>
            </div>

            <!-- costs -->
            <div class="d-flex flex-column flex-wrap w-100 px-3 text-end">
              <div class="h5 w-100 pb-2">
                {{ basket.subtotal | currency }}
              </div>
              <div class="h5 w-100 pb-2">
                {{ basket.salestax | currency }}
              </div>
              <div class="h5 w-100 pb-2" *ngIf="basket.totalfees">
                {{ basket.totalfees | currency }}
              </div>
              <div class="h5 w-100 pb-2" *ngIf="basket.coupondiscount">-{{ basket.coupondiscount | currency }}</div>
              <div class="h3 fw-bold w-100">
                {{ basket.total | currency }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <app-accessibility-toggle></app-accessibility-toggle>
</div>

<ng-template #gcModal>
  <div class="bg-pp-light-tan modal-wrap" style="position: relative;">
    <div (click)="closeModal()" style="position: absolute; right: 0; top: 15px;">
      <lib-svg-sprite svgID="popup-x" style="height: 25px; width: 25px"></lib-svg-sprite>
    </div>
    <div class="h2 fw-bold text-center text-uppercase">
      Gift Card Info
    </div>
    <div class="h5 text-center text-uppercase">
      please enter any missing gift card info
    </div>
    <form class="w-100 " [formGroup]="giftCardForm" autocomplete="off">
      <div class="mb-3 d-flex flex-row flex-nowrap pb-3 mb-3">
        <div class="d-flex flex-row flex-wrap w-100">
          <label for="gcNumber" class="h4 fw-bold text-pp-orange text-uppercase w-100 mb-1">
            Gift Card Number
          </label>
          <input
            type="text"
            class="form-control form-control-lg h-fit-content p-3 w-100 border-pp-dark-brown bw-2"
            inputmode="numeric"
            autocomplete="off"
            id="gcNumber"
            aria-describedby=""
            placeholder="_ _ _ _ _ - _ _ _ _ - _ _ _ _ _"
            mask="00000-0000-00000"
            formControlName="gcNumber"
          />
        </div>
      </div>
      <div class="mb-3 d-flex flex-row flex-nowrap pb-3 mb-3">
        <div class="d-flex flex-row flex-wrap w-100">
          <label for="gcPIN" class="h4 fw-bold text-pp-orange text-uppercase w-100 mb-1">
            Gift Card PIN
          </label>
          <input
            type="text"
            class="form-control form-control-lg h-fit-content p-3 w-100 border-pp-dark-brown bw-2"
            id="gcPIN"
            aria-describedby=""
            autocomplete="off"
            placeholder="_ _ _ _ _ _"
            inputmode="numeric"
            formControlName="gcPin"
          />
        </div>
      </div>
    </form>
    <button class="btn pp-btn-primary text-white w-100 justify-content-center" (click)="checkBalance()">
      {{ applyingGiftCard ? 'Checking Balance' : 'Apply Gift Card' }}
    </button>
  </div>
</ng-template>
