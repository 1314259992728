import { CustomField } from './olo.customfield';
import { BasketProduct } from './olo.basketproduct';

export class Basket {
  id: string;
  timewanted: string;
  earliestreadytime: string;
  subtotal: number;
  salestax: number;
  taxes: TaxResult[];
  suggestedtipamount: number;
  suggestedtippercentage: number;
  discount: number;
  total: number;
  tip: number;
  coupon: BasketCoupon;
  coupondiscount: number;
  vendorid: string;
  vendoronline: boolean;
  deliverymode: string;
  isupsellenabled: boolean;
  wasupsold: boolean;
  customerhandoffcharge: number;
  leadtimeestimateminutes: number;
  timemode: 'advance' | 'asap' | 'manualfire';
  deliveryaddress: any;
  appliedrewards: LoyaltyReward[];
  fees: BasketFee[];
  totalfees: any;
  donations: BasketDonation[];
  contactnumber?: string;
  contextualpricing: ContextualPricing;
  customfields: BasketCustomField[];
  validationmessages: ValidationMessage[];
  allowstip: boolean;
  discounts: Discount[];
  products: BasketProduct[];
}

interface BasketCoupon {
  couponcode: string;
  description: string;
}

interface ValidationMessage {
  key: string;
  message: string;
  category: 'Error' | 'Warning';
}

interface LoyaltyReward {
  membershipid: number;
  reference: string;
  label: string;
  type: string;
  value: number;
  rewardid: number;
  applied: boolean;
  quantityavailable: number;
  quantityapplied: number;
  description: string;
  fineprint: string;
  imageurl: string;
  validminutes: number;
  availableonline: boolean;
  availableoffline: boolean;
  expirationdate?: string;
  externalreference: string;
  vendorrefs?: string[];
  items: number[];
  categories: number[];
}

export interface Discount {
  description: string;
  amount: number;
}

export class BasketTransfer {
  basket: Basket;
  itemsnottransferred: string[];
}

export interface BasketValidation {
  basketid: string;
  contextualpricing: ContextualPricing;
  tax: number;
  taxes: TaxResult[];
  customerhandoffcharge: number;
  fees: BasketFee[];
  donations: BasketDonation[];
  subtotal: number;
  total: number;
  readytime: string;
  totalfees: number;
  totaldonations: number;
  upsellgroups?: any[];
  posreferenceresponse?: string;
}

export interface ContextualPricing {
  isposvalidated: boolean;
  issyndicated: boolean;
}

export interface TaxResult {
  label: string;
  tax: number;
}

interface BasketFee {
  amount: number;
  description: string;
  note?: string;
}

interface BasketDonation {
  id: number;
  amount: number;
  description: string;
  note: string;
}

export interface BasketCustomField {
  id: number;
  label: string;
  value: string;
  isrequired: boolean;
  validationregex: string;
  scope: string;
}
