import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { KioskCheckoutComponent } from './checkout.component';
import { Shell } from '@app/components/shell/shell.service';
import { KMSGuard } from '@app/providers/kms.guard';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'kiosk/checkout',
      component: KioskCheckoutComponent,
      data: { title: extract('Checkout') },
      canActivate: [KMSGuard]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class KioskCheckoutRoutingModule {}
