<div class="modal-wrapper">
  <i class="fas fa-times x-close" (click)="close()"></i>
  <h1 class="text-center text-uppercase">Add Any Extras?</h1>
  <lib-button id="continueToCheckoutBtn" text="Continue to Checkout" large="true" (buttonClick)="close()"></lib-button>
  <div class="grid-wrapper">
    <ng-container *ngFor="let group of groups">
      <h2 class="text-center text-uppercase" style="padding-top: 20px">{{ group.title }}</h2>
      <div class="item-grid">
        <ng-container *ngFor="let item of group.items">
          <div class="card cardd">
            <div class="cardimagecol">
              <img class="cardimage" src="{{ item.imagePath }}" [alt]="item.name" />
            </div>
            <div class="card-body" style="float: bottom">
              <div class="d-flex flex-row mb-3">
                <div class="col-12">
                  <h5 class="card-title">{{ item.name }}</h5>
                  <p class="cardprice">{{ item.cost }}</p>
                  <ng-container *ngIf="item.shortdescription">
                    <p class="card-text small">
                      {{ item.shortdescription }}
                    </p>
                  </ng-container>
                </div>
              </div>
              <div class="d-flex flex-row">
                <div class="col-12">
                  <div class="d-flex flex-row">
                    <div class="col-12">
                      <lib-button href="javascript:void(0)" (click)="addToCart(item.id)" text="Quick Add"></lib-button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </ng-container>
      </div>
    </ng-container>
  </div>
</div>
