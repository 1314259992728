import {
  Component,
  OnInit,
  ViewChild,
  ElementRef,
  AfterViewInit,
  TemplateRef,
  Output,
  EventEmitter,
  OnDestroy
} from '@angular/core';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { Product } from 'src/assets/chepri-modules/src/models/olo.product';
import { DineInName } from 'src/assets/chepri-modules/src/models/dineinname';
import { TimeRange } from 'src/assets/chepri-modules/src/models/olo.calendars';
import { Observable, Subscription } from 'rxjs';
import { RestaurantStateModel, RestaurantState } from '@app/store/state/restaurant.state';
import { BasketStateModel, BasketState } from '@app/store/state/basket.state';
import {
  SetTimeWanted,
  SetDispatchAddress,
  ValidateBasket,
  GetBasket,
  SetBuckets,
  SetCustomField
} from '@app/store/actions/basket.action';
import { SetBanner } from '@app/store/actions/app.action';
import { UserStateModel, UserState } from '@app/store/state/user.state';
import { UntypedFormBuilder, Validators, UntypedFormGroup, UntypedFormControl } from '@angular/forms';
import { AddFavoriteMeal, SetPartySize, SetDineInName, UpdateUserPreferences } from '@app/store/actions/user.action';
import { ToastrService } from 'ngx-toastr';
import { TimeSlot } from '@app/models/capacity.model';
import { BasketService } from '@app/providers/basket.service';
import { OloUpsellGroup, OloUpsellResponse } from '@app/models/olo-upsell';
import { CmsService } from '@app/providers/cms.service';
import { ModeService } from '@app/providers/mode.service';
import { CapacityService } from '@app/providers/capacity.service';
import { CustomField } from '../../../assets/chepri-modules/src/models/olo.customfield';
import { GlobalStateModel } from '@app/store/state.model';
import { BasketCustomField } from '../../../assets/chepri-modules/src/models/olo.basket';
import { collapseAnimation } from 'angular-animations';
import * as moment from 'moment';
import { AnalyticsService } from '@app/providers/analytics.service';

@Component({
  selector: 'app-order-details',
  templateUrl: './order-details.component.html',
  styleUrls: ['./order-details.component.scss'],
  animations: [collapseAnimation()]
})
export class OrderDetailsComponent implements OnInit, AfterViewInit, OnDestroy {
  @Select() restaurant$: Observable<RestaurantStateModel>;
  @Select() basket$: Observable<BasketStateModel>;
  @Select() user$: Observable<UserStateModel>;
  saveFavoriteCheckbox: any;
  @ViewChild('saveFavorite') saveFavoriteCheckboxRef: ElementRef;
  @ViewChild('upsellModal') upsellModal: TemplateRef<any>;

  // @Input() availableTimes: string[];
  mode: string;

  restaurantID: string;
  closeResult: string;
  selectedProduct: Product;
  isChecked = false;
  dailyRange: TimeRange;
  availableTimes: TimeSlot[] = [];
  hasOrderDetails = false;
  orderType: string;
  buttonAvailable = false;
  deliveryInfoForm: UntypedFormGroup;
  ordersPlacedToday: any;
  subs: Subscription[] = [];
  upsellGroups: OloUpsellGroup[];
  guestForm: UntypedFormGroup;
  maxTableSize: number = 8;
  selectedTableSize: number = null;
  isLoading = false;
  tableSizeArray = Array;

  emailOptIn = new UntypedFormControl(false);
  withAnotherOrder = new UntypedFormControl(false);
  withAnotherOrderCustomField: BasketCustomField = null;

  selectedTime: string;

  private subscriptions: Subscription[] = [];

  // Please Make These Protected Not Private
  constructor(
    private router: Router,
    private store: Store,
    private modalService: NgbModal,
    private fb: UntypedFormBuilder,
    private toastr: ToastrService,
    private basketService: BasketService,
    private cms: CmsService,
    private modeService: ModeService,
    private capacityService: CapacityService,
    private analytics: AnalyticsService
  ) {
    this.mode = this.modeService.getStoredMode();
  }

  async ngOnInit() {
    this.subscriptions.push(
      this.store
        .select((state: GlobalStateModel) => state.basket.basket)
        .subscribe(basket => {
          if (basket && basket.customfields) {
            this.withAnotherOrderCustomField = basket.customfields.find(cf => cf.label.toLowerCase().includes('other'));
            if (this.withAnotherOrder) {
              this.withAnotherOrder.setValue(this.withAnotherOrderCustomField?.value === 'Yes');
            }
            this.analytics.logCheckoutDetail(basket.products);
          }
        })
    );
    this.store.dispatch(new SetBanner('CONFIRM ORDER DETAILS', true, true, ''));
    this.store
      .dispatch(new GetBasket())
      .toPromise()
      .then(() => {
        this.store
          .dispatch(new ValidateBasket())
          .toPromise()
          .then(
            res => {
              // this.toastr.success('Basket Validated');
            },
            err => {
              this.toastr.warning(err.error.message);
            }
          );
      });
    this.orderType = this.store.selectSnapshot(BasketState).basket.deliverymode;
    if (this.orderType === 'dispatch' || this.orderType === 'delivery') {
      const address = this.store.selectSnapshot(BasketState).basket.deliveryaddress;
      this.deliveryInfoForm = this.fb.group({
        streetAddress: address.streetaddress,
        city: address.city,
        zipcode: address.zipcode,
        specialInstructions: address.specialinstructions,
        phone: address.phonenumber,
        saveDetails: false
      });
    }
    const basket = this.store.selectSnapshot(BasketState);
    this.subscriptions.push(
      this.basketService.getUpsells(basket.basket.id).subscribe((res: OloUpsellResponse) => {
        this.upsellGroups = res.groups;
        if (this.upsellGroups.length > 0) {
          this.openUpsell(this.upsellModal);
        }
      })
    );
    this.guestForm = this.fb.group({
      firstName: [basket.billingInfo.firstname ? basket.billingInfo.firstname : '', Validators.required],
      lastName: [basket.billingInfo.lastname ? basket.billingInfo.lastname : '', Validators.required]
    });
    const rest = this.store.selectSnapshot(RestaurantState).restaurant;
    this.subscriptions.push(
      this.cms.getCMSLocations().subscribe((res: any) => {
        res.forEach((loc: any) => {
          if (loc.location_name === rest.name) {
            if (loc.max_table_number) {
              this.maxTableSize = Number(loc.max_table_number);
            }
          }
        });
      })
    );
  }

  ngOnDestroy() {
    this.subscriptions.forEach(sub => sub.unsubscribe());
  }

  iClicked() {}

  onTimeChange(index: any) {
    if (index === 'Select Time') {
      this.buttonAvailable = false;
      return;
    }
    // TODO: Set buckets in basket state
    const basket: BasketStateModel = this.store.selectSnapshot(BasketState);
    const selected = basket.timeSlots[index].value;
    this.selectedTime = moment(selected.buckets[selected.buckets.length - 1].time.moment).format('h:mm A');
    const isManualFire = basket.basket.deliverymode === 'dinein' ? true : false;
    this.store.dispatch(new SetTimeWanted(selected.buckets[selected.buckets.length - 1].time, isManualFire)).subscribe(
      () => {
        this.store.dispatch(new SetBuckets(selected.buckets, isManualFire));
        this.buttonAvailable = true;
      },
      error => {
        // TODO: Fix bug where business hours are used when delivery hours should be used
        this.toastr.warning(error.error.message);
        this.buttonAvailable = false;
      }
    );
  }

  navigateToProduct(product: Product) {
    if (this.mode === 'kms') {
      this.router.navigateByUrl('/kiosk/product/' + product.id);
    }
    if (this.mode !== 'kms') {
      this.router.navigateByUrl('/product/' + product.id);
    }
  }

  tableSizeSelected(index: number) {
    this.selectedTableSize = index;
  }

  checkAvailability() {
    const basket = this.store.selectSnapshot(BasketState).basket;
    let isAvail = false;
    if (basket.deliverymode === 'dinein') {
      if (
        this.buttonAvailable &&
        this.selectedTableSize !== null &&
        this.guestForm.get('firstName').value &&
        this.guestForm.get('firstName').value
      ) {
        isAvail = true;
      }
    } else {
      if (this.buttonAvailable) {
        isAvail = true;
      }
    }
    return isAvail;
  }

  openOrderName(orderNameModal: any) {
    this.modalService.open(orderNameModal, { ariaLabelledBy: 'modal-basic-title', centered: true }).result.then(
      result => {
        this.closeResult = ` Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getOrderNameDismissReason(reason)}`;
      }
    );
  }

  continueToNextPage() {
    this.isLoading = true;
    this.store
      .dispatch(new GetBasket())
      .toPromise()
      .then(() => {
        this.store.dispatch(new ValidateBasket()).subscribe(
          stateResponse => {
            // Basket Valid
            this.submitForm();
            if (this.selectedTableSize) {
              this.store.dispatch(new SetPartySize(this.selectedTableSize + 1));
            }
            if (this.guestForm.get('firstName').value && this.guestForm.get('firstName').value) {
              const dineInName: DineInName = {
                firstname: this.guestForm.get('firstName').value,
                lastname: this.guestForm.get('lastName').value
              };
              this.store.dispatch(new SetDineInName(dineInName));
            }
            const restaurantId = stateResponse.restaurant.restaurant.id;
            const capacitySettings = stateResponse.restaurant.capacity_settings;
            const buckets = stateResponse.basket.buckets;
            const currentBucketIds = stateResponse.basket.capacityPlaceholder;
            const products = stateResponse.basket.basket.products;
            const checkBuzzCapacity: boolean =
              stateResponse.basket.basket.deliverymode === 'pickup' && localStorage.getItem('modeKey') === 'order';
            this.capacityService
              .checkLastSecond(capacitySettings, restaurantId, buckets, currentBucketIds, products, checkBuzzCapacity)
              .subscribe(lastSecond => {
                if (lastSecond.canFit) {
                  if (this.withAnotherOrderCustomField && this.withAnotherOrder.value) {
                    this.store.dispatch(
                      new SetCustomField(
                        this.withAnotherOrderCustomField.id,
                        this.withAnotherOrder.value ? 'Yes' : 'No'
                      )
                    );
                  }
                  if (this.emailOptIn.value) {
                    this.store.dispatch(new UpdateUserPreferences(this.emailOptIn.value));
                  }
                  this.router.navigateByUrl('/checkout');
                  this.isLoading = false;
                } else {
                  this.isLoading = false;
                  this.toastr.warning(
                    lastSecond.enzoError
                      ? 'Sorry, Gluten-Free is sold out. To proceed with your order please remove Gluten-Free from your basket.'
                      : 'The order time selected is no longer available, please choose a different time'
                  );
                }
              });
          },
          err => {
            this.isLoading = false;
            this.toastr.warning(err.error.message);
          }
        );
      });
  }

  saveOrderAsFavorite(event: any, modal: any) {
    this.store.dispatch(new AddFavoriteMeal(event)).subscribe(
      res => {
        this.toastr.success('Order has been added to your favorites!');
      },
      err => {
        this.toastr.warning(err.error.message);
      }
    );
    modal.dismiss();
    // Save to favorites
  }

  submitForm() {
    // We need to update the delivery info
    if (this.orderType === 'dispatch' || this.orderType === 'delivery') {
      const body = {
        streetAddress: this.deliveryInfoForm.get('streetAddress').value,
        city: this.deliveryInfoForm.get('city').value,
        zip: this.deliveryInfoForm.get('zipcode').value,
        specialInstructions: this.deliveryInfoForm.get('specialInstructions').value
      };
      const basketId = this.store.selectSnapshot(BasketState).basket.id;
      this.store.dispatch(new SetDispatchAddress(body, basketId));
    }
  }

  openUpsell(modalRef: TemplateRef<any>) {
    this.modalService.open(modalRef, { size: 'lg', centered: true });
  }

  ngAfterViewInit() {
    this.saveFavoriteCheckbox = this.saveFavoriteCheckboxRef;
  }

  private getOrderNameDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      this.saveFavoriteCheckbox.nativeElement.checked = false;
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      this.saveFavoriteCheckbox.nativeElement.checked = false;
      return 'by clicking on a backdrop';
    } else if (reason === 'Cross click') {
      this.saveFavoriteCheckbox.nativeElement.checked = false;
      return `with: ${reason}`;
    } else {
      // this.addProductToCart();
      return `with: ${reason}`;
    }
  }
}
