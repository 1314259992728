import { NgModule } from '@angular/core';
import { PartySizeComponent } from './party-size.component';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';

@NgModule({
  declarations: [PartySizeComponent],
  imports: [CommonModule, NgbDropdownModule, SvgSpriteModule],
  exports: [PartySizeComponent]
})
export class PartySizeModule {}
