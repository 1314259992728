import { NgModule } from '@angular/core';
import { SvgSpriteComponent } from './svg-sprite.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [SvgSpriteComponent],
  imports: [CommonModule],
  exports: [SvgSpriteComponent]
})
export class SvgSpriteModule {}
