<div class="cks-body d-flex flex-column home-background kiosk-height">
  <!-- welcome -->
  <section
    class="d-flex fg-1 fb-0 align-items-center align-self-stretch accessibility-transition"
    [style.padding-top.vh]="(accessibilityMode$ | async) ? 28 : 0"
  >
    <div class="container">
      <!-- logo -->
      <div class="d-flex flex-row py-5">
        <div class="fg-1 fb-0 align-self-stretch">
          <img
            class="mw-0 w-100 h-auto px-7"
            src="https://cms.punchpizza.chepri.com/assets/cb83d2c8-00c6-49a5-989e-267b265cd0fc"
            alt=""
          />
        </div>
      </div>

      <!-- kiosk on -->
      <div *ngIf="isKioskOn; else kioskOff">
        <!-- welcome message -->
        <div class="d-flex flex-row flex-wrap fg-1 fb-0 align-self-stretch">
          <!-- please see -->
          <div class="w-100">
            <img
              class="px-7 w-100 pb-5"
              src="https://cms.punchpizza.chepri.com/assets/653c95a4-ca17-4e5b-8d26-bc507e62ddfa"
              alt=""
            />
          </div>

          <!-- cashier -->
          <div class="w-100">
            <img
              class="px-7 w-100 pb-5"
              src="https://cms.punchpizza.chepri.com/assets/70795300-9a19-44a4-8e93-f7c98c00de62"
              alt=""
            />
          </div>
        </div>

        <!-- order types -->
        <div>
          <!-- order type btn container -->
          <div class="d-flex flex-row py-5">
            <div class="fg-1 fb-0 align-self-stretch"></div>
            <!-- order type btn row -->
            <div class="d-flex fg-4 fb-0 align-self-stretch flex-row flex-wrap justify-content-center gap-4">
              <!-- dine in btn -->
              <div *ngIf="isDineinOn" class="dine-in-btn w-100 py-3" (click)="startOrder('dinein', kiosk.restaurantID)">
                <ng-container *ngIf="!isDineInLoading; else dineInLoader">Dine-In</ng-container>
                <ng-template #dineInLoader><div class="spin-loader"></div></ng-template>
              </div>

              <!-- takeout btn -->
              <div
                *ngIf="isTakeoutOn"
                class="takeout-btn w-100 py-3"
                (click)="startOrder('pickup', kiosk.restaurantID)"
              >
                <ng-container *ngIf="!isTakeoutLoading; else takeoutLoader">Take out</ng-container>
                <ng-template #takeoutLoader><div class="spin-loader"></div></ng-template>
              </div>
            </div>
            <div class="fg-1 fb-0 align-self-stretch"></div>
          </div>
        </div>
      </div>
      <ng-template #kioskOff>
        <!-- welcome message -->
        <div class="d-flex flex-row flex-wrap fg-1 fb-0 align-self-stretch pb-5">
          <!-- please see -->
          <div class="w-100">
            <img
              class="px-7 w-100 pb-5"
              src="https://cms.punchpizza.chepri.com/assets/812e30de-a5fb-43ca-8aa6-50fce026365a"
              alt=""
            />
          </div>

          <!-- cashier -->
          <div class="w-100">
            <img
              class="px-7 w-100 pb-5"
              src="https://cms.punchpizza.chepri.com/assets/d62ed51b-ba80-455e-8265-213cb7d4be7e"
              alt=""
            />
          </div>

          <!-- to place your -->
          <div class="w-100">
            <img
              class="px-7 w-100 pb-5"
              src="https://cms.punchpizza.chepri.com/assets/64a6e464-5e93-45b2-992f-e19f397fe2c6"
              alt=""
            />
          </div>

          <!-- order -->
          <div class="w-100">
            <img
              class="px-7 w-100"
              src="https://cms.punchpizza.chepri.com/assets/a53503f4-7ecd-415c-8546-ae2752773389"
              alt=""
            />
          </div>
        </div>
      </ng-template>
    </div>
  </section>

  <!-- wait time -->
  <section class="d-flex fg-0 fs-1 fb-0 align-self-stretch">
    <div class="container">
      <!-- current wait time -->
      <div
        class="pt-5 accessibility-transition"
        [class.padding-wait-time]="!(accessibilityMode$ | async)"
        [class.padding-wait-time-accessibility]="accessibilityMode$ | async"
      >
        <!-- current wait time message container -->
        <div class="h1 fw-bold text-white text-center text-uppercase home-current-wait-time pb-4">
          Current Wait Time For:
        </div>

        <!-- wait times container -->
        <div class="d-flex flex-row">
          <!-- wait time 1 -->
          <div class="d-flex flex-row flex-wrap justify-content-center fg-1 fb-0 align-self-stretch">
            <div class="h2 fw-bold text-white text-center text-uppercase w-100 mb-3">1 Pizza</div>
            <div class="hexagon-background">
              <div class="p-5 mb-4 mx-4 text-white display-1">
                {{ kiosk.upcomingSlots[0] ? kiosk.upcomingSlots[0] : '-' }}
              </div>
            </div>
          </div>

          <!-- wait time 2 -->
          <div class="d-flex flex-row flex-wrap justify-content-center fg-1 fb-0 align-self-stretch">
            <div class="h2 fw-bold text-white text-center text-uppercase w-100 mb-3">2 Pizzas</div>
            <div class="hexagon-background">
              <div class="p-5 mb-4 mx-4 text-white display-1">
                {{ kiosk.upcomingSlots[1] ? kiosk.upcomingSlots[1] : '-' }}
              </div>
            </div>
          </div>

          <!-- wait time 3 -->
          <div class="d-flex flex-row flex-wrap justify-content-center fg-1 fb-0 align-self-stretch">
            <div class="h2 fw-bold text-white text-center text-uppercase w-100 mb-3">3 Pizzas</div>
            <div class="hexagon-background">
              <div class="p-5 mb-4 mx-4 text-white display-1">
                {{ kiosk.upcomingSlots[2] ? kiosk.upcomingSlots[2] : '-' }}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <app-accessibility-toggle></app-accessibility-toggle>
</div>
