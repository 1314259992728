import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Message } from '@app/models/cms.messages';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'lib-scheduled-message-modal',
  templateUrl: './scheduled-message-modal.component.html',
  styleUrls: ['./scheduled-message-modal.component.scss']
})
export class ScheduledMessageModalComponent implements OnInit {

  @Input() message: Message
  @Output() xClicked = new EventEmitter<any>()

  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {
  }

  close(): void {
    this.xClicked.emit()
  }

  allowHtml(content: string) {
    return this.sanitizer.bypassSecurityTrustHtml(content)
  }

}
