import { User, NewUser, UserAuth } from 'src/assets/chepri-modules/src/models/olo.user';
import { Card } from 'src/assets/chepri-modules/src/models/olo.card';
import { Product } from '@lib/models/olo.product';
import { Option } from '@lib/models/olo.option';
import { DirectusUser } from '@directus/sdk';
import { DirectusSchema } from '@app/providers/expo/directus/directus-collections.interface';

export class AddUser {
  static readonly type = '[User] Add';
  constructor(public payload: NewUser) {}
}

export class Login {
  static readonly type = '[User] Authenticate';
  constructor(public userAuth: UserAuth, public remember?: boolean) {}
}

export class GetUser {
  static readonly type = '[User] Get User';
  constructor(public token: string) {}
}

export class SetUser {
  static readonly type = '[User] Set User';
  constructor(public user: any) {}
}

export class Logout {
  static readonly type = '[User] Logout';
  constructor() {}
}

export class UpdatePassword {
  static readonly type = '[User] Update Password';
  constructor(public currentPassword: string, public newPassword: string) {}
}

export class ForgotPassword {
  static readonly type = '[User] Forgot Password';
  constructor(public emailAddress: string) {}
}

export class RemoveUser {
  static readonly type = '[User] Remove';
  constructor(public payload: User) {}
}

export class UpdateUser {
  static readonly type = '[User] Update';
  constructor(public info: User) {}
}

export class UpdateCard {
  static readonly type = '[User] Update Card';
  constructor(public payload: Card) {}
}

export class UpdateFavoriteMeal {
  static readonly type = '[User] Update Favorite Meal';
  constructor(public payload: any, public faveid: number) {}
}

export class DeleteFavoriteMeal {
  static readonly type = '[User] Delete Favorite Meal';
  constructor(public payload: any) {}
}

export class DeleteFavoriteLocation {
  static readonly type = '[User] Delete Favorite Location';
  constructor(public payload: any) {}
}
export class UpdateFavoriteLocation {
  static readonly type = '[User] Update Favorite Location';
  constructor(public oldOnes: any, public restaurant_id: any) {}
}

export class GetRecentOrders {
  static readonly type = '[User] Get Recent Orders';
}

export class GetFavoriteMeals {
  static readonly type = '[User] Get Favorite Meals';
}

export class AddLocation {
  static readonly type = '[User] Add Location';
  constructor(public payload: { lat: number; lng: number }) {}
}

export class AddFavoriteLocation {
  static readonly type = '[User] Add Favorite Location';
  constructor(public resturant_id: number) {}
}

// export class UpdateFavoriteLocation {
//   static readonly type = '[User] Update Favorite Location';
//   constructor (public resturant_id: number ) { }
// }

export class AddFavoriteMeal {
  static readonly type = '[User] Add Favorite Meal';
  constructor(public payload: {}) {}
}

export class GetFavStore {
  static readonly type = '[User] Get Favorite Store';
}

export class FoundLocation {
  static readonly type = '[User] Found Location';
  constructor(public payload: { lat: number; lng: number }) {}
}

export class FindGeocodeAddress {
  static readonly type = '[User] Find Geocode Address';
  constructor(public payload: any) {}
}

export class GetLoyaltyInfo {
  static readonly type = '[User] Get Loyalty Info';
  constructor() {}
}

export class GetOloRewardNear {
  static readonly type = '[User] Get Olo Reward Info';
  constructor() {}
}

export class GetOloReward {
  static readonly type = '[User] Get Olo Reward Info';
  constructor() {}
}

export class GetRewardInProfile {
  static readonly type = '[User] Get Reward In Profile';
  constructor() {}
}

export class GetRewardInfo {
  static readonly type = '[User] Get Reward Info';
  constructor() {}
}

export class GetLoyaltyActivity {
  static readonly type = '[User] Get Activity Info';
  constructor() {}
}

export class GetOloBillingAccounts {
  static readonly type = '[User] Get Saved Billing';
  constructor() {}
}

export class GetOfferInfo {
  static readonly type = '[User] Get Offer Info';
  constructor() {}
}

export class ClearUsers {
  static readonly type = '[User] Clear Users';
  constructor() {}
}
export class AddNavStack {
  static readonly type = '[User] Add Nav Page';
  constructor() {}
}

export class RemoveNavStack {
  static readonly type = '[User] Remove Nav Page';
  constructor() {}
}
export class ClearNavStack {
  static readonly type = '[User] Clear Nav';
  constructor() {}
}

export class PaytronixUserLogin {
  static readonly type = '[User] Retrieving Paytronix User Info';
  constructor(public payload: { username: string; password: string; cardnumber: string }) {}
}

export class PaytronixOloUserLogin {
  static readonly type = '[User] Retrieving Paytronix OLO User Login Info';
  constructor(public payload: { username: string; password: string; cardnumber: string }) {}
}

export class PaytronixUserInfo {
  static readonly type = '[User] Retrieving Paytronix OLO User Account Info';
  constructor() {}
}

export class ClearKioskUserData {
  static readonly type = '[User] Clearing Kiosk User Data';
  constructor() {}
}

export class EditPaytronixUser {
  static readonly type = '[User] Saving Paytronix User';
  constructor(public payload: any) {}
}

export class SetDefaultCard {
  static readonly type = '[User] Set Card as Default';
  constructor(public accountidstring: string) {}
}

export class DeleteCard {
  static readonly type = '[User] Delete Card';
  constructor(public accountidstring: string) {}
}

export class SetSelectedFave {
  static readonly type = '[User] Setting Selected Favorite';
  constructor(public payload: any) {}
}

export class SetPartySize {
  static readonly type = '[User] Set Party Size';
  constructor(public size: number) {}
}

export class SetDineInName {
  static readonly type = '[User] Set DineIn Name';
  constructor(public payload: any) {}
}

// also set isMaster as it is based on role
export class SetRole {
  static readonly type = '[User] Set Role';
  constructor(public user: DirectusUser<DirectusSchema>) {}
}

export class SetUserPreferences {
  static readonly type = '[User] Set User Preferences';
  constructor(public authToken: string) {}
}
export class UpdateUserPreferences {
  static readonly type = '[User] Update User Preferences';
  constructor(public emailOptIn: boolean, public smsOptIn?: boolean) {}
}

export class SetSavedProducts {
  static readonly type = '[User] Set Saved Products';

  constructor() {}
}

export class CreateSavedProduct {
  static readonly type = '[User] Create Saved Product';

  constructor(
    public name: string,
    public product: Product,
    public options: number[],
    public quantity: number,
    public specialInstructions?: string,
    public recipientName?: string
  ) {}
}

export class DeleteSavedProduct {
  static readonly type = '[User] Delete Saved Product';

  constructor(public savedProductID: string) {}
}

export class DeleteAccount {
  static readonly type = '[User] Delete Account';
  constructor() {}
}
