import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { RadioButtonComponent } from './radio-button.component';
import { CommonModule } from '@angular/common';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';

@NgModule({
  declarations: [RadioButtonComponent],
  imports: [CommonModule, SvgSpriteModule, ReactiveFormsModule],
  exports: [RadioButtonComponent]
})
export class RadioButtonModule {}
