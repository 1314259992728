import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LocationInfoComponent } from './location-info.component';

// Lib Dependencies
import { LinkModule } from '../link/link.module';

@NgModule({
  declarations: [LocationInfoComponent],
  imports: [LinkModule, CommonModule],
  exports: [LocationInfoComponent]
})
export class LocationInfoModule {}
