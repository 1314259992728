<div *ngIf="children; else noChildren" ngbDropdown>
  <button
    appButtonFeedback
    class="btn link-with-children"
    id="dropdownBasic1"
    ngbDropdownToggle
    [disabled]="disabled"
    (click)="clickEvent()"
  >
    {{ text }}<span class="fa-arrows-v"></span>
  </button>
  <div ngbDropdownMenu aria-labelledby="dropdownBasic1">
    <button
      appButtonFeedback
      class="child-link"
      ngbDropdownItem
      *ngFor="let child of children; index as i"
      (click)="clickEvent(i + 1)"
    >
      {{ child }}
    </button>
  </div>
</div>
<ng-template #noChildren>
  <button
    appButtonFeedback
    *ngIf="ghost && !large"
    [disabled]="disabled"
    [class.buttonLoading]="isLoading"
    class="btn btn-fifthColor link regularButton"
    id="ghostButton"
    (click)="clickEvent()"
  >
    <ng-container *ngIf="!isLoading"> {{ text }} </ng-container>
    <div class="spin-loader-ghost" *ngIf="isLoading"></div>
  </button>
  <button
    appButtonFeedback
    *ngIf="ghost && large"
    [disabled]="disabled"
    [class.buttonLoading]="isLoading"
    class="btn btn-fifthColor link largeButton"
    id="ghostButtonlarge"
    (click)="clickEvent()"
  >
    <ng-container *ngIf="!isLoading"> {{ text }} </ng-container>
    <div class="spin-loader-ghost" *ngIf="isLoading"></div>
  </button>
  <button
    appButtonFeedback
    *ngIf="!ghost && !large"
    class="btn btn-primary text-white link regularButton"
    [class.buttonLoading]="isLoading"
    id="regularButton"
    [disabled]="disabled"
    (click)="clickEvent()"
  >
    <ng-container *ngIf="!isLoading"> {{ text }} </ng-container>
    <div class="spin-loader" *ngIf="isLoading"></div>
  </button>
  <button
    appButtonFeedback
    *ngIf="!ghost && large"
    [class.buttonLoading]="isLoading"
    class="btn btn-primary text-white link largeButton"
    [disabled]="disabled"
    (click)="clickEvent()"
    style="width: 100%;"
  >
    <ng-container *ngIf="!isLoading"> {{ text }} </ng-container>
    <div class="spin-loader" *ngIf="isLoading"></div>
  </button>
  <!-- <button class="btn btn-primary text-white link" [disabled]="disabled" [type]="buttonType" (click)="clickEvent()">{{text}}</button> -->
</ng-template>
