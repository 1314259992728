import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { UntypedFormGroup } from '@angular/forms';
import { CredentialsService } from '@app/core';
import { Store } from '@ngxs/store';
import { SetBillingInfo } from '@app/store/actions/basket.action';
import { BillingInfo } from 'src/assets/chepri-modules/src/models/olo.billinginfo';

@Component({
  selector: 'app-guest-info',
  templateUrl: './guest-info.component.html',
  styleUrls: ['./guest-info.component.scss']
})
export class GuestInfoComponent {
  constructor(private router: Router, private credentialsService: CredentialsService, private store: Store) {}

  leavePage(route: string) {
    if (route === 'sign-up') {
      this.router.navigateByUrl('/sign-up');
    } else if (route === 'sign-in') {
      this.router.navigateByUrl('/sign-in');
    }
  }

  goToCheckout(form: UntypedFormGroup) {
    // Get form date here
    // update BillingInfo
    const billingInfo = new BillingInfo();
    billingInfo.firstname = form.get('firstName').value;
    billingInfo.lastname = form.get('lastName').value;
    billingInfo.emailaddress = form.get('emailAddress').value;
    billingInfo.contactnumber = form.get('phoneNumber').value;
    billingInfo.usertype = 'guest';
    billingInfo.guestoptin = form.get('guestOptIn').value;
    this.store.dispatch(new SetBillingInfo(billingInfo));

    // Set credentials to lift checkout guard
    this.credentialsService.setCredentials({ username: 'guest', token: '', isGuest: true }, false);
    this.router.navigateByUrl('/order-details');
  }
}
