type LimitersConstructor = new (limiters: Limiter[]) => LimitersInterface;

export interface LimitersInterface {
  hasCategory(categoryId: number): boolean;
  find(categoryId: number): number;
  add(limiter: Limiter): Limiters;
  remove(categoryId: number): Limiters;
}

export class Limiters implements LimitersInterface {
  _items: Limiter[];
  length: number;

  constructor(limiters?: Limiter[]) {
    this.length = limiters ? limiters.length : 0;
    this._items = limiters ? limiters : [];
    const temp: Limiter[] = [];
    this._items.forEach(i => {
      temp.push(new Limiter(i.categoryId, i.max, i.products));
    });
    this._items = temp;
  }

  get items() {
    return this._items;
  }

  set items(limiters: Limiter[]) {
    this._items = limiters;
  }

  /**
   * Returns Limiter with categoryId
   * @param categoryId  selector
   */
  of(categoryId: number) {
    if (this.hasCategory(categoryId)) {
      return this._items.find(l => l.categoryId === categoryId).copy();
    } else {
      throw new Error('Does not contain Limiter with this category id');
    }
  }

  /**
   * Returns categoryId if it exists, otherwise -1
   * @param productId id of product to find category for
   *
   * bottom text
   */
  findCategoryOf(productId: number) {
    let categoryId = -1;
    this.items.forEach(limiter => {
      if (limiter.products.some(p => p === productId)) {
        categoryId = limiter.categoryId;
      }
    });
    return categoryId;
  }

  addProductToCategory(productId: number, categoryId: number, quant?: number) {
    if (this.hasCategory(categoryId)) {
      const newLimiter = this.of(categoryId);
      newLimiter.addProduct(productId, quant);
      this.remove(categoryId);
      this.add(newLimiter);
      return this;
    } else {
      throw new Error('Does not contain Limiter with this category id');
    }
  }

  removeProductFromCategory(productId: number, categoryId: number, quant?: number) {
    if (this.hasCategory(categoryId)) {
      const newLimiter = this.of(categoryId);
      newLimiter.removeProduct(productId, quant);
      this.remove(categoryId);
      this.add(newLimiter);
      return this;
    } else {
      throw new Error('Does not contain Limiter with this category id');
    }
  }

  hasCategory(categoryId: number) {
    return this.length > 0 ? this.items.map(el => el.categoryId).includes(categoryId) : false;
  }

  find(categoryId: number) {
    return this.items.map(el => el.categoryId).indexOf(categoryId);
  }

  add(limiter: Limiter) {
    const newLimiters = [...this.items];
    newLimiters.push(limiter);
    this.items = newLimiters;
    return new Limiters(newLimiters);
  }

  remove(categoryId: number) {
    const newLimiters = [...this.items];
    newLimiters.splice(this.find(categoryId), 1);
    this.items = newLimiters;
    return new Limiters(newLimiters);
  }
}

type LimiterConstructor = new (categoryId: number, max: number, products: number[]) => LimiterInterface;

interface LimiterInterface {
  /*
   * Returns true if the max is reached, false otherwise
   */
  isFull(): boolean;

  /*
   * Attempts to add either provided quant or 1 to quantity
   *
   * Returns true if quant is added successfully, false otherwise
   */
  canAdd(quant?: number): boolean;

  /*
   * Attempts to subtract either provided quant or 1 from quantity
   *
   * Returns true if resulting quantity is non negative, false otherwise
   *
   * When resulting quantity is negative, it is reset to 0.
   */
  canRemove(quant?: number): boolean;

  /*
   * Returns the length of the product array
   */
  length(): number;
}

export class Limiter implements LimiterInterface {
  categoryId: number;
  max: number;
  products: number[] = [];
  constructor(categoryId: number, max: number, products?: number[]) {
    this.categoryId = categoryId;
    this.max = max;
    this.products = products || [];
  }

  public copy() {
    return new Limiter(this.categoryId, this.max, this.products);
  }

  public isFull() {
    return this.length() === this.max;
  }
  public canAdd(quant?: number): boolean {
    if (quant && this.length() + quant <= this.max) {
      // this.quantity += quant;
      return true;
    } else if (quant && this.length() + quant > this.max) {
      // Unable to increment
      return false;
    } else if (this.length() + 1 <= this.max) {
      // this.quantity++;
      return true;
    } else {
      // Unable to increment, max has been exceeded
      return false;
    }
  }
  public canRemove(quant?: number): boolean {
    if (quant && this.length() - quant >= 0) {
      // this.quantity -= quant;
      return true;
    } else if (this.length() - 1 >= 0) {
      // this.quantity--;
      return true;
    } else {
      return false;
    }
  }

  /**
   *
   * @param productId id of product to add
   * @param quant (optional) number of times to add the product
   */
  public addProduct(productId: number, quant?: number) {
    quant = quant || 1;
    if (this.canAdd(quant)) {
      for (let i = 0; i < quant; i++) {
        this.products = [...this.products, productId];
      }
    } else {
      throw new Error('Cannot add specified product' + (quant > 1 ? ' in specified quantity' : ''));
    }
  }

  /**
   * Removes a product from
   * @param productId id of product to remove
   * @param quant (optional) number of times to remove the product
   */
  public removeProduct(productId: number, quant?: number) {
    quant = quant || 1;
    for (let i = 0; i < quant; i++) {
      const index = this.products.findIndex(id => id === productId);
      if (index >= 0) {
        const productsCopy = [...this.products];
        productsCopy.splice(index, 1);
        this.products = productsCopy;
      }
    }
  }

  public length() {
    if (this.products) {
      return this.products.length;
    }
    return 0;
  }
}
