<div class="modal-wrapper" style="gap: 16px">
  <i class="fas fa-times x-close" (click)="close()"></i>
  <h1 class="modal-title text-center text-uppercase fw-bold">{{ title }}</h1>

  <div class="w-100 text-center justify-content-center">
    Select which items you would like to refund.
  </div>
  <div class="w-100 d-flex flex-column justify-content-start align-items-center" style="gap: 16px">
    <div class="d-flex flex-row flex-nowrap w-100 justify-content-between bg-white p-3 br-4" style="gap: 0.5rem">
      <label class="containerChk rowCheck flex-grow-1">
        <input type="checkbox" [formControl]="refundAll!" style="margin-bottom: 10px" />
        <span class="checkmarkChk">
          <lib-svg-sprite svgID="checkmarkIcon"></lib-svg-sprite>
        </span>
        <p class="boldText text-dark">
          Refund All
        </p>
      </label>
      <span class="f-22">{{ total | currency }}</span>
    </div>
    <div class="d-flex flex-row flex-nowrap w-100 justify-content-between bg-white p-3 br-4" style="gap: 0.5rem">
      <label class="containerChk rowCheck flex-grow-1">
        <input type="checkbox" [formControl]="refundTip" style="margin-bottom: 10px" />
        <span class="checkmarkChk">
          <lib-svg-sprite svgID="checkmarkIcon"></lib-svg-sprite>
        </span>
        <p class="boldText text-dark">
          Refund Tip
        </p>
      </label>
      <span class="f-22">{{ tip | currency }}</span>
    </div>
    <ng-container *ngFor="let control of refundItemsForm.controls; index as index; last as last">
      <ng-container *ngFor="let product of products; index as jindex">
        <ng-container *ngIf="index === jindex">
          <div class="lineBorder m-0"></div>
          <div
            class="d-flex flex-row flex-nowrap w-100 justify-content-between px-3 align-items-center br-4"
            [class.disabled]="control.disabled"
            style="gap: 0.5rem"
          >
            <label class="containerChk rowCheck flex-grow-1">
              <input type="checkbox" [formControl]="control!" style="margin-bottom: 10px" />
              <span class="checkmarkChk">
                <lib-svg-sprite svgID="checkmarkIcon"></lib-svg-sprite>
              </span>
              <p class="boldText text-dark">{{ product.name }} ({{ product.quantity }})</p>
            </label>
            <span class="f-22">{{ product.totalcost | currency }}</span>
          </div>
        </ng-container>
      </ng-container>
    </ng-container>
  </div>
  <div class="p-3 bg-white">
    <div class="d-flex flex-row flex-nowrap justify-content-between">
      <small class="boldText text-dark">Selected Total:</small
      ><small class="boldText text-dark">{{ selectedTotal | currency }}</small>
    </div>
    <div class="d-flex flex-row flex-nowrap justify-content-between">
      <small class="boldText text-dark">Total:</small><small class="boldText text-dark">{{ total | currency }}</small>
    </div>
  </div>

  <div class="d-flex flex-row align-items-center w-100" style="gap: 16px; min-width: max-content">
    <div class="flex-grow-1">
      <lib-button large="true" ghost="true" text="Cancel" (click)="close()"></lib-button>
    </div>
    <div class="flex-grow-1">
      <lib-button large="true" text="Refund Selected" (click)="refund()"></lib-button>
    </div>
  </div>
</div>
