import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DirectusTokenService {
  authTokenKey = 'authToken';
  authToken = '';
  authToken$: Observable<string>;
  refreshTokenKey = 'refreshToken';
  refreshToken = '';
  refreshToken$: Observable<string>;

  private authTokenSubject = new BehaviorSubject<string>(null);
  private refreshTokenSubject = new BehaviorSubject<string>(null);

  constructor() {
    this.authToken$ = this.authTokenSubject.asObservable();
    this.authToken = this.getStoredToken();
    this.authTokenSubject.next(this.authToken);
    this.refreshToken$ = this.refreshTokenSubject.asObservable();
    this.refreshToken = this.getStoredRefreshToken();
    this.refreshTokenSubject.next(this.refreshToken);
  }

  isAuthed(): boolean {
    return this.authToken && this.authToken !== '';
  }

  updateStoredToken(newToken: string) {
    this.authToken = newToken;
    sessionStorage.setItem(this.authTokenKey, this.authToken);
  }

  getStoredToken(): string {
    return sessionStorage.getItem(this.authTokenKey);
  }

  clearStoredToken() {
    sessionStorage.removeItem(this.authTokenKey);
  }

  updateRefreshStoredToken(newToken: string) {
    this.refreshToken = newToken;
    sessionStorage.setItem(this.refreshTokenKey, this.refreshToken);
  }

  getStoredRefreshToken(): string {
    return sessionStorage.getItem(this.refreshTokenKey);
  }

  clearStoredRefreshToken() {
    sessionStorage.removeItem(this.refreshTokenKey);
  }
}
