import { NgModule } from '@angular/core';

import { KioskProductComponent } from './product.component';
import { SharedModule } from '@app/shared';
import { KioskProductRoutingModule } from './product-routing.module';
import { CustomizeItemModule } from 'src/assets/chepri-modules/src/lib/customize-item/customize-item.module';
import { CoreModule } from '@app/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [KioskProductComponent],
  imports: [SharedModule, CoreModule, CommonModule, KioskProductRoutingModule, CustomizeItemModule]
})
export class KioskProductModule {}
