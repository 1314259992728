<div class="container-fluider kiosk-body" style="padding-top: env(safe-area-inset-top, 0)">
  <div class="formDiv">
    <div class="kioskCont">
      <div class="expoFixed pt-4">
        <div class="d-flex justify-content-between">
          <div class="px-3 align-left">
            <button class="btn btn-primary text-white largeButton" [routerLink]="'/expo/fired'">Fired Orders</button>
          </div>
          <div class="px-3">
            <h3 class="text-center">Live Fire Expo</h3>
          </div>
          <div class="px-3 align-right">
            <div class="timeCont text-end pe-1 pt-1">{{ time | date: 'h:mm:ss aaaaa' }}</div>
          </div>
        </div>

        <!-- //Column Headings -->
        <div class="d-flex justify-content-between expo-heading mx-2 pt-4">
          <div class="col-1 ps-1">
            <h5 class="text-center">Order</h5>
          </div>
          <div class="col-3">
            <h5 class="text-center">Name</h5>
          </div>
          <div class="col-1">
            <h5 class="text-center">Size</h5>
          </div>
          <div class="col-2 p-0">
            <h5 class="text-center">Check-in</h5>
          </div>
          <div class="col-2 p-0">
            <h5 class="text-center">Pref</h5>
          </div>
          <div class="col-1">
            <h5 class="text-center">Fire</h5>
          </div>
          <div class="col-1">
            <h5 class="text-center">Edit</h5>
          </div>
        </div>
      </div>

      <!-- //Row Details -->
      <div class="expoScroll">
        <ng-container *ngIf="(orderFire.expoOrders || orderFire.expoOrders.length > 0) && !displayError; else errors">
          <ng-container *ngFor="let order of orderFire.expoOrders">
            <div class="d-flex justify-content-between py-2 px-2 mx-2 mt-2 align-items-center expoDetails">
              <div class="col-1 text-center mb-0 ps-0">
                <h6>{{ order.ready_time | ShortTimePipe | date: 'h:mm aaaaa' }}</h6>
              </div>
              <div
                class="col-3 text-center clickable"
                (click)="openOrderDetails(order)"
                *ngIf="editOrderItem !== order.id.toString()"
              >
                <u>
                  <h6>{{ order.customer_name }}</h6>
                </u>
              </div>
              <div class="col-3 text-center me-2" *ngIf="editOrderItem === order.id.toString()">
                <input
                  class="customer-name-input text-uppercase"
                  [(ngModel)]="editOrderName"
                  [placeholder]="order.customer_name"
                />
              </div>
              <div class="col-1 p-0 text-center">
                <h6 *ngIf="editOrderItem !== order.id.toString()">{{ order.party_size }}</h6>
                <select
                  name="partSize"
                  class="btn party-dropdown dropdown-toggle"
                  *ngIf="editOrderItem === order.id.toString()"
                  (change)="order.party_size = $event.target.value.toString()"
                >
                  <option
                    class="dropdown-item"
                    [value]="i + 1"
                    *ngFor="let num of Arr(maxTableSize).fill(1); index as i"
                    [selected]="order.party_size === (i + 1).toString()"
                    >{{ i + 1 }}</option
                  >
                </select>
              </div>
              <div class="col-2 text-center p-0">
                <button
                  *ngIf="order.order_status === 'placed' && editOrderItem !== order.id.toString()"
                  (click)="checkInGuest(order)"
                  class="btn checkinBtn"
                >
                  Check-In
                </button>
                <button
                  *ngIf="order.order_status === 'arrived' && editOrderItem !== order.id.toString()"
                  class="btn arrived-btn"
                >
                  {{ order.check_in_time | ShortTimePipe | date: 'h:mm aaaaa' }}
                </button>
                <button
                  *ngIf="editOrderItem === order.id.toString()"
                  class="btn expoGhostButton"
                  (click)="cancelOrder(order)"
                >
                  Cancel
                </button>
              </div>
              <div class="col-2 text-center p-0">
                <h6 *ngIf="editOrderItem !== order.id.toString()">{{ order.table_preference }}</h6>
                <select
                  name="partSize"
                  class="btn mx-2 text-uppercase party-dropdown-queue dropdown-toggle"
                  *ngIf="editOrderItem === order.id.toString()"
                  (change)="order.table_preference = $event.target.value.toString()"
                >
                  <option class="dropdown-item text-center" value="any" [selected]="order.table_preference === 'any'"
                    >ANY
                  </option>
                  <option class="dropdown-item" value="outside" [selected]="order.table_preference === 'outside'"
                    >OUT
                  </option>
                  <option class="dropdown-item" value="inside" [selected]="order.table_preference === 'inside'"
                    >IN
                  </option>
                </select>
              </div>
              <div class="col-1 text-center pe-0 px-1">
                <button
                  (click)="fireOrder(order)"
                  class="btn fireBtn text-center"
                  [disabled]="order.order_status === 'placed'"
                  [ngStyle]="{
                    'background-color': order.order_status === 'pre-fired' && timeInSecsIsOdd() ? '#D94D20' : '#f04e29'
                  }"
                >
                  {{ fireButtonString(order) }}
                </button>
              </div>
              <div class="col-1 ps-4 pe-0 text-center">
                <img
                  *ngIf="editOrderItem !== order.id.toString()"
                  class="editBtn"
                  src="assets/images/pencil-sharp.svg"
                  alt="Edit Order"
                  (click)="editOrder(order.id)"
                />
                <img
                  *ngIf="editOrderItem === order.id.toString()"
                  class="editBtn"
                  src="assets/images/checkmark-circle-outline.svg"
                  alt="Save Order"
                  (click)="saveOrder(order)"
                />
              </div>
            </div>
          </ng-container>
        </ng-container>
      </div>

      <ng-template #errors>
        <div class="expo-form">
          <div class="error-container">
            <div class="text-center py-3">
              <h2 color="primary pb-2">Uh-Oh</h2>
              <p class="py-3 text-uppercase">
                There are no orders currently in the queue, please check back later.
              </p>
              <button [routerLink]="'/kiosk-expo'" class="w-100 mx-auto pt-2 btn fireBtn">
                Return to Login
              </button>
            </div>
          </div>
        </div>
      </ng-template>
    </div>
  </div>
</div>
