import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DeliveryZoneFailComponent } from './delivery-zone-fail.component';

import { AuthNavModule } from '../auth-nav/auth-nav.module';
import { LogoModule } from '../logo/logo.module';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MyBagModule } from '@app/components/my-bag/my-bag.module';
import { SingleTextFieldModule } from '../single-text-field/single-text-field.module';
import { ButtonModule } from '../button/button.module';

@NgModule({
  declarations: [DeliveryZoneFailComponent],
  imports: [
    CommonModule,
    AuthNavModule,
    LogoModule,
    MyBagModule,
    SvgSpriteModule,
    BrowserModule,
    NgbModule,
    SingleTextFieldModule,
    ButtonModule
  ],
  exports: [DeliveryZoneFailComponent]
})
export class DeliveryZoneFailModule {}
