import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FavoriteOrderNameComponent } from './favorite-order-name.component';

import { AuthNavModule } from '../auth-nav/auth-nav.module';
import { LogoModule } from '../logo/logo.module';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MyBagModule } from '@app/components/my-bag/my-bag.module';
import { SingleTextFieldModule } from '../single-text-field/single-text-field.module';
import { ButtonModule } from '../button/button.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [FavoriteOrderNameComponent],
  imports: [
    CommonModule,
    AuthNavModule,
    LogoModule,
    MyBagModule,
    SvgSpriteModule,
    BrowserModule,
    NgbModule,
    SingleTextFieldModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [FavoriteOrderNameComponent]
})
export class FavoriteOrderNameModule {}
