<div class="modal-wrapper justify-content-center">
  <i class="fas fa-times x-close" (click)="close()"></i>

  <ng-container *ngIf="!isKioskFlow()">
    <h1 class="modal-title text-center text-uppercase fw-bold">{{ expoTitle }}</h1>
    <div class="d-flex flex-column text-center justify-content-center pt-4 mb-4">
      <span>Cancel Order for</span>
      <a class="bold-modal-txt text-uppercase">{{ guestName }}</a>
      <span
        >party of
        <span class="bold-modal-txt"
          >{{ partySize }} at <span class="bold-modal-txt">{{ readyTime | ShortTimePipe | date: 'h:mm aaaaa' }}</span
          >?</span
        ></span
      >
    </div>

    <div class="d-flex flex-row pt-1">
      <div class="col ps-0 me-3">
        <lib-button text="Return" large="true" ghost="true" (click)="close()"></lib-button>
      </div>
      <div class="col pe-0">
        <lib-button text="Confirm" large="true" (click)="cancelOrderClicked()"></lib-button>
      </div>
    </div>
  </ng-container>

  <ng-container *ngIf="isKioskFlow()">
    <h1 class="modal-title text-center text-uppercase fw-bold pt-3" style="font-size: 20px !important;">
      Cancel
      <span style="color: #333333;">{{ guestName }}'s</span>
      Order?
    </h1>
    <div class="d-flex flex-column text-center justify-content-center pt-4 mb-4">
      <p>This will remove the order from the live fire expo.</p>
      <p class="boldText text-danger text-uppercase">This does not refund the order!</p>
      <p>Are you sure that you want to cancel this order?</p>
    </div>

    <div class="d-flex flex-row pt-1">
      <div class="col ps-0 me-3">
        <lib-button text="No, Don't Cancel" ghost="true" large="true" (click)="close()"></lib-button>
      </div>
      <div class="col px-0">
        <lib-button text="Yes, Cancel Order" large="true" (click)="cancelOrderClicked()"></lib-button>
      </div>
    </div>
  </ng-container>
</div>
