<div class="d-flex flex-row">
  <div class="col p-0">
    <span *ngIf="!isCheckoutBag"></span>
    <span [ngClass]="{ itemName: !isCheckoutBag, toppingsText: isCheckoutBag }"
      >{{ product.name }}
      <span *ngIf="isCheckoutBag || this.router.url === '/order-details' || this.router.url === '/checkout'">{{
        '(' + product.quantity + ')'
      }}</span>
      <span *ngIf="!isCheckoutBag && hasModifiers">
        - <lib-link id="editLink" text="edit" (linkClick)="editClick()"></lib-link></span
    ></span>
  </div>
  <div class="col p-0">
    <div class="d-flex flex-row" style="float: right; margin: 0">
      <lib-incrementor
        *ngIf="!isCheckoutBag && this.router.url !== '/order-details' && this.router.url !== '/checkout'"
        [startingValue]="product.quantity"
        [displayValue]="product.quantity"
        [max]="getMax(product)"
        (valueChanged)="updateQuant($event)"
      >
      </lib-incrementor>
      <span class="itemPrice">{{ product.totalcost | currency }}</span>
    </div>
  </div>
</div>
<div class="d-flex flex-row" id="toppingsText">
  <div class="col-12 p-0">
    <p [ngClass]="{ 'toppingsText small': !isCheckoutBag, toppingsCheckout: isCheckoutBag }">
      <ng-container *ngIf="cleanChoices(product.choices) as choices">
        <span *ngFor="let choice of choices; index as i">
          {{ choice.quantity > 1 ? choice.quantity + ' x ' : '' }}{{ choice.name
          }}{{ choice.cost ? ' + ' + (choice.cost * choice.quantity | currency) : ''
          }}{{ i < choices.length - 1 ? ', ' : '' }}
        </span>
      </ng-container>
    </p>
  </div>
</div>
<div *ngIf="product.specialinstructions" class="d-flex flex-row special">
  <div class="col-12">
    <p class="text-muted" *ngIf="!isCheckoutBag">
      <strong>Special Instructions: </strong>{{ product.specialinstructions }}
    </p>
  </div>
</div>
