import { MetaData } from './olo.metadata';
import { Availability } from './olo.availability';
import { Option } from './olo.option';

export class OptionGroup {
  id: number; // Olo option group id (a.k.a. modifier id). ,
  chainmodifierid?: number; // Olo's chain wide option group id. This id is not to be used in any Ordering API call. ,
  description: string; // Description of the option group. ,
  mandatory: boolean; // Whether or not one and only one option from the option group must be selected. ,
  options: Option[];
  parentchoiceid?: string; // This is a legacy property that should be ignored. ,
  minselects: string; // Minimum number of options that must be selected from the option group. ,
  maxselects: string; // Maximum number of options that may be selected from the option group. ,
  minaggregatequantity?: string; // Minimum aggregate quantity of all selected options from the option group. ,
  maxaggregatequantity?: string; // Maximum aggregate quantity of all selected options from the option group. ,
  minchoicequantity?: string; // Minimum quantity for options within the option group. ,
  maxchoicequantity?: string; // Maximum quantity for options within the option group. ,
  supportschoicequantities?: boolean; // Whether the option group supports options with quantities greater than 1...
  choicequantityincrement?: string; // Quantity increment for options in the option group...
  availability?: Availability; // Describes the availability of the product...
  metadata?: MetaData[];
  explanationtext?: string; // Explanation text for the option group.
}
