import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment_ from 'moment';
import { Basket, BasketValidation } from 'src/assets/chepri-modules/src/models/olo.basket';
import { BillingInfo } from 'src/assets/chepri-modules/src/models/olo.billinginfo';
import { EnvironmentService } from './environment.service';
import { Product } from 'src/assets/chepri-modules/src/models/olo.product';
import { BasketProduct, BasketProductToAdd } from 'src/assets/chepri-modules/src/models/olo.basketproduct';
import { BasketStateModel } from '@app/store/state/basket.state';
import { orderstatus } from 'src/assets/chepri-modules/src/models/olo.recentorders';
import { OloHttpService } from '@app/providers/expo/olo/olo-http.service';
import {
  AddSingleProductByChainIDToBasketRequest,
  AddSingleProductByChainIDToBasketResponse,
  NewBasketProductChoiceByChainID
} from '@app/models/add-product-by-chain-id.olo';
const moment = moment_;

@Injectable({
  providedIn: 'root'
})
export class BasketService {
  domainAPI = this.environment.getDomainAPI();
  constructor(private http: OloHttpService, private environment: EnvironmentService) {}

  /*
   **********************************************************************
   *                        GET Requests
   **********************************************************************
   */

  getBasket(basketId: string) {
    return this.http.get('/baskets/' + basketId);
  }

  getBillingSchemes(basketId: string) {
    return this.http.get('/baskets/' + basketId + '/billingschemes').pipe(map((res: any) => res.billingschemes));
  }

  checkLoyaltySchemes(basketId: string, authtoken: string) {
    return this.http
      .get('/baskets/' + basketId + '/loyaltyschemes?authtoken=' + authtoken + '&checkbalance=true&checkrewards=true')
      .pipe(map((res: any) => res));
  }

  getUpsells(basketId: string) {
    return this.http.get(`/baskets/${basketId}/upsell`).pipe(map((res: any) => res));
  }

  getOrderByBasketId(basketID: string) {
    return this.http.get(`/orders/${basketID}`);
  }

  /*
   **********************************************************************
   *                        PUT Requests
   **********************************************************************
   */

  tryReward(rewardId: string, memberId: string, basketId: string) {
    const body = {
      membershipid: Number(memberId),
      references: [rewardId]
    };
    return this.http.put('/baskets/' + basketId + '/loyaltyrewards/byref', body);
    // .map(res => this.setBasket(<Basket>res.json()))
    // .catch((error: any) => Observable.throw(error.json().message));
  }

  updateProductInBasket(basketProductId: string, basketId: string, body: any): Observable<Basket> {
    return this.http.put<Basket>(`/baskets/${basketId}/products/${basketProductId}`, body);
  }

  setTimeWanted(basketId: string, body: any) {
    return this.http.put<Basket>('/baskets/' + basketId + '/timewanted', body);
  }

  setTimeWantedToAsap(basketId: string) {
    return this.http.delete<Basket>('/baskets/' + basketId + '/timewanted');
  }

  applyCoupon(couponcode: string, basketId: string) {
    const body = { couponcode };
    return this.http.put<Basket>('/baskets/' + basketId + '/coupon', body);
  }

  setDeliveryAddress(addressInfo: any, basketId: string) {
    const body = addressInfo;
    // {
    //   streetaddress: streetaddress,
    //   building: building,
    //   city: city,
    //   zipcode: zipcode,
    //   phonenumber: phonenumber
    //   specialinstructions: specialinstructions
    // };
    return this.http.put('/baskets/' + basketId + '/dispatchaddress', body);
  }

  setDeliveryMode(deliverymode: string, basketId: string) {
    const body = {
      deliverymode
    };
    return this.http.put('/baskets/' + basketId + '/deliverymode', body);
  }

  addTip(amount: number, basketId: string) {
    const body = {
      amount
    };
    return this.http.put<Basket>('/baskets/' + basketId + '/tip', body);
  }

  setBasketCustomField(basketID: string, customFieldID: number, value: string): Observable<Basket> {
    const body = {
      id: customFieldID,
      value
    };
    return this.http.put<Basket>(`/baskets/${basketID}/customfields`, body);
  }

  updateDonationOnABasket(basketID: string, donationID: number, amount: number): Observable<Basket> {
    const body = {
      id: donationID,
      amount
    };
    return this.http.put<Basket>(`/baskets/${basketID}/donations`, body);
  }

  addReferralToBasket(basketID: string, referralCode: string, referralSource: string): Observable<Basket> {
    const body = {
      referrals: [
        {
          token: referralCode,
          source: referralSource
        }
      ]
    };
    return this.http.put(`/baskets/${basketID}/referrals`, body);
  }

  /*
   **********************************************************************
   *                        POST Requests
   **********************************************************************
   */

  createBasket(restaurantID: string, auth?: string): Observable<Basket> {
    if (!restaurantID) {
      throwError('no restaurant id');
    }
    const body = { vendorid: restaurantID, authtoken: auth };

    return this.http.post<Basket>('/baskets/create', body);
  }

  addProductToBasket(product: BasketProductToAdd, basketId: string, catId: number): Observable<Basket> {
    const body = { ...product };
    if (catId) {
      const catIdString = catId.toString();
      body.customdata = catIdString;
    }
    return this.http.post<Basket>('/baskets/' + basketId + '/products', body);
  }

  checkGiftBalance(schemeId: string, basketId: string, body: any) {
    return this.http.post(`/baskets/${basketId}/billingschemes/${schemeId}/retrievebalance`, body);
  }

  transferBasket(vendorId: number, basketId: string) {
    const body = { vendorid: vendorId };
    return this.http.post('/baskets/' + basketId + '/transfer', body);
  }

  createBasketFromOrder(orderref: string, id: string, token: string) {
    const body = {
      orderref,
      id,
      ignoreunavailableproducts: false
    };

    return this.http.post<Basket>('/baskets/createfromorder?authtoken=' + token, body);
  }

  reorderFavoriteOrder(id: string, token: string) {
    const body = {
      faveid: id,
      ignoreunavailableproducts: false
    };

    return this.http.post<Basket>('/baskets/createfromfave?authtoken=' + token, body);
  }

  /*
   * TODO: Implement multiple payment types
   */
  submitOrder(basketId: string, billingInfo: BillingInfo, authtoken?: string): Observable<any> {
    const body = { authtoken, ...billingInfo };
    return this.http.post<orderstatus>('/baskets/' + basketId + '/submit', body);
  }

  submitOrderMultiPay(basketId: string, billinginfo: any, authtoken?: string): Observable<any> {
    const body = { authtoken, ...billinginfo };
    // console.log('body', body);
    return this.http.post<orderstatus>('/baskets/' + basketId + '/submit/multiplepayments', body);
  }

  validateBasket(basketId: string, checkUpsell?: string): Observable<BasketValidation> {
    const upsellFlag = checkUpsell ? checkUpsell : '';
    const body = {};
    return this.http.post<BasketValidation>('/baskets/' + basketId + '/validate?checkupsell=' + upsellFlag, body);
  }

  addUpsellToBasket(basketId: string, items: any[]): Observable<any> {
    const body = { items: items };
    return this.http.post(`/baskets/${basketId}/upsell`, body);
  }

  addSingleProductByChainIDToBasket(
    basketID: string,
    chainProductID: number | string,
    quantity: number,
    choices: NewBasketProductChoiceByChainID[] = [],
    specialInstructions?: string,
    recipient?: string,
    customData?: string
  ): Observable<AddSingleProductByChainIDToBasketResponse> {
    const resource = `/baskets/${basketID}/products/bychainid`;
    const body: AddSingleProductByChainIDToBasketRequest = {
      chainproductid: chainProductID,
      quantity,
      choices
    };
    if (specialInstructions) {
      body.specialinstructions = specialInstructions;
    }
    if (recipient) {
      body.recipient = recipient;
    }
    if (customData) {
      body.customdata = customData;
    }
    return this.http.post<AddSingleProductByChainIDToBasketResponse>(resource, body);
  }

  /*
   **********************************************************************
   *                        DELETE Requests
   **********************************************************************
   */

  removeProductFromBasket(basketProductId: string, basketId: string): Observable<Basket> {
    return this.http.delete<Basket>(`/baskets/${basketId}/products/${basketProductId}`);
  }

  removeReward(rewardId: string, basketId: string) {
    return this.http.delete('/baskets/' + basketId + '/loyaltyrewards/' + rewardId);
  }

  removeCoupon(basketId: string): Observable<Basket> {
    return this.http.delete('/baskets/' + basketId + '/coupon');
  }

  removeBasketCustomFieldValue(basketID: string, customFieldID: number): Observable<Basket> {
    return this.http.delete<Basket>(`/baskets/${basketID}/customfields/${customFieldID}`);
  }
}
