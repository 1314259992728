import { State, Action, StateContext } from '@ngxs/store';
import { ToggleBag, ToggleHeader, SetBanner, ToggleAccessibility } from '../actions/app.action';
import { Injectable } from '@angular/core';

export interface AppStateModel {
  bag: string;
  header: boolean;
  banner: Banner;
  accessibilityMode: boolean;
}

export interface Banner {
  text: string;
  showOrderInfo: boolean;
  canGoBack: boolean;
  locationName: string;
}

@State<AppStateModel>({
  name: 'app',
  defaults: {
    bag: 'closed',
    header: false,
    banner: {
      text: 'FIND YOUR LOCATION',
      showOrderInfo: false,
      canGoBack: null,
      locationName: ''
    },
    accessibilityMode: false
  }
})
@Injectable({
  providedIn: 'root'
})
export class AppState {
  constructor() {}

  @Action(ToggleBag)
  toggleBag({ patchState, getState }: StateContext<AppStateModel>, payload: any) {
    let currentBag = getState().bag;
    if (window.innerWidth < 992 || !currentBag || currentBag == 'open') {
      if (!payload) {
        if (!currentBag) {
          currentBag = 'closed';
        } else {
          currentBag = currentBag === 'closed' ? 'open' : 'closed';
        }
      } else {
        currentBag = payload;
      }

      patchState({ bag: currentBag });
    }
  }

  @Action(ToggleHeader)
  toggleHeader({ patchState }: StateContext<AppStateModel>, payload: any) {
    patchState({ header: payload.payload });
  }

  @Action(SetBanner)
  setBanner({ patchState }: StateContext<AppStateModel>, { text, showOrderInfo, canGoBack, locationName }: SetBanner) {
    patchState({
      banner: {
        text,
        showOrderInfo,
        canGoBack,
        locationName
      }
    });
  }

  @Action(ToggleAccessibility)
  toggleAccessibility(ctx: StateContext<AppStateModel>, action: ToggleAccessibility) {
    const appState = ctx.getState();
    ctx.patchState({
      accessibilityMode: !appState.accessibilityMode
    });
  }
}
