import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FavoritesOrderModel } from './favorites-order.model';
import { UntypedFormBuilder, Validators, UntypedFormGroup, FormControl } from '@angular/forms';
import { Store } from '@ngxs/store';
import { BasketState } from '../../../../../app/store/state/basket.state';
import * as moment from 'moment';

@Component({
  selector: 'lib-favorite-order-name',
  templateUrl: './favorite-order-name.component.html',
  styles: []
})
export class FavoriteOrderNameComponent implements OnInit {
  @Output() continue = new EventEmitter<any>();
  @Output() xClicked = new EventEmitter<any>();
  @Output() deliveryOutOfRange = new EventEmitter<any>();
  @Output() saveOrderFavorite = new EventEmitter<any>();

  favorites: FavoritesOrderModel;
  orderNameForm: UntypedFormGroup;

  constructor(protected store: Store, private fb: UntypedFormBuilder) {
    const basket = this.store.selectSnapshot(BasketState).basket;
    this.favorites = new FavoritesOrderModel(basket.id);
  }

  ngOnInit() {
    const defaultName = 'Family dinner';
    this.orderNameForm = this.fb.group({
      orderName: [defaultName, [Validators.minLength(1)]]
    });
  }

  xClick() {
    this.xClicked.emit();
  }

  saveOrderAsFavorite() {
    this.saveOrderFavorite.emit(this.orderNameForm.value.orderName);
  }
}
