import { NgModule } from '@angular/core';
import { LinkComponent } from './link.component';
import { CommonModule } from '@angular/common';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [LinkComponent],
  imports: [CommonModule, NgbDropdownModule],
  exports: [LinkComponent]
})
export class LinkModule {}
