import { Injectable } from '@angular/core';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { User } from '@lib/models/olo.user';
import { filter } from 'rxjs/operators';
import { Restaurant } from '@lib/models/olo.restaurant';
import { Category } from '@lib/models/olo.category';
import { Product } from '@lib/models/olo.product';
import { BasketProduct } from '@lib/models/olo.basketproduct';
import { Order } from '@lib/models/olo.order';
import { Basket } from '@lib/models/olo.basket';
import { GTMImpression, GTMItem, GTMProduct } from '@app/models/gtm.model';
import { Capacitor } from '@capacitor/core';
import { GoogleTagManagerService } from 'angular-google-tag-manager';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {
  private previousURL = '';
  private currentURL = '';
  private timers: number[] = [];
  private useUA = false; // Switch this to use GA4 events instead

  constructor(private router: Router, private gtmService: GoogleTagManagerService) {
    this.router.events
      .pipe(filter(e => e instanceof NavigationEnd || e instanceof NavigationStart))
      .subscribe(event => {
        if (event instanceof NavigationStart) {
          this.timers.push(Date.now());
        } else {
          // @ts-ignore
          const start: number = this.timers.pop();
          const delta = (Date.now() - start) / 1000;
          this.previousURL = this.currentURL;
          // @ts-ignore
          this.currentURL = event.urlAfterRedirects;
          this.logNavigationTime(this.currentURL, this.previousURL, delta);
        }
      });
  }

  logNavigationTime(currentURL: string, previousURL: string, delta: number): void {
    this.pushTag({
      event: this.useUA ? 'pageNavigation' : 'page_navigation',
      previousURL,
      currentURL,
      time: delta
    });
  }

  logProductListView(category: Category): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.pushTag({
        event: 'viewItemList',
        ecommerce: {
          currencyCode: 'USD',
          impressions: category.products.map((cat, i) => this.oloMenuProductToGTMProduct(cat, i))
        }
      });
    } else {
      this.pushTag({
        event: 'view_item_list',
        ecommerce: {
          items: category.products.map(product => this.oloMenuProductToGTMItem(product))
        }
      });
    }

    // this.hotjar.hj('event', 'categoryView');
  }

  logProductClick(product: Product): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.pushTag({
        event: 'productClick',
        ecommerce: {
          click: { list: 'Menu' },
          products: [this.oloMenuProductToGTMProduct(product, 0)]
        }
      });
    } else {
      this.pushTag({
        event: 'select_item',
        ecommerce: {
          items: [this.oloMenuProductToGTMItem(product)]
        }
      });
    }

    // this.hotjar.hj('event', 'productClick');
  }

  logProductDetailView(product: Product): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.pushTag({
        event: 'viewItem',
        ecommerce: {
          detail: {
            actionField: {},
            products: [this.oloMenuProductToGTMProduct(product, 0)]
          }
        }
      });
    } else {
      this.pushTag({
        event: 'view_item',
        ecommerce: {
          items: [this.oloMenuProductToGTMItem(product)]
        }
      });
    }

    // this.hotjar.hj('event', 'productView');
  }

  logFavoriteOrderClick(): void {
    this.pushTag({
      event: this.useUA ? 'favoriteOrderClick' : 'favorite_order_click'
    });
  }

  logAddToCart(products: BasketProduct[]): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.pushTag({
        event: 'addToCart',
        ecommerce: {
          currencyCode: 'USD',
          add: {
            products: products.map(product => this.oloBasketProductToGTMProduct(product))
          }
        }
      });
    } else {
      this.pushTag({
        event: 'add_to_cart',
        ecommerce: {
          items: products.map(product => this.oloBasketProductToGTMItem(product))
        }
      });
    }

    // this.hotjar.hj('event', 'addToCart');
  }

  logRemoveFromCart(product: BasketProduct): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.pushTag({
        event: 'removeFromCart',
        ecommerce: {
          remove: {
            products: [this.oloBasketProductToGTMProduct(product)]
          }
        }
      });
    } else {
      this.pushTag({
        event: 'remove_from_cart',
        ecommerce: {
          items: [this.oloRemoveBasketProductToGTMItem(product)]
        }
      });
    }

    // this.hotjar.hj('event', 'removeFromCart');
  }

  logCheckoutDetail(products: BasketProduct[]): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.pushTag({
        event: 'checkoutDetails',
        ecommerce: {
          checkout: {
            actionField: { step: 1, option: 'Checkout Details' },
            products: products.map(prod => this.oloBasketProductToGTMProduct(prod))
          }
        }
      });
    } else {
      this.pushTag({
        event: 'begin_checkout',
        ecommerce: {
          items: products.map(prod => this.oloRemoveBasketProductToGTMItem(prod))
        }
      });
    }
    // this.hotjar.hj('event', 'checkoutStart');
  }

  logCheckoutReview(products: BasketProduct[], basket: Basket): void {
    this.resetEcommerce();
    if (this.useUA) {
      this.pushTag({
        event: 'checkoutReview',
        ecommerce: {
          checkout: {
            actionField: { step: 2, option: 'Checkout Review' },
            products: products.map(prod => this.oloBasketProductToGTMProduct(prod))
          }
        }
      });
    } else {
      this.pushTag({
        event: 'add_payment_info',
        ecommerce: {
          items: products.map(prod => this.oloRemoveBasketProductToGTMItem(prod)),
          currency: 'USD',
          value: basket.subtotal
        }
      });
    }
  }

  logPurchase(basket: Basket, order: Order): void {
    // const properties: SegmentOrderCompleted = {
    //   checkout_id: basket.id,
    //   currency: 'USD',
    //   order_id: order.oloid,
    //   order_revenue: basket.subtotal,
    //   order_total: basket.total,
    //   products: basket.products.map((prod) =>
    //     this.oloBasketProductToSegmentProduct(
    //       prod,
    //       menu.find((cat) =>
    //         cat.products.find((product) => prod.productId === product.id),
    //       )!,
    //     ),
    //   ),
    //   promo_codes:
    //     basket.appliedrewards && basket.appliedrewards[0]
    //       ? basket.appliedrewards[0].label
    //       : undefined,
    //   venue_id: order.vendorextref,
    //   venue_name: order.vendorname,
    // };
    // this.segment.track(SegmentEvents.ORDER_COMPLETED, properties);
    this.resetEcommerce();
    if (this.useUA) {
      this.pushTag({
        event: 'purchase',
        ecommerce: {
          purchase: {
            actionField: {
              id: order.oloid,
              affiliation: Capacitor.getPlatform(),
              revenue: basket.subtotal,
              tax: basket.salestax,
              shipping: basket.customerhandoffcharge,
              coupon: basket.coupon ? basket.coupon.couponcode : ''
            },
            products: basket.products.map(prod => this.oloBasketProductToGTMProduct(prod))
          }
        }
      });
    } else {
      this.pushTag({
        event: 'purchase',
        ecommerce: {
          transaction_id: order.oloid,
          affiliation: Capacitor.getPlatform(),
          value: basket.subtotal,
          tax: basket.salestax,
          shipping: basket.customerhandoffcharge,
          coupon: basket.coupon ? basket.coupon.couponcode.toUpperCase() : '',
          currency: 'USD',
          items: basket.products.map(prod => this.oloRemoveBasketProductToGTMItem(prod))
        }
      });
    }
    // this.hotjar.hj('event', 'purchase');
  }

  logSiteAbandoned(): void {
    this.pushTag({
      event: this.useUA ? 'siteAbandoned' : 'site_abandoned',
      currentURL: this.currentURL
    });
  }

  private resetEcommerce(): void {
    this.pushTag({ ecommerce: null });
  }

  // GOOGLE ANALYTICS 4

  private oloCategoryToGTMItem(category: Category, index: number): GTMItem {
    return {
      item_name: category?.name,
      item_id: String(category?.id),
      index: index,
      quantity: 1
    } as GTMItem;
  }

  private oloMenuProductToGTMItem(product: Product): GTMItem {
    return {
      item_name: product.name,
      item_id: String(product.id),
      item_variant: product.name,
      quantity: 1
    } as GTMItem;
  }

  private oloBasketProductToGTMItem(product: BasketProduct): GTMItem {
    return {
      item_name: product.name,
      item_id: String(product.productId),
      item_variant: product.name,
      price: product.totalcost,
      quantity: product.quantity
    } as GTMItem;
  }

  private oloRemoveBasketProductToGTMItem(product: BasketProduct): GTMItem {
    return {
      item_name: product.name,
      item_id: String(product.productId),
      item_variant: product.choices?.length ? product.choices[0].name : '',
      price: product.totalcost,
      quantity: product.quantity,
      currency: 'USD',
      item_modifiers: product.choices ? product.choices.map(opt => opt.name) : []
    } as GTMItem;
  }

  // UNIVERSAL ANALYTICS

  private oloCategoryToGTMImpression(category: Category, index: number): GTMImpression {
    return {
      id: String(category.id),
      name: category.name
    } as GTMImpression;
  }

  private oloMenuProductToGTMProduct(product: Product, index: number): GTMProduct {
    return {
      id: String(product.chainproductid),
      name: product.name,
      position: index
    } as GTMProduct;
  }

  private oloMenuProductToGTMProductWithPrice(
    product: Product,
    category: Category,
    price: number,
    useCategory: boolean
  ): GTMProduct {
    return {
      id: String(product.id),
      name: `${category.name} - ${product.name}`,
      variant: product.name,
      price,
      position: category.products.indexOf(product)
    } as GTMProduct;
  }

  private oloBasketProductToGTMProduct(product: BasketProduct): GTMProduct {
    return {
      id: String(product.productId),
      name: product.name,
      variant: product.name,
      price: product.totalcost,
      quantity: product.quantity
    } as GTMProduct;
  }

  private oloRemoveBasketProductToGTMProduct(product: BasketProduct): GTMProduct {
    return {
      id: String(product.productId),
      name: product.name,
      variant: product.choices[0].name ? product.choices[0].name : '',
      price: product.totalcost,
      quantity: product.quantity
    } as GTMProduct;
  }

  private useCategory(category: Category): boolean {
    return category.name.toUpperCase() !== 'Sides, Drinks and Desserts'.toUpperCase();
  }

  private pushTag(tag: object) {
    this.gtmService.pushTag(tag);
  }
}
