import { NgModule } from '@angular/core';
import { LogoComponent } from './logo.component';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';

@NgModule({
  declarations: [LogoComponent],
  imports: [SvgSpriteModule],
  exports: [LogoComponent]
})
export class LogoModule {}
