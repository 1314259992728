/*
 * Based on OLO's Restaurant Model; more information can be found in OLO's API documentation.
 *
 * Excluded fields: deliveryfeetiers, customfields, labels, metadata
 */
export class Restaurant {
  id: number; // Olo restaurant id. Also referred to as "vendor id". Unique across brands...
  name: string; // Olo's configured name for the restaurant, generally set with guidance from the brand.
  brand: string; // Olo's internal name of the brand associated with the restaurant...
  storename: string; // Standard name of the brand's restaurants...
  telephone: string; // The restaurant's phone number. Will be formatted like (xxx) xxx-xxxx.
  streetaddress: string; // The restaurant's street address.
  crossstreet: string; // Closest cross street to the restaurant.
  city: string; // The restaurant's city.
  state: string; // The restaurant's state.
  zip: string; // The restaurant's zip code.
  country: string; // The restaurant's country (eg. US, CA).
  latitude: number; // The restaurant's latitude.
  longitude: number; // The restaurant's longitude.
  utcoffset: number; // UTC offset of the timezone the restaurant is in. This factors in Daylight Savings time.
  url: string; // If the API credentials are set to show URLs (enabled by default), this will return the URL...
  mobileurl: string; // If the API credentials are set to show URLs (enabled by default), this will return the URL...
  distance: number; // Distance in miles from the set of coordinates provided...
  extref: string; // The restaurant's external reference (a.k.a. identifier)...
  advanceonly: boolean; // Whether or not the restaurant only accepts Advance (a.k.a. later) orders.
  advanceorderdays: number; // For Advance (a.k.a. later) orders...
  supportscoupons: boolean; // Whether or not the restaurant supports Olo coupon codes.
  supportsloyalty: boolean; // Whether or not the restaurant accepts any loyalty schemes.
  supportedcardtypes: string; // List of card types (full names) the restaurant supports, delimited by '/'.
  supportsmanualfire: boolean; // Whether or not the restaurant supports manual fire orders...
  candeliver: boolean; // Whether or not the restaurant supports the handoff mode "Delivery"...
  canpickup: boolean; // Whether or not the restaurant supports the handoff mode "Counter Pickup" (a.k.a. Pickup).
  supportscurbside: boolean; // Whether or not the restaurant supports the handoff mode "Curbside Pickup".
  supportsdispatch: boolean; // Whether or not the restaurant supports the handoff mode "Dispatch"...
  hasolopass: boolean; // This is a legacy property that should be ignored.
  deliveryarea: string; // Description of the area the restaurant delivers to...
  minimumdeliveryorder: number; // Minimum total amount for an order to be eligible for delivery...
  minimumpickuporder: number; // Minimum total amount for an order to be eligible for pickup.
  maximumpayinstoreorder: number; // Maximum total amount for any Pay in Store (a.k.a. cash) order...
  deliveryfee: number; // Fee for delivery. This will return as 0 if the restaurant has multiple fee tiers...
  supportstip: boolean; // Whether or not the restaurant allows tipping...
  supportsspecialinstructions: boolean; // Whether or not the restaurant allows special instructions...
  specialinstructionsmaxlength: number; // Max character length of special instructions...
  supportsguestordering: boolean; // Whether or not the restaurant allows guests to place an order...
  requiresphonenumber: boolean; // Whether or not the restaurant requires a phone number to place an order...
  supportsonlineordering: boolean; // Whether or not the restaurant supports online ordering...
  supportsnationalmenu: boolean; // Whether or not a brand wants to surface their menu when ordering is disabled.
  supportsfeedback: boolean; // Whether or not the restaurant allows user feedback. ,
  supportssplitpayments: boolean; // Whether or not the restaurant allows split payments for orders...
  slug: string; // Olo's configured name for the restaurant represented by a URL friendly slug...
  isavailable: boolean; // Whether or not the restaurant is available to take orders...
  supportsgrouporders: boolean; // Whether or not the restaurant supports group orders...
  supportsproductrecipientnames: boolean; // Whether the restaurant allows setting a name for each product...
  supportsbaskettransfers: boolean; // Whether the restaurant supports basket transfers to another restaurant...
  allowhandoffchoiceatmanualfire: boolean; // For manual fire - see OLO documentation
  productrecipientnamelabel: string; // used to describe the recipient name attached to a product...
  customerfacingmessage: string; // message that the restaurant wishes to display...
  availabilitymessage: string; // message that indicates if the restaurant is temporarily closed...
  supportsdrivethru: boolean; // Whether or not the restaurant supports the handoff mode "Drive-Thru".
  acceptsordersuntilclosing: boolean; // Whether or not the restaurant accepts orders until closing.
  acceptsordersbeforeopening: boolean; // Whether or not the restaurant accepts orders before opening.
  supportedtimemodes: string[]; // List of time modes supported by the restaurant...
  attributes: string[]; // List of location attributes for the restaurant...
  supportedcountries: string[]; // List of countries supported by the restaurant
  iscurrentlyopen: boolean;
}
