import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { EnvironmentService } from './environment.service';

@Injectable({
  providedIn: 'root'
})
export class RestaurantService {
  domainAPI = this.environment.getDomainAPI();
  constructor(private http: HttpClient, private environment: EnvironmentService) {}

  getRestaurants(includePrivate?: boolean): Observable<any> {
    let stringPrivate = '';
    if (includePrivate) {
      stringPrivate = '?includePrivate=true';
    }
    const result = this.http.get(this.domainAPI + '/api/restaurants' + stringPrivate, this.environment.getOLOHeaders());
    return result;
  }

  getMenu(restaurantID: string) {
    if (!restaurantID) {
      console.log('redirecting');
      return;
      // this.router.navigate(['/locations']);
      // return Observable.throw('restaurant id is NaN');
    }
    return this.http.get(
      this.domainAPI + '/api/restaurants/' + restaurantID + '/menu',
      this.environment.getOLOHeaders()
    );
  }
}
