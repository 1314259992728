import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Logger } from '@app/core';
import { Store } from '@ngxs/store';

const log = new Logger('MenuGuard');

@Injectable({
  providedIn: 'root'
})
export class MenuGuard  {
  constructor(private router: Router, private store: Store) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): boolean {
    if (route.params.id) {
      return true;
    } else {
      // Attempt to find id, if none exists - navigate to locations page
      const basketState = this.store.selectSnapshot(state => state.basket);
      if (basketState && basketState.basket && basketState.basket.vendorid) {
        this.router.navigate(['/menu/' + basketState.basket.vendorid]);
      } else {
        log.debug('No location specified or selected, navigating to locations page...');
        this.router.navigate(['/locations']);
      }
      return false;
    }
  }
}
