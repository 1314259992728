import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { KioskPastOrdersComponent } from './past-orders.component';
import { KioskPastOrdersRoutingModule } from './past-orders-routing.module';
import { ButtonModule } from 'src/assets/chepri-modules/src/public-api';
import { SvgSpriteModule } from 'src/assets/chepri-modules/src/lib/svg-sprite/svg-sprite.module';
import { PastOrderModalModule } from 'src/assets/chepri-modules/src/lib/past-order-modal/past-order-modal.module';
import { SharedModule } from '@app/shared';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

@NgModule({
  declarations: [KioskPastOrdersComponent],
  imports: [
    CommonModule,
    KioskPastOrdersRoutingModule,
    ButtonModule,
    SharedModule,
    SvgSpriteModule,
    PastOrderModalModule,
    NgbModule
  ]
})
export class KioskPastOrdersModule {}
