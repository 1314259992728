import { NgModule } from '@angular/core';
import { SingleTextFieldComponent } from './single-text-field.component';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';

@NgModule({
  declarations: [SingleTextFieldComponent],
  imports: [CommonModule, ReactiveFormsModule, NgxMaskDirective, NgxMaskPipe],
  exports: [SingleTextFieldComponent],
  providers: [provideNgxMask()]
})
export class SingleTextFieldModule {}
