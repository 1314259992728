import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-fire-now-modal',
  templateUrl: './fire-now-modal.component.html',
  styleUrls: ['./fire-now-modal.component.scss']
})
export class FireNowModalComponent implements OnInit {
  @Input() guestName: string;
  @Input() readyTime: string;
  @Output() xClicked = new EventEmitter<any>();
  @Output() fireClicked = new EventEmitter<any>();

  tableNumber: string;
  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {}

  close(): void {
    this.xClicked.emit();
  }

  fireOrder() {
    this.fireClicked.emit(this.tableNumber);
  }

  currentTime() {
    return new Date();
  }
}
