import { NgModule } from '@angular/core';
import { CustomizeItemBagComponent } from './customize-item-bag.component';
import { CommonModule } from '@angular/common';
import { IncrementorModule } from '../incrementor/incrementor.module';
import { PillModule } from '../pill/pill.module';
import { ServicesModule } from '../../providers/services.module';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';
import { ButtonModule } from '../button/button.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

@NgModule({
  declarations: [CustomizeItemBagComponent],
  imports: [
    CommonModule,
    IncrementorModule,
    PillModule,
    ServicesModule,
    SvgSpriteModule,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule
  ],
  exports: [CustomizeItemBagComponent]
})
export class CustomizeItemBagModule {}
