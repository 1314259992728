import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { ResetPasswordComponent } from './reset-password.component';
import { Shell } from '@app/components/shell/shell.service';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/reset-password', pathMatch: 'full' },
    { path: 'reset-password', component: ResetPasswordComponent, data: { title: extract('ResetPassword') } }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ResetPasswordRoutingModule {}
