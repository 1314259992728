import { NgModule } from '@angular/core';
import { SubNavComponent } from './sub-nav.component';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [SubNavComponent],
  imports: [CommonModule],
  exports: [SubNavComponent]
})
export class SubNavModule {}
