import { Component, OnInit, Input } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { Select } from '@ngxs/store';
import { AppStateModel } from '@app/store/state/app.state';
import { Observable } from 'rxjs';
import { UntypedFormGroup, UntypedFormBuilder, Validators } from '@angular/forms';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.scss']
})
export class ResetPasswordComponent {
  @Select() app$: Observable<AppStateModel>;
  resetPasswordForm: UntypedFormGroup;
  closeResult: string;

  constructor(private router: Router, private fb: UntypedFormBuilder, private modalService: NgbModal) {}
  /*
  leavePage(route: string) {
    if (route === 'sign-up') {
      this.router.navigateByUrl('/sign-up');
    } else if (route === 'guest-auth') {
      this.router.navigateByUrl('/guest-info');
    } else if (route === 'forgot-password') {
      // Forgot password logic here
    }
  }
*/

  resetPasswordModal(resetPass: any) {
    this.modalService.open(resetPass, { ariaLabelledBy: 'resetPassword', centered: true }).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getOrderNameDismissReason(reason)}`;
      }
    );
  }

  private getOrderNameDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      // this.addProductToCart();
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      // this.addProductToCart();
      return 'by clicking on a backdrop';
    } else {
      // this.addProductToCart();
      return `with: ${reason}`;
    }
  }
  /*
  submitFormData(form: FormGroup) {
    // Form group data
  }
  */
}
