import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-soldout-modal',
  templateUrl: './soldout-modal.component.html',
  styleUrls: ['./soldout-modal.component.scss']
})
export class SoldoutModalComponent {
  @Output() continue = new EventEmitter<any>();
  @Output() xClicked = new EventEmitter<any>();
  @Output() continueClicked = new EventEmitter<any>();
  @Output() deliveryOutOfRange = new EventEmitter<any>();
  constructor() {}

  xClick() {
    this.xClicked.emit();
  }

  continueLinkClicked() {
    this.continueClicked.emit();
  }
}
