<div class="container bag">
  <div class="d-flex flex-row insideBag" *ngIf="basket.products.length > 0; else emptyBag">
    <div class="col-12 p-0">
      <div class="title">YOUR ORDER</div>

      <lib-order-info class="mobilecenteralign" [orderInfoBag]="true" [location]="location" [basket]="basket">
      </lib-order-info>

      <div class="d-flex flex-row separator"></div>
      <div class="d-flex flex-row">
        <div class="col-12 bag-item-container p-0">
          <div *ngFor="let product of basket.products; index as i" class="d-flex flex-row orderItems">
            <lib-bag-item
              [product]="product"
              (editClicked)="onClose()"
              (updateProduct)="updateProduct($event)"
              style="width: 100%"
            >
            </lib-bag-item>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ng-template #emptyBag>
    <div class="d-flex flex-row py-5 mx-5">
      <div class="col-12 emptyBag">
        <p class="emptyBagText">You do not have any items in your basket, start an order?</p>
      </div>
    </div>
  </ng-template>

  <div class="bottomBag">
    <div class="middleInfo" style="padding-top: 5px">
      <!--      <div *ngIf="basket$ | async as basket" class="col-12">-->
      <!--        <div *ngIf="basket.basket.deliverymode === 'dispatch' || basket.basket.deliverymode === 'delivery'" class="d-flex flex-row">-->
      <!--          <div class="col deliveryFeeText">-->
      <!--            Delivery Fee-->
      <!--          </div>-->
      <!--          <div class="col">-->
      <!--            <span class="deliveryFeeText" style="float: right">-->
      <!--              {{-->
      <!--                (basket.basketValidation-->
      <!--                  ? basket.basketValidation.customerhandoffcharge-->
      <!--                  : basket.basket.customerhandoffcharge-->
      <!--                ) | currency-->
      <!--              }}-->
      <!--            </span>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--      </div>-->

      <div *ngIf="basket.products.length > 0" class="col-12">
        <div class="d-flex flex-row bag-subtotal">
          <div class="col subtotalText">
            Subtotal
          </div>
          <div class="col">
            <span class="subtotalText" style="float: right">{{ basket.subtotal | currency }}</span>
          </div>
        </div>
        <!--
        <div class="d-flex flex-row subtotal">
          <div class="col subtotalText">
            Tax
          </div>
          <div class="col">
            <span class="subtotalText" style="float: right">{{ basket.salestax | currency }}</span>
          </div>
        </div>
        <div class="d-flex flex-row total-separator"></div>
        <div class="d-flex flex-row total">
          <div class="col totalText">
            Total
          </div>
          <div class="col">
            <span class="totalText" style="float: right">{{ basket.total | currency }}</span>
          </div>
        </div>
      -->

        <div class="d-flex flex-row">
          <div class="col-12 justify-content-center" style="display: flex">
            <lib-button
              id="checkoutBtn"
              large="true"
              text="Checkout"
              (buttonClick)="onCheckout()"
              class="w-100"
            ></lib-button>
          </div>
        </div>
        <div class="d-flex flex-row add-more">
          <div class="col-12 justify-content-center" style="display: flex">
            <a (click)="addMore()">ADD MORE</a>
          </div>
        </div>
        <!--
        <div class="d-flex flex-row bottomTextLink">
          <div class="col-12 justify-content-center" style="display: flex">
            <lib-link text="ADD MORE" style="color: white" (linkClick)="onClose()"></lib-link>
          </div>
        </div>
      --></div>
      <div *ngIf="basket.products.length === 0" class="col-12">
        <div class="d-flex flex-row py-5 mobilePadding">
          <div class="col-12 justify-content-center" style="display: flex">
            <lib-button class="w-100" large="true" text="Start an Order" (buttonClick)="addMore()"></lib-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
