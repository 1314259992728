import { Component, Input, ViewChild, ElementRef, NgZone, Output, EventEmitter, AfterViewInit } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Store } from '@ngxs/store';
import { SetLocation, SetAddress } from '@app/store/actions/restaurant.action';
import { Address } from '../../models/Address';
import { FormatService } from '../../providers/format.service';
import MapboxGeocoder, { Result } from '@mapbox/mapbox-gl-geocoder';
import { Capacitor } from '@capacitor/core';
import { environment } from '../../../../../environments/environment';

@Component({
  selector: 'lib-search-bar',
  templateUrl: './search-bar.component.html',
  styles: []
})
export class SearchBarComponent implements AfterViewInit {
  @Input() inputWidth: string;
  @Input() buttonColor: string;
  @Input() deliveryZone: boolean;
  @Input() geoLocLoading: any;
  @Input() text: string = 'Enter Address';
  @Output() locChange = new EventEmitter<any>();
  @Output() setCurrentLoc = new EventEmitter<any>();
  @ViewChild('search', { static: true }) searchBarRef: ElementRef;
  latitude: any;
  longitude: any;
  geoCoder: any;
  formattedAddress: any;
  autoSessionToken: any;

  mbGeocoder = new MapboxGeocoder({
    accessToken: Capacitor.getPlatform() !== 'ios' ? environment.mapboxKey : environment.mapboxIOSKey
  });

  constructor(
    private store: Store,
    private toast: ToastrService,
    private ngZone: NgZone,
    private format: FormatService
  ) {
    this.inputWidth = '300px';
  }

  ngOnInit() {}

  ngAfterViewInit() {
    if (!this.deliveryZone) {
      this.mbGeocoder.addTo('#mapboxlocationinput');
      this.mbGeocoder.setTypes('address, place, postcode');
    } else {
      this.mbGeocoder.addTo('#mapboxdeliveryinput');
      this.mbGeocoder.setTypes('address');
    }
    this.mbGeocoder.setPlaceholder('Enter Address');
    this.mbGeocoder.setCountries('us');
    this.mbGeocoder.on('result', res => {
      let result: Result = res.result;
      this.locChange.emit(result);
    });
  }

  setCurrentLocation() {
    this.mbGeocoder.setInput('');
    this.setCurrentLoc.emit();
  }

  warning(msg: string) {
    this.toast.warning(msg, 'Warning!', {
      timeOut: 2000
    });
  }
}
