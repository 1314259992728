<div class="popupShell">
  <a class="close" aria-label="Close" (click)="xClick()" style="text-align: right;"
    ><div class="popup-x-modal"><lib-svg-sprite svgID="popup-x"></lib-svg-sprite></div
  ></a>
  <div class="popupHeader">
    Out Of Delivery Zone
  </div>
  <div class="deliveryDiv">
    <div>
      <p class="deliveryZoneFailText">
        Your selected address is outside of our delivery zone. Would you like to pickup from this address?
      </p>
    </div>
    <div class="deliveryZoneFailButtons">
      <lib-button (click)="goToPickUp()" large="true" text="Switch to pickup"></lib-button>
    </div>
  </div>
</div>
