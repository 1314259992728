<div
  class="container-fluider customizeContainer noPadddingCol d-xl-flex d-none flex-column flex-grow-1 overflow-hidden"
  [style.height]="
    accessibilityMode
      ? 'calc(100vh - (57.5vh + 172px))'
      : mode !== 'cks'
      ? 'calc(100vh - 140px)'
      : 'calc(100vh - 172px)'
  "
>
  <div class="d-flex flex-row noPadddingCol flex-grow-1 overflow-hidden">
    <div class="d-xl-block d-none col mh-100 pt-5 mb-5 customizePageLeft">
      <ng-container *ngTemplateOutlet="contentLeft"></ng-container>
    </div>
    <div class="d-xl-block d-none col mh-100 overflow-auto py-5 customizeColRight noPadddingCol">
      <ng-container *ngTemplateOutlet="contentRight"></ng-container>
    </div>
  </div>
</div>

<div class="d-xl-none container-fluider p-0" [ngClass]="{ customizeContainer: mode !== 'cks' }">
  <div class="d-xl-none col-12 px-0">
    <ng-container *ngTemplateOutlet="contentLeft"></ng-container>
  </div>
  <div class="d-xl-none col-12 p-0" style="margin-bottom: 180px;">
    <ng-container *ngTemplateOutlet="contentRight"></ng-container>
  </div>
</div>

<ng-template #contentRight>
  <div *ngIf="isPizza; else defaultCase">
    <div *ngFor="let optionGroup of optionGroups; index as i">
      <lib-modifier-selector
        [mode]="mode"
        [isEdit]="isEdit"
        [optionGroup]="optionGroup"
        [showEnzoItem]="showEnzoItem"
        [enzoName]="enzoName"
        [glutenFreeName]="glutenFreeName"
      >
      </lib-modifier-selector>
    </div>
  </div>
  <ng-container *ngIf="mode === 'kms'">
    <div style="margin-bottom: 150px;">
      <div class="d-flex flex-row" style="margin-top: 10px">
        <div class="col" style="padding: 0">
          <p class="p-0 m-0 fs-16" style="text-align: left;">
            Special Instructions
          </p>
        </div>
      </div>
      <lib-single-text-field
        label="Enter special instructions"
        controlName="specialInstructions"
        [parentForm]="specialInstructionsForm"
        type="text"
        (changeInputEvent)="inputChanged()"
      >
      </lib-single-text-field>
    </div>
  </ng-container>
</ng-template>

<ng-template #defaultCase>
  <div class="customizeRightCardBottom">
    <ng-container *ngFor="let optionGroup of optionGroups; index as i">
      <lib-modifier-selector
        *ngIf="!isPizza"
        [mode]="mode"
        [optionGroup]="optionGroup"
        [recursive]="true"
        [enzoName]="enzoName"
        [showEnzoItem]="showEnzoItem"
        [glutenFreeName]="glutenFreeName"
      ></lib-modifier-selector>
    </ng-container>
  </div>
</ng-template>

<ng-template #contentLeft>
  <lib-customize-item-bag
    (addToCartClick)="addToCart($event)"
    [product]="product"
    [optionGroups]="optionGroups"
    [defaultImageUrl]="defaultImageUrl"
    [displayImage]="displayImage"
    [isEdit]="isEdit"
    [productQuant]="basketProdQuant"
    [isLoading]="isLoading"
    [mode]="mode"
    [accessibilityMode]="accessibilityMode"
  >
  </lib-customize-item-bag>
</ng-template>
