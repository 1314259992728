import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { CustomerKioskMenuComponent } from './menu.component';
import { Shell } from '@app/components/shell/shell.service';
import { CKSGuard } from '@app/providers/cks.guard';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'cks/menu',
      component: CustomerKioskMenuComponent,
      data: { title: extract('Menu') },
      canActivate: [CKSGuard]
    },
    {
      path: 'cks/menu/:id',
      component: CustomerKioskMenuComponent,
      data: { title: extract('Menu') },
      canActivate: [CKSGuard]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class CustomerKioskMenuRoutingModule {}
