import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import * as moment_ from 'moment';
import { Basket } from '../models/olo.basket';
import { BillingInfo } from '../models/olo.billinginfo';
import { EnvironmentService } from './environment.service';
import { Product } from '../models/olo.product';
const moment = moment_;

@Injectable({
  providedIn: 'root'
})
export class BasketService {
  domainAPI = this.environment.getDomainAPI();
  constructor(private http: HttpClient, private environment: EnvironmentService) {}

  /*
   **********************************************************************
   *                        GET Requests
   **********************************************************************
   */

  getBasket(basketId: string) {
    return this.http.get(this.domainAPI + '/api/baskets/' + basketId, this.environment.getOLOHeaders());
  }

  getBillingSchemes(basketId: string) {
    return this.http
      .get(this.domainAPI + '/api/baskets/' + basketId + '/billingschemes', this.environment.getOLOHeaders())
      .pipe(map((res: any) => res.billingschemes));
    // .map(res => this.setBillingSchemes(res.json().billingschemes))
    // .catch((error: any) => Observable.throw(error.json().error));
  }

  checkLoyaltySchemes(basketId: string, authtoken: string) {
    return this.http
      .get(
        this.domainAPI +
          '/api/baskets/' +
          basketId +
          '/loyaltyschemes?authtoken=' +
          authtoken +
          '&checkbalance=true&checkrewards=true',
        this.environment.getOLOHeaders()
      )
      .pipe(map((res: any) => res));
  }

  /*
   **********************************************************************
   *                        PUT Requests
   **********************************************************************
   */

  tryReward(rewardId: string, memberId: number, basketId: string) {
    const body = {
      membershipid: memberId,
      references: [rewardId]
    };
    return this.http.put(
      this.domainAPI + '/api/baskets/' + basketId + '/loyaltyrewards/byref',
      body,
      this.environment.getOLOHeaders()
    );
    // .map(res => this.setBasket(<Basket>res.json()))
    // .catch((error: any) => Observable.throw(error.json().message));
  }

  updateProductInBasket(basketProductId: string, basketId: string, body: any) {
    return this.http.put(
      this.domainAPI + `/api/baskets/${basketId}/products/${basketProductId}`,
      body,
      this.environment.getOLOHeaders()
    );
  }

  setTimeWanted(basketId: string, date?: string) {
    if (!date || date === 'asap') {
      return this.http.delete(
        this.domainAPI + '/api/baskets/' + basketId + '/timewanted',
        this.environment.getOLOHeaders()
      );
    }
    const mmt = moment(date, 'YYYYMMDD hh:mm');
    const body = {
      ismanualfire: false,
      year: mmt.format('YYYY'),
      month: mmt.format('MM'),
      day: mmt.format('DD'),
      hour: mmt.format('HH'),
      minute: mmt.format('mm')
    };
    if (body.year === 'Invalid date') {
      return;
    }
    return this.http.put(
      this.domainAPI + '/api/baskets/' + basketId + '/timewanted',
      body,
      this.environment.getOLOHeaders()
    );
  }

  tryCoupon(couponcode: string, basketId: string) {
    const body = { couponcode };
    return this.http.put(
      this.domainAPI + '/api/baskets/' + basketId + '/coupon',
      body,
      this.environment.getOLOHeaders()
    );
  }

  setDeliveryAddress(addressInfo: any, basketId: string) {
    const body = addressInfo;
    // {
    //   streetaddress: streetaddress,
    //   building: building,
    //   city: city,
    //   zipcode: zipcode,
    //   phonenumber: phonenumber
    //   specialinstructions: specialinstructions
    // };
    return this.http.put(
      this.domainAPI + '/api/baskets/' + basketId + '/dispatchaddress',
      body,
      this.environment.getOLOHeaders()
    );
  }

  setDeliveryMode(deliverymode: string, basketId: string) {
    const body = {
      deliverymode
    };
    return this.http.put(
      this.domainAPI + '/api/baskets/' + basketId + '/deliverymode',
      body,
      this.environment.getOLOHeaders()
    );
  }

  addTip(amount: number, basketId: string) {
    const body = {
      amount
    };
    return this.http.put(this.domainAPI + '/api/baskets/' + basketId + '/tip', body, this.environment.getOLOHeaders());
  }

  /*
   **********************************************************************
   *                        POST Requests
   **********************************************************************
   */

  createBasket(restaurantID: string, auth?: string): Observable<Basket> {
    if (!restaurantID) {
      throwError('no restaurant id');
    }
    const body = { vendorid: restaurantID, auth_token: auth }; // TODO: not restaurantID?

    return this.http.post<Basket>(this.domainAPI + '/api/baskets/create', body, this.environment.getOLOHeaders());
  }

  addProductToBasket(product: Product, basketId: string) {
    return this.http.post(
      this.domainAPI + '/api/baskets/' + basketId + '/products',
      product,
      this.environment.getOLOHeaders()
    );
  }

  checkGiftBalance(schemeId: string, basketId: string, body: any) {
    return this.http.post(
      this.domainAPI + `/api/baskets/${basketId}/billingschemes/${schemeId}/retrievebalance`,
      body,
      this.environment.getOLOHeaders()
    );
  }

  transferBasket(vendorId: string, basketId: string) {
    const body = { vendorid: vendorId };
    return this.http.post(
      this.domainAPI + '/api/baskets/' + basketId + '/transfer',
      body,
      this.environment.getOLOHeaders()
    );
  }

  createBasketFromOrder(orderref: string, id: string, token: string) {
    const body = {
      orderref,
      id,
      ignoreunavailableproducts: true
    };

    return this.http.post<Basket>(
      this.domainAPI + '/api/baskets/createfromorder?authtoken=' + token,
      body,
      this.environment.getOLOHeaders()
    );
  }

  reorderFavoriteOrder(id: string, token: string) {
    const body = {
      faveid: id,
      ignoreunavailableproducts: true
    };

    return this.http.post<Basket>(
      this.domainAPI + '/api/baskets/createfromfave?authtoken=' + token,
      body,
      this.environment.getOLOHeaders()
    );
  }

  /*
   * TODO: Implement multiple payment types
   */
  submitOrder(authtoken: string, billingInfo: BillingInfo, basketId: string): Observable<any> {
    const body: any = { ...billingInfo, authtoken: authtoken ? authtoken : '' };
    return this.http.post(
      this.domainAPI + '/api/baskets/' + basketId + '/submit',
      body,
      this.environment.getOLOHeaders()
    );
  }

  validateBasket(basketId: string) {
    const body = {};
    return this.http
      .post(
        this.domainAPI + '/api/baskets/' + basketId + '/validate?checkupsell=true',
        body,
        this.environment.getOLOHeaders()
      )
      .toPromise();
  }

  /*
   **********************************************************************
   *                        DELETE Requests
   **********************************************************************
   */

  removeProductFromBasket(basketProductId: string, basketId: string) {
    return this.http.delete(
      this.domainAPI + `/api/baskets/${basketId}/products/${basketProductId}`,
      this.environment.getOLOHeaders()
    );
  }

  removeReward(rewardId: string, basketId: string) {
    return this.http.delete(
      this.domainAPI + '/api/baskets/' + basketId + '/loyaltyrewards/' + rewardId,
      this.environment.getOLOHeaders()
    );
  }

  removeCoupon(basketId: string) {
    return this.http.delete(this.domainAPI + '/api/baskets/' + basketId + '/coupon', this.environment.getOLOHeaders());
  }
}
