<div class="modal-wrapper justify-content-center">
  <i class="fas fa-times x-close" (click)="clickedX()"></i>
  <h1 class="modal-title text-center text-uppercase bold">{{ title }}</h1>

  <div class="d-flex flex-row text-center justify-content-center pb-4 text-uppercase bold">
    {{ type === 'pickup' ? 'Takeout' : 'Digital Dine-In' }}
  </div>
  <div class="d-flex flex-row">
    <div class="col mx-2">
      <form [formGroup]="userForm">
        <div class="d-flex flex-row kiosk-inside-row">
          <div class="col p-0 pe-2 text-start">
            <div>First Name</div>
            <input placeholder="First Name" id="firstName" class="kiosk-input-field ps-3" formControlName="firstName" />
          </div>
          <div class="col p-0 ps-2 text-start">
            <div>Last Name</div>
            <input placeholder="Last Name" id="lastName" class="kiosk-input-field ps-3" formControlName="lastName" />
          </div>
        </div>
      </form>

      <div class="d-flex flex-row kiosk-inside-row" *ngIf="type === 'dinein'">
        <div class="col mt-4 p-0 text-start">
          <div>Party Size</div>
          <lib-party-size
            [maxTableSize]="maxTableSize"
            [selectedTableSize]="selectedTableSize"
            (tableSizeEmit)="tableSizeSelected($event)"
          >
          </lib-party-size>
        </div>
      </div>
    </div>
  </div>

  <div class="d-flex flex-row pt-1 mt-4">
    <div class="col-12">
      <button id="kioskStartOrderBtn" class="btn btn-primary text-white largeButton" (click)="startOrder()">
        <div *ngIf="!isLoading">Start Order</div>
        <div class="spin-loader" *ngIf="isLoading"></div>
      </button>
    </div>
  </div>
</div>
