import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cmsLocationName'
})
export class CmsLocationNamePipe implements PipeTransform {

  transform(locationName: string): unknown {
    return locationName.replace('Punch Neapolitan Pizza - ', '');
  }

}
