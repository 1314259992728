export class BillingInfo {
  billingmethod: string; // creditcard, creditcardonfile, billingaccount, cash, storedvalue, prepaid
  billingaccountid?: number;
  billingschemeid?: string;
  billingfields?: BillingFieldData[]; // Required when we add support for storedvalue
  usertype: string; // user, guest
  firstname?: string; // required if usertype is guest
  lastname?: string; // required if usertype is guest
  emailaddress?: string; // required if usertype is guest
  contactnumber?: string;
  reference?: string; // Required for Rails partners
  cardnumber?: string; // Required if billingmethod is creditcard
  expiryyear?: number; // Full 4 digit year. Required if billingmethod is creditcard
  expirymonth?: number; // No preceding zeros (i.e. 3 not 03). Required if billingmethod is creditcard
  cvv?: string; // Required if billingmethod is creditcard
  streetaddress?: string; // Only needed if billingmethod is creditcard and address validation is required by restaurant
  streetaddress2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
  saveonfile?: string;
  orderref?: string;
  prepaidtransactionid?: string;
  prepaiddescription?: string;
  customdata?: OrderCustomData[];
  guestoptin?: boolean;
  receivinguser?: ReceivingUser;
  token?: string;
  cardtype?: string;
  cardlastfour?: string;
}

export class BillingFieldData {
  name: string;
  value: string;
}

export class OrderCustomData {
  key: string;
  value: string;
}

export class ReceivingUser {
  firstname: string;
  lastname: string;
  emailaddress: string;
  contactnumber: string;
}
