import { Component, Input, Output, EventEmitter, ViewChild } from '@angular/core';
import { Restaurant } from '../../models/olo.restaurant';
import { NgbModal, ModalDismissReasons } from '@ng-bootstrap/ng-bootstrap';
import { Store, Select } from '@ngxs/store';
import { RestaurantState, RestaurantStateModel } from '@app/store/state/restaurant.state';
import { GetCalendars } from '@app/store/actions/restaurant.action';
import { Observable } from 'rxjs';

interface OrderHeader {
  restaurantID: number;
  orderType: string;
}

@Component({
  selector: 'lib-location-landing',
  templateUrl: './location-landing.component.html',
  styles: []
})
export class LocationLandingComponent {
  @Input() location: Restaurant;
  @Select() restaurant$: Observable<RestaurantStateModel>;
  closeResult: string;

  @Output() proceedToMenuEvent = new EventEmitter<OrderHeader>();
  @Output() checkDelivery = new EventEmitter<any>();
  distance: any;
  storeTime: any;
  constructor(private modalService: NgbModal, private store: Store) {}

  ngOnInit() {
    this.distance = sessionStorage.getItem(this.location.city);
    this.store.dispatch(new GetCalendars(this.location.id.toString(), true));
  }
  // DECOUPLING HELPERS

  // Passes the Restaurant ID and Order Type to the parent component

  passOrderHeader = (identifier: number, type: string) => {
    const temp = {
      restaurantID: identifier,
      orderType: type
    };
    this.proceedToMenuEvent.emit(temp);
  };

  deliveryZone(content: any) {
    this.modalService.open(content, { ariaLabelledBy: 'informationModal', centered: true }).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  checkoutDeliveryAddress(outOfZone: any, modal: any, address: any) {
    const temp = {
      outOfZone: outOfZone,
      modal: modal,
      address: address
    };
    this.checkDelivery.emit(temp);
  }
}
