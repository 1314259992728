import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map, catchError, switchMap } from 'rxjs/operators';
import { EnvironmentService } from '@app/providers/environment.service';

@Injectable({
  providedIn: 'root'
})
export class OloHttpService {
  private baseURL = this.environment.getDomainAPI();

  // private headers = this.environment.getOLOHeaders()

  constructor(private http: HttpClient, private environment: EnvironmentService) {}

  getDomainAPI() {
    return this.environment.getDomainAPI();
  }

  get<T>(resource: string): Observable<T> {
    return this.environment.getOLOHeaders().pipe(
      switchMap(headers => {
        return this.http.get<T>(this.getDomainAPI() + resource, headers).pipe(
          map(
            res => {
              return res;
            },
            catchError(e => {
              return e;
            })
          )
        );
      })
    );
  }

  put<T>(resource: string, body: any): Observable<T> {
    return this.environment.getOLOHeaders().pipe(
      switchMap(headers => {
        return this.http.put<T>(this.getDomainAPI() + resource, body, headers).pipe(
          map(
            res => {
              return res;
            },
            catchError(e => {
              return e;
            })
          )
        );
      })
    );
  }

  post<T>(resource: string, body?: any): Observable<T> {
    return this.environment.getOLOHeaders().pipe(
      switchMap(headers => {
        if (body === undefined) {
          return this.http.post<T>(this.getDomainAPI() + resource, headers).pipe(
            map(
              res => {
                return res;
              },
              catchError(e => {
                return e;
              })
            )
          );
        } else {
          return this.http.post<T>(this.getDomainAPI() + resource, body, headers).pipe(
            map(
              res => {
                return res;
              },
              catchError(e => {
                return e;
              })
            )
          );
        }
      })
    );
  }

  patch<T>(resource: string, body: any): Observable<T> {
    return this.environment.getOLOHeaders().pipe(
      switchMap(headers => {
        return this.http.patch<T>(this.getDomainAPI() + resource, body, headers).pipe(
          map(
            res => {
              return res;
            },
            catchError(e => {
              return e;
            })
          )
        );
      })
    );
  }

  delete<T>(resource: string): Observable<T> {
    return this.environment.getOLOHeaders().pipe(
      switchMap(headers => {
        return this.http.delete<T>(this.getDomainAPI() + resource, headers).pipe(
          map(
            res => {
              return res;
            },
            catchError(e => {
              return e;
            })
          )
        );
      })
    );
  }
}
