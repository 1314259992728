import { Component, OnInit } from '@angular/core';
import { DirectusExpoProvider } from '@app/providers/expo/directus/directus-expo.provider';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { OrderDetailsModalComponent } from '@app/components/order-details-modal/order-details-modal.component';
import { OloApiService } from '@app/providers/expo/olo/olo-api.service';
import * as moment from 'moment';
import { Router } from '@angular/router';
import { SetBanner } from '@app/store/actions/app.action';
import { Store } from '@ngxs/store';
import { switchMap } from 'rxjs/operators';
import { TrancloudProviderService } from '@app/datacap-trancloud/services/trancloud-provider.service';
import { ExpoMetadata } from '@app/providers/expo/directus/directus-collections.interface';

@Component({
  selector: 'app-fired',
  templateUrl: './fired.component.html',
  styleUrls: ['./fired.component.scss']
})
export class FiredComponent implements OnInit {
  displayExpoOrders: ExpoMetadata[] = [];
  time = new Date();
  displayError = false;

  constructor(
    private expo: DirectusExpoProvider,
    private modalService: NgbModal,
    private olo: OloApiService,
    private router: Router,
    private store: Store,
    private tranCloud: TrancloudProviderService
  ) {
    this.store.dispatch(new SetBanner('FIRED', false, false, ''));
  }

  ngOnInit() {
    setInterval(() => {
      this.time = new Date();
    }, 1000);
    this.getExpoItems();
  }

  getExpoItems() {
    const date = moment().format('YYYY-MM-DD');
    const location = this.expo.getCurrentLocation();
    this.expo.getFiredExpoOrders(date, location).subscribe(
      expoOrders => {
        this.displayExpoOrders = expoOrders;
        this.displayError = false;
      },
      error => {
        console.log('error', error);
        this.displayError = true;
        if (error.error.error.message === 'Reading items from "expo_metadata" collection was denied') {
          this.expo.logoutExpoUser();
          this.router.navigateByUrl('/kiosk-expo');
        }
      }
    );
  }

  openOrderDetails(order: ExpoMetadata) {
    this.olo.getOrderByID(order.order_id).subscribe((oloOrder: any) => {
      const orderDetailsModal = this.modalService.open(OrderDetailsModalComponent, {
        centered: true,
        keyboard: false,
        backdrop: 'static'
      });
      orderDetailsModal.componentInstance.guestName = order.customer_name;
      orderDetailsModal.componentInstance.readyTime = order.ready_time;
      orderDetailsModal.componentInstance.orderStatus = order.order_status;
      orderDetailsModal.componentInstance.products = oloOrder.products;
      orderDetailsModal.componentInstance.total = oloOrder.total;

      orderDetailsModal.componentInstance.xClicked.subscribe(() => {
        this.modalService.dismissAll();
      });
      orderDetailsModal.componentInstance.refundClicked.subscribe(() => {
        this.refundOrder(order);
      });
    });
  }

  refundOrder(order: ExpoMetadata) {
    this.olo.getOrderByID(order.order_id).subscribe((oloOrder: any) => {
      order.order_status = 'refunded';
      this.expo.updateExpoOrder(order).subscribe(() => {
        this.getExpoItems();
        this.modalService.dismissAll();
      });
      if (order.record_number) {
        this.tranCloud
          .refundByRecordNo(order.invoice_number, order.auth_code, oloOrder.total, order.record_number)
          .subscribe(() => {});
      } else {
        this.tranCloud.creditCardRefund(order.invoice_number, order.auth_code, oloOrder.total).subscribe(() => {});
      }
    });
  }
}
