import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { MyBagComponent } from './my-bag.component';
import { BagModule } from 'src/assets/chepri-modules/src/lib/bag/bag.module';

@NgModule({
  declarations: [MyBagComponent],
  imports: [CommonModule, CoreModule, SharedModule, BagModule],
  exports: [MyBagComponent]
})
export class MyBagModule {}
