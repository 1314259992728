import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { Shell } from '@app/components/shell/shell.service';
import { KioskProductComponent } from './product.component';
import { KMSGuard } from '@app/providers/kms.guard';

const routes: Routes = [
  Shell.childRoutes([{ path: 'kiosk/product/:id', component: KioskProductComponent, canActivate: [KMSGuard] }])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class KioskProductRoutingModule {}
