import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';
import { ReactiveFormsModule, NgModel, FormsModule } from '@angular/forms';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { CheckoutRoutingModule } from './checkout-routing.module';
import { CheckoutComponent } from './checkout.component';

import { SingleTextFieldModule } from 'src/assets/chepri-modules/src/lib/single-text-field/single-text-field.module';
import { RadioButtonModule } from 'src/assets/chepri-modules/src/lib/radio-button/radio-button.module';
import { IconModule } from 'src/assets/chepri-modules/src/lib/icon/icon.module';
import { ButtonModule } from 'src/assets/chepri-modules/src/lib/button/button.module';

import { CheckoutBagModule } from '../checkout-bag/checkout-bag.module';
import { CapacityErrorModule } from 'src/assets/chepri-modules/src/lib/capacity-error/capacity-error.module';
import { NgxMaskDirective } from 'ngx-mask';

@NgModule({
  declarations: [CheckoutComponent],
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    Angulartics2Module,
    ReactiveFormsModule,
    FormsModule,
    CheckoutRoutingModule,
    SingleTextFieldModule,
    RadioButtonModule,
    IconModule,
    CheckoutBagModule,
    ButtonModule,
    CapacityErrorModule,
    NgxMaskDirective
  ],
  providers: [CurrencyPipe]
})
export class CheckoutPageModule {}
