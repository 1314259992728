import { Component, Input, Output, EventEmitter } from '@angular/core';
import { Select } from '@ngxs/store';

@Component({
  selector: 'lib-favorite-order-modal',
  templateUrl: './favorite-order-modal.component.html',
  styles: []
})
export class FavoriteOrderModalComponent {
  @Output() continue = new EventEmitter<any>();
  @Output() xClicked = new EventEmitter<any>();
  @Output() deliveryOutOfRange = new EventEmitter<any>();
  @Output() handleFavroiteOrder = new EventEmitter<any>();
  @Select() user$: any;

  @Input() pastOrders = [
    {
      name: 'Johns Favorite',
      orderType: 'Delivery',
      orderDate: '08/15/19',
      orderItems: 'Margherita, Napoli, +2 More',
      price: '$25.32',
      date: '08/15/19',
      item: 'item'
    },
    {
      name: 'Johns Favorite',
      orderType: 'Delivery',
      orderDate: '08/15/19',
      orderItems: ['Margherita'],
      price: '$25.32',
      date: '08/15/19',
      item: 'item'
    },
    {
      name: 'Johns Favorite',
      orderType: 'Delivery',
      orderDate: '08/15/19',
      orderItems: 'Margherita, Napoli, +2 More',
      price: '$25.32',
      date: '08/15/19',
      item: 'item'
    }
  ];

  @Input() items = [
    {
      item: 'item'
    },
    {
      item: 'item'
    },
    {
      item: 'item'
    },
    {
      item: 'item'
    }
  ];

  constructor() {
  }

  handleFavoriteOrders() {
    this.handleFavroiteOrder.emit();
  }

  xClick() {
    this.xClicked.emit();
  }
}
