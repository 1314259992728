import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Select, Store } from '@ngxs/store';
import { AppStateModel } from '@app/store/state/app.state';
import { Observable } from 'rxjs';
import { UntypedFormGroup } from '@angular/forms';
import { AddUser } from '@app/store/actions/user.action';
import { ToastrService } from 'ngx-toastr';
import { SetBanner } from '@app/store/actions/app.action';
import { Location } from '@angular/common';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.scss']
})
export class SignUpComponent implements OnInit {
  @Select() app$: Observable<AppStateModel>;

  constructor(
    private router: Router,
    private store: Store,
    private toastService: ToastrService,
    private _location: Location
  ) {}

  ngOnInit() {
    this.store.dispatch(new SetBanner('CREATE ACCOUNT', false, true, ''));
  }

  leavePage(route: string) {
    if (route === 'sign-in') {
      this.router.navigateByUrl('/sign-in');
    } else if (route === 'guest-auth') {
      this.router.navigateByUrl('/guest-info');
    }
  }

  submitFormData(form: UntypedFormGroup) {
    // Dispatch action to create a new account
    this.store
      .dispatch(
        new AddUser({
          firstname: form.get('firstName').value,
          lastname: form.get('lastName').value,
          emailaddress: form.get('emailAddress').value,
          password: form.get('passwords').get('password').value,
          contactnumber: form.get('phoneNumber').value,
          reference: 'web',
          basketid: this.store.selectSnapshot(state => (state.basket ? state.basket.id : null)),
          emailoptin: form.get('emailOptIn').value,
          smsoptin: form.get('smsOptIn').value
        })
      )
      .subscribe(
        (res: any) => {
          // Success - toast success message and route to homepage
          this.toastService.success('Account Created!');
          this._location.back();
        },
        (error: any) => {
          // Error - toast warning or error
          if (error.error && error.error.code === 200) {
            this.toastService.warning(error.error.message);
          } else if (error.error) {
            this.toastService.error(error.error.message);
          } else {
            this.toastService.error(error.message);
          }
        },
        () => {}
      );
  }
}
