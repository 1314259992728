import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'handoff'
})
export class HandoffPipe implements PipeTransform {
  transform(value: string): string {
    switch (value) {
      case 'pickup':
        return 'takeout';
      case 'dinein':
        return 'dine-in';
      case 'dispatch':
        return 'delivery';
      default:
        return value;
    }
  }
}
