import { Component, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-timeout-modal',
  templateUrl: './timeout-modal.component.html',
  styleUrls: ['./timeout-modal.component.scss']
})
export class TimeoutModalComponent {
  @Output() continueClicked = new EventEmitter<any>();
  constructor() {}

  continueLinkClicked() {
    this.continueClicked.emit();
  }
}
