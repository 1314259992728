import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LoaderComponent } from './loader/loader.component';
import { ScheduledMessageModalComponent } from '@app/components/scheduled-message-modal/scheduled-message-modal.component';
import { UpsellModalComponent } from '@app/components/upsell-modal/upsell-modal.component';
import { ButtonModule } from '../../assets/chepri-modules/src/lib/button/button.module';
import { FireModalComponent } from '@app/components/fire-modal/fire-modal.component';
import { FireNowModalComponent } from '@app/components/fire-now-modal/fire-now-modal.component';
import { CheckInModalComponent } from '@app/components/check-in-modal/check-in-modal.component';
import { LoginModalComponent } from '@app/components/login-modal/login-modal.component';
import { StartOverModalComponent } from '@app/components/start-over-modal/start-over-modal.component';
import { LogoutModalComponent } from '@app/components/logout-modal/logout-modal.component';
import { SwipeCardModalComponent } from '@app/components/swipe-card-modal/swipe-card-modal.component';
import { OrderDetailsModalComponent } from '@app/components/order-details-modal/order-details-modal.component';
import { CancelOrderModalComponent } from '@app/components/cancel-order-modal/cancel-order-modal.component';
import { ShortTimePipe } from './pipes/short-time.pipe';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RefundConfirmationModalComponent } from '@app/components/refund-confirmation-modal/refund-confirmation-modal.component';
import { ButtonFeedbackDirective } from './directives/button-feedback.directive';
import { TruncatePipe } from '@app/shared/pipes/truncate.pipe';
import { HandoffPipe } from './pipes/handoff.pipe';
import { CmsLocationNamePipe } from './pipes/cms-location-name.pipe';
import { SvgSpriteModule } from '../../assets/chepri-modules/src/lib/svg-sprite/svg-sprite.module';
import { OloDatePipe } from './pipes/olo-date.pipe';
import { SavedProductModalComponent } from '@app/components/saved-product-modal/saved-product-modal.component';
import { GetControlFromFormGroupPipe } from './pipes/get-control-from-form-group.pipe';
import { FormGroupHasControlPipe } from './pipes/form-group-has-control.pipe';
import { GetArrayFromFormGroupPipe } from './pipes/get-array-from-form-group.pipe';
import { SingleTextFieldModule } from '../../assets/chepri-modules/src/lib/single-text-field/single-text-field.module';
import { SavedProductChoicesPipe } from './pipes/saved-product-choices.pipe';
import { NotAvailableWithEnzoCheckPipe } from './pipes/not-available-with-enzo-check.pipe';
import { IntervalToMinutesRemainingPipe } from './pipes/interval-to-minutes-remaining.pipe';
import { SavedCardDedupePipe } from './pipes/saved-card-dedupe.pipe';
import { LongOptionGroupPipe } from './pipes/long-option-group.pipe';
import { SelectedOptionsPipe } from './pipes/selected-options.pipe';

@NgModule({
  imports: [CommonModule, ButtonModule, FormsModule, ReactiveFormsModule, SvgSpriteModule, SingleTextFieldModule],
  declarations: [
    LoaderComponent,
    ShortTimePipe,
    ScheduledMessageModalComponent,
    UpsellModalComponent,
    FireModalComponent,
    FireNowModalComponent,
    CheckInModalComponent,
    LoginModalComponent,
    StartOverModalComponent,
    LogoutModalComponent,
    SwipeCardModalComponent,
    OrderDetailsModalComponent,
    CancelOrderModalComponent,
    RefundConfirmationModalComponent,
    TruncatePipe,
    HandoffPipe,
    CmsLocationNamePipe,
    OloDatePipe,
    SavedProductModalComponent,
    GetControlFromFormGroupPipe,
    FormGroupHasControlPipe,
    GetArrayFromFormGroupPipe,
    SavedProductChoicesPipe,
    NotAvailableWithEnzoCheckPipe,
    IntervalToMinutesRemainingPipe,
    SavedCardDedupePipe,
    LongOptionGroupPipe,
    SelectedOptionsPipe
  ],
  exports: [
    LoaderComponent,
    ShortTimePipe,
    ScheduledMessageModalComponent,
    UpsellModalComponent,
    FireModalComponent,
    FireNowModalComponent,
    CheckInModalComponent,
    LoginModalComponent,
    StartOverModalComponent,
    LogoutModalComponent,
    SwipeCardModalComponent,
    OrderDetailsModalComponent,
    CancelOrderModalComponent,
    RefundConfirmationModalComponent,
    TruncatePipe,
    HandoffPipe,
    CmsLocationNamePipe,
    OloDatePipe,
    SavedProductModalComponent,
    GetControlFromFormGroupPipe,
    FormGroupHasControlPipe,
    GetArrayFromFormGroupPipe,
    SavedProductChoicesPipe,
    NotAvailableWithEnzoCheckPipe,
    IntervalToMinutesRemainingPipe,
    SavedCardDedupePipe,
    LongOptionGroupPipe,
    SelectedOptionsPipe
  ],
  providers: [TruncatePipe]
})
export class SharedModule {}
