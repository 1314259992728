import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/components/shell/shell.service';
import { CustomerKioskOrderSummaryComponent } from '@app/components/customer-kiosk/order-summary/order-summary.component';
import { CKSGuard } from '@app/providers/cks.guard';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'cks/order-summary',
      component: CustomerKioskOrderSummaryComponent,
      data: { title: extract('Order Summary') },
      canActivate: [CKSGuard]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class OrderSummaryRoutingModule {}
