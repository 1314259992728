import { Injectable } from '@angular/core';
import { GeocoderResult, Address } from '../models/Address';

@Injectable({
  providedIn: 'root'
})
export class FormatService {
  constructor() {}

  googleAddToStandard(geocoderResponse: GeocoderResult): Address {
    const result: Address = {
      streetAddress: '',
      city: '',
      state: {
        short: '',
        long: ''
      },
      zip: ''
    };
    let streetNumber: string;
    let streetName: string;
    geocoderResponse.address_components.forEach(comp => {
      if (comp.types.includes('street_number')) {
        streetNumber = comp.short_name;
      } else if (comp.types.includes('route')) {
        streetName = comp.short_name;
      } else if (comp.types.includes('city') || comp.types.includes('locality')) {
        result.city = comp.short_name;
      } else if (comp.types.includes('administrative_area_level_1')) {
        result.state = { short: comp.short_name, long: comp.long_name };
      } else if (comp.types.includes('postal_code')) {
        result.zip = comp.short_name;
      }
    });
    result.streetAddress = streetNumber + ' ' + streetName;

    return result;
  }
}
