import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

import { ShellComponent } from './shell.component';
import { NavbarModule } from 'src/assets/chepri-modules/src/lib/navbar/navbar.module';
import { OrderInfoModule } from 'src/assets/chepri-modules/src/lib/order-info/order-info.module';
import { HeaderHeroModule } from '../../../assets/chepri-modules/src/lib/header-hero/header-hero.module';
import { MyBagModule } from '../my-bag/my-bag.module';

@NgModule({
  imports: [CommonModule, TranslateModule, RouterModule, NavbarModule, OrderInfoModule, HeaderHeroModule, MyBagModule],
  declarations: [ShellComponent]
})
export class ShellModule {}
