import { Component, Input, Output, EventEmitter } from '@angular/core';
import { NgbDropdownModule } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'lib-link',
  providers: [NgbDropdownModule],
  templateUrl: './link.component.html',
  styles: []
})
export class LinkComponent {
  @Input() text: string;
  @Input() url: string;
  @Input() children: any;
  @Input() isChild: boolean;
  @Input() caps: boolean;
  @Input() edit: boolean;

  @Output() linkClick = new EventEmitter<any>();

  clickEvent(index?: number) {
    if (index) {
      // Log index of child selected
      this.linkClick.emit(index);
    } else {
      // Emit link clicked
      this.linkClick.emit();
    }
  }
}
