import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Store } from '@ngxs/store';
import { Observable, throwError } from 'rxjs';
import { mergeMap, map, catchError } from 'rxjs/operators';
import { EnvironmentService } from './environment.service';
import { User, NewUser } from 'src/assets/chepri-modules/src/models/olo.user';
import { FavoritesOrderModel } from '../../assets/chepri-modules/src/lib/favorite-order-name/favorites-order.model';
import { OloHttpService } from '@app/providers/expo/olo/olo-http.service';
import { UserCommunicationPreferences } from '../../assets/chepri-modules/src/models/olo.usercommunicationpreferences';
import {
  CreateSavedProductRequest,
  CreateSavedProductResponse,
  RequestCreateSavedProduct,
  RetrieveSavedProductsResponse
} from '@app/models/saved-products.olo';

@Injectable({
  providedIn: 'root'
})
export class UserService {
  domainAPI = this.environment.getDomainAPI();

  constructor(private http: OloHttpService, private store: Store, private environment: EnvironmentService) {}

  create(body: NewUser): Observable<any> {
    const oloBody = { ...body };
    delete oloBody.emailoptin;
    delete oloBody.smsoptin;
    oloBody.basketid = this.store.selectSnapshot(state => (state.basket ? state.basket.id : null));

    return this.http.post<User>('/users/create', oloBody);
  }

  login(body: any) {
    return this.http.post('/users/authenticate', body);
  }

  getUser(token: string): Observable<User> {
    return this.http.get('/users/' + token);
  }

  updateInfo(info: User) {
    const authToken = this.store.selectSnapshot(state => state.user.info.authtoken);
    // body is just firstname, lastname, email
    const body = { firstname: info.firstname, lastname: info.lastname, emailaddress: info.emailaddress };
    return this.http.put('/users/' + authToken, body);
  }

  changePassword(currentPassword: string, newPassword: string) {
    const authToken = this.store.selectSnapshot(state => state.user.info.authtoken);
    const body = {
      currentpassword: currentPassword,
      newpassword: newPassword
    };
    return this.http.post('/users/' + authToken + '/password', body);
  }

  forgotPassword(emailAddress: string) {
    const body = { emailaddress: emailAddress };
    return this.http.post('/users/forgotpassword', body);
  }

  getBillingAccounts(token: string) {
    return this.http.get('/users/' + token + '/billingaccounts');
  }

  setCreditCardDefault(token: string, billingAccountId: string) {
    const body = { isdefault: true };
    return this.http.put('/users/' + token + '/creditcards/' + billingAccountId, body);
  }

  deleteCreditCard(token: string, billingAccountId: string) {
    return this.http.delete('/users/' + token + '/billingaccounts/' + billingAccountId);
  }

  saveResturantFave(restaurant_id: any): Observable<any> {
    const body = {
      restaurant_id,
      isDefault: false
    };

    const authToken = this.store.selectSnapshot(gState => gState.auth.token);
    const url = '/users/' + authToken + '/favelocations/' + restaurant_id + '?isdefault=false';
    return this.http.post(url);
  }

  updateRestaruantFave(oldOnes: any): Observable<any> {
    const authToken = this.store.selectSnapshot(gState => gState.auth.token);

    const urlDelete = '/users/' + authToken + '/favelocations' + oldOnes;

    return this.http.delete(urlDelete);
  }

  deleteFavoriteMeal(payload: any): Observable<any> {
    const authToken = this.store.selectSnapshot(gState => gState.user.info.authtoken);
    const favId = payload.id;

    const urlDelete = '/users/' + authToken + '/faves/' + favId;
    return this.http.delete(urlDelete);
  }

  deleteFavoriteLocation(payload: any): Observable<any> {
    const authToken = this.store.selectSnapshot(gState => gState.auth.token);

    const urlDelete = '/users/' + authToken + '/favelocations/' + payload;
    return this.http.delete(urlDelete);
  }

  saveFavoriteBasket(payload: FavoritesOrderModel): Observable<any> {
    /// users/{authtoken}/faves
    const basketId = this.store.selectSnapshot(state => state.basket.basket.id);
    const body = {
      basketid: basketId,
      description: payload
    };
    const authToken = this.store.selectSnapshot(gState => gState.user.info.authtoken);
    const url = '/users/' + authToken + '/faves';
    return this.http.post(url, body);
  }

  logout(info: any) {
    return Observable.create((observer: any) => {
      observer.next({ token: 'testtoken' });
      observer.complete();
    });
  }

  getRecentOrders(token: any) {
    return this.http.get('/users/' + token + '/recentorders');
    // .map(res => res.json())
    // .catch((error: any) => Observable.throw(error));
  }

  getFavStore(token: any) {
    return this.http.get('/users/' + token + '/favelocations');
  }

  getFavoriteMeals(token: any) {
    return this.http.get('/users/' + token + '/faves');
  }

  getUserCommunicationPreferences(token: string): Observable<UserCommunicationPreferences> {
    const resource = `/users/${token}/contactoptions`;
    return this.http.get<UserCommunicationPreferences>(resource);
  }

  updateUserCommunicationPreferences(
    token: string,
    body: UserCommunicationPreferences
  ): Observable<UserCommunicationPreferences> {
    const resource = `/users/${token}/contactoptions`;
    return this.http.put<UserCommunicationPreferences>(resource, body);
  }

  /**
   * Creates a saved product for a user from a chainproductid. Users can save brand-level (chain) products, which can also include
   * chainchoiceid's (modifier options a.k.a. choices) and custom fields.
   * @param authToken - Olo user authentication token.
   * @param name - User defined name of the saved product.
   * @param product - The chain product to save.
   */
  createSavedProduct(
    authToken: string,
    name: string,
    product: RequestCreateSavedProduct
  ): Observable<CreateSavedProductResponse> {
    const resource = `/users/${authToken}/savedproducts`;
    const body: CreateSavedProductRequest = {
      name,
      product
    };
    return this.http.post<CreateSavedProductResponse>(resource, body);
  }

  /**
   * Gets all saved products created by a user. These products are brand-level (chain) products, which can also include chainchoiceid's
   * (modifier options a.k.a. choices) and custom fields. After retrieving saved products, baskets can be created using the Create Basket
   * endpoint, and then saved products (savedproducts[].product) can directly be added to that basket using the Add Single Product by Chain
   * ID to Basket endpoint.
   *
   * The maximum number of saved products returned is 100.
   *
   * The returned saved products are in date created descending sort order (newest first).
   *
   * Deleted saved products will not be returned.
   *
   * Unavailable products and choices (i.e. deleted from brand menu) will be included in the response, and availability can be determined by
   * checking product.availability.available and choices[].availability.available.
   * @param token - Olo user authentication token.
   */
  retrieveSavedProducts(token: string): Observable<RetrieveSavedProductsResponse> {
    const resource = `/users/${token}/savedproducts`;
    return this.http.get<RetrieveSavedProductsResponse>(resource);
  }

  /**
   * Deletes the user's saved product by its savedproductid.
   *
   * Returns 200 if the saved product was deleted or if the savedproductid does not exist. (Calling delete on the same product repeatedly
   * gives the same result)
   *
   * Returns 403 if the user has an invalid/expired authtoken.
   *
   * Returns 403 if the savedproductid does not belong to the user given by the authtoken.
   *
   * @param authToken - Olo user authentication token.
   * @param savedProductID - ID of the saved product to delete.
   */
  deleteSavedProduct(authToken: string, savedProductID: string): Observable<void> {
    const resource = `/users/${authToken}/savedproducts/${savedProductID}`;
    return this.http.delete<void>(resource);
  }

  /**
   * Deletes the user account associated with the provided Olo authentication token. Please note that this action cannot be reversed and the user will need to create a new account if they wish to login in the future. All previously issued authentication tokens for the user will no longer be usable. Calling this endpoint using an authentication token obtained for an SSO linked user will only delete the user account in Olo's system.
   * @param authToken - Olo user authentication token.
   */
  deleteUser(authToken: string): Observable<void> {
    const resource = `/users/${authToken}/account`;
    return this.http.delete<void>(resource);
  }
}
