<ng-container *ngIf="(restaurant$ | async).restaurant as rest">
  <ng-container *ngIf="(basket$ | async).basket as basket">
    <div *ngIf="orderInfoTop" class="orderInfoTop">
      <p class="orderInfo white" *ngIf="basket.deliverymode !== 'dinein'" id="boldInfo">
        {{ basket.deliverymode === 'dispatch' ? 'Delivering' : 'Pickup' }} From - {{ rest.name }}
      </p>
      <p class="orderInfo white" *ngIf="basket.deliverymode === 'dinein'" id="boldInfo">Dine-In At - {{ rest.name }}</p>
      <p class="orderInfo white">
        {{ rest.streetaddress }}
      </p>
      <p class="orderInfo white">{{ rest.city }}, {{ rest.state }} {{ rest.zip }}</p>
      <p class="orderInfo white">
        {{ rest.telephone }}
      </p>
      <a class="whitecolor" href="/locations">Change Location</a>
    </div>

    <div *ngIf="orderInfoBag" class="orderInfoBag">
      <div style="display: inline-flex; flex-direction: row">
        <lib-svg-sprite style="position: relative; bottom: 3px;" svgID="location"></lib-svg-sprite>
        <p class="orderInfo ps-2" *ngIf="basket.deliverymode !== 'dinein'" id="boldInfo">
          {{ basket.deliverymode === 'dispatch' ? 'Delivering' : 'Pickup' }} From - {{ rest.name }}
        </p>
        <p class="orderInfo ps-2" *ngIf="basket.deliverymode === 'dinein'" id="boldInfo">Dine-In - {{ rest.name }}</p>
      </div>
      <p class="orderInfo ">
        {{ rest.streetaddress }}
      </p>
      <p class="orderInfo ">{{ rest.city }}, {{ rest.state }} {{ rest.zip }}</p>
      <p class="orderInfo ">
        {{ rest.telephone }}
      </p>
      <!-- <a href="/locations">Change Location</a> -->
    </div>
  </ng-container>
</ng-container>
