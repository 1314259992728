import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'lib-max-pizza-modal',
  templateUrl: './max-pizza-modal.component.html',
  styles: []
})
export class MaxPizzaModalComponent {
  @Output() continue = new EventEmitter<any>();
  @Output() xClicked = new EventEmitter<any>();
  @Output() deliveryOutOfRange = new EventEmitter<any>();
  constructor() {}

  xClick() {
    this.xClicked.emit();
  }
}
