<div class="container-fluider" *ngIf="app$ | async as app" style="padding-top: env(safe-area-inset-top, 0)">
  <lib-sign-in-form
    (leavePageEvent)="leavePage($event, modalContent)"
    (signInSubmitEvent)="submitFormData($event)"
    [isCheckout]="(from | async) === 'checkout'"
  >
  </lib-sign-in-form>
</div>

<ng-template #modalContent let-modal>
  <lib-reset-password-modal (xClicked)="modal.dismiss()"> </lib-reset-password-modal>
</ng-template>
