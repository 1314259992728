import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  /*
   * Environment variables
   *
   * TODO: Determine how we will store/interact with these values
   *
   * In previous projects that include a CMS, the values are stored there and retrieved
   * dynamically here using the Directus SDK.
   *
   * This service should handle requests to a proxy server, which will add OLO authorization to the headers
   * and proxy the request to OLO's API. Proxying requests is required by OLO.
   */
  domainAPI = 'https://changethisurl.com/api';
  constructor() {}

  /*
   * Retrieval methods
   */

  getDomainAPI() {
    return this.domainAPI;
  }

  // Get Headers
  getOLOHeaders() {
    return {
      headers: new HttpHeaders({
        Accept: 'application/json',
        'Content-Type': 'application/json'
      })
    };
  }
}
