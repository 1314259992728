import { Injectable } from '@angular/core';
import { Availability } from '../models/olo.availability';
import { BasketProduct } from '../models/olo.basketproduct';
import { Basket } from '../models/olo.basket';
import { BillingInfo } from '../models/olo.billinginfo';
import { Category } from '../models/olo.category';
import { OloMenu } from '../models/olo.menu';
import { Modifiers } from '../models/olo.modifiers';
import { OptionGroup } from '../models/olo.optiongroup';
import { Product } from '../models/olo.product';
import { Restaurant } from '../models/olo.restaurant';

@Injectable({
  providedIn: 'root'
})
export class ModelService {
  constructor() {}

  // Instantiation methods for models

  makeAvailability() {
    return new Availability();
  }

  makeBasket() {
    return new Basket();
  }

  makeBasketProduct() {
    return new BasketProduct();
  }

  makeBillingInfo() {
    return new BillingInfo();
  }

  makeCategory() {
    return new Category();
  }

  makeMenu() {
    return new OloMenu();
  }

  makeModifiers() {
    return new Modifiers();
  }

  makeOptionGroup() {
    return new OptionGroup();
  }

  makeProduct() {
    return new Product();
  }

  makeRestaurant() {
    return new Restaurant();
  }
}
