import { NgModule } from '@angular/core';
import { ProductService } from './product.service';
import { BasketService } from './basket.service';
import { RestaurantService } from './restaurant.service';
import { HttpClientModule } from '@angular/common/http';
import { EnvironmentService } from './environment.service';
import { ModelService } from './model.service';
import { DateTimeService } from '../models/DateTime';

@NgModule({
  declarations: [],
  imports: [HttpClientModule],
  providers: [ProductService, BasketService, RestaurantService, EnvironmentService, ModelService, DateTimeService],
  exports: []
})
export class ServicesModule {}
