import { NgModule } from '@angular/core';
import { IncrementorComponent } from './incrementor.component';
import { CommonModule } from '@angular/common';
import { IconModule } from '../icon/icon.module';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';

@NgModule({
  declarations: [IncrementorComponent],
  imports: [CommonModule, IconModule, SvgSpriteModule],
  exports: [IncrementorComponent]
})
export class IncrementorModule {}
