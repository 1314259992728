import { NgModule } from '@angular/core';
import { PillComponent } from './pill.component';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [PillComponent],
  imports: [SvgSpriteModule, CommonModule],
  exports: [PillComponent]
})
export class PillModule {}
