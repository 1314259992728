import { NgModule } from '@angular/core';
import { BagComponent } from './bag.component';
import { CommonModule } from '@angular/common';
import { OrderInfoModule } from '../order-info/order-info.module';
import { BagItemModule } from '../bag-item/bag-item.module';
import { ButtonModule } from '../button/button.module';
import { LinkModule } from '../link/link.module';
import { ServicesModule } from '../../providers/services.module';

@NgModule({
  declarations: [BagComponent],
  imports: [CommonModule, OrderInfoModule, BagItemModule, ButtonModule, LinkModule, ServicesModule],
  exports: [BagComponent]
})
export class BagModule {}
