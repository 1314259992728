import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { environment } from '@env/environment';
import { Capacitor } from '@capacitor/core';
import { ModeService } from './mode.service';
import { Device, DeviceId } from '@capacitor/device';
import { map } from 'rxjs/operators';
import { from } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class EnvironmentService {
  /*
   * Environment variables
   *
   * TODO: Determine how we will store/interact with these values
   *
   * In previous projects that include a CMS, the values are stored there and retrieved
   * dynamically here using the Directus SDK.
   *
   * This service should handle requests to a proxy server, which will add OLO authorization to the headers
   * and proxy the request to OLO's API. Proxying requests is required by OLO.
   */
  constructor(private mode: ModeService) {}

  /*
   * Retrieval methods
   */

  getHostname(): string {
    if (Capacitor.getPlatform() !== 'web') {
      return 'order.punchpizza.com';
    } else {
      return location.hostname;
    }
  }

  getPort(): string {
    let port = '';

    if (location.port) {
      port = ':' + location.port;
    }

    return port;
  }

  getDomainAPI(): string {
    let mode;
    switch (Capacitor.getPlatform()) {
      case 'ios':
        mode = '/api-ios';
        break;
      case 'android':
        mode = '/api-android';
        break;
      case 'web':
      // use default
      default:
        let apimode = this.mode.getStoredMode();
        if (apimode === 'kms') {
          mode = '/api-kiosk';
        } else if (apimode === 'cks') {
          mode = '/api-kiosk';
        } else {
          mode = '/api';
        }
    }
    let uri = 'https://dev01.punchpizza.chepri.com' + mode;
    if (environment.production) {
      uri = 'https://' + 'order.punchpizza.com' + this.getPort() + mode;
    }
    return uri;
  }

  // Get Headers
  getOLOHeaders() {
    return from(Device.getId()).pipe(
      map((res: DeviceId) => {
        return {
          headers: new HttpHeaders({
            Accept: 'application/json, text',
            'Content-Type': 'application/json',
            'X-Device-Id': res.identifier
          })
        };
      })
    );
  }
}
