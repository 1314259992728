import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { TrancloudProviderService } from '@app/datacap-trancloud/services/trancloud-provider.service';
import { TranCloudHttpService } from '@app/datacap-trancloud/services/trancloud-http.service';
import { TranCloudApiService } from '@app/datacap-trancloud/services/trancloud-api.service';
// import { BrandConfig } from '@app/datacap-trancloud/models/trancloud-config.interface';
import { TranCloudDeviceService } from '@app/datacap-trancloud/services/trancloud-device.service';
import { PinPadDeviceService } from '@app/datacap-trancloud/services/pin-pad-device.service';
import { TrancloudErrorLoggingService } from '@app/datacap-trancloud/services/trancloud-error-logging.service';

@NgModule({
  declarations: [],
  imports: [CommonModule],
  providers: [
    TranCloudApiService,
    TranCloudHttpService,
    TrancloudProviderService,
    TranCloudDeviceService,
    PinPadDeviceService,
    TrancloudErrorLoggingService,
    DecimalPipe
  ]
})
export class DatacapTrancloudModule {
  // static forRoot(config: BrandConfig): ModuleWithProviders<DatacapTrancloudModule> {
  //   // console.log(config);
  //   return {
  //     ngModule: DatacapTrancloudModule,
  //     providers: [TrancloudProviderService, { provide: 'config', useValue: config }]
  //   };
  // }
}
