<div class="popupShell">
  <a class="close" aria-label="Close" (click)="xClick()" style="text-align: right;">
    <div class="popup-x-modal">
      <lib-svg-sprite svgID="popup-x"></lib-svg-sprite>
    </div>
  </a>
  <div class="popupHeader">
    Max Capacity
  </div>
  <div class="deliveryDiv">
    <div>
      <p class="deliveryZoneFailText">
        You have hit the limit for the amount of this item you can add to your order. Feel free to add other items from
        our menu
      </p>
    </div>
  </div>
</div>
