<div class="popupShell">
  <a class="close" aria-label="Close" (click)="xClick()" style="text-align: right;"
    ><div class="popup-x-modal"><lib-svg-sprite svgID="popup-x"></lib-svg-sprite></div
  ></a>
  <div class="popupHeader">
    Enter Order Name
  </div>
  <p>Name your new favorite order.</p>
  <div class="d-flex flex-row">
    <div class="col">
      <form [formGroup]="orderNameForm">
        <lib-single-text-field
          class="searchBox"
          label="Favorite Order Name"
          inputType="text"
          controlName="orderName"
          [parentForm]="orderNameForm"
        ></lib-single-text-field>
      </form>
    </div>
  </div>
  <div class="deliveryZoneButtons">
    <lib-button id="checkoutContinueBtn" text="CONTINUE" (buttonClick)="saveOrderAsFavorite()"></lib-button>
  </div>
</div>
