<div class="formDiv px-3">
  <div class="container formCont">
    <form [formGroup]="signInForm" (ngSubmit)="submitForm()">
      <div *ngIf="errorMessage" class="d-flex flex-row">
        <div class="col-12">
          <p>{{ errorMessage }}</p>
        </div>
      </div>

      <div class="d-flex flex-row">
        <div class="col-12">
          <lib-single-text-field
            autoComplete="email"
            id="loginEmail"
            label="Email Address"
            controlName="emailAddress"
            [parentForm]="signInForm"
            inputType="email"
            [errorMessage]="
              signInForm.get('emailAddress').touched && signInForm.get('emailAddress').invalid
                ? 'Valid email required'
                : ''
            "
            inputMode="email"
          >
          </lib-single-text-field>
        </div>
      </div>

      <div class="d-flex flex-row">
        <div class="col-12">
          <lib-single-text-field
            autoComplete="current-password"
            id="loginPass"
            label="Password"
            inputType="password"
            controlName="password"
            [parentForm]="signInForm"
            [errorMessage]="
              signInForm.get('password').touched && signInForm.get('password').invalid ? 'Password required' : ''
            "
          >
          </lib-single-text-field>
        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="col">
          <label class="containerChk rowCheck">
            <input type="checkbox" formControlName="remember" style="margin-bottom: 10px" />
            <span class="checkmarkChk" style="top: 2px">
              <lib-svg-sprite svgID="checkmarkIcon"></lib-svg-sprite>
            </span>
            <p class="p-0 m-0 ms-2" style="font-size: 16px">Remember Me</p>
          </label>
        </div>
        <div class="col">
          <lib-link
            class="link"
            [text]="forgotPasswordText"
            (linkClick)="clickEvent('forgot-password')"
            style="float: right;"
          >
          </lib-link>
        </div>
      </div>

      <div class="d-flex flex-row firstButton">
        <div class="col-12">
          <lib-button
            id="loginSubmit"
            class="button"
            [text]="signInButtonText"
            buttonType="submit"
            large="true"
            [disabled]="!signInForm.valid"
          >
          </lib-button>
        </div>
      </div>
    </form>
    <div class="d-flex flex-row button-margin">
      <div class="col-12">
        <lib-button class="button" large="true" [text]="createAccountButtonText" (buttonClick)="clickEvent('sign-up')">
        </lib-button>
      </div>
    </div>

    <div *ngIf="isCheckout">
      <div class="d-flex flex-row orGroup">
        <div class="col-12">
          <lib-svg-sprite svgID="orGroup"></lib-svg-sprite>
        </div>
      </div>

      <div class="d-flex flex-row orGroup">
        <div class="col-12">
          <lib-link class="link guestLink" [text]="continueAsGuestText" (linkClick)="clickEvent('guest-auth')">
          </lib-link>
        </div>
      </div>
    </div>
  </div>
</div>
