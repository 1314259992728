<div class="kiosk-conf" *ngIf="basket$ | async as basket">
  <ng-container *ngIf="basket.previousBasket">
    <div class="conf-heading d-flex flex-column justify-content-center align-items-center">
      <div class="d-flex ">
        <h2 color="primary">
          {{ basket.previousBasket.deliverymode === 'dinein' ? 'Dine-in' : 'Takeout' }} Order Placed
        </h2>
      </div>
      <div class="d-flex flex-row py-2">
        Your {{ basket.previousBasket.deliverymode === 'dinein' ? 'dine-in' : 'takeout' }} order has been placed for
      </div>
      <div class="d-flex flex-row bold f-18 py-2 name">
        {{ basket.billingInfo.firstname }} {{ basket.billingInfo.lastname }}
      </div>
      <div class="d-flex flex-row py-2">
        Order will be ready by
        {{
          basket.previousBasket.deliverymode === 'dinein'
            ? getTime(basket.manualFireTime)
            : getTime(basket.previousBasket.readytime)
        }}.
      </div>
      <div class="kiosk-conf-divider"></div>
    </div>
  </ng-container>
  <div class="payment-info">
    <!---
    <div class="d-flex flex-row py-2">
      <div class="col text-start">
        [PAYMENT 1]
      </div>
      <div class="col text-end">
        -[$00.00]
      </div>
    </div>
    <div class="d-flex flex-row py-2">
      <div class="col text-start">
        [PAYMENT 2]
      </div>
      <div class="col text-end">
        -[$00.00]
      </div>
    </div>
  -->
    <ng-container *ngIf="basket.previousBasket; else showLoader">
      <div class="d-flex flex-row py-2">
        <div class="col text-start">
          Subtotal
        </div>
        <div class="col text-end">
          {{ basket.previousBasket.subtotal | currency }}
        </div>
      </div>
      <div
        class="d-flex flex-row py-2"
        *ngIf="basket.previousBasket.discounts && basket.previousBasket.discounts.length > 0"
      >
        <div class="col text-start">
          Discount
        </div>
        <div class="col text-end">-{{ basket.previousBasket.discounts[0].amount | currency }}</div>
      </div>
      <div class="d-flex flex-row py-2">
        <div class="col text-start">
          Tax
        </div>
        <div class="col text-end">
          {{ basket.previousBasket.salestax | currency }}
        </div>
      </div>
      <div class="d-flex flex-row py-2">
        <div class="col text-start">
          Total
        </div>
        <div class="col text-end bold">
          {{ basket.previousBasket.total | currency }}
        </div>
      </div>
      <ng-container *ngIf="basket.change">
        <div class="d-flex flex-row py-2">
          <div class="col text-start">
            Change Due
          </div>
          <div class="col text-end">
            {{ basket.change | currency }}
          </div>
        </div>
      </ng-container>
    </ng-container>

    <ng-template #showLoader>
      <div class="d-flex flex-row">
        <div class="col">
          <div class="spin-loader-heat-map"></div>
        </div>
      </div>
    </ng-template>

    <div class="pt-4 px-3">
      <button class="btn btn-primary text-white largeButton py-2" (click)="startNewOrder()">Start New Order</button>
    </div>
  </div>

  <div id="canvasFrame" style="display:none">
    <canvas id="canvasPaper" width="576" height="640"></canvas>
  </div>
</div>
