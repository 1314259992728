<div class="modal-wrapper justify-content-center">
  <i class="fas fa-times x-close" (click)="close()"></i>
  <h1 class="modal-title text-center text-uppercase fw-bold">{{ title }}</h1>

  <div class="d-flex flex-column text-center justify-content-center pt-4">
    {{ actionText }}
    <div class="d-flex flex-row justify-content-center align-item-center">
      <a class="bold-modal-txt text-uppercase me-2">{{ guestName }}</a>
      <span class="me-2">party of</span>
      <a class="bold-modal-txt">{{ partySize }}</a>
    </div>
    <span>at</span>
    <div class="d-flex flex-row justify-content-center align-items-center">
      <a class="bold-modal-txt text-uppercase">{{ readyTime | ShortTimePipe | date: 'h:mm aaaaa' }}</a>
      <span>?</span>
    </div>
  </div>

  <div class="d-flex flex-column text-center align-items-center py-4">
    <div class="col bold-modal-txt mb-1 text-start">
      Enter Table #:
    </div>
    <div class="col-3 ps-0 align-top">
      <input
        class="form-control"
        [(ngModel)]="tableNumber"
        id="tableNum"
        placeholder=""
        type="number"
        inputmode="numeric"
      />
    </div>
  </div>

  <div class="d-flex flex-row pt-1">
    <div class="col me-3">
      <lib-button text="Return" ghost="true" (click)="close()"></lib-button>
    </div>
    <div class="col">
      <lib-button text="Confirm" [disabled]="!tableNumber" (click)="fireOrder()"></lib-button>
    </div>
  </div>
</div>
