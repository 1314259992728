import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { LocationsComponent } from './locations.component';

// Lib Dependencies
import { LocationListModule } from '../location-list/location-list.module';
import { LocationLandingModule } from '../location-landing/location-landing.module';
import { SearchBarModule } from '../search-bar/search-bar.module';
import { MapModule } from '../map/map.module';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';
import { DeliveryZoneFailModule } from '../delivery-zone-fail/delivery-zone-fail.module';

@NgModule({
  declarations: [LocationsComponent],
  imports: [
    CommonModule,
    SvgSpriteModule,
    MapModule,
    SearchBarModule,
    DeliveryZoneFailModule,
    LocationListModule,
    LocationLandingModule,
    MapModule
  ],
  exports: [LocationsComponent]
})
export class LocationsModule {}
