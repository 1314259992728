import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AddUpsellToBasket } from '@app/store/actions/basket.action';
import { Store } from '@ngxs/store';
import { ToastrService } from 'ngx-toastr';
import { OloUpsellGroup, OloUpsellItem } from '@app/models/olo-upsell';
import { RestaurantState } from '@app/store/state/restaurant.state';
import { environment } from '@env/environment';

@Component({
  selector: 'app-upsell-modal',
  templateUrl: './upsell-modal.component.html',
  styleUrls: ['./upsell-modal.component.scss']
})
export class UpsellModalComponent implements OnInit {
  @Output() xClicked = new EventEmitter<any>();
  @Input() groups: OloUpsellGroup[];
  isLoading = false;
  selectedProduct: number;

  constructor(private store: Store, private toastr: ToastrService) {}

  ngOnInit() {
    this.groups.map(group => group.items.map(item => this.setImage(item)));
  }

  setImage(item: OloUpsellItem) {
    const images = this.store.selectSnapshot(RestaurantState).images;
    const imagePath = images.data.find((obj: { name: string }) => obj.name === item.name);
    if (imagePath && imagePath.image) {
      item.imagePath = environment.cmsDomain.concat('assets/', imagePath.image.id);
    } else {
      item.imagePath = 'assets/images/default.png';
    }
    return item;
  }

  close() {
    this.xClicked.emit();
  }

  addToCart(productID: number) {
    if (this.isLoading) {
      return;
    }
    this.selectedProduct = productID;
    this.isLoading = true;
    const item = [{ id: productID, quantity: 1 }];
    this.store.dispatch(new AddUpsellToBasket(item)).subscribe(
      () => {
        this.toastr.success('Item added to your cart!');
        this.isLoading = false;
      },
      error => {
        this.toastr.error(error.error.message);
        this.isLoading = false;
      }
    );
  }
}
