import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FiredComponent } from './fired.component';
import { FiredRoutingModule } from './fired-routing.module';
import { SharedModule } from '@app/shared';

@NgModule({
  declarations: [FiredComponent],
  imports: [CommonModule, FiredRoutingModule, SharedModule]
})
export class FiredModule {}
