import { Component, Input } from '@angular/core';

@Component({
  selector: 'lib-cart-nav',
  templateUrl: './cart-nav.component.html',
  styles: []
})
export class CartNavComponent {
  @Input() buttons = true;
  @Input() basketCreated = true;
  @Input() productCount = 0;
  @Input() orderText = 'order';
  @Input() dropdownChildren = ['indivdual', 'group', 'catering'];

  constructor() {}

  clickEvent(event: any) {
    console.log(event);
    if (event && event < this.dropdownChildren.length && event >= 0) {
      this.basketCreated = true;
    }
  }
}
