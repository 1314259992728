import { Component, EventEmitter, Output } from '@angular/core';
import moment from 'moment/moment';

@Component({
  selector: 'app-dine-in-confirmation-modal',
  templateUrl: './dine-in-confirmation-modal.component.html',
  styleUrls: ['../customer-kiosk/customer-kiosk.component.scss']
})
export class DineInConfirmationModalComponent {
  @Output() xClicked = new EventEmitter<void>();
  @Output() confirmClicked = new EventEmitter<void>();

  close(): void {
    this.xClicked.emit();
  }

  confirm(): void {
    this.confirmClicked.emit();
  }
}
