import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AccessibilityToggleComponent } from './accessibility-toggle.component';
import { SvgSpriteModule } from '../../../../assets/chepri-modules/src/lib/svg-sprite/svg-sprite.module';

@NgModule({
  declarations: [AccessibilityToggleComponent],
  imports: [CommonModule, SvgSpriteModule],
  exports: [AccessibilityToggleComponent]
})
export class AccessibilityToggleModule {}
