<div class="kiosk-card p-3">
  <div class="d-flex flex-row align-middle">
    <!-- <div class="col-4 align-middle img-container">
      <img class="kiosk-card-img" src="{{ imagePath }}" [alt]="product.name" />
    </div> -->

    <div class="w-100">
      <div class="title-row">
        <div class="d-flex flex-row text-uppercase text-start f-16 bold">
          {{ product.name }}
        </div>
        <div class="d-flex flex-row">
          {{ product.cost ? (product.cost | currency) : '' }}
        </div>
      </div>
      <div class="d-flex flex-row pb-auto px-0 gap-3">
        <ng-container *ngIf="hasModifiers && product.cost > 0">
          <div class="w-100 ps-0">
            <button class="kiosk-btn customize-btn btn regularButton" (click)="emitCustomize()">
              <ng-template #custBtn1>Customize</ng-template>
              <div class="spin-loader-ghost" *ngIf="isLoading && loadingType === 'customize'; else custBtn1"></div>
            </button>
          </div>
          <div class="w-100 px-0">
            <button class="kiosk-btn btn regularButton kiosk-fill-btn" (click)="emitAddToBag()">
              <ng-template #quickBtn1>Quick Add</ng-template>
              <div class="spin-loader" *ngIf="isLoading && loadingType === 'quickadd'; else quickBtn1"></div>
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="!hasModifiers">
          <div class="w-100 p-0">
            <button class="kiosk-btn btn regularButton kiosk-fill-btn" (click)="emitAddToBag()">
              <ng-template #quickBtn2>Quick Add</ng-template>
              <div class="spin-loader" *ngIf="isLoading && loadingType === 'quickadd'; else quickBtn2"></div>
            </button>
          </div>
        </ng-container>
        <ng-container *ngIf="hasModifiers && product.cost === 0">
          <div class="w-100 ps-0">
            <button class="kiosk-btn customize-btn btn regularButton" (click)="emitCustomize()">
              <ng-template #custBtn2>Customize</ng-template>
              <div class="spin-loader-ghost" *ngIf="isLoading && loadingType === 'customize'; else custBtn2"></div>
            </button>
          </div>
        </ng-container>
      </div>
    </div>
  </div>
</div>
