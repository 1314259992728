<div class="container-fluider">
  <div class="d-flex flex-row">
    <div class="col-12 p-0" [formGroup]="form">
      <div *ngIf="mode !== 'cks'; else cksModifierSelector">
        <div class="desktop-modifiers">
          <h5 class="modifier-title bold" style="text-align: center;">
            {{ optionGroup.description === 'Customize' ? 'Additional Toppings' : optionGroup.description }}
            <span style="font-weight: 200">{{ optionGroup.maxselects === '1' ? ' (Select One)' : '' }}</span>
          </h5>
          <ng-container *ngIf="optionGroup.explanationtext">
            <p class="modifier-explanation text-center">{{ optionGroup.explanationtext }}</p>
          </ng-container>
          <div class="separator-product"></div>

          <div>
            <div *ngIf="oneChoice; else desktopCheckboxes" class="w-100">
              <lib-radio-button
                [form]="form"
                controlName="option"
                [values]="formVals"
                [text]="formText"
                [cost]="formCosts"
              >
              </lib-radio-button>
            </div>
            <ng-template #desktopCheckboxes>
              <div class="w-100 ps-0" style="width: 100%;" formArrayName="options">
                <lib-checkbox
                  [long]="optionGroup | longOptionGroup"
                  [form]="form"
                  [options]="options"
                  [text]="formText"
                  [cost]="formCosts"
                  [subModifiers]="subModifiers"
                  [selectedItems]="selectedItems"
                  [showEnzoItem]="showEnzoItem"
                  [enzoName]="enzoName"
                  [glutenFreeName]="glutenFreeName"
                  [values]="formVals"
                  (modifyEmit)="modifyClicked($event)"
                  [enzoSelected]="enzoSelected"
                  [optionGroup]="optionGroup"
                >
                </lib-checkbox>
              </div>
            </ng-template>
          </div>
        </div>

        <div class="mobile-modifiers">
          <div *ngIf="isChecked && mode !== 'kms'" (click)="sectionClicked()">
            <h5 class="modifier-title bold" style="text-align: center;">
              {{ optionGroup.description === 'Customize' ? 'Additional Toppings' : optionGroup.description }}
              <span style="font-weight: 200">{{ optionGroup.maxselects === '1' ? ' (Select One)' : '' }}</span>
              <label>
                <i class="fa fa-caret-down" aria-hidden="true" style="font-size: 24px; margin-left: 10px;">
                  <input [checked]="isChecked" type="button" class="hidden" />
                </i>
              </label>
            </h5>
          </div>

          <div *ngIf="!isChecked && mode !== 'kms'" (click)="sectionUnclicked()">
            <div class="modifier-open">
              <h5 class="modifier-title bold" style="text-align: center;">
                {{ optionGroup.description === 'Customize' ? 'Additional Toppings' : optionGroup.description }}
                <span style="font-weight: 200">{{ optionGroup.maxselects === '1' ? ' (Select One)' : '' }}</span>
                <label>
                  <i class="fa fa-caret-up" aria-hidden="true" style="font-size: 24px; margin-left: 10px;">
                    <input [checked]="isChecked" type="button" class="hidden" />
                  </i>
                </label>
              </h5>
            </div>
          </div>

          <div *ngIf="isChecked && mode === 'kms'" (click)="sectionClicked()">
            <div class="modifier-open">
              <h5 class="modifier-title bold" style="text-align: center;">
                {{ optionGroup.description === 'Customize' ? 'Additional Toppings' : optionGroup.description }}
                <span style="font-weight: 200">{{ optionGroup.maxselects === '1' ? ' (Select One)' : '' }}</span>
                <label>
                  <i class="fa fa-caret-up" aria-hidden="true" style="font-size: 24px; margin-left: 10px;">
                    <input [checked]="isChecked" type="button" class="hidden" />
                  </i>
                </label>
              </h5>
            </div>
          </div>

          <div *ngIf="!isChecked && mode === 'kms'" (click)="sectionUnclicked()">
            <h5 class="modifier-title bold" style="text-align: center;">
              {{ optionGroup.description === 'Customize' ? 'Additional Toppings' : optionGroup.description }}
              <span style="font-weight: 200">{{ optionGroup.maxselects === '1' ? ' (Select One)' : '' }}</span>
              <label>
                <i class="fa fa-caret-down" aria-hidden="true" style="font-size: 24px; margin-left: 10px;">
                  <input [checked]="isChecked" type="button" class="hidden" />
                </i>
              </label>
            </h5>
          </div>

          <div class="separator-product"></div>
          <ng-container *ngIf="optionGroup.explanationtext && !isChecked">
            <p class="modifier-explanation mt-3 mb-0 bold text-center">{{ optionGroup.explanationtext }}</p>
          </ng-container>

          <div *ngIf="mode !== 'kms'">
            <div *ngIf="oneChoice; else checkboxes" class="w-100" [@slideInOut]="isChecked">
              <ng-container *ngIf="!isChecked">
                <lib-radio-button
                  [form]="form"
                  controlName="option"
                  [values]="formVals"
                  [text]="formText"
                  [cost]="formCosts"
                >
                </lib-radio-button>
              </ng-container>
            </div>
            <ng-template #checkboxes>
              <div class="ps-0" style="width: 100%;" formArrayName="options" [@slideInOut]="isChecked">
                <ng-container *ngIf="!isChecked">
                  <lib-checkbox
                    [long]="optionGroup | longOptionGroup"
                    [form]="form"
                    [options]="options"
                    [text]="formText"
                    [cost]="formCosts"
                    [values]="formVals"
                    [subModifiers]="subModifiers"
                    [selectedItems]="selectedItems"
                    (modifyEmit)="modifyClicked($event)"
                    [mode]="mode"
                    [showEnzoItem]="showEnzoItem"
                    [enzoName]="enzoName"
                    [glutenFreeName]="glutenFreeName"
                    [enzoSelected]="enzoSelected"
                    [optionGroup]="optionGroup"
                  >
                  </lib-checkbox>
                </ng-container>
              </div>
            </ng-template>
          </div>

          <div *ngIf="mode === 'kms'">
            <div *ngIf="oneChoice; else checkboxes" class="w-100" [@slideInOut]="isChecked">
              <ng-container *ngIf="isChecked">
                <lib-radio-button
                  [form]="form"
                  controlName="option"
                  [values]="formVals"
                  [text]="formText"
                  [cost]="formCosts"
                >
                </lib-radio-button>
              </ng-container>
            </div>
            <ng-template #checkboxes>
              <div class="w-100" formArrayName="options" [@slideInOut]="isChecked">
                <ng-container *ngIf="isChecked">
                  <lib-checkbox
                    [long]="optionGroup | longOptionGroup"
                    [form]="form"
                    [options]="options"
                    [text]="formText"
                    [cost]="formCosts"
                    [values]="formVals"
                    [subModifiers]="subModifiers"
                    [selectedItems]="selectedItems"
                    (modifyEmit)="modifyClicked($event)"
                    [mode]="mode"
                    [showEnzoItem]="showEnzoItem"
                    [enzoName]="enzoName"
                    [glutenFreeName]="glutenFreeName"
                    [enzoSelected]="enzoSelected"
                    [optionGroup]="optionGroup"
                  >
                  </lib-checkbox>
                </ng-container>
              </div>
            </ng-template>
          </div>
        </div>
      </div>
      <ng-template #cksModifierSelector>
        <lib-kiosk-modifier-list
          [listType]="oneChoice ? 'radio' : 'checkbox'"
          [long]="optionGroup | longOptionGroup"
          [form]="form"
          [options]="options"
          [text]="formText"
          [cost]="formCosts"
          [values]="formVals"
          [subModifiers]="subModifiers"
          [selectedItems]="selectedItems"
          (modifyEmit)="modifyClicked($event)"
          [mode]="mode"
          [showEnzoItem]="showEnzoItem"
          [enzoName]="enzoName"
          [glutenFreeName]="glutenFreeName"
          [optionGroup]="optionGroup"
        >
        </lib-kiosk-modifier-list>
      </ng-template>
    </div>
  </div>

  <ng-container *ngIf="recursive">
    <ng-container *ngFor="let opt of optionGroup?.options">
      <ng-container
        *ngIf="
          optionInSelected(opt.id) &&
          opt.modifiers &&
          opt.name !== 'Included Ingredients' &&
          opt.name !== 'Additional Ingredients'
        "
      >
        <ng-container *ngFor="let group of opt.modifiers">
          <lib-modifier-selector
            [optionGroup]="group"
            [recursive]="true"
            [glutenFreeName]="glutenFreeName"
            [showEnzoItem]="showEnzoItem"
            [enzoName]="enzoName"
          >
          </lib-modifier-selector>
        </ng-container>
      </ng-container>
    </ng-container>
  </ng-container>

  <ng-template class="pup" #content let-modal>
    <div class="popupShell">
      <a class="close" aria-label="Close" (click)="modal.dismiss()" style="text-align: right;">
        <div class="popup-x-modal" style="cursor: pointer">
          <lib-svg-sprite svgID="popup-x"></lib-svg-sprite>
        </div>
      </a>
      <div class="popupHeader">
        Modify Ingredient
      </div>
      <div class="d-flex flex-row justify-content-center" style="width: 100%; margin-top: 30px; padding: 0 15px;">
        <div
          class="col modifier-tabs"
          [ngClass]="{
            'modifier-tabs': mode !== 'cks',
            'modifier-tabs-kiosk': mode === 'cks',
            'left-modifer-item': i === 0,
            'right-modifier-item': i === editSelectedGroup.options.length - 1,
            'selected-submodifier': i === selectedSubModifier
          }"
          *ngFor="let group of editSelectedGroup.options; index as i"
          (click)="addCustomization(i)"
        >
          <div [class.modifier-text-kiosk]="mode === 'cks'">
            {{ group.name.split(' ')[0] }} {{ group.name.includes('(Cooked)') ? '(Cooked)' : '' }}
            {{ group.name.includes('(Fresh)') ? '(Fresh)' : '' }}
          </div>
        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="col">
          <button
            class="btn btn-primary text-white"
            style="width: 100%; margin-top: 40px; margin-bottom: 10px"
            (click)="submitCustomization()"
          >
            SAVE CHANGES
          </button>
        </div>
      </div>
    </div>
  </ng-template>
</div>
