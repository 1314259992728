import { Component, Input, Output, EventEmitter } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';

@Component({
  selector: 'lib-single-text-field',
  templateUrl: 'single-text-field.component.html',
  styles: []
})
export class SingleTextFieldComponent {
  @Input() couponCode: boolean;
  @Input() hasLabel: boolean;
  @Input() placeholder: string;
  @Input() type: string;
  @Input() label: string;
  @Input() inputType = 'text';
  @Input() inputMask: 'A*';
  @Input() parentForm: UntypedFormGroup;
  @Input() controlName: string;
  @Input() errorMessage = '';
  @Input() inputMode: 'text' | 'decimal' | 'numeric' | 'tel' | 'search' | 'email' | 'url' = 'text';
  @Input() bottomText: string;
  @Input() autoComplete = '';

  @Output() changeInputEvent = new EventEmitter<any>();
  @Output() blurClicked = new EventEmitter<any>();
  @Output() focusClicked = new EventEmitter<any>();

  changeInput(input: any) {
    this.changeInputEvent.emit(input);
  }

  blurClick() {
    this.blurClicked.emit();
  }

  focusClick() {
    this.focusClicked.emit();
  }
}
