import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { AccountRoutingModule } from './account-routing.module';
import { AccountComponent } from './account.component';
import { SvgSpriteModule } from 'src/assets/chepri-modules/src/lib/svg-sprite/svg-sprite.module';
import { AccountDetailsModule } from 'src/assets/chepri-modules/src/lib/account-details/account-details.module';
import { PastOrdersModule } from 'src/assets/chepri-modules/src/lib/past-orders/past-orders.module';
import { FavoriteOrdersModule } from 'src/assets/chepri-modules/src/lib/favorite-orders/favorite-orders.module';
import { SavedCardsModule } from 'src/assets/chepri-modules/src/lib/saved-cards/saved-cards.module';

import { GuestFormModule } from 'src/assets/chepri-modules/src/lib/guest-form/guest-form.module';

@NgModule({
  declarations: [AccountComponent],
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    Angulartics2Module,
    AccountRoutingModule,
    GuestFormModule,
    SvgSpriteModule,
    AccountDetailsModule,
    PastOrdersModule,
    FavoriteOrdersModule,
    SavedCardsModule
  ]
})
export class AccountPageModule {}
