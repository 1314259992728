import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { extract } from '@app/core';
import { Shell } from '@app/components/shell/shell.service';
import { ConfirmationComponent } from '@app/components/customer-kiosk/confirmation/confirmation.component';

const routes: Routes = [
  Shell.childRoutes([
    {
      path: 'cks/confirmation',
      component: ConfirmationComponent,
      data: { title: extract('Order Confirmation') }
      // canActivate: [KMSGuard]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class ConfirmationRoutingModule {}
