import { Component, Input, Output, OnInit, EventEmitter } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup, AbstractControl } from '@angular/forms';

@Component({
  selector: 'lib-guest-form',
  templateUrl: './guest-form.component.html',
  styles: []
})
export class GuestFormComponent implements OnInit {
  @Input() isCheckout = false;
  @Input() createAccountOptInText = 'Use this info to create an account';
  @Input() continueButtonText = 'CONTINUE';
  @Input() signInButtonText = 'SIGN IN';
  @Input() createAccountText = 'NO ACCOUNT? SIGN UP!';
  @Input() signInText = 'ALREADY HAVE AN ACCOUNT? SIGN IN!';
  @Output() checkoutAuthSubmit = new EventEmitter<UntypedFormGroup>();
  @Output() leavePage = new EventEmitter<string>();

  guestForm: UntypedFormGroup;
  errorMessage = '';
  constructor(private fb: UntypedFormBuilder) {}

  ngOnInit() {
    this.guestForm = this.fb.group({
      firstName: ['', Validators.required],
      lastName: ['', Validators.required],
      emailAddress: ['', [Validators.required, Validators.email]],
      phoneNumber: ['', Validators.required],
      passwords: this.fb.group(
        // Reactivate validators after client demo
        {
          // password: ['', Validators.required],
          password: [''],
          // confirm: ['', Validators.required]
          confirm: ['']
        },
        { validator: this.passwordConfirming }
      ),
      guestOptIn: [false]
    });
    // this.guestForm.valueChanges.subscribe(newVal => console.log(newVal));
  }

  clickEvent(identifier?: string) {
    this.leavePage.emit(identifier);
    // console.log('Navigate Away From Page: '.concat(identifier ? identifier : 'no id'));
  }

  submitEvent(form?: UntypedFormGroup) {
    this.checkoutAuthSubmit.emit(this.guestForm);
    // console.log('Checkout Auth Submit: ', this.guestForm);
  }

  passwordConfirming(formGroup: AbstractControl): { invalid: boolean } {
    if (formGroup.get('password').value !== formGroup.get('confirm').value) {
      if (formGroup.get('password').touched && formGroup.get('password').touched) {
      }
      return { invalid: true };
    }
  }
}
