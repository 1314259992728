import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { extract } from '@app/core';
import { OrderDetailsComponent } from './order-details.component';
import { Shell } from '@app/components/shell/shell.service';
import { GuestGuard } from '@app/core/authentication/guest.guard';

const routes: Routes = [
  Shell.childRoutes([
    { path: '', redirectTo: '/order-details', pathMatch: 'full' },
    {
      path: 'order-details',
      component: OrderDetailsComponent,
      data: { title: extract('OrderDetails') },
      canActivate: [GuestGuard]
    }
  ])
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
  providers: []
})
export class OrderDetailsRoutingModule {}
