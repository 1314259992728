<div class="container-fluider" style="padding-top: env(safe-area-inset-top, 0)">
  <div class="expo-form">
    <div class="expo-container kioskCont">
      <div class="text-center">
        <h2 class="mb-0" id="signInHeader">Kiosk Sign-In</h2>
        <p class="f-20 mb-0">
          Please sign in to the kiosk admin account <br />
          to access the kiosk.
        </p>
      </div>

      <form [formGroup]="expoKioskLoginForm">
        <div class="pt-4 px-4">
          <div class="d-flex flex-row">
            <div class="col">
              <p class="p-0 m-0 fs-16">Email Address</p>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="col-12">
              <lib-single-text-field
                id="loginEmail"
                label="Email Address"
                controlName="emailAddress"
                autoComplete="email"
                [parentForm]="expoKioskLoginForm"
                inputType="email"
                [errorMessage]="
                  expoKioskLoginForm.get('emailAddress').touched && expoKioskLoginForm.get('emailAddress').invalid
                    ? 'Valid email required'
                    : ''
                "
                (changeInputEvent)="inputChanged()"
              >
              </lib-single-text-field>
            </div>
          </div>

          <div class="d-flex flex-row">
            <div class="col">
              <p class="p-0 m-0 fs-16">Password</p>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="col-12">
              <lib-single-text-field
                id="loginPass"
                label="Password"
                inputType="password"
                controlName="password"
                autoComplete="current-password"
                [parentForm]="expoKioskLoginForm"
                [errorMessage]="
                  expoKioskLoginForm.get('password').touched && expoKioskLoginForm.get('password').invalid
                    ? 'Password required'
                    : ''
                "
                (changeInputEvent)="inputChanged()"
              >
              </lib-single-text-field>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="col">
              <p class="p-0 m-0 fs-16">Kiosk ID</p>
            </div>
          </div>
          <div class="d-flex flex-row">
            <div class="col-12">
              <lib-single-text-field
                id="loginKiosk"
                label="Kiosk ID"
                controlName="kioskID"
                [parentForm]="expoKioskLoginForm"
                inputType="text"
                [errorMessage]="
                  expoKioskLoginForm.get('kioskID').touched && expoKioskLoginForm.get('kioskID').invalid
                    ? 'Kiosk ID'
                    : ''
                "
                (changeInputEvent)="inputChanged()"
              >
              </lib-single-text-field>
            </div>
          </div>

          <div class="d-flex flex-row pt-2">
            <div class="col-12">
              <lib-button
                id="loginSubmit"
                class="button expo-button"
                text="Sign In"
                large="true"
                [disabled]="!expoKioskLoginForm.valid"
                (click)="login()"
                [isLoading]="isLoading"
              >
              </lib-button>
            </div>
          </div>
          <p *ngIf="errorMessage" class="m-0 errorMessage" style="padding: 0 15px">* {{ errorMessage }}</p>
        </div>
      </form>
    </div>
  </div>
</div>
