import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { BasketProduct, BasketChoice } from '../../models/olo.basketproduct';
import { Router } from '@angular/router';
import { Store } from '@ngxs/store';
import { GetProduct } from '@app/store/actions/product.action';
import { BasketService } from '../../../../../app/providers/basket.service';
import { ProductService } from '../../../../../app/providers/product.service';

@Component({
  selector: 'lib-bag-item',
  templateUrl: './bag-item.component.html',
  styles: []
})
export class BagItemComponent implements OnInit {
  @Input() product: BasketProduct;
  @Input() isCheckoutBag = false;
  @Output() editClicked = new EventEmitter<any>();
  @Output() updateProduct = new EventEmitter<any>();

  hasModifiers = false;

  constructor(public router: Router, private store: Store, private productService: ProductService) {}

  ngOnInit() {
    // if (this.isCheckoutBag === true) {
    //   this.updateQuant(1);
    // }
    if (!this.isCheckoutBag) {
      this.productService.getProductOptions(this.product.productId).subscribe(res => {
        if (res.optiongroups.length > 0) {
          this.hasModifiers = true;
        }
      });
    }
  }

  editClick() {
    this.router.navigate(['/product/' + this.product.productId], {
      queryParams: { edit: true, basketProd: this.product.id },
      replaceUrl: true
    });
    this.editClicked.emit();
  }

  updateQuant(newQuant: number) {
    // // This works with product service but not really with State due to readonly values
    // // The correct way to update the value is to actually update it with OLO and patch the state
    // let productCost = this.product.basecost;
    // for (const choice of this.product.choices) {
    //   productCost += choice.cost * choice.quantity;
    // }
    // this.product.quantity = newQuant;
    // this.product.totalcost = newQuant * productCost;
    // this.updateProduct.emit({ quant: newQuant, cost: this.product.totalcost });

    // Updating quantity with OLO and patching state
    const newProd: BasketProduct = { ...this.product };
    newProd.quantity = newQuant;
    this.updateProduct.emit(newProd);
  }

  cleanChoices(choices: BasketChoice[]) {
    return choices.filter(choice => {
      if (choice.name.toLowerCase().includes('included') || choice.name.toLowerCase().includes('additional toppings')) {
        return false;
      }
      return true;
    });
  }

  getMax(product: BasketProduct): number {
    return 6;
  }
}
