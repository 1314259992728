import { BasketProductToAdd } from 'src/assets/chepri-modules/src/models/olo.basketproduct';
import { BillingInfo } from 'src/assets/chepri-modules/src/models/olo.billinginfo';
import { FavoritesOrderPayloadModel } from '../../../assets/chepri-modules/src/lib/favorite-order-name/favorites-order.model';
import { DateTime } from 'src/assets/chepri-modules/src/models/DateTime';
import { SelectableTime } from '@app/models/capacity.model';
import { ResponseRetrieveSavedProductsSavedProduct } from '@app/models/saved-products.olo';
import { MetaData } from '@lib/models/olo.metadata';

export class CreateBasket {
  static readonly type = '[Basket] Create Basket';
  constructor(public locationId: string, public authToken?: string) {}
}

export class GetBasket {
  static readonly type = '[Basket] Get Basket';
  constructor(public payload?: any) {}
}

export class GetTimeSlots {
  static readonly type = '[Basket] Get Available Time Slots';
  constructor(public isFirstLoad: boolean, public deliveryMode?: string) {}
}

export class SetBuckets {
  static readonly type = '[Basket] Set Capacity Buckets Selected';
  constructor(public buckets: SelectableTime[], public isManualFire?: boolean) {}
}

export class CreateFromOrder {
  static readonly type = '[Basket] Create From Order';
  constructor(public orderRef: string, public id: string, public token: string) {}
}
export class CreateFromFaves {
  static readonly type = '[Basket] Create From Faves';
  constructor(public payload: FavoritesOrderPayloadModel) {}
}
export class SetHandoffMode {
  static readonly type = '[Basket] Set Basket Handoff Mode';
  constructor(public payload: any) {}
}

export class SetDispatchAddress {
  static readonly type = '[Basket] Set Dispatch Address';
  constructor(public addressInfo: any, public basketId: string) {}
}

export class AddProductToBasket {
  static readonly type = '[Basket] Add Product';
  constructor(public product: BasketProductToAdd, public metadata?: any) {}
}

export class AddSavedProductToBasket {
  static readonly type = '[Basket] Add Saved Product';
  constructor(
    public product: ResponseRetrieveSavedProductsSavedProduct,
    public productMenuID: number,
    public metadata?: MetaData[]
  ) {}
}
export class RemoveProductFromBasket {
  static readonly type = '[Basket] Remove Product';
  constructor(public payload: string) {}
}

export class AddToCategoryLimit {
  static readonly type = '[Basket] Add To Category Limit';
  constructor(public categoryId: number, public productId: number, public quant?: number) {}
}

export class AddReorderToLimit {
  static readonly type = '[Basket] Add Reorder to Limit';
  constructor(public reorderMetaData: any) {}
}

export class RemoveFromCategoryLimit {
  static readonly type = '[Basket] Remove From Category Limit';
  constructor(public categoryId: number, public productId: number, public quant?: number) {}
}

export class UpdateSingleProductInBasket {
  static readonly type = '[Basket] Update Product';
  constructor(public basketProductId: string, public body: any, public isIncrementing: boolean) {}
}

export class SetTimeWanted {
  static readonly type = '[Basket] Update Time Wanted';
  constructor(public dateTime: DateTime, public isManualFire?: boolean) {}
}

export class SetTimeWantedToAsap {
  static readonly type = '[Basket] Update Time Wanted To Asap';
  constructor() {}
}

export class SetBillingInfo {
  static readonly type = '[Basket] Set Billing Info';
  constructor(public billingInfo: BillingInfo) {}
}

export class SetTipAmount {
  static readonly type = '[Basket] Update Tip Amount';

  constructor(public payload: string) {}
}

export class GetBillingSchemes {
  static readonly type = '[Basket] Get Billing Schemes';
  constructor() {}
}

export class ValidateBasket {
  static readonly type = '[Basket] Validate Basket';
  constructor(public checkUpsell?: string, public isCheckout?: boolean) {}
}

export class SubmitBasket {
  static readonly type = '[Basket] Submit Basket';
  constructor(public payload?: any) {}
}

export class SubmitTrancloudBasket {
  static readonly type = '[Basket] Submit Trancloud Basket';
  constructor(public payload?: any) {}
}

export class SubmitBasketMultiPay {
  static readonly type = '[Basket] Submit Basket with Multi Payment';
  constructor(
    public payload: any,
    public cardNumber: string,
    public cardType: string,
    public authCode: string,
    public refNo: string,
    public recordNo: string,
    public tableNumber: string
  ) {}
}

export class EmptyBasket {
  static readonly type = '[Basket] Empty Basket';
  constructor() {}
}
export class EmptyPreviousBasket {
  static readonly type = '[Basket] Empty Previous Basket';
}
export class EmptyValidation {
  static readonly type = '[Basket] Empty Validation';
}

export class EmptyLimits {
  static readonly type = '[Basket] Clear Limits';
  constructor() {}
}

export class ApplyCoupon {
  static readonly type = '[Basket] Apply Coupon';
  constructor(public payload: any) {}
}

export class RemoveCoupon {
  static readonly type = '[Basket] Remove Coupon';
  constructor() {}
}

export class ApplyGiftCard {
  static readonly type = '[Basket] Apply Gift Card';
  constructor(public payload: any) {}
}

export class CheckBalance {
  static readonly type = '[Basket] Check Balance';
  constructor(public payload: any) {}
}
export class CleanGiftCard {
  static readonly type = '[Basket] Clean Gift Card';
  constructor() {}
}

export class SetIncrementor {
  static readonly type = '[Basket] Set Incrementor';
  constructor(public payload: any) {}
}

export class TransferBasket {
  static readonly type = '[Basket] Transfer Basket';
  constructor(public vendorId: number) {}
}

export class RemoveGiftCard {
  static readonly type = '[Basket] Remove Gift Card';
  constructor(public payload: number) {}
}

export class SaveExtraCreditCard {
  static readonly type = '[Basket] Save extra card';
  constructor(public payload: any) {}
}

export class AddUpsellToBasket {
  static readonly type = '[Basket] Add Upsell To Basket';
  constructor(public items: any[]) {}
}

export class AddManualFireTime {
  static readonly type = '[Basket] Add Manual Fire Time';
  constructor(public payload: any) {}
}

export class SetChange {
  static readonly type = '[Basket] Set Change';
  constructor(public change: number) {}
}

export class ClearChange {
  static readonly type = '[Basket] Clear Change';
  constructor() {}
}

export class ClearExtraCard {
  static readonly type = '[Basket] Clear ExtraCard';
  constructor() {}
}

export class StoreTrancloudCardData {
  static readonly type = '[Basket] Store Trancloud Card Data';

  constructor(public cardNumber: string, public cardType: string, public authCode: string, public refNo: string) {}
}

export class ClearTrancloudCardData {
  static readonly type = '[Basket] Clear Trancloud Card Data';
  constructor() {}
}

export class SetOrderPlaceholder {
  static readonly type = '[Basket] Set Order Placeholder';
  constructor() {}
}

export class RemoveOrderPlaceholder {
  static readonly type = '[Basket] Remove Order Placeholder';
  constructor() {}
}

export class SetCustomField {
  static readonly type = '[Basket] Set CustomField';
  constructor(public customFieldID: number, public value: string) {}
}

export class SetReferralToken {
  static readonly type = '[Basket] Set Token';
  constructor() {}
}
