<div *ngIf="(user$ | async).info as info" class="d-flex flex-column gap-3 px-3">
  <div class="col-lg-8 col-md-12 accountDetails">
    <div class="accountDetailsInnerInfo">
      <div class="accountDetailsHeader">Edit Your Account Information</div>
      <div class="accountDetailsLine"></div>
      <div class="accountDetailsUserName">{{ info.firstname }} {{ info.lastname }}</div>
      <div class="accountDetailsEmail">
        {{ info.emailaddress }}
        <a (click)="editEmailCollapsed = !editEmailCollapsed" class="accountEditEmail clickable">{{
          editEmailCollapsed ? 'Edit Account' : 'Cancel'
        }}</a>
      </div>
      <div class="mt-2" id="collapseEditEmail" [ngbCollapse]="editEmailCollapsed">
        <form [formGroup]="editEmailForm" (ngSubmit)="updateEmail()">
          <lib-single-text-field
            inputType="text"
            label="New Email"
            [parentForm]="editEmailForm"
            controlName="newEmail"
            inputMode="email"
          >
          </lib-single-text-field>
          <lib-single-text-field
            inputType="text"
            label="First Name"
            [parentForm]="editEmailForm"
            controlName="newFirstName"
            inputMode="text"
          >
          </lib-single-text-field>
          <lib-single-text-field
            inputType="text"
            label="Last Name"
            [parentForm]="editEmailForm"
            controlName="newLastName"
            inputMode="email"
          >
          </lib-single-text-field>
          <div class="text-start">
            <div class="form-check p-0 pb-4">
              <input type="checkbox" formControlName="newEmailOptIn" class="bg-primary" /><label
                style="margin: 0px 20px; vertical-align: text-bottom;"
                >Send me special deals via email!</label
              >
              <br />
              <small class="text-emphasis"
                ><em
                  >We will never share your contact information or use for anything else. You can unsubscribe at any
                  time.</em
                ></small
              >
            </div>
          </div>
          <lib-button
            text="Update Account"
            buttonType="submit"
            class="button"
            large="true"
            [disabled]="!editEmailForm.valid"
          ></lib-button>
        </form>
      </div>
      <div class="accountResetPass">
        <a
          class="clickable"
          (click)="passwordChangeCollapsed = !passwordChangeCollapsed"
          [attr.aria-expanded]="!passwordChangeCollapsed"
          aria-controls="collapsePasswordChange"
        >
          {{ passwordChangeCollapsed ? 'Change Your Password?' : 'Cancel' }}
        </a>
      </div>
      <div class="mt-2" id="collapsePasswordChange" [ngbCollapse]="passwordChangeCollapsed">
        <form [formGroup]="changePasswordForm" (ngSubmit)="updatePassword()">
          <lib-single-text-field
            inputType="password"
            label="Current Password"
            [parentForm]="changePasswordForm"
            controlName="currentPassword"
          >
          </lib-single-text-field>
          <lib-single-text-field
            inputType="password"
            label="New Password"
            [parentForm]="changePasswordForm.get('newPasswords')"
            controlName="password"
          ></lib-single-text-field>
          <lib-single-text-field
            inputType="password"
            label="Repeat New Password"
            [parentForm]="changePasswordForm.get('newPasswords')"
            controlName="confirm"
          ></lib-single-text-field>
          <lib-button
            text="Update Password"
            buttonType="submit"
            class="button"
            large="true"
            [disabled]="!changePasswordForm.valid"
          ></lib-button>
        </form>
      </div>
      <div class="accountResetPass">
        <small class="clickable text-muted" (click)="openDeleteAccountModal()">Delete Account </small>
      </div>
    </div>
  </div>
  <div class="accountDetailsBottomBorder"></div>
  <div class="col-lg-4 col-md-0"></div>
</div>
