<div class="container-fluid locationsList" style="margin: 0px;">
  <div class="mt-3 d-flex flex-row individualLocationSection">
    <div class="col p-0">
      <lib-location-info [locationMobile]="true" [location]="location"></lib-location-info>
    </div>
    <div class="col rightMobile" style="text-align: right; padding-right: 0px;">
      <div *ngIf="distance >= 0">{{ distance }} miles away</div>
      <br />
      <div *ngIf="(restaurant$ | async).calendar as calendar">
        {{ calendar }}
      </div>
    </div>
    <!-- <lib-svg-sprite style="width: 100%;" svgID="orderDetailsIcon"></lib-svg-sprite> -->
    <p style="text-align: end;">
      <!-- First paragraph of info -->
    </p>
    <p style="text-align: end;">
      <!-- Second paragraph of info -->
    </p>
  </div>
  <div class="d-flex flex-row mb-4 mx-3">
    <div class="col individualLocationButtons">
      <lib-button
        *ngIf="location.supportsdispatch"
        class="delButMobile"
        [ghost]="true"
        large="true"
        text="Delivery"
        (buttonClick)="deliveryZone(content); deliveryLocation = location"
      >
      </lib-button>
    </div>
    <div class="col individualLocationButtons">
      <lib-button large="true" class="pickBut" text="Pickup" (buttonClick)="passOrderHeader(location.id, 'pickup')">
      </lib-button>
    </div>
  </div>

  <ng-template class="pup" #content let-modal>
    <div class="popupShell">
      <lib-delivery-zone-popup
        [restaurant]="location"
        (continue)="checkoutDeliveryAddress(outOfZone, modal, $event)"
        (xClicked)="modal.dismiss()"
      >
      </lib-delivery-zone-popup>
    </div>
  </ng-template>

  <ng-template class="pup" #outOfZone let-outOfZone>
    <lib-delivery-zone-fail
      (xClicked)="outOfZone.dismiss()"
      (deliveryOutOfRange)="outOfZone.dismiss(); passOrderHeader(location.id, 'pickup')"
    ></lib-delivery-zone-fail>
  </ng-template>
</div>
