<div class="d-flex flex-row flex-nowrap w-100">
  <div
    class="table-size-tab flex-grow-1"
    [ngClass]="{
      'table-size-left': i === 0,
      'table-size-right': i === tableSizeArray(maxTableSize).fill(1).length - 1,
      'selected-table-size-tab': i === selectedTableSize
    }"
    *ngFor="let num of tableSizeArray(maxTableSize).fill(1); index as i"
    (click)="tableSizeSelected(i)"
  >
    {{ i + 1 }}
  </div>
</div>
