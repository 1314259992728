export class GetRestaurants {
  static readonly type = '[Restaurant] Get Restaurants';
  constructor(public includePrivate?: boolean) {}
}

export class GetMenu {
  static readonly type = '[Restaurant] Get Menu';
  constructor(public payload: string) {}
}
export class GetRestaurantCapacity {
  static readonly type = '[Restaurant] Get Restaurant Capacity';
  constructor(public payload: string) {}
}
export class SetMenu {
  static readonly type = '[Restaurant] Set Menu';
  constructor(public payload: any) {}
}

export class SetLocation {
  static readonly type = '[Restaurant] Set Location';
  constructor(public payload: any) {}
}

export class SetAddress {
  static readonly type = '[Restaurant] Set Address';
  constructor(public payload: any) {}
}

export class UpdateDistance {
  static readonly type = '[Restaurant] Update Distance';
  constructor(public payload: any) {}
}

export class GetCalendars {
  static readonly type = '[Restaurant] Get Calendars';
  constructor(public payload: string, public check: boolean) {}
}

export class AddCalendars {
  static readonly type = '[Restaurant] Add Calendars';
  constructor(public payload: any) {}
}

export class CleanCalendars {
  static readonly type = '[Restaurant] Clean Calendars';
  constructor() {}
}

export class CheckDelivery {
  static readonly type = '[Restaurant] Check Delivery';
  constructor(public payload: number) {}
}

export class GetImages {
  static readonly type = '[Restaurant] Get Images';
  constructor() {}
}

export class GetSettings {
  static readonly type = '[Restaurant] Get Settings';
  constructor() {}
}

export class GetCMSLocations {
  static readonly type = '[Restaurant] Get CMS Locations';
  constructor() {}
}
