import { NgModule } from '@angular/core';

import { ProductComponent } from './product.component';
import { SharedModule } from '@app/shared';
import { ProductRoutingModule } from './product-routing.module';
import { CustomizeItemModule } from 'src/assets/chepri-modules/src/lib/customize-item/customize-item.module';
import { CoreModule } from '@app/core';
import { CommonModule } from '@angular/common';

@NgModule({
  declarations: [ProductComponent],
  imports: [SharedModule, CoreModule, CommonModule, ProductRoutingModule, CustomizeItemModule]
})
export class ProductModule {}
