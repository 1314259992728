import { Component, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'lib-logout-modal',
  templateUrl: './logout-modal.component.html',
  styleUrls: ['./logout-modal.component.scss']
})
export class LogoutModalComponent {
  @Output() xClicked = new EventEmitter<any>();
  @Output() returnClicked = new EventEmitter<any>();
  @Output() continueClicked = new EventEmitter<any>();

  title = 'Log Out';

  clickedX(): void {
    this.xClicked.emit();
  }

  clickedReturn(): void {
    this.returnClicked.emit();
  }

  clickedContinue(): void {
    this.continueClicked.emit();
  }
}
