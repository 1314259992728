import { State, Action, StateContext, Store } from '@ngxs/store';
import {
  GetRestaurants,
  SetMenu,
  GetMenu,
  GetCalendars,
  SetLocation,
  SetAddress,
  UpdateDistance,
  CheckDelivery,
  AddCalendars,
  CleanCalendars,
  GetImages,
  GetRestaurantCapacity,
  GetSettings,
  GetCMSLocations
} from '../actions/restaurant.action';
import { Restaurant } from 'src/assets/chepri-modules/src/models/olo.restaurant';
import { OloMenu } from 'src/assets/chepri-modules/src/models/olo.menu';
import { RestaurantCalendars } from 'src/assets/chepri-modules/src/models/olo.calendars';
import { RestaurantService } from '@app/providers/restaurant.service';
import { map, switchMap, tap } from 'rxjs/operators';
import { FormatService } from 'src/assets/chepri-modules/src/providers/format.service';
import { CmsService } from '@app/providers/cms.service';
import { UserStateModel } from '@app/store/state/user.state';
import { DirectusProvider } from '@app/providers/directus.provider';
import * as moment from 'moment';
import { DateTime } from 'src/assets/chepri-modules/src/models/DateTime';
import { GetTimeSlots } from '../actions/basket.action';
import { merge, forkJoin } from 'rxjs';
import { CapacityService } from '@app/providers/capacity.service';
import { Injectable } from '@angular/core';
import { Locations } from '@app/models/directus.collections';

export interface RestaurantStateModel {
  restaurants: Restaurant[];
  restaurant: Restaurant;
  menu: OloMenu;
  capacity_settings: Locations;
  cmsLocations: Locations[];
  currentOrders: any;
  calendars: any;
  calendar: any;
  location: any;
  address: any;
  distance: any;
  images: any;
  settings: any;
  enzoID: number;
}

@State<RestaurantStateModel>({
  name: 'restaurant',
  defaults: {
    restaurants: [],
    restaurant: null,
    menu: null,
    capacity_settings: null,
    cmsLocations: [],
    currentOrders: null,
    images: null,
    calendar: null,
    calendars: {
      name: null,
      calendar: null
    },
    location: null,
    address: null,
    distance: null,
    settings: null,
    enzoID: null
  }
})
@Injectable({
  providedIn: 'root'
})
export class RestaurantState {
  constructor(
    private restaurant: RestaurantService,
    private store: Store,
    private format: FormatService,
    private cms: CmsService,
    protected directus: DirectusProvider,
    private capacity: CapacityService
  ) {}

  formatStoreTime(res: any) {
    let locStoreTime = '';
    const storeTime = res.calendar;
    if (storeTime.length > 0) {
      const start = DateTime.fromOlo(storeTime[0].ranges[0].start).time;
      const end = DateTime.fromOlo(storeTime[0].ranges[0].end).time;
      locStoreTime = start + ' - ' + end;
    } else {
      locStoreTime = 'olo not set up yet';
    }
    return locStoreTime;
  }

  @Action(GetRestaurants)
  GetRestaurants({ patchState }: StateContext<RestaurantStateModel>, { includePrivate }: GetRestaurants) {
    return this.restaurant.getRestaurants(includePrivate).pipe(
      tap(res => {
        const restaurants = [...res.restaurants];
        patchState({ restaurants });
      })
    );
  }

  @Action(GetMenu)
  getMenu({ patchState, getState }: StateContext<RestaurantStateModel>, payload: any) {
    // dispatch(new SetLocation(restaurantId));
    return forkJoin(this.restaurant.getRestaurant(payload.payload), this.restaurant.getMenu(payload.payload)).pipe(
      tap(res => {
        const restaurant = res[0];
        const menu = res[1];
        patchState({
          restaurant,
          menu
        });
      })
    );
  }
  @Action(GetRestaurantCapacity)
  GetRestaurantCapacity(ctx: StateContext<RestaurantStateModel>, { payload }: { payload: any }) {
    return this.cms.getLocation(payload).pipe(
      map(location => {
        return ctx.patchState({
          capacity_settings: location
        });
        //
        // const settings: any = await this.directus.clearQueries().setItems('capacity_settings').setFilters({
        //   column: 'location.olo_id',
        //   comparison: 'eq',
        //   value: payload
        // }).setSingle(true).setFields('*.*.*').get();
        // if (settings.data) {
        //   ctx.setState({
        //     ...ctx.getState(),
        //     capacity_settings: settings.data
        //   });
        // }
      })
    );
  }

  @Action(SetMenu)
  SetMenu(context: StateContext<RestaurantStateModel>, newMenu: OloMenu) {
    const state = context.getState();
    context.patchState({
      menu: newMenu
    });
  }

  @Action(SetLocation)
  SetLocation(context: StateContext<RestaurantStateModel>, { payload }: SetLocation) {
    const state = context.getState();
    context.patchState({
      location: payload
    });
  }

  @Action(SetAddress)
  SetAddress(context: StateContext<RestaurantStateModel>, { payload }: SetAddress) {
    const state = context.getState();
    context.setState({
      ...state,
      address: payload
    });
  }

  @Action(CheckDelivery)
  CheckDelivery({ patchState, getState }: StateContext<RestaurantStateModel>, { payload }: any) {
    const state = getState();
    const location = this.store.selectSnapshot(RestaurantState).location;
    const temp = {
      restaurantId: payload,
      street: location.streetaddress,
      city: location.city,
      zip: location.zip
    };
    return this.restaurant.checkDelivery(temp).pipe(
      tap(res => {
        patchState({ ...res });
      })
    );
  }

  @Action(UpdateDistance)
  UpdateDistance({ patchState, getState, dispatch }: StateContext<RestaurantStateModel>, { payload }: UpdateDistance) {
    const distance = getState().restaurants[payload.index];
    distance.distance = payload.distance;
    patchState({ distance });
  }

  @Action(GetCalendars)
  GetCalendars({ patchState, getState }: StateContext<RestaurantStateModel>, payload: any) {
    return this.restaurant.getCalendars(payload.payload).pipe(
      tap(res => {
        if (payload.check) {
          const locStoreTime = this.formatStoreTime(res);
          patchState({
            calendar: locStoreTime
          });
        } else {
          patchState({
            calendar: res
          });
        }
      })
    );
  }

  @Action(AddCalendars)
  AddCalendars({ patchState, getState }: StateContext<RestaurantStateModel>, payload: any) {
    const name = payload.payload.name;
    return this.restaurant.getCalendars(payload.payload.id.toString()).pipe(
      tap(res => {
        const state = getState();
        if (state.calendars == null) {
          patchState({ calendars: [] });
        }
        const locStoreTime = this.formatStoreTime(res);

        const cals = {
          name,
          calendar: locStoreTime
        };
        patchState({
          calendars: [...state.calendars, cals]
        });
      })
    );
  }

  @Action(GetImages)
  GetImages({ patchState, getState }: StateContext<RestaurantStateModel>, payload: any) {
    return this.cms.getCMSProducts().pipe(
      tap(res => {
        patchState({ images: res });
      })
    );
  }

  @Action(GetSettings)
  GetSettings({ patchState, getState }: StateContext<RestaurantStateModel>) {
    return this.cms.getMainSettings().pipe(
      map(res => {
        return patchState({ settings: res });
      })
    );
  }

  @Action(GetCMSLocations)
  getCMSLocations(ctx: StateContext<RestaurantStateModel>, action: GetCMSLocations) {
    return this.cms.getCMSLocations().pipe(
      map(res => {
        return ctx.patchState({
          cmsLocations: res
        });
      })
    );
  }

  @Action(CleanCalendars)
  CleanCalendars({ patchState, getState }: StateContext<RestaurantStateModel>, payload: any) {
    patchState({
      calendars: []
    });
  }
}
