import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { IDirectusExpoOrder } from '@app/models/expo-order.interface';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { OrderStatusProduct } from '../../../assets/chepri-modules/src/models/olo.order';
import { debounceTime, distinctUntilChanged } from 'rxjs/operators';

@Component({
  selector: 'lib-order-details-modal',
  templateUrl: './refund-confirmation-modal.component.html',
  styleUrls: ['./refund-confirmation-modal.component.scss']
})
export class RefundConfirmationModalComponent implements OnInit, OnChanges {
  @Output() xClicked = new EventEmitter<any>();
  @Output() confirmClicked = new EventEmitter<{
    total: number;
    products: Array<OrderStatusProduct & { selected: boolean }>;
    refundTip: boolean;
  }>();
  @Input() order: IDirectusExpoOrder;
  @Input() guestName: string;
  @Input() readyTime: string;
  @Input() products: OrderStatusProduct[];
  @Input() total: number;
  @Input() tip: number;

  refundItemsForm: UntypedFormArray;
  refundAllForm: UntypedFormGroup = new UntypedFormGroup({
    refundAll: new UntypedFormControl(false)
  });

  refundTip = new UntypedFormControl(false);

  selectedTotal: number = 0;

  title = 'Refund Order';

  private productSelections: Array<OrderStatusProduct & { selected: boolean }> = [];

  ngOnInit() {
    this.populateFormArray();
    this.refundItemsForm.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(_ => (this.selectedTotal = this.getItemTotals()));
    this.refundAll.valueChanges
      .pipe(distinctUntilChanged())
      .subscribe(value => (value ? this.checkAndDisableItems() : this.enableItems()));
  }

  ngOnChanges() {
    this.populateFormArray();
  }

  refund(): void {
    this.confirmClicked.emit({
      total: this.getItemTotals() + (this.refundTip.value ? this.tip : 0),
      products: this.productSelections,
      refundTip: this.refundTip.value
    });
  }

  close(): void {
    this.xClicked.emit();
  }

  get refundAll(): AbstractControl {
    return this.refundAllForm.get('refundAll');
  }

  private populateFormArray() {
    this.refundItemsForm = new UntypedFormArray(this.products.map(product => new UntypedFormControl(false)));
  }

  private getItemTotals(): number {
    this.productSelections = [];
    this.products.forEach((product, j) => {
      this.productSelections.push({
        ...product,
        selected: !!this.refundItemsForm.value[j]
      });
    });
    let total = 0;
    if (this.productSelections.every(product => product.selected)) {
      if (!this.refundAll.value) {
        this.refundAll.patchValue(true);
      }
      total = this.total;
    } else {
      this.productSelections.forEach(product => {
        if (product.selected) {
          total += product.totalcost;
        }
      });
      total = total * 1.0825;
    }
    return total;
  }

  private checkAndDisableItems() {
    this.refundItemsForm.setValue(Array.from({ length: this.refundItemsForm.controls.length }, (_, i) => true));
    this.refundItemsForm.disable();
  }

  private enableItems() {
    this.refundItemsForm.setValue(Array.from({ length: this.refundItemsForm.controls.length }, (_, i) => false));
    this.refundItemsForm.enable();
  }
}
