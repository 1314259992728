import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { ConfirmRoutingModule } from './confirm-routing.module';
import { ConfirmComponent } from './confirm.component';

import { LocationInfoModule } from 'src/assets/chepri-modules/src/lib/location-info/location-info.module';
import { MapModule } from 'src/assets/chepri-modules/src/lib/map/map.module';
import { ButtonModule } from 'src/assets/chepri-modules/src/lib/button/button.module';
import { BagItemModule } from 'src/assets/chepri-modules/src/lib/bag-item/bag-item.module';

import { CheckoutBagModule } from '../checkout-bag/checkout-bag.module';

@NgModule({
  declarations: [ConfirmComponent],
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    Angulartics2Module,
    ConfirmRoutingModule,
    LocationInfoModule,
    MapModule,
    ButtonModule,
    CheckoutBagModule,
    BagItemModule
  ]
})
export class ConfirmPageModule {}
