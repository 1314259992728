<div
  class="product-bag-container px-3"
  style="text-align: center; height: 100%; overflow-y: auto;"
  *ngIf="mode !== 'cks'; else cksView"
>
  <div class="d-flex flex-row">
    <div class="col px-0" style="flex-direction: column">
      <div class="d-flex flex-row">
        <div class="col px-0">
          <div class="d-flex flex-row mb-3">
            <div
              class="col justify-content-center d-flex"
              style="flex-direction: column; background-color: white; border-radius: 4px; max-width: 400px; margin: auto;"
            >
              <div class="image-container product-bag-image" *ngIf="displayImage && product$ | async as product">
                <img class="cardimage" src="{{ product.url ? product.url : defaultImageUrl }}" />
              </div>
              <div class="d-flex flex-row">
                <div class="col" style="flex-direction: column" [class.p-3]="!displayImage">
                  <h5 [class.p-0]="!displayImage">{{ product.name }}</h5>
                  <p class="text-muted" [class.m-0]="!displayImage">
                    {{ product.description }}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div *ngIf="isEdit === false" class="d-flex flex-row mb-3 d-lg-block d-none">
            <div class="col justify-content-center d-flex" style="flex-direction: column">
              <lib-incrementor [startingValue]="productQuant" (valueChanged)="updateQuant($event)"></lib-incrementor>
            </div>
          </div>
          <div *ngIf="isEdit === false" class="d-flex flex-row mb-3 d-lg-none">
            <div class="col justify-content-center d-flex" style="flex-direction: column">
              <lib-incrementor [startingValue]="productQuant" [big]="true" (valueChanged)="updateQuant($event)">
              </lib-incrementor>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex flex-row">
        <div class="col px-0 mb-4">
          <div
            *ngIf="included.length"
            class="d-flex flex-row w-100 flex-wrap align-items-center mb-3 product-bag-included"
            style="padding: 0 15px"
          >
            <div class="w-100" style="text-align: left;">
              <div class="d-flex flex-row">
                <h5>Includes:</h5>
              </div>
            </div>
            <div *ngFor="let ingredient of included; index as i" class="col p-2" style="max-width: fit-content">
              <lib-pill
                text="{{ ingredient.name.length < 35 ? ingredient.name : ingredient.name.substring(0, 34) + '...' }}"
                [lineThrough]="ingredient.deselected || selectedItems[i]?.name?.includes('No ')"
                [extraItemName]="selectedItems[i]?.name.replace(ingredient.name, '')"
                (closed)="toggleIncluded(ingredient.id)"
                (showOptions)="showOptionsClick(i)"
                style="cursor: pointer"
              >
              </lib-pill>
            </div>
          </div>
          <div class="d-lg-block d-none">
            <ng-container *ngTemplateOutlet="addToOrder"></ng-container>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="d-flex flex-row d-lg-none add-to-cart-mobile py-3">
  <div class="col" style="margin-bottom: env(safe-area-inset-bottom, 0)">
    <ng-container *ngTemplateOutlet="addToOrderMobile"></ng-container>
  </div>
</div>

<ng-template #cksView>
  <section
    class="fg-0 fs-1 fb-0 align-self-stretch bg-white shadow-sm product-kiosk-fixed accessibility-transition"
    [ngClass]="{ isAccessibilityHeight: accessibilityMode }"
  >
    <div class="container">
      <div class="py-3">
        <div class="d-flex flex-row flex-nowrap w-100">
          <div class="d-flex flex-row flex-no-wrap align-items-center fg-1 fb-0 align-self-stretch h4 m-0 pe-3 py-3">
            <span class="h-fit-content fw-bold text-uppercase customize-product-title">{{ product.name }}</span>
            <span class="d-flex flex-row flex-no-wrap align-items-center"
              >&nbsp;–<span>&nbsp;{{ totalCost | currency }}</span></span
            >
          </div>
          <div class="d-flex align-items-center ms-auto gap-2">
            <div class="d-flex flex-row flex-nowrap">
              <button class="incrementer-btn" (click)="updateQuant(productQuant - 1 > 0 ? productQuant - 1 : 1)">
                <!-- <i class="bi bi-dash"></i> -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="currentColor"
                  class="bi bi-dash"
                  viewBox="0 0 16 16"
                >
                  <path d="M4 8a.5.5 0 0 1 .5-.5h7a.5.5 0 0 1 0 1h-7A.5.5 0 0 1 4 8z" />
                </svg>
              </button>
              <input class="incrementer-input" type="number" [value]="productQuant" />
              <button class="incrementer-btn" (click)="updateQuant(productQuant + 1)">
                <!-- <i class="bi bi-plus"></i> -->
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="26"
                  height="26"
                  fill="currentColor"
                  class="bi bi-plus"
                  viewBox="0 0 16 16"
                >
                  <path
                    d="M8 4a.5.5 0 0 1 .5.5v3h3a.5.5 0 0 1 0 1h-3v3a.5.5 0 0 1-1 0v-3h-3a.5.5 0 0 1 0-1h3v-3A.5.5 0 0 1 8 4z"
                  />
                </svg>
              </button>
            </div>
            <button class="btn pp-btn-primary text-white" style="min-width: 166px;" (click)="addToCart()">
              <div *ngIf="!isLoading">{{ isEdit ? 'UPDATE ORDER' : 'ADD TO ORDER' }}</div>
              <div *ngIf="isLoading" class="spin-loader"></div>
            </button>
          </div>
        </div>
      </div>
    </div>
  </section>
  <section class="fg-1 fb-0 align-self-stretch" *ngIf="included.length" style="margin-top: 91px;">
    <!-- outer accordion -->
    <div class="accordion" id="ingredientAccordions">
      <!-- included -->
      <div class="">
        <div class="bg-white border-bottom p-0" id="headingOne">
          <h2 class="mb-0">
            <button
              class="btn h4 fw-bold text-center text-uppercase d-flex flex-row flex-nowrap w-100 justify-content-center align-items-center m-0 p-4  gap-1"
              type="button"
              data-toggle="collapse"
              data-target="#collapseOne"
              aria-expanded="true"
              aria-controls="collapseOne"
              (panelChange)="testFunc()"
            >
              <div class="">Included</div>
              <!-- <i class="bi bi-caret-up-fill"></i> -->
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="16"
                height="16"
                fill="currentColor"
                class="bi bi-caret-down-fill"
                viewBox="0 0 16 16"
              >
                <path
                  d="M7.247 11.14 2.451 5.658C1.885 5.013 2.345 4 3.204 4h9.592a1 1 0 0 1 .753 1.659l-4.796 5.48a1 1 0 0 1-1.506 0z"
                />
              </svg>
            </button>
          </h2>
        </div>

        <!-- accordion 1 -->
        <div
          id="collapseOne"
          class="collapse container show mx-auto"
          aria-labelledby="headingOne"
          data-parent="#ingredientAccordions"
        >
          <!-- internal padding -->
          <div class="py-4">
            <!-- grid -->
            <div class="grid-4 gap-2">
              <!-- ingredient card -->
              <label
                *ngFor="let ingredient of included; index as i"
                id="ingredientCardBtn1"
                [ngClass]="{
                  'ingredient-card-btn-active': !(ingredient.deselected || selectedItems[i]?.name?.includes('No '))
                }"
                class="ingredient-card-btn"
              >
                <!-- checkbox -->
                <div class="w-100 d-flex justify-content-center pb-3">
                  <input
                    (click)="toggleIncluded(ingredient.id)"
                    id="ingredientCheck1"
                    type="checkbox"
                    [checked]="!(ingredient.deselected || selectedItems[i]?.name?.includes('No '))"
                  />
                </div>
                <div class="w-100 text-center h5 mb-3">
                  <div class="w-100 mb-1">
                    {{ ingredient.name }}
                    {{
                      selectedItems[i]?.name.replace(ingredient.name, '')
                        ? '(' + selectedItems[i]?.name.replace(ingredient.name, '') + ')'
                        : ''
                    }}
                  </div>
                </div>
                <div class="w-100 d-flex justify-content-center">
                  <button
                    (click)="showOptionsClick(i)"
                    type="button"
                    data-toggle="modal"
                    data-target="#modifyModal"
                    class="btn modify-btn"
                  >
                    Modify
                  </button>
                </div>
              </label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</ng-template>

<ng-template #addToOrder>
  <div class="d-flex flex-row mb-2">
    <div class="col" style="flex-direction: column" style="text-align: left">
      <p class="customizeProductPrice m-0 p-0 ps-2">{{ productQuant }}x {{ product.name }}</p>
    </div>
    <div class="col product-bag-subtotal" style="flex-direction: column">
      <p class="customizeProductPrice m-0 p-0 pe-2" style="float: right">{{ totalCost | currency }}</p>
    </div>
  </div>
  <div class="d-flex flex-row mb-2">
    <div class="col" style="flex-direction: column" style="text-align: left">
      <p class="m-0 p-0 ps-2 bold" style="font-size: 20px;">
        Subtotal
      </p>
    </div>
    <div class="col" style="flex-direction: column">
      <p class="m-0 p-0 pe-2 bold" style="font-size: 20px;">
        <span style="float: right" class="bold">{{ totalCost | currency }}</span>
      </p>
    </div>
  </div>
  <div class="d-flex flex-row mb-3">
    <ng-container *ngTemplateOutlet="saveProduct"></ng-container>
  </div>
  <div class="d-flex flex-row mb-2">
    <div class="col justify-content-center" style="display: flex; flex-direction: column">
      <lib-button
        large="true"
        [disabled]="zeroQuant"
        (buttonClick)="addToCart()"
        [text]="isEdit ? 'UPDATE ORDER' : 'ADD TO ORDER'"
        [isLoading]="isLoading"
      ></lib-button>
      <!--      <button [disabled]="zeroQuant" (click)="addToCart()" class="btn btn-primary text-white" style="width: 100%">-->
      <!--        {{ isEdit ? 'UPDATE ORDER' : 'ADD TO ORDER' }}-->
      <!--      </button>-->
    </div>
  </div>
</ng-template>

<ng-template #addToOrderMobile>
  <div class="d-flex flex-row mb-2">
    <ng-container *ngTemplateOutlet="saveProduct"></ng-container>
  </div>
  <div class="d-flex flex-row px-3 mb-2">
    <div class="col justify-content-center" style="display: flex" style="flex-direction: column">
      <lib-button
        large="true"
        [disabled]="zeroQuant"
        (buttonClick)="addToCart()"
        [text]="isEdit ? 'UPDATE ORDER' : 'ADD TO ORDER'"
        [isLoading]="isLoading"
      ></lib-button>
      <!--      <button [disabled]="zeroQuant" (click)="addToCart()" class="btn btn-primary text-white" style="width: 100%">-->
      <!--        {{ isEdit ? 'UPDATE ORDER' : 'ADD TO ORDER' }}-->
      <!--      </button>-->
    </div>
  </div>
</ng-template>

<ng-template class="pup" #content let-modal>
  <div class="popupShell">
    <a class="close" aria-label="Close" (click)="modal.dismiss()" style="text-align: right;">
      <div class="popup-x-modal" style="cursor: pointer"><lib-svg-sprite svgID="popup-x"></lib-svg-sprite></div>
    </a>
    <div class="popupHeader">
      Modify Ingredient
    </div>
    <div class="d-flex flex-row justify-content-center" style="width: 100%; margin-top: 30px; padding: 0 15px;">
      <div
        class="col"
        [ngClass]="{
          'modifier-tabs': mode !== 'cks',
          'modifier-tabs-kiosk': mode === 'cks',
          'left-modifer-item': i === 0,
          'right-modifier-item': i === editSelectedGroup.options.length - 1,
          'selected-submodifier': i === selectedSubModifier
        }"
        *ngFor="let group of editSelectedGroup.options; index as i"
        (click)="addCustomization(i)"
      >
        <div [class.modifier-text-kiosk]="mode === 'cks'">
          {{ group.name.replace(editSelectedGroup.description, '') }}
        </div>
      </div>
    </div>
    <div class="d-flex flex-row">
      <div class="col" style="padding-top: 40px; padding-bottom: 10px">
        <lib-button
          large="true"
          (buttonClick)="submitCustomization()"
          text="Save Changes"
          [isLoading]="isLoading"
        ></lib-button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #saveProduct>
  <ng-container *ngIf="user$ | async as userInfo">
    <form [formGroup]="saveProductForm" class="text-start">
      <label
        class="containerChk ms-4 text-start"
        style="display: grid;
    grid-template-columns: auto 1fr;
    grid-gap: 0.5rem;
    place-items: center;"
      >
        <input formControlName="saveProduct" type="checkbox" style="margin-bottom: 10px" />
        <span class="checkmarkChk">
          <lib-svg-sprite svgID="checkmarkIcon"></lib-svg-sprite>
        </span>
        <p class="modifierPriceText">
          Save this product as a favorite
        </p>
      </label>
    </form>
  </ng-container>
</ng-template>
