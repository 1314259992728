import { Component, OnInit } from '@angular/core';
import { Store } from '@ngxs/store';
import { ToggleAccessibility } from '@app/store/actions/app.action';

@Component({
  selector: 'app-accessibility-toggle',
  templateUrl: './accessibility-toggle.component.html',
  styleUrls: ['./accessibility-toggle.component.scss', '../customer-kiosk.component.scss']
})
export class AccessibilityToggleComponent {
  constructor(private store: Store) {}

  toggleAccessibility() {
    this.store.dispatch(new ToggleAccessibility());
  }
}
