import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Store } from '@ngxs/store';
import { DeleteAccount } from '@app/store/actions/user.action';
import { Router } from '@angular/router';

@Component({
  selector: 'app-delete-account-modal',
  templateUrl: './delete-account-modal.component.html',
  styleUrls: ['./delete-account-modal.component.scss']
})
export class DeleteAccountModalComponent {
  deleteLoading = false;

  constructor(private modalRef: NgbActiveModal, private store: Store, private router: Router) {}

  closeModal() {
    this.modalRef.close();
  }

  deleteAccount() {
    this.deleteLoading = true;
    this.store.dispatch(new DeleteAccount()).subscribe(() => {
      this.router.navigate(['locations']).then(() => {
        this.closeModal();
        this.deleteLoading = false;
      });
    });
  }
}
