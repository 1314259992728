import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngxs/store';
import { SetIncrementor } from '@app/store/actions/basket.action';

@Component({
  selector: 'lib-incrementor',
  templateUrl: './incrementor.component.html',
  styles: []
})
export class IncrementorComponent implements OnInit {
  @Input() startingValue: number;
  @Input() basket: any;
  @Input() big: boolean;
  @Input() max: number;
  @Input() displayValue: number;

  @Output() valueChanged = new EventEmitter<any>();
  currentValue: number;

  allowsNegative = false;

  constructor(private store: Store) {}

  ngOnInit() {
    this.currentValue = this.startingValue;
  }

  get disabled() {
    if (this.displayValue) {
      return this.displayValue >= this.max;
    } else {
      return this.currentValue >= this.max;
    }
  }

  onValueChange(modifier: number) {
    if (this.displayValue) {
      this.store.dispatch(new SetIncrementor(modifier));
      this.valueChanged.emit(this.currentValue + modifier);
    } else {
      this.currentValue += modifier;
      this.valueChanged.emit(this.currentValue);
    }
  }
}
