import { Component, Input, Output, EventEmitter } from '@angular/core';
import { BasketService } from '../../providers/basket.service';
import { BasketProductToAdd, BasketProduct } from '../../models/olo.basketproduct';
import { Store, Select } from '@ngxs/store';
import { RemoveProductFromBasket, UpdateSingleProductInBasket } from '@app/store/actions/basket.action';
import { ProductService } from '@app/providers/product.service';
import { Router } from '@angular/router';
import { Observable } from 'rxjs';
import { BasketStateModel } from '@app/store/state/basket.state';

@Component({
  selector: 'lib-bag',
  templateUrl: './bag.component.html',
  styles: []
})
export class BagComponent {
  @Select() basket$: Observable<BasketStateModel>;

  @Input() location: any;
  @Input() basket: any;
  @Input() orderInfoBag: boolean;
  @Output() checkout = new EventEmitter<any>();
  @Output() close = new EventEmitter<any>();

  productCount = 0;

  constructor(private store: Store, private productService: ProductService, private router: Router) {}

  updateProduct(product: BasketProduct) {
    if (product.quantity === 0) {
      this.store.dispatch(new RemoveProductFromBasket(product.id.toString()));
    } else {
      // Update product
      const newProduct = this.productService.convertBasketProductToBasketProductToAdd(product);
      this.store.dispatch(new UpdateSingleProductInBasket(product.id.toString(), newProduct, true));
    }
  }

  onCheckout() {
    this.checkout.emit();
  }

  onClose() {
    this.close.emit();
  }
  addMore() {
    this.router.navigateByUrl('/menu');
    this.close.emit();
  }
}
