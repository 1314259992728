import { Injectable } from '@angular/core';
import { BehaviorSubject, from, Observable } from 'rxjs';
import { DirectusHttpService } from '@app/providers/expo/directus/directus-http.service';
import {
  authentication,
  createDirectus,
  DirectusUser,
  readItem,
  readItems,
  readUsers,
  realtime,
  rest,
  staticToken
} from '@directus/sdk';
import { environment } from '@env/environment';
import { CustomerKiosk, DirectusSchema } from '@app/providers/expo/directus/directus-collections.interface';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class CustomerKioskService {
  kioskIdKey = 'customerKioskKey';
  kioskIdToken = '';
  kioskUserisAuthedKey = 'customerKioskUserKey';
  kioskUserisAuthed: boolean;

  private _client = createDirectus<DirectusSchema>(environment.cmsDomain)
    .with(staticToken(environment.kioskUserKey))
    .with(rest())
    .with(realtime());

  constructor(private directusHttp: DirectusHttpService) {
    this.kioskIdToken = this.getStoredToken();
    this.setKioskAuth(false);
  }

  readonly client = this._client;

  updateStoredToken(newToken: string) {
    this.kioskIdToken = newToken;
    localStorage.setItem(this.kioskIdKey, this.kioskIdToken);
  }

  getStoredToken(): string {
    return localStorage.getItem(this.kioskIdKey);
  }

  clearStoredToken() {
    localStorage.removeItem(this.kioskIdKey);
  }

  setKioskAuth(value: boolean) {
    if (!this.isAuthed()) {
      localStorage.setItem(this.kioskUserisAuthedKey, value.toString());
    }
  }

  isAuthed(): boolean {
    return localStorage.getItem(this.kioskUserisAuthedKey) === 'true';
  }

  getKioskInfo(kioskID: string): Observable<CustomerKiosk> {
    return from(
      this._client.request(
        readItems('customer_kiosk', {
          filter: {
            kiosk_id: { _eq: kioskID }
          },
          fields: ['*', { location: ['*'] }]
        })
      )
    ).pipe(map(res => res[0]));
    // return this.directusHttp.get(`items/customer_kiosk?fields=*.*&filter[kiosk_id][_eq]=` + kioskID);
  }

  getLocationTrancloudInfo(locationID: number): Observable<Partial<DirectusUser<DirectusSchema>>> {
    return from(
      this._client.request(
        readUsers({
          fields: ['location_trancloud_id', 'location_merchant_id', 'location_restaurant_id'],
          filter: {
            location_restaurant_id: { _eq: String(locationID) }
          }
        })
      )
    ).pipe(map(res => res[0]));
    // return this.directusHttp.get(
    //   `users?fields=location_trancloud_id,location_merchant_id,location_restaurant_id&filter[location_restaurant_id][_eq]=${locationID}`
    // );
  }
}
