import { NgModule } from '@angular/core';
import { MapComponent } from './map.component';
import { CommonModule } from '@angular/common';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import { Capacitor } from '@capacitor/core';
import { environment } from '../../../../../environments/environment';
import { LocationInfoModule } from '@lib/lib/location-info/location-info.module';

@NgModule({
  declarations: [MapComponent],
  imports: [
    CommonModule,
    SvgSpriteModule,
    NgxMapboxGLModule.withConfig({
      accessToken: Capacitor.getPlatform() !== 'ios' ? environment.mapboxKey : environment.mapboxIOSKey // Optional, can also be set per map (accessToken input of mgl-map)
    }),
    LocationInfoModule
  ],
  exports: [MapComponent]
})
export class MapModule {}
