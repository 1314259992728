import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { Title } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { from, merge } from 'rxjs';
import { filter, map, switchMap } from 'rxjs/operators';

import { environment } from '@env/environment';
import { Logger, I18nService, untilDestroyed, CredentialsService } from '@app/core';
import { Store } from '@ngxs/store';
import { GetImages, GetSettings } from './store/actions/restaurant.action';
// import { Plugins } from '@capacitor/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { AppUpdatingModalComponent } from '@app/components/app-updating-modal/app-updating-modal.component';
import { Capacitor } from '@capacitor/core';
import { codePush, InstallMode } from 'capacitor-codepush';
import { SyncStatus } from 'capacitor-codepush/dist/esm/syncStatus';
import { ToastrService } from 'ngx-toastr';
import { LocalNotifications } from '@capacitor/local-notifications';
import { NavigationService } from './providers/navigation.service';
import { App } from '@capacitor/app';
import { SetUser } from '@app/store/actions/user.action';
import { AppUpdate, AppUpdateAvailability } from '@capawesome/capacitor-app-update';

// const { LocalNotifications, SplashScreen } = Plugins;

const log = new Logger('App');

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, OnDestroy {
  private downloadModalRef: NgbModalRef;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translateService: TranslateService,
    private i18nService: I18nService,
    private store: Store,
    private modalService: NgbModal,
    private toastr: ToastrService,
    private routeNavigation: NavigationService,
    private credentialService: CredentialsService
  ) {
    if (Capacitor.getPlatform() === 'android') {
      this.performImmediateUpdate();
    }
    App.addListener('appStateChange', ({ isActive }: any) => {
      if (isActive) {
        from(this.credentialService.getUser()).subscribe((user: any) => {
          if (user) {
            this.store.dispatch(new SetUser(user));
          }
        });
      }
    });
  }

  ngOnInit() {
    // Setup logger
    if (environment.production) {
      Logger.enableProductionMode();
    }

    log.debug('init');

    this.activatedRoute.queryParams.subscribe(params => {
      if (params.rwg_token) {
        sessionStorage.setItem('rwg_token', params.rwg_token);
      }
    });

    // Setup translations
    this.i18nService.init(environment.defaultLanguage, environment.supportedLanguages);

    const onNavigationEnd = this.router.events.pipe(filter(event => event instanceof NavigationEnd));

    // SplashScreen.hide();

    if (Capacitor.getPlatform() !== 'web' && environment.production) {
      codePush.sync(
        {
          installMode: InstallMode.IMMEDIATE,
          updateDialog: false,
          mandatoryInstallMode: InstallMode.IMMEDIATE,
          onSyncStatusChanged: status => {
            switch (status) {
              case SyncStatus.UP_TO_DATE:
                break;
              case SyncStatus.DOWNLOADING_PACKAGE:
                if (this.downloadModalRef) {
                  this.downloadModalRef.componentInstance.status = 'Downloading Update';
                  this.downloadModalRef.componentInstance.receivedAmount = 0.25;
                  this.downloadModalRef.componentInstance.totalAmount = 1;
                } else {
                  if (this.modalService.hasOpenModals()) {
                    this.modalService.dismissAll();
                  }
                  this.downloadModalRef = this.modalService.open(AppUpdatingModalComponent, {
                    keyboard: false,
                    centered: true,
                    backdrop: 'static'
                  });
                  this.downloadModalRef.componentInstance.status = 'Downloading Update';
                  this.downloadModalRef.componentInstance.receivedAmount = 0.25;
                  this.downloadModalRef.componentInstance.totalAmount = 1;
                }
                break;
              case SyncStatus.INSTALLING_UPDATE:
                if (this.downloadModalRef) {
                  this.downloadModalRef.componentInstance.status = 'Installing Update';
                  this.downloadModalRef.componentInstance.receivedAmount = 0.75;
                  this.downloadModalRef.componentInstance.totalAmount = 1;
                }
                break;
              case SyncStatus.UPDATE_INSTALLED:
                if (this.downloadModalRef) {
                  this.downloadModalRef.componentInstance.status = 'Update Installed';
                  this.downloadModalRef.componentInstance.receivedAmount = 1;
                  this.downloadModalRef.componentInstance.totalAmount = 1;
                }
                break;
              case SyncStatus.ERROR:
                this.toastr.error('Download Error');
                if (this.downloadModalRef) {
                  this.downloadModalRef.dismiss();
                }
                break;
              case SyncStatus.CHECKING_FOR_UPDATE:
                break;
            }
          }
        },
        downloadProgress => {}
      );
    }

    // Change page title on navigation or language change, based on route data
    merge(this.translateService.onLangChange, onNavigationEnd)
      .pipe(
        map(() => {
          let route = this.activatedRoute;
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter(route => route.outlet === 'primary'),
        switchMap(route => route.data),
        untilDestroyed(this)
      )
      .subscribe(event => {
        const title = event.title;
        if (title) {
          this.titleService.setTitle(this.translateService.instant(title));
        }
      });
    this.store.dispatch(new GetImages());
    this.store.dispatch(new GetSettings());
  }

  ngOnDestroy() {
    this.i18nService.destroy();
  }

  private performImmediateUpdate = async () => {
    const result = await AppUpdate.getAppUpdateInfo();
    if (result.updateAvailability !== AppUpdateAvailability.UPDATE_AVAILABLE) {
      return;
    }
    if (result.immediateUpdateAllowed) {
      await AppUpdate.performImmediateUpdate();
    }
  };
}
