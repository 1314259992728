import { Component, Input } from '@angular/core';
import { Restaurant } from '../../models/olo.restaurant';
import { Store } from '@ngxs/store';
import { GetCalendars, AddCalendars } from '@app/store/actions/restaurant.action';
import { FormatService } from '../../providers/format.service';

@Component({
  selector: 'lib-location-info',
  templateUrl: './location-info.component.html',
  styles: []
})
export class LocationInfoComponent {
  @Input() location: Restaurant;
  @Input() locationMobile: boolean;
  @Input() isOrderConfirmation: boolean;
  @Input() isOrderReview?: boolean = false;
  locStoreTime: any;
  constructor(private store: Store, private format: FormatService) {}

  ngOnInit() {}
}
