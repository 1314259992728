import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';

@Injectable({
  providedIn: 'root'
})
export class DirectusProvider {
  domain = environment.cmsDomain;
  endPoint: any;
  queryBuilder: any = {};
  single = false;
  constructor(private http: HttpClient) {
    this.clearQueries();
  }
  setEndPoint(endPoint: string) {
    this.endPoint = endPoint;
    return this;
  }
  setSingle(isSingle: boolean) {
    this.single = isSingle;
    return this;
  }
  getSingle() {
    return this.single;
  }
  getEndPoint() {
    if (this.endPoint) {
      return this.endPoint;
    }
    return false;
  }
  clearQueries(): DirectusProvider {
    this.queryBuilder = {
      items: '',
      filters: [],
      fields: '*.*'
    };
    return this;
  }
  setItems(itemName: string): DirectusProvider {
    this.queryBuilder.items = itemName;
    return this;
  }
  setFilters(filter: any): DirectusProvider {
    this.queryBuilder.filters.push(filter);
    return this;
  }
  setFields(fields: string) {
    this.queryBuilder.fields = fields;
    return this;
  }
  buildQueryString() {
    let mainQuery = this.domain;
    if (this.getEndPoint()) {
      mainQuery = this.getEndPoint();
    }
    mainQuery += 'items/' + this.queryBuilder.items + '?';
    if (this.queryBuilder.filters.length) {
      for (const filter of this.queryBuilder.filters) {
        mainQuery += 'filter[' + filter.column + '][_' + filter.comparison + ']=' + filter.value + '&';
      }
    }
    mainQuery += 'fields=' + this.queryBuilder.fields;
    if (this.getSingle()) {
      mainQuery += '&single=true';
    }
    return mainQuery;
  }
  async get() {
    const mainQuery = this.buildQueryString();
    this.setSingle(false);
    return await this.http.get(mainQuery).toPromise();
  }
}
