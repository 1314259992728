import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { Store, Select } from '@ngxs/store';
import { GetRecentOrders } from '@app/store/actions/user.action';
import { UserStateModel } from '@app/store/state/user.state';
import { Observable } from 'rxjs';
import { SetBanner } from '@app/store/actions/app.action';
import { EmptyPreviousBasket } from '@app/store/actions/basket.action';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { Subscription, combineLatest } from 'rxjs';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  styleUrls: ['./account.component.scss']
})
export class AccountComponent implements OnInit {
  @Select() user$: Observable<UserStateModel>;
  @Input() accountItems = [
    {
      text: 'Account Details'
    },
    {
      text: 'Favorite Orders'
    },
    {
      text: 'Saved Credit Cards'
    }
  ];
  private subs: Subscription[] = [];

  @Output() itemClick = new EventEmitter<any>();
  selected = 0;
  constructor(private store: Store, private route: ActivatedRoute, private router: Router) {
    this.subs.push(
      combineLatest([this.route.params, this.route.queryParams]).subscribe(([pathParams, queryParams]) => {
        if (queryParams.fave) {
          this.selected = 1;
        }
      })
    );
  }

  clicked(index?: number) {
    this.selected = index;
    this.itemClick.emit(index);
  }

  ngOnInit() {
    this.store.dispatch(new SetBanner('ACCOUNT DETAILS', false, true, ''));
    this.store.dispatch(new EmptyPreviousBasket());
  }
}
