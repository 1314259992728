import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { UntypedFormBuilder, Validators, UntypedFormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { ModeService } from '@app/providers/mode.service';
import { DirectusExpoProvider } from '@app/providers/expo/directus/directus-expo.provider';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginModalComponent } from '@app/components/login-modal/login-modal.component';
import { Observable, Subscription } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Select } from '@ngxs/store';

@Component({
  selector: 'app-kiosk-expo-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {
  @ViewChild('logIn') logInModal: ElementRef;
  @Select((state: any) => state.user.role) role$: Observable<string>;
  @Select((state: any) => state.user.isMaster) isMaster$: Observable<number>;
  expoKioskLoginForm: UntypedFormGroup;
  errorMessage: string = null;
  loadingForm = false;

  private subs: Subscription[] = [];

  constructor(
    private expoService: DirectusExpoProvider,
    private fb: UntypedFormBuilder,
    private router: Router,
    private modeService: ModeService,
    private modalService: NgbModal,
    private toastr: ToastrService
  ) {
    this.modeService.setApplicationMode('kms');
  }

  ngOnInit() {
    this.expoKioskLoginForm = this.fb.group({
      emailAddress: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
      remember: true
    });
  }

  ngOnDestroy() {
    if (this.subs) {
      this.subs.forEach(sub => sub.unsubscribe());
    }
  }

  inputChanged() {
    this.errorMessage = '';
  }

  // 'admin@chepri.com', '*:=Z]wB(Uwa1;'
  emailAddress() {
    return this.expoKioskLoginForm.get('emailAddress');
  }

  password() {
    return this.expoKioskLoginForm.get('password');
  }

  loginModal() {
    if (this.loadingForm) {
      return;
    }
    this.loadingForm = true;
    const form = this.expoKioskLoginForm.value;
    this.expoService.loginExpoUser(form).subscribe(
      currentUser => {
        this.expoService.getLocationsByUserRef().subscribe((locationData: any) => {
          const loginModal = this.modalService.open(LoginModalComponent, {
            centered: true,
            keyboard: false,
            backdrop: 'static'
          });
          this.delayUnlock();
          if (currentUser.role === '07fbb946-5744-44d6-b805-302fd48b261f' || 'ce85129e-5e39-4701-9fc3-fccaaad55bd8') {
            loginModal.componentInstance.locationName =
              currentUser.role === '07fbb946-5744-44d6-b805-302fd48b261f'
                ? 'All Locations'
                : locationData[0].location_name;
            loginModal.componentInstance.xClicked.subscribe(() => {
              this.modalService.dismissAll();
              this.expoService.logoutExpoUser();
            });
            loginModal.componentInstance.changeLocationClicked.subscribe(() => {
              this.modalService.dismissAll();
              this.expoService.logoutExpoUser();
            });
            loginModal.componentInstance.confirmClicked.subscribe(() => {
              this.modalService.dismissAll();
              this.router.navigateByUrl('/kiosk/start-order');
            });
          } else {
            this.toastr.error(
              'You cannot sign into kiosk with a non-location user. Please try again with a location user.'
            );
            loginModal.close();
          }
        });
      },
      e => {
        this.delayUnlock();
        // {
        //   "errors": [
        //   {
        //     "message": "Invalid user credentials.",
        //     "extensions": {
        //       "code": "INVALID_CREDENTIALS"
        //     }
        //   }
        // ]
        // }

        const error: string[] = [];
        e.errors.forEach((er: { message: string }) => {
          error.push(er.message);
        });

        this.errorMessage = error.join('. ');
        this.toastr.error(this.errorMessage);

        // if (e && e.error && e.error.error && e.error.error.message) {
        //   this.toastr.warning(e.error.error.message);
        // }
      }
    );
  }

  delayUnlock() {
    setTimeout(() => {
      this.loadingForm = false;
    }, 1000);
  }
}
