<div class="formDiv">
  <div class="container formCont">
    <form [formGroup]="guestForm" (ngSubmit)="submitEvent()">
      <div class="d-flex flex-row">
        <div class="col-12">
          <p>{{ this.errorMessage }}</p>
        </div>
      </div>

      <div class="d-flex flex-row gap-3 flex-nowrap">
        <div class="col">
          <lib-single-text-field
            label="First Name"
            inputType="text"
            controlName="firstName"
            [parentForm]="guestForm"
            autoComplete="given-name"
            [errorMessage]="
              guestForm.get('firstName').touched && guestForm.get('firstName').invalid ? 'First name required.' : ''
            "
          >
          </lib-single-text-field>
        </div>
        <div class="col">
          <lib-single-text-field
            label="Last Name"
            inputType="text"
            controlName="lastName"
            autoComplete="family-name"
            [parentForm]="guestForm"
            [errorMessage]="
              guestForm.get('lastName').touched && guestForm.get('lastName').invalid ? 'Last name required.' : ''
            "
          >
          </lib-single-text-field>
        </div>
      </div>

      <div class="d-flex flex-row">
        <div class="col-12">
          <lib-single-text-field
            label="Email Address"
            inputType="email"
            controlName="emailAddress"
            autoComplete="email"
            [parentForm]="guestForm"
            [errorMessage]="
              guestForm.get('emailAddress').touched && guestForm.get('emailAddress').invalid
                ? 'Valid email required.'
                : ''
            "
            inputMode="email"
          >
          </lib-single-text-field>
        </div>
      </div>

      <div class="d-flex flex-row">
        <div class="col-12">
          <lib-single-text-field
            label="Phone Number"
            inputType="tel"
            inputMask="(000) 000-0000"
            controlName="phoneNumber"
            autoComplete="tel-national"
            [parentForm]="guestForm"
            [errorMessage]="
              guestForm.get('phoneNumber').touched && guestForm.get('phoneNumber').invalid
                ? 'Phone number required.'
                : ''
            "
            inputMode="tel"
          >
          </lib-single-text-field>
        </div>
      </div>

      <!--      <div class="d-flex flex-row">-->
      <!--        <div class="col-12 form-check">-->
      <!--          <input type="checkbox" formControlName="guestOptIn" class="bg-primary" /><label-->
      <!--            style="margin: 0px 20px; vertical-align: text-bottom;"-->
      <!--            >Send me special deals via email!</label-->
      <!--          >-->
      <!--          <br />-->
      <!--          <small class="text-emphasis"-->
      <!--            ><em-->
      <!--              >We will never share your contact information or use for anything else. You can unsubscribe at any-->
      <!--              time.</em-->
      <!--            ></small-->
      <!--          >-->
      <!--        </div>-->
      <!--      </div>-->

      <!-- TODO: create account from guest info -->
      <!-- <div class="d-flex flex-row" style="margin-bottom: 10px;">
        <div class="col-12">
          <input type="checkbox" style="margin-bottom: 10px" [disabled]="" />
          <span class="formBoxText">{{ createAccountOptInText }}</span>
        </div>
      </div>

      <div class="d-flex flex-row">
        <div class="col">
          <lib-single-text-field
            label="Password"
            inputType="password"
            controlName="password"
            [parentForm]="guestForm.get('passwords')"
            [errorMessage]="
              guestForm.get('passwords').get('confirm').touched && guestForm.get('passwords').invalid
                ? 'Passwords must match.'
                : ''
            "
          >
          </lib-single-text-field>
        </div>
        <div class="col">
          <lib-single-text-field
            label="Confirm Password"
            inputType="password"
            controlName="confirm"
            [parentForm]="guestForm.get('passwords')"
          >
          </lib-single-text-field>
        </div>
      </div> -->

      <div class="d-flex flex-row firstButton">
        <div class="col-12">
          <lib-button
            class="button"
            large="true"
            [text]="continueButtonText"
            buttonType="submit"
            [disabled]="!this.guestForm.valid"
          >
          </lib-button>
        </div>
      </div>

      <div class="bottomGroupLinks">
        <div class="d-flex flex-row">
          <div class="col-12">
            <lib-link class="link orangeLink" [text]="createAccountText" (linkClick)="clickEvent('sign-up')">
            </lib-link>
          </div>
        </div>

        <div class="d-flex flex-row">
          <div class="col-12">
            <lib-svg-sprite svgID="orGroup"></lib-svg-sprite>
          </div>
        </div>

        <div class="d-flex flex-row">
          <div class="col-12">
            <lib-link class="link orangeLink" [text]="signInText" (linkClick)="clickEvent('sign-in')"> </lib-link>
          </div>
        </div>
      </div>
    </form>
  </div>
</div>
