import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { rotateAnimation } from 'angular-animations';
import { interval, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  animations: [
    rotateAnimation({
      degrees: 720,
      duration: 1000
    })
  ],
  selector: 'app-app-updating-modal',
  templateUrl: './app-updating-modal.component.html',
  styleUrls: ['./app-updating-modal.component.scss']
})
export class AppUpdatingModalComponent implements OnInit, OnDestroy {
  @Input() receivedAmount: number;
  @Input() totalAmount: number;
  @Input() status: string;
  rotateState = false;

  private intervalSubject = new Subject<void>();

  constructor() {}

  ngOnInit(): void {
    interval(1500)
      .pipe(takeUntil(this.intervalSubject))
      .subscribe(() => (this.rotateState = !this.rotateState));
  }

  ngOnDestroy() {
    this.intervalSubject.next();
    this.intervalSubject.complete();
  }
}
