import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class TranCloudDeviceService {
  public tranDeviceID = 'PT7292160939'; // this default ID is the Chepri device
  private tranDeviceIDKey = 'currentLocation';

  updateStoredTranDeviceID(newTranDeviceID: string) {
    localStorage.setItem(this.tranDeviceIDKey, newTranDeviceID);
  }

  getStoredTranDeviceID(): string {
    return localStorage.getItem(this.tranDeviceIDKey);
  }

  clearStoredTranDeviceID() {
    localStorage.removeItem(this.tranDeviceIDKey);
  }
}
