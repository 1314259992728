import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { NavbarComponent } from './navbar.component';

import { AuthNavModule } from '../auth-nav/auth-nav.module';
import { LogoModule } from '../logo/logo.module';
import { CartNavModule } from '../cart-nav/cart-nav.module';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';
import { MenuSlideModule } from '../menu-slide/menu-slide.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { MyBagModule } from '@app/components/my-bag/my-bag.module';
import { ButtonModule } from '../button/button.module';
import { SharedModule } from '@app/shared';
import { LinkModule } from '@lib/lib/link/link.module';
import { RouterLink } from '@angular/router';

@NgModule({
  declarations: [NavbarComponent],
  imports: [
    CommonModule,
    AuthNavModule,
    LogoModule,
    CartNavModule,
    MyBagModule,
    SvgSpriteModule,
    MenuSlideModule,
    BrowserAnimationsModule,
    BrowserModule,
    NgbModule,
    ButtonModule,
    SharedModule,
    LinkModule,
    RouterLink
  ],
  exports: [NavbarComponent]
})
export class NavbarModule {}
