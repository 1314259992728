import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login.component';
import { LoginRoutingModule } from './login-routing.module';
import { SingleTextFieldModule } from 'src/assets/chepri-modules/src/lib/single-text-field/single-text-field.module';
import { ButtonModule } from 'src/assets/chepri-modules/src/lib/button/button.module';
import { ReactiveFormsModule } from '@angular/forms';
import { SharedModule } from '@app/shared';

@NgModule({
  declarations: [LoginComponent],
  imports: [CommonModule, LoginRoutingModule, SingleTextFieldModule, ButtonModule, ReactiveFormsModule, SharedModule]
})
export class LoginModule {}
