import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { ResetPasswordRoutingModule } from './reset-password-routing.module';
import { ResetPasswordComponent } from './reset-password.component';
import { SingleTextFieldModule } from 'src/assets/chepri-modules/src/lib/single-text-field/single-text-field.module';
import { ButtonModule } from 'src/assets/chepri-modules/src/lib/button/button.module';
import { ResetPasswordModalModule } from 'src/assets/chepri-modules/src/lib/reset-password-modal/reset-password-modal.module';

import { SignInFormModule } from 'src/assets/chepri-modules/src/lib/sign-in-form/sign-in-form.module';

@NgModule({
  declarations: [ResetPasswordComponent],
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    Angulartics2Module,
    ResetPasswordRoutingModule,
    SignInFormModule,
    SingleTextFieldModule,
    ButtonModule,
    ResetPasswordModalModule
  ]
})
export class ResetPasswordPageModule {}
