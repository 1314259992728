import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { Angulartics2Module } from 'angulartics2';

import { CoreModule } from '@app/core';
import { SharedModule } from '@app/shared';
import { LocationsRoutingModule } from './locations-routing.module';
import { LocationsComponent } from './locations.component';

import { LocationsModule } from 'src/assets/chepri-modules/src/lib/locations/locations.module';
import { SvgSpriteModule } from 'src/assets/chepri-modules/src/lib/svg-sprite/svg-sprite.module';
import { DeliveryZonePopupModule } from 'src/assets/chepri-modules/src/lib/delivery-zone-popup/delivery-zone-popup.module';

@NgModule({
  declarations: [LocationsComponent],
  imports: [
    CommonModule,
    TranslateModule,
    CoreModule,
    SharedModule,
    Angulartics2Module,
    LocationsRoutingModule,
    LocationsModule,
    SvgSpriteModule,
    DeliveryZonePopupModule
  ]
})
export class LocationsPageModule {}
