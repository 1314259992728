<div class="d-flex flex-column flex-lg-row" style="margin-top: calc(140px + env(safe-area-inset-top, 0))">
  <div class="col-lg-4 accountLeftCol" align="center">
    <div class="insideInfo">
      <p class="accountHeader leftAlign">MY ACCOUNT</p>
      <div class="accountItems">
        <div class="d-flex flex-row accountItem" *ngFor="let item of accountItems; index as i" (click)="clicked(i)">
          <div class="col-9 leftAlign noPaddingCol">
            <a
              href="javascript:void(0)"
              class="accountItemText"
              [ngStyle]="{ color: selected === i ? '#f04e29' : '#333' }"
            >
              {{ item.text }}
            </a>
          </div>
          <div class="col-3 noPaddingCol rightAlign">
            <a href="javascript:void(0)">
              <lib-svg-sprite svgID="forwardArrow"></lib-svg-sprite>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-4 accountLeftColMobile" align="center" *ngIf="selected == 0">
    <div class="insideInfo">
      <div class="accountItems">
        <div class="d-flex flex-row accountItem" *ngFor="let item of accountItems; index as i" (click)="clicked(i)">
          <div class="col-9 leftAlign noPaddingCol" *ngIf="i > 0">
            <a
              href="javascript:void(0)"
              class="accountItemText"
              [ngStyle]="{ color: selected === i ? '#f04e29' : '#333' }"
            >
              {{ item.text }}
            </a>
          </div>
          <div class="col-3 noPaddingCol rightAlign" *ngIf="i > 0">
            <a href="javascript:void(0)">
              <lib-svg-sprite svgID="forwardArrow"></lib-svg-sprite>
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="col-lg-8 col-md-12 accountRightCol">
    <lib-account-details *ngIf="selected == 0"></lib-account-details>
    <!--<lib-past-orders *ngIf="selected == 1" [hasPastOrders]="true"></lib-past-orders>-->
    <lib-favorite-orders *ngIf="selected == 1" [hasFavoriteOrders]="true"></lib-favorite-orders>
    <lib-saved-cards *ngIf="selected == 2"></lib-saved-cards>
  </div>
</div>
