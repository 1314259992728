<p *ngIf="!locationMobile && !isOrderConfirmation" style="padding: 0; margin: 0" class="locationInfo">
  <b class="bold">{{ location.name }}</b>
  <br />
  {{ location.streetaddress }}
  <br />
  {{ location.city }}, {{ location.state }} {{ location.zip }}
  <br />
  {{ location.telephone }}
  <br />
  <span
    class="badge badge-closed boldText text-white text-uppercase"
    *ngIf="!location.iscurrentlyopen && !isOrderReview"
    >Closed</span
  >
</p>

<div *ngIf="locationMobile" style="padding: 0; margin: 0" class="locationInfoMobile">
  <b class="bold">{{ location.name }}</b>
  <br />
  <div class="locationAddress">
    {{ location.streetaddress }}
    <br />
    {{ location.city }}, {{ location.state }} {{ location.zip }}
  </div>
  <span
    class="badge badge-closed boldText text-white text-uppercase"
    *ngIf="!location.iscurrentlyopen && !isOrderReview"
    >Closed</span
  >
</div>

<p *ngIf="isOrderConfirmation" style="padding: 0; margin: 0" class="locationInfoMobile">
  <b class="bold">{{ location.name }}</b>
  <br />
  {{ location.streetaddress }}
  <br />
  {{ location.city }}, {{ location.state }} {{ location.zip }}
  <br />
  {{ location.telephone }}
</p>
