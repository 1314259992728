import { NgModule } from '@angular/core';
import { OloKioskMenuComponent } from './olo-menu.component';
import { CommonModule } from '@angular/common';
import { SubNavModule, ProductCardModule } from 'src/assets/chepri-modules/src/public-api';
import { ServicesModule } from '@app/providers/services.module';
import { SvgSpriteModule } from 'src/assets/chepri-modules/src/lib/svg-sprite/svg-sprite.module';
import { KioskCardModule } from 'src/assets/chepri-modules/src/lib/kiosk-card/kiosk-card.module';

@NgModule({
  declarations: [OloKioskMenuComponent],
  imports: [CommonModule, SubNavModule, ProductCardModule, KioskCardModule, ServicesModule, SvgSpriteModule],
  exports: [OloKioskMenuComponent]
})
export class OloKioskMenuModule {}
