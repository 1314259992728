import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PrinterDeviceService {
  public printerIPAddress = '0.0.0.0';
  private printerIPAddressKey = 'printerIPAddress';

  constructor() {
    const storedAddress = this.getStoredPrinterIPAddress();
    if (storedAddress) {
      this.printerIPAddress = storedAddress;
    }
  }

  updateStoredPrinterIPAddress(newPrinterIPAddress: string) {
    this.printerIPAddress = newPrinterIPAddress;
    localStorage.setItem(this.printerIPAddressKey, newPrinterIPAddress);
  }

  getStoredPrinterIPAddress(): string {
    return localStorage.getItem(this.printerIPAddressKey);
  }

  clearStoredPrinterIPAddress() {
    localStorage.removeItem(this.printerIPAddressKey);
  }
}
