import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SavedCardsComponent } from './saved-cards.component';
import { ButtonModule } from '../button/button.module';
import { LinkModule } from '../link/link.module';
import { IconModule } from '../icon/icon.module';
import { SvgSpriteModule } from '../svg-sprite/svg-sprite.module';
import { SharedModule } from '@app/shared';

@NgModule({
  declarations: [SavedCardsComponent],
  imports: [CommonModule, ButtonModule, LinkModule, IconModule, SvgSpriteModule, SharedModule],
  exports: [SavedCardsComponent]
})
export class SavedCardsModule {}
