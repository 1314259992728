import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Message } from '@app/models/cms.messages';
import { DomSanitizer } from '@angular/platform-browser';
import { IDirectusExpoOrder } from '@app/models/expo-order.interface';

@Component({
  selector: 'lib-fire-modal',
  templateUrl: './fire-modal.component.html',
  styleUrls: ['./fire-modal.component.scss']
})
export class FireModalComponent implements OnInit {
  @Input() guestName: string;
  @Input() partySize: string;
  @Input() readyTime: string;
  @Output() xClicked = new EventEmitter<any>();
  @Output() fireClicked = new EventEmitter<any>();

  tableNumber: string;
  title = '';
  actionText = '';
  constructor(private sanitizer: DomSanitizer) {}

  ngOnInit() {}

  close(): void {
    this.xClicked.emit();
  }

  fireOrder() {
    this.fireClicked.emit(this.tableNumber);
  }
}
